import Home from './pages/Module_1_Home/page/Home';
import Residential from './pages/Module_2_Residential_Commercial/residential/page/Residential';
import CommercialIndustrial from './pages/Module_2_Residential_Commercial/commercial/page/CommericalIndustrial';
import About from './pages/Module_3_About/page/About';
import Portfolios from './pages/Module_4_Portfolios/page/Portfolios';
import Services from './pages/Module_5_Services/page/Services';
import NewsEvents from './pages/Module_6_News_Events/page/NewsEvents';
import ProposalOverviewResidential from './pages/Module_2_Residential_Commercial/residential/page/ProposalOverviewResidential';
import SolarInvestmentCalculator from './pages/Module_2_Residential_Commercial/residential/page/SolarInvestmentCalculator';
import FillUpDetailResidential from './pages/Module_2_Residential_Commercial/residential/page/FillUpDetailResidential';
import PrivacyPolicyPage from './pages/Module_7_Privacy_Policy/page/PrivacyPolicyPage';
import DetailPageResidentialPortfolios from './pages/Module_4_Portfolios/residential/page/DetailPageResidentialPortfolios';
import DetailPageCommercialPortfolios from './pages/Module_4_Portfolios/commercial/page/DetailPageCommercialPortfolios';
import NewsEventDetailPage from './pages/Module_6_News_Events/page/NewsEventDetailPage';
import FillUpDetailCommercial from './pages/Module_2_Residential_Commercial/commercial/page/FillUpDetailCommercial';
import SolarInvestmentCalculatorCommercial from './pages/Module_2_Residential_Commercial/commercial/page/SolarInvestmentCalculatorCommercial';
import CareersPage from './pages/Module_9_Career/page/CareersPage';
import ScopeQMSPage from './pages/Module_10_QMS_Quality_Policy/page/ScopeQMSPage';
import QualityPolicyPage from './pages/Module_10_QMS_Quality_Policy/page/QualityPolicyPage';
import ComingSoon from './pages/Module_11_Testimonial/page/ComingSoonPage';
import ProsperityDragonDanceTnC from './pages/Module_6_News_Events/ProsperityDragonDanceTnC';

export enum Path {
    HOME = '/',
    RESIDENTIAL = '/residential/:id?',
    COMMERCIAL_INDUSTRIAL = '/commercial-industrial/:id?',
    ABOUT = '/about',
    PORTFOLIOS = '/portfolios',
    SERVICES = '/services',
    NEWS_EVENTS = '/news-events',
    CAREERS = '/careers',
    FILLUPDETAILRESIDENTIAL = '/fill-up-detail-residential',
    FILLUPDETAILCOMMERCIAL = '/fill-up-detail-commercial',
    SOLARINVESTIMENTCALCULATOR = '/solar-investment-calculator-residential',
    SOLARINVESTIMENTCALCULATORCOMMERCIAL = '/solar-investment-calculator-commercial',
    PROPOSALOVERVIEW = '/proposal-overview/:id?',
    POLICY = '/privacy-policy',
    QMS = '/scope-qms',
    QUALITY = '/quality-policy',
    DETAILRESIDENTIALPORTFOLIOS = '/residential-portfolios',
    DETAILCOMMERCIALPORTFOLIOS = '/commercial-portfolios',
    NEWS_EVENTS_DETAIL = '/news-events/:id',
    TESTIMONIALS = '/testimonials/:id',
    MIDA = '/malaysian-investment-development-authority',
    PROSPERITY_TNC = '/terms-and-conditions'
}

export const Routes = [
    {
        path: Path.HOME,
        exact: true,
        main: () => <Home />
    },
    {
        path: Path.RESIDENTIAL,
        exact: true,
        main: () => <Residential />
    },
    {
        path: Path.COMMERCIAL_INDUSTRIAL,
        main: () => <CommercialIndustrial />
    },
    {
        path: Path.ABOUT,
        main: () => <About />
    },
    {
        path: Path.PORTFOLIOS,
        main: () => <Portfolios />
    },
    {
        path: Path.SERVICES,
        main: () => <Services />
    },
    {
        path: Path.NEWS_EVENTS,
        main: () => <NewsEvents />
    },
    {
        path: Path.FILLUPDETAILRESIDENTIAL,
        main: () => <FillUpDetailResidential />
    },
    {
        path: Path.FILLUPDETAILCOMMERCIAL,
        main: () => <FillUpDetailCommercial />
    },
    {
        path: Path.SOLARINVESTIMENTCALCULATOR,
        main: () => <SolarInvestmentCalculator />
    },
    {
        path: Path.SOLARINVESTIMENTCALCULATORCOMMERCIAL,
        main: () => <SolarInvestmentCalculatorCommercial />
    },
    {
        path: Path.PROPOSALOVERVIEW,
        main: (props: any) => <ProposalOverviewResidential {...props} />
    },
    {
        path: Path.POLICY,
        main: () => <PrivacyPolicyPage />
    },
    {
        path: Path.CAREERS,
        main: () => <CareersPage />
    },
    {
        path: Path.QMS,
        main: () => <ScopeQMSPage />
    },
    {
        path: Path.QUALITY,
        main: () => <QualityPolicyPage />
    },
    {
        path: Path.DETAILRESIDENTIALPORTFOLIOS,
        main: () => <DetailPageResidentialPortfolios />
    },
    {
        path: Path.DETAILCOMMERCIALPORTFOLIOS,
        main: () => <DetailPageCommercialPortfolios />
    },
    {
        path: Path.NEWS_EVENTS_DETAIL,
        main: (props: any) => <NewsEventDetailPage  {...props} />
    },
    {
        path: Path.TESTIMONIALS,
        main: (props: any) => <ComingSoon  {...props} />
    },
    {
        path: Path.MIDA,
        main: () => <ComingSoon />
    },
    {
        path: Path.PROSPERITY_TNC,
        main: () => <ProsperityDragonDanceTnC />
    },
];