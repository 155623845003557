import React, { useState } from 'react';
import './ResidentialBanner.scss';
// import SCMRA from '../../../images/sc-mr-a.png';
// import MiniCarousel from 'react-material-ui-carousel';
import { Button, Popover, Typography, Paper } from '@material-ui/core';
// import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import A from "../../../images/residence-A.png";
import B from "../../../images/residence-B.png";
import C from "../../../images/residence-C.png";
import D from "../../../images/residence-D.png";
import E from "../../../images/residence-E.png";
import Residence from "../../../images/residence-blue.png";
import X from "../../../images/x-black.svg"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";



// class ResidentialBanner extends React.Component {
//     state = {
//         currentIndex: 0
//     }

//     render() {

function ResidentialBanner() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [itemClicked, setItemClicked] = useState("")

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, value: string) => {
        setItemClicked(value)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };


    //     const displayContent = [
    //     (<div key="0" className="feedback-content">
    //         <div className="feedback-comments">
    //             <label className="heading">“</label><br />
    //             <label className="description">Previously, my parents and I were using electricity sparingly because we were worried of the high electricity bills. After electrical appliances for my parents to make our house more comfortable. Now, we are able to optimise our electricity consumption to create a quality green lifestyle without sacrificing our comfortability. This investment brings joy to my parents and I hope that you all can walk this green journey together with us.”</label><br />
    //             <br />
    //             <label className="who">Mr. A</label>
    //         </div>
    //         <div className="feedback-images">
    //             <img src={SCMRA} alt="Mr. A feedback" />
    //         </div>
    //     </div>),
    //     (<div key="1" className="feedback-content">
    //         <div className="feedback-comments">
    //             <label className="heading">“</label><br />
    //             <label className="description">Previously, my parents and I were using electricity sparingly because we were worried of the high electricity bills. After electrical appliances for my parents to make our house more comfortable. Now, we are able to optimise our electricity consumption to create a quality green lifestyle without sacrificing our comfortability. This investment brings joy to my parents and I hope that you all can walk this green journey together with us.”</label><br />
    //             <br />
    //             <label className="who">Mr. B</label>
    //         </div>
    //         <div className="feedback-images">
    //             <img src={SCMRA} alt="Mr. B feedback" />
    //         </div>
    //     </div>)
    // ]

    return (
        <div className="residential-banner">
            {/* <div className="feedback-section">
                    <MiniCarousel
                        swipe={false}
                        autoPlay={false}
                        animation="slide"
                        indicators={false}
                        navButtonsAlwaysVisible={true}
                        onChange={(currentIndex: number) => this.setState({ ...this.state, currentIndex })}
                        NavButton={({ onClick, style, next, prev }) => {
                            if (prev) {
                                return (
                                    <div onClick={this.state.currentIndex === 0 ? undefined : onClick as any} className={[
                                        this.state.currentIndex === 0 ? 'feedback-nav-buttons-disabled' : 'feedback-nav-buttons-active',
                                        'feedback-nav-buttons' 
                                    ].join(' ')} style={style}>
                                        <IconButton color="default" className="nav-icon" component="span">
                                            <ChevronLeft className="svg-icon" />
                                        </IconButton>
                                    </div>
                                )
                            }
                            
                            if (next) {
                                return (
                                    <div onClick={this.state.currentIndex === displayContent.length - 1 ? undefined : onClick as any} className={[
                                        this.state.currentIndex === displayContent.length - 1 ? 'feedback-nav-buttons-disabled' : 'feedback-nav-buttons-active',
                                        'feedback-nav-buttons' 
                                    ].join(' ')} style={style}>
                                        <IconButton color="default" className="nav-icon" component="span">
                                            <ChevronRight className="svg-icon" />
                                        </IconButton>
                                    </div>
                                )
                            }

                            return (<div>&nbsp;</div>)
                        }}
                    >
                        { displayContent }
                    </MiniCarousel>
                </div> */}
            <div className="bottom-explaination">
                <div className="left-description">
                    <label className="title">HOW DOES SOLAR WORK?</label><br />
                    <label className="sub-title">NEM 3.0 Rakyat</label><br />
                    <label className="description">
                        The energy generated by NEM consumers will be consumed first which implies that less energy will be imported from the Distribution Licensee (DL). In many countries, the NEM scheme is effective to hedge against fluctuation or increase in electricity tariff in the future. This is especially relevant for consumers that fall under the high electricity tariff block.
                        <br /><br />
                        Under this program, any excess energy generated will be exported to the utility grid and will be paid on a “one-on-one” offset basis. The priority is for self-consumption, however most of domestic consumers may not be at home during the weekdays and may have excess energy exported to the grid. The credit shall be allowed to roll over for a maximum of 12 months.
                    </label>
                </div>
                <div id="horizontal-scroll" className="right-node">
                    <div className="content click-show" >
                        <img style={{ width: "inherit", height: "inherit" }} src={Residence} alt="Residential" />
                        <Button
                            aria-owns={id}
                            size="small"
                            variant="contained"
                            className="button buttonA"
                            onClick={(event: any) => handleClick(event, "A")}
                        >A</Button>
                        <Popover
                            id={id}
                            open={open && itemClicked === "A"}
                            anchorEl={anchorEl}
                            onClick={handleClose}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Paper style={{ padding: "10px" }} className="residential-mini-bar">
                                <Typography className="close-button-bar"><a style={{ cursor: "pointer" }} onClick={handleClose}><img src={X} className="x-image" alt="" /></a></Typography>
                                <Paper style={{ marginTop: "30px", height: "380px" }} elevation={0}>
                                    <Typography className="content-title"><span className="big-title">A.</span> Solar Panel</Typography>
                                    <Typography className="content-description">Sunlight falls on high capacity solar panels during daylight hours.
                                        the solar panels convert the sun's energy into Direct Current(DC) electricity which is sent to an inverter</Typography>
                                </Paper>
                            </Paper>
                        </Popover>

                        <Button
                            size="small"
                            variant="contained"
                            className="button buttonB"
                            onClick={(event: any) => handleClick(event, "B")}
                        >B</Button>
                        <Popover
                            id={id}
                            open={open && itemClicked === "B"}
                            anchorEl={anchorEl}
                            onClick={handleClose}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Paper style={{ padding: "10px" }} className="residential-mini-bar">
                                <Typography className="close-button-bar"><a style={{ cursor: "pointer" }} onClick={handleClose}><img src={X} className="x-image" alt="" /></a></Typography>
                                <Paper style={{ marginTop: "30px", height: "380px" }} elevation={0}>
                                    <Typography className="content-title"><span className="big-title">B.</span> Inverter</Typography>
                                    <Typography className="content-description">The inverter converts the Direct Current into Alternating Current (AC) electricity.
                                        <br /><br /> This is sometimes called "conditioning" the power.</Typography>
                                </Paper>
                            </Paper>
                        </Popover>

                        <Button
                            size="small"
                            variant="contained"
                            className="button buttonC"
                            onClick={(event: any) => handleClick(event, "C")}
                        >C</Button>
                        <Popover
                            id={id}
                            open={open && itemClicked === "C"}
                            anchorEl={anchorEl}
                            onClick={handleClose}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Paper style={{ padding: "10px" }} className="residential-mini-bar">
                                <Typography className="close-button-bar"><a style={{ cursor: "pointer" }} onClick={handleClose}><img src={X} className="x-image" alt="" /></a></Typography>
                                <Paper style={{ marginTop: "30px", height: "380px" }} elevation={0}>
                                    <Typography className="content-title"><span className="big-title">C.</span> Electricity Box</Typography>
                                    <Typography className="content-description">The electricity then passes through the electricity box to outlets in the
                                        building and the items can plug into the outlets of power.</Typography>
                                </Paper>
                            </Paper>
                        </Popover>

                        <Button
                            size="small"
                            variant="contained"
                            className="button buttonD"
                            onClick={(event: any) => handleClick(event, "D")}
                        >D</Button>
                        <Popover
                            id={id}
                            open={open && itemClicked === "D"}
                            anchorEl={anchorEl}
                            onClick={handleClose}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Paper style={{ padding: "10px" }} className="residential-mini-bar">
                                <Typography className="close-button-bar"><a style={{ cursor: "pointer" }} onClick={handleClose}><img src={X} className="x-image" alt="" /></a></Typography>
                                <Paper style={{ marginTop: "30px", height: "380px" }} elevation={0}>
                                    <Typography className="content-title"><span className="big-title">D.</span> Meter</Typography>
                                    <Typography className="content-description">When the solar energy system produces more electricity than is needed duringpeak sun hours, excess
                                        electricity is automatically sent to utility company and it runs backwards!</Typography>
                                </Paper>
                            </Paper>
                        </Popover>

                        <Button
                            size="small"
                            variant="contained"
                            className="button buttonE"
                            onClick={(event: any) => handleClick(event, "E")}
                        >E</Button>
                        <Popover
                            id={id}
                            open={open && itemClicked === "E"}
                            anchorEl={anchorEl}
                            onClick={handleClose}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Paper style={{ padding: "10px" }} className="residential-mini-bar">
                                <Typography className="close-button-bar"><a style={{ cursor: "pointer" }} onClick={handleClose}><img src={X} className="x-image" alt="" /></a></Typography>
                                <Paper style={{ marginTop: "30px", height: "380px" }} elevation={0}>
                                    <Typography className="content-title"><span className="big-title">E.</span> Utility Pole</Typography>
                                    <Typography className="content-description">Utility power is continuously provided at night and during the day when demand exceeds solar
                                        production.</Typography>
                                </Paper>
                            </Paper>
                        </Popover>

                    </div>
                    <div className="content slideshow">
                        <Carousel infiniteLoop useKeyboardArrows autoPlay>
                            <div>
                                <img src={A} alt="" />
                            </div>
                            <div>
                                <img src={B} alt="" />
                            </div>
                            <div>
                                <img src={C} alt="" />
                            </div>
                            <div>
                                <img src={D} alt="" />
                            </div>
                            <div>
                                <img src={E} alt="" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResidentialBanner;