import Logo from "../../images/tera-logo-white-cropped.png";
import "./FooterOurLocation.scss";
import { Grid, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import facebook from "../../images/facebook.svg";
import mail from "../../images/envelope.svg";
import location from "../../images/geo-alt.svg";
import phone from "../../images/telephone.svg";

function FooterOurLocation() {
  let history = useHistory();
  const handleClick = (page: string) => {
    if (page === "privacy") {
      history.push({
        pathname: `/privacy-policy`,
      });
    } else if (page === "qms") {
      history.push({
        pathname: `/scope-qms`,
      });
    } else if (page === "quality") {
      history.push({
        pathname: `/quality-policy`,
      });
    }
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer-our-location">
      <div className="location">
        <div className="address">
          <div>
            <img className="logo" src={Logo} alt="tera-logo" />
          </div>
          <div>
            <span className="description ">
              <Grid container spacing={2} className="footer-container">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="info-container__content"
                >
                  <Box>
                    <label className="footer-title">CONTACT US</label>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="info-container__content"
                >
                  <Box>
                    <img
                      src={location}
                      alt="location"
                      className="footer-icon"
                    />
                  </Box>
                  <Box
                    component="span"
                    className="info-container__content__box"
                  >
                    <span>Lot 873, Jalan Subang 8, </span>
                    <span>Taman Perindustrian Subang, </span>
                    <span>47620 Subang Jaya,</span>
                    <span>Selangor Darul Ehsan. Malaysia.</span>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="info-container__content"
                >
                  <Box>
                    <img src={phone} alt="phone" className="footer-icon" />
                  </Box>
                  <Box
                    component="span"
                    className="info-container__content__box"
                  >
                    <span>Tel: +603 - 6151 8236 (General Line) </span>
                    <span>Tel: +6019 - 750 2386 (Sales & Marketing)</span>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="info-container__content"
                >
                  <Box>
                    <img src={mail} alt="phone" className="footer-icon" />
                  </Box>
                  <Box
                    component="span"
                    className="info-container__content__box"
                  >
                    <span>Email: enquiry@terava-group.com</span>
                  </Box>
                </Grid>
              </Grid>
            </span>
          </div>
        </div>

        <div className="connect-with-us">
          <Grid container spacing={2} className="footer-container">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="info-container__content"
            >
              <Box>
                <label className="footer-title">CONNECT WITH US</label>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="info-container__content"
            >
              <Box>
                <a href="https://www.facebook.com/vasolar.pv" target="_blank">
                  <img src={facebook} alt="" />
                </a>
              </Box>
            </Grid>
          </Grid>
        </div>

        <div className="google-maps">
          <div className="map-route">
            <div className="map">
              <div className="map-detail">
                <iframe
                  title="googlemap"
                  width="400"
                  height="370"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Vasolar&t=&z=15&ie=UTF8&embed=&output=embed"
                ></iframe>
                <a href="https://fmovies-to.com"></a>
                <br />
                <a href="https://www.embedgooglemap.net"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="bottom-line" />
      <div className="copyright">
        <div className="copyright-content">
          <label className="company-name">
            Copyright © 2024 by TERA VA SDN BHD 201101001113 (929247-P)
          </label>
          <label className="pipe">|</label>
          <label>
            <a
              onClick={() => handleClick("privacy")}
              style={{
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Privacy Policy
            </a>
          </label>
          <label className="pipe">|</label>
          <label>
            <a
              onClick={() => handleClick("qms")}
              style={{
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Scope Of QMS
            </a>
          </label>
          <label className="pipe">|</label>
          <label>
            <a
              onClick={() => handleClick("quality")}
              style={{
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Quality Policy
            </a>
          </label>
        </div>
      </div>
    </div>
  );
}

export default FooterOurLocation;
