import React from 'react';
import { Collapse, SwipeableDrawer } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import { NavLink } from 'react-router-dom';
import './SideDrawer.scss';
import Logo from '../../images/tera-logo-full-colour.png'
import { ExpandLess, ExpandMore } from '@material-ui/icons';

class SideDrawer extends React.Component {
    state = {
        isOpen: false,
        open: false
    }
    render() {
        // const [open, setOpen] = React.useState(true);

        const handleClick = () => {
            this.setState({ ...this.state, open: !this.state.open });
        };
        return (
            <div className="side-drawer">
                <IconButton color="default" component="span" onClick={() => this.setState({ ...this.state, isOpen: !this.state.isOpen })}>
                    <Menu />
                </IconButton>
                <SwipeableDrawer 
                    anchor="right" 
                    open={this.state.isOpen} 
                    onClose={() => this.setState({ ...this.state, isOpen: false })}
                    onOpen={() => this.setState({ ...this.state, isOpen: true })}
                >
                    <div className="side-drawer-content">
                        <div className="logo-container">
                            <img className="logo" alt="tera-logo" src={Logo} />
                        </div>
                        <NavLink className="link" exact to="/residential">Residential</NavLink>
                        <NavLink className="link" exact to="/commercial-industrial">Commercial & Industrial</NavLink>
                        <NavLink className="link" exact to="/about">About</NavLink>
                        <NavLink className="link" exact to="/portfolios">Portfolios</NavLink>
                        <NavLink className="link" exact to="/services">Services</NavLink>
                        <NavLink className="link" exact to="/careers">Careers</NavLink>
                        <NavLink className="link" exact to="/news-events">News & Events</NavLink>
                        <div className="expandable-button" onClick={handleClick}>
                            <span>Testimonials</span>
                            {this.state.open ? <ExpandLess /> : <ExpandMore />}
                        </div>
                        <Collapse 
                            in={this.state.open} 
                            timeout="auto"
                            unmountOnExit
                        >
                            <div className="collapsed-menu">
                                <NavLink className="collapsed-link" to="/testimonials/solar-panel-petaling-jaya">Petaling Jaya</NavLink>
                                <NavLink className="collapsed-link" to="/testimonials/solar-panel-melaka">Melaka</NavLink>
                                <NavLink className="collapsed-link" to="/testimonials/solar-panel-johor-bahru">Johor Bahru</NavLink>
                            </div>
                        </Collapse>
                        <NavLink className="link" to="/malaysian-investment-development-authority">MIDA</NavLink>
                    </div>
                </SwipeableDrawer>
            </div>
        )
    }
}

export default SideDrawer;