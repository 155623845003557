/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addEnquiry = /* GraphQL */ `
  mutation AddEnquiry(
    $clientType: String
    $email: String
    $enquiry: String
    $name: String
    $phone: String
    $subscribe: Boolean
  ) {
    addEnquiry(
      clientType: $clientType
      email: $email
      enquiry: $enquiry
      name: $name
      phone: $phone
      subscribe: $subscribe
    ) {
      status
      statusCode
    }
  }
`;
export const addProposal = /* GraphQL */ `
  mutation AddProposal(
    $appointment: String
    $appointmentMessage: String
    $avgElectricityBill: Float
    $batterySystem: Boolean
    $batterySystemCost: Float
    $batterySystemPackage: String
    $buildingType: String
    $cTRatio: String
    $carDistance: Float
    $carbonAvoidance: Float
    $clientAddress: String
    $clientAddress2: String
    $clientAddress3: String
    $clientEmail: String
    $clientName: String
    $clientPhone: String
    $clientType: String
    $companyName: String
    $currentMonthlyBill: Float
    $deposit: Float
    $extendWarranty: Boolean
    $extendWarrantyCost: Float
    $extendWarrantyPackage: String
    $extendWorkmanshipWarranty: Boolean
    $extendWorkmanshipWarrantyCost: Float
    $extendWorkmanshipWarrantyPackage: String
    $finalAmount: Float
    $industryType: String
    $insuranceCoverage: Boolean
    $insuranceCoverageCost: Float
    $insuranceCoveragePackage: String
    $maxCapacity: Float
    $maximumDemand: Float
    $minUpfrontCost: Float
    $modeOfPurchase: String
    $monthSavings: Float
    $monthlyBillWithVASolar: Float
    $operationAndMaintenance: Boolean
    $operationAndMaintenanceCost: Float
    $operationAndMaintenancePackage: String
    $otherRequest: Boolean
    $otherRequestCost: Float
    $otherRequestPackage: String
    $panel: String
    $paybackPeriod: Float
    $phaseSystem: String
    $postCode: String
    $promoCode: String
    $proposalId: String
    $proposedInstalledCapacity: Float
    $referralCode: String
    $referralName: String
    $roiWithTaxSavings: Float
    $rooftopArea: Float
    $salesAgentCode: String
    $sharedMaintenance: Boolean
    $sharedMaintenanceCost: Float
    $sharedMaintenancePackage: String
    $state: String
    $status: String
    $tariffGroup: String
    $taxSavings: Float
    $termsAndConditionStatus: Boolean
    $totalAddOnCost: Float
    $totalCost: Float
    $totalDiscount: Float
    $totalProposedInstalledCapacity: Float
    $town: String
    $treesPlanted: Float
    $upgradeInverter: Boolean
    $upgradeInverterCost: Float
    $upgradeInverterPackage: String
  ) {
    addProposal(
      appointment: $appointment
      appointmentMessage: $appointmentMessage
      avgElectricityBill: $avgElectricityBill
      batterySystem: $batterySystem
      batterySystemCost: $batterySystemCost
      batterySystemPackage: $batterySystemPackage
      buildingType: $buildingType
      cTRatio: $cTRatio
      carDistance: $carDistance
      carbonAvoidance: $carbonAvoidance
      clientAddress: $clientAddress
      clientAddress2: $clientAddress2
      clientAddress3: $clientAddress3
      clientEmail: $clientEmail
      clientName: $clientName
      clientPhone: $clientPhone
      clientType: $clientType
      companyName: $companyName
      currentMonthlyBill: $currentMonthlyBill
      deposit: $deposit
      extendWarranty: $extendWarranty
      extendWarrantyCost: $extendWarrantyCost
      extendWarrantyPackage: $extendWarrantyPackage
      extendWorkmanshipWarranty: $extendWorkmanshipWarranty
      extendWorkmanshipWarrantyCost: $extendWorkmanshipWarrantyCost
      extendWorkmanshipWarrantyPackage: $extendWorkmanshipWarrantyPackage
      finalAmount: $finalAmount
      industryType: $industryType
      insuranceCoverage: $insuranceCoverage
      insuranceCoverageCost: $insuranceCoverageCost
      insuranceCoveragePackage: $insuranceCoveragePackage
      maxCapacity: $maxCapacity
      maximumDemand: $maximumDemand
      minUpfrontCost: $minUpfrontCost
      modeOfPurchase: $modeOfPurchase
      monthSavings: $monthSavings
      monthlyBillWithVASolar: $monthlyBillWithVASolar
      operationAndMaintenance: $operationAndMaintenance
      operationAndMaintenanceCost: $operationAndMaintenanceCost
      operationAndMaintenancePackage: $operationAndMaintenancePackage
      otherRequest: $otherRequest
      otherRequestCost: $otherRequestCost
      otherRequestPackage: $otherRequestPackage
      panel: $panel
      paybackPeriod: $paybackPeriod
      phaseSystem: $phaseSystem
      postCode: $postCode
      promoCode: $promoCode
      proposalId: $proposalId
      proposedInstalledCapacity: $proposedInstalledCapacity
      referralCode: $referralCode
      referralName: $referralName
      roiWithTaxSavings: $roiWithTaxSavings
      rooftopArea: $rooftopArea
      salesAgentCode: $salesAgentCode
      sharedMaintenance: $sharedMaintenance
      sharedMaintenanceCost: $sharedMaintenanceCost
      sharedMaintenancePackage: $sharedMaintenancePackage
      state: $state
      status: $status
      tariffGroup: $tariffGroup
      taxSavings: $taxSavings
      termsAndConditionStatus: $termsAndConditionStatus
      totalAddOnCost: $totalAddOnCost
      totalCost: $totalCost
      totalDiscount: $totalDiscount
      totalProposedInstalledCapacity: $totalProposedInstalledCapacity
      town: $town
      treesPlanted: $treesPlanted
      upgradeInverter: $upgradeInverter
      upgradeInverterCost: $upgradeInverterCost
      upgradeInverterPackage: $upgradeInverterPackage
    ) {
      discountDetail {
        discountPercentage
        discountType
        totalCost
        totalDiscount
      }
      proposalId
      status
      statusCode
      validReferralPromo
    }
  }
`;
export const createEnquiry = /* GraphQL */ `
  mutation CreateEnquiry(
    $condition: ModelEnquiryConditionInput
    $input: CreateEnquiryInput!
  ) {
    createEnquiry(condition: $condition, input: $input) {
      clientType
      createdAt
      email
      enquiry
      enquiryId
      name
      owner
      phone
      subscribe
      updatedAt
    }
  }
`;
export const createNewsAndEvent = /* GraphQL */ `
  mutation CreateNewsAndEvent(
    $condition: ModelNewsAndEventConditionInput
    $input: CreateNewsAndEventInput!
  ) {
    createNewsAndEvent(condition: $condition, input: $input) {
      createdAt
      createdBy
      description
      imageLink
      newsAndEventId
      owner
      title
      updatedAt
      updatedBy
    }
  }
`;
export const createPromoCodeCampaign = /* GraphQL */ `
  mutation CreatePromoCodeCampaign(
    $condition: ModelPromoCodeCampaignConditionInput
    $input: CreatePromoCodeCampaignInput!
  ) {
    createPromoCodeCampaign(condition: $condition, input: $input) {
      createdAt
      createdBy
      discountPercentage
      discountType
      effectiveEndDateTime
      effectiveStartDateTime
      owner
      promoCode
      promoCodeCampaignId
      promoCodeType
      salesAgentName
      salesAgentPhoneNo
      status
      title
      updatedAt
      updatedBy
    }
  }
`;
export const createPromotionPartner = /* GraphQL */ `
  mutation CreatePromotionPartner(
    $condition: ModelPromotionPartnerConditionInput
    $input: CreatePromotionPartnerInput!
  ) {
    createPromotionPartner(condition: $condition, input: $input) {
      backgroundImage
      createdAt
      createdBy
      discountPercentage
      displayInputText
      owner
      partnerLogo
      partnerName
      partnerURL
      promoCode
      promotionPartnerId
      updatedAt
      updatedBy
    }
  }
`;
export const createProposal = /* GraphQL */ `
  mutation CreateProposal(
    $condition: ModelProposalConditionInput
    $input: CreateProposalInput!
  ) {
    createProposal(condition: $condition, input: $input) {
      appointment
      appointmentMessage
      avgElectricityBill
      batterySystem
      batterySystemCost
      batterySystemPackage
      buildingType
      cTRatio
      carDistance
      carbonAvoidance
      channelEnquiry
      clientAddress
      clientAddress2
      clientAddress3
      clientEmail
      clientName
      clientPhone
      clientType
      companyName
      createdAt
      currentMonthlyBill
      deposit
      discountPercentage
      discountType
      documentLink
      extendWarranty
      extendWarrantyCost
      extendWarrantyPackage
      extendWorkmanshipWarranty
      extendWorkmanshipWarrantyCost
      extendWorkmanshipWarrantyPackage
      finalAmount
      industryType
      insuranceCoverage
      insuranceCoverageCost
      insuranceCoveragePackage
      maxCapacity
      maximumDemand
      minUpfrontCost
      modeOfPurchase
      modelCode
      monthSavings
      monthlyBillWithVASolar
      operationAndMaintenance
      operationAndMaintenanceCost
      operationAndMaintenancePackage
      otherRequest
      otherRequestCost
      otherRequestPackage
      owner
      panel
      paybackPeriod
      paymentLink
      phaseSystem
      postCode
      promoCode
      proposalId
      proposalNumber
      proposedInstalledCapacity
      pvQuantity
      referralCode
      referralName
      roiWithTaxSavings
      rooftopArea
      salesAgentCode
      salesAgentName
      salesAgentPhoneNo
      sharedMaintenance
      sharedMaintenanceCost
      sharedMaintenancePackage
      signature
      state
      status
      submissionDate
      tariffGroup
      taxSavings
      termsAndConditionStatus
      totalAddOnCost
      totalCost
      totalDiscount
      totalProposedInstalledCapacity
      town
      treesPlanted
      updatedAt
      updatedBy
      upgradeInverter
      upgradeInverterCost
      upgradeInverterPackage
      validReferralPromo
    }
  }
`;
export const createReferral = /* GraphQL */ `
  mutation CreateReferral(
    $condition: ModelReferralConditionInput
    $input: CreateReferralInput!
  ) {
    createReferral(condition: $condition, input: $input) {
      amount
      createdAt
      date
      owner
      proposalId
      refereeName
      refereePhone
      referralCode
      referralId
      referralName
      referralPhone
      status
      updatedAt
    }
  }
`;
export const createTariff = /* GraphQL */ `
  mutation CreateTariff(
    $condition: ModelTariffConditionInput
    $input: CreateTariffInput!
  ) {
    createTariff(condition: $condition, input: $input) {
      category
      createdAt
      owner
      rate
      tariffId
      updatedAt
      usageStart
    }
  }
`;
export const deleteEnquiry = /* GraphQL */ `
  mutation DeleteEnquiry(
    $condition: ModelEnquiryConditionInput
    $input: DeleteEnquiryInput!
  ) {
    deleteEnquiry(condition: $condition, input: $input) {
      clientType
      createdAt
      email
      enquiry
      enquiryId
      name
      owner
      phone
      subscribe
      updatedAt
    }
  }
`;
export const deleteNewsAndEvent = /* GraphQL */ `
  mutation DeleteNewsAndEvent(
    $condition: ModelNewsAndEventConditionInput
    $input: DeleteNewsAndEventInput!
  ) {
    deleteNewsAndEvent(condition: $condition, input: $input) {
      createdAt
      createdBy
      description
      imageLink
      newsAndEventId
      owner
      title
      updatedAt
      updatedBy
    }
  }
`;
export const deletePromoCodeCampaign = /* GraphQL */ `
  mutation DeletePromoCodeCampaign(
    $condition: ModelPromoCodeCampaignConditionInput
    $input: DeletePromoCodeCampaignInput!
  ) {
    deletePromoCodeCampaign(condition: $condition, input: $input) {
      createdAt
      createdBy
      discountPercentage
      discountType
      effectiveEndDateTime
      effectiveStartDateTime
      owner
      promoCode
      promoCodeCampaignId
      promoCodeType
      salesAgentName
      salesAgentPhoneNo
      status
      title
      updatedAt
      updatedBy
    }
  }
`;
export const deletePromotionPartner = /* GraphQL */ `
  mutation DeletePromotionPartner(
    $condition: ModelPromotionPartnerConditionInput
    $input: DeletePromotionPartnerInput!
  ) {
    deletePromotionPartner(condition: $condition, input: $input) {
      backgroundImage
      createdAt
      createdBy
      discountPercentage
      displayInputText
      owner
      partnerLogo
      partnerName
      partnerURL
      promoCode
      promotionPartnerId
      updatedAt
      updatedBy
    }
  }
`;
export const deleteProposal = /* GraphQL */ `
  mutation DeleteProposal(
    $condition: ModelProposalConditionInput
    $input: DeleteProposalInput!
  ) {
    deleteProposal(condition: $condition, input: $input) {
      appointment
      appointmentMessage
      avgElectricityBill
      batterySystem
      batterySystemCost
      batterySystemPackage
      buildingType
      cTRatio
      carDistance
      carbonAvoidance
      channelEnquiry
      clientAddress
      clientAddress2
      clientAddress3
      clientEmail
      clientName
      clientPhone
      clientType
      companyName
      createdAt
      currentMonthlyBill
      deposit
      discountPercentage
      discountType
      documentLink
      extendWarranty
      extendWarrantyCost
      extendWarrantyPackage
      extendWorkmanshipWarranty
      extendWorkmanshipWarrantyCost
      extendWorkmanshipWarrantyPackage
      finalAmount
      industryType
      insuranceCoverage
      insuranceCoverageCost
      insuranceCoveragePackage
      maxCapacity
      maximumDemand
      minUpfrontCost
      modeOfPurchase
      modelCode
      monthSavings
      monthlyBillWithVASolar
      operationAndMaintenance
      operationAndMaintenanceCost
      operationAndMaintenancePackage
      otherRequest
      otherRequestCost
      otherRequestPackage
      owner
      panel
      paybackPeriod
      paymentLink
      phaseSystem
      postCode
      promoCode
      proposalId
      proposalNumber
      proposedInstalledCapacity
      pvQuantity
      referralCode
      referralName
      roiWithTaxSavings
      rooftopArea
      salesAgentCode
      salesAgentName
      salesAgentPhoneNo
      sharedMaintenance
      sharedMaintenanceCost
      sharedMaintenancePackage
      signature
      state
      status
      submissionDate
      tariffGroup
      taxSavings
      termsAndConditionStatus
      totalAddOnCost
      totalCost
      totalDiscount
      totalProposedInstalledCapacity
      town
      treesPlanted
      updatedAt
      updatedBy
      upgradeInverter
      upgradeInverterCost
      upgradeInverterPackage
      validReferralPromo
    }
  }
`;
export const deleteReferral = /* GraphQL */ `
  mutation DeleteReferral(
    $condition: ModelReferralConditionInput
    $input: DeleteReferralInput!
  ) {
    deleteReferral(condition: $condition, input: $input) {
      amount
      createdAt
      date
      owner
      proposalId
      refereeName
      refereePhone
      referralCode
      referralId
      referralName
      referralPhone
      status
      updatedAt
    }
  }
`;
export const deleteTariff = /* GraphQL */ `
  mutation DeleteTariff(
    $condition: ModelTariffConditionInput
    $input: DeleteTariffInput!
  ) {
    deleteTariff(condition: $condition, input: $input) {
      category
      createdAt
      owner
      rate
      tariffId
      updatedAt
      usageStart
    }
  }
`;
export const downloadProposalReport = /* GraphQL */ `
  mutation DownloadProposalReport(
    $DateFrom: String
    $DateTo: String
    $Keyword: String
    $Limit: Int
    $NextToken: String
    $SearchCategory: String
    $Sort: SortProposal
    $Status: String
  ) {
    downloadProposalReport(
      DateFrom: $DateFrom
      DateTo: $DateTo
      Keyword: $Keyword
      Limit: $Limit
      NextToken: $NextToken
      SearchCategory: $SearchCategory
      Sort: $Sort
      Status: $Status
    )
  }
`;
export const solarInvestmentCalculator = /* GraphQL */ `
  mutation SolarInvestmentCalculator(
    $avgElectricityBill: Float
    $proposedInstalledCapacity: Float
  ) {
    solarInvestmentCalculator(
      avgElectricityBill: $avgElectricityBill
      proposedInstalledCapacity: $proposedInstalledCapacity
    ) {
      batterySystemCost
      extendWarrantyCost
      extendWarrantyPackage
      insuranceCoverageCost
      insuranceCoveragePackage
      monthSavings
      monthlyBillWithVASolar
      operationAndMaintenanceCost
      operationAndMaintenancePackage
      otherRequestPackage
      proposedInstalledCapacity
      totalAddOnCost
      totalCost
    }
  }
`;
export const updateEnquiry = /* GraphQL */ `
  mutation UpdateEnquiry(
    $condition: ModelEnquiryConditionInput
    $input: UpdateEnquiryInput!
  ) {
    updateEnquiry(condition: $condition, input: $input) {
      clientType
      createdAt
      email
      enquiry
      enquiryId
      name
      owner
      phone
      subscribe
      updatedAt
    }
  }
`;
export const updateNewsAndEvent = /* GraphQL */ `
  mutation UpdateNewsAndEvent(
    $condition: ModelNewsAndEventConditionInput
    $input: UpdateNewsAndEventInput!
  ) {
    updateNewsAndEvent(condition: $condition, input: $input) {
      createdAt
      createdBy
      description
      imageLink
      newsAndEventId
      owner
      title
      updatedAt
      updatedBy
    }
  }
`;
export const updatePromoCodeCampaign = /* GraphQL */ `
  mutation UpdatePromoCodeCampaign(
    $condition: ModelPromoCodeCampaignConditionInput
    $input: UpdatePromoCodeCampaignInput!
  ) {
    updatePromoCodeCampaign(condition: $condition, input: $input) {
      createdAt
      createdBy
      discountPercentage
      discountType
      effectiveEndDateTime
      effectiveStartDateTime
      owner
      promoCode
      promoCodeCampaignId
      promoCodeType
      salesAgentName
      salesAgentPhoneNo
      status
      title
      updatedAt
      updatedBy
    }
  }
`;
export const updatePromotionPartner = /* GraphQL */ `
  mutation UpdatePromotionPartner(
    $condition: ModelPromotionPartnerConditionInput
    $input: UpdatePromotionPartnerInput!
  ) {
    updatePromotionPartner(condition: $condition, input: $input) {
      backgroundImage
      createdAt
      createdBy
      discountPercentage
      displayInputText
      owner
      partnerLogo
      partnerName
      partnerURL
      promoCode
      promotionPartnerId
      updatedAt
      updatedBy
    }
  }
`;
export const updateProposal = /* GraphQL */ `
  mutation UpdateProposal(
    $condition: ModelProposalConditionInput
    $input: UpdateProposalInput!
  ) {
    updateProposal(condition: $condition, input: $input) {
      appointment
      appointmentMessage
      avgElectricityBill
      batterySystem
      batterySystemCost
      batterySystemPackage
      buildingType
      cTRatio
      carDistance
      carbonAvoidance
      channelEnquiry
      clientAddress
      clientAddress2
      clientAddress3
      clientEmail
      clientName
      clientPhone
      clientType
      companyName
      createdAt
      currentMonthlyBill
      deposit
      discountPercentage
      discountType
      documentLink
      extendWarranty
      extendWarrantyCost
      extendWarrantyPackage
      extendWorkmanshipWarranty
      extendWorkmanshipWarrantyCost
      extendWorkmanshipWarrantyPackage
      finalAmount
      industryType
      insuranceCoverage
      insuranceCoverageCost
      insuranceCoveragePackage
      maxCapacity
      maximumDemand
      minUpfrontCost
      modeOfPurchase
      modelCode
      monthSavings
      monthlyBillWithVASolar
      operationAndMaintenance
      operationAndMaintenanceCost
      operationAndMaintenancePackage
      otherRequest
      otherRequestCost
      otherRequestPackage
      owner
      panel
      paybackPeriod
      paymentLink
      phaseSystem
      postCode
      promoCode
      proposalId
      proposalNumber
      proposedInstalledCapacity
      pvQuantity
      referralCode
      referralName
      roiWithTaxSavings
      rooftopArea
      salesAgentCode
      salesAgentName
      salesAgentPhoneNo
      sharedMaintenance
      sharedMaintenanceCost
      sharedMaintenancePackage
      signature
      state
      status
      submissionDate
      tariffGroup
      taxSavings
      termsAndConditionStatus
      totalAddOnCost
      totalCost
      totalDiscount
      totalProposedInstalledCapacity
      town
      treesPlanted
      updatedAt
      updatedBy
      upgradeInverter
      upgradeInverterCost
      upgradeInverterPackage
      validReferralPromo
    }
  }
`;
export const updateReferral = /* GraphQL */ `
  mutation UpdateReferral(
    $condition: ModelReferralConditionInput
    $input: UpdateReferralInput!
  ) {
    updateReferral(condition: $condition, input: $input) {
      amount
      createdAt
      date
      owner
      proposalId
      refereeName
      refereePhone
      referralCode
      referralId
      referralName
      referralPhone
      status
      updatedAt
    }
  }
`;
export const updateTariff = /* GraphQL */ `
  mutation UpdateTariff(
    $condition: ModelTariffConditionInput
    $input: UpdateTariffInput!
  ) {
    updateTariff(condition: $condition, input: $input) {
      category
      createdAt
      owner
      rate
      tariffId
      updatedAt
      usageStart
    }
  }
`;
