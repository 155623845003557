import React from "react";
import './QMSQualityPolicy.scss';
import {Typography} from '@material-ui/core'


function QualityPolicy() {

    const listContent = [
        "Total Customer Satisfaction – from your first contact to the benefits you reap from our systems",
        "Effective and Efficient use of resources – to acquire and deliver all our work",
        "Review and continually improve the way we conduct our business to benefit all stakeholders",
        "Anticipate current and future needs by staying in touch with latest development in the Solar System Technologies as well as changes to Malaysian regulatory requirements"
    ]
  
    return (
        <div className="privacy-policy-descriptions">
            <div className="privacy-policy-description-container" >
                <div className="privacy-policy-description">
                <span className="privacy-policy">QUALITY POLICY</span><br />
                    <label className="title">TERA Quality Policy</label><br />
                    <label className="description">
                        In Tera Va, we strive to be the preferred Solar System Provider in Malaysia. We believe  in  understanding  our  customers  as  well  as  all  applicable  statutory requirements  before  deriving  the  most  effective  and  efficient  approach  to satisfy them. 
                    </label>
                    <br />
                    <label className="description">
                    To deliver our pledge, we are committed to : 
                    </label>

                   <div>
                       <ul>
                           {listContent.map((item) => {
                               let first = item[0]
                               let second = item.replace(item[0], "")
                               return(
                                <li>
                                    <Typography variant="h6" gutterBottom component="div">
                                       <b style={{color: "#1DA5FF", fontSize: "2.2rem"}}>{first}</b>{second}
                                    </Typography>
                                 
                              </li>
                           )})}
                         
                       </ul>
                   </div>
                </div>
            </div>
        </div>
    )
}

export default QualityPolicy;