import React from "react";
import './PrivacyPolicy.scss';
import {Accordion, AccordionDetails, AccordionSummary, Typography, FormControl} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function PrivacyPolicy() {

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    return (
        <div className="privacy-policy-descriptions">
            <div className="privacy-policy-description-container" >
                <div className="privacy-policy-description">
                <span className="privacy-policy">Privacy Policy</span><br />
                    <label className="title">TERA Privacy Policy</label><br />
                    <label className="description">This page explains our privacy policies which include the use and protection of information submitted by visitors. If you make a transaction or send an e-mail containing personal information, this information may be shared with other public agencies to serve you in a more efficient and effective manner. For example, in resolving a complaint that requires feedback from other agencies.</label><br />
                </div>
                <div className="privacy-policy-expand-bar">
                <div className="privacy-policy-sub-expand-bar">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1bh-content"
                         id="panel1bh-header"
                    >
                    <Typography className="big-title">Privacy Policy</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                        <FormControl component="fieldset">
                        <Typography className="title">
                            Information Collected 
                            </Typography>
                            <Typography className="subtitle">
                            No personally identifiable information is gathered during the browsing of this website except for information given by you via e-mail.
                            </Typography>
                            <Typography className="title">
                            What Happens When I Link to Another Site?
                            </Typography>
                            <Typography className="subtitle">
                            This web site contains links to other websites. This privacy policy only applies to the TERA (TERA VA Sdn Bhd) website. It should be noted that the websites made available via links may have different privacy policies and visitors are advised to read and understand the privacy statements of the respective website visit. 
                            </Typography>
                            <Typography className="title">
                            Amendment Policy 
                            </Typography>
                            <Typography className="subtitle">
                            In the event the privacy policy is revised, the amendments will be updated on this page. You will be updated with; the type of information that was collected; how it is to be used and under what circumstances, and how information is to be shared with other parties.
                            </Typography>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                </div>
                <div className="privacy-policy-sub-expand-bar">
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1bh-content"
                         id="panel1bh-header"
                    >
                        <Typography className="big-title">Terms and Conditions of Use</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                        <FormControl component="fieldset">
                        <Typography className="subtitle">
                            You should assume that all material on the Site is protected by copyright unless otherwise noted and may not be used except as permitted in these Terms and Conditions or in the text on the Site.
                            </Typography>
                            <Typography className="subtitle">
                            Your use of the Site is at your risk. TERA (TERA VA Sdn Bhd) makes no warranties or representations as to its accuracy and TERA (TERA VA Sdn Bhd) specifically disclaims any liability or responsibility for any errors or omissions in the content on the Site. Neither TERA (TERA VA Sdn Bhd) nor any other party involved in creating, producing, or delivering the Site is liable for any direct, incidental, consequential, indirect, or punitive damages arising out of your access to, or use of, or inability to use or access, the Site. Without limiting the foregoing, everything on the Site is provided to you "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. Please note that some jurisdictions may not allow the exclusion of implied warranties, so some of the above exclusions may not apply to you. Check your local laws for any restrictions or limitations regarding the exclusion of implied warranties.
                            </Typography>
                            <Typography className="subtitle">
                            Features and specifications of products described or depicted on the Site are subject to change at any time without notice.
                            </Typography>
                            <Typography className="subtitle">
                            TERA (TERA VA Sdn Bhd) assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect, your computer equipment or other property on account of your access to, use of, or browsing in the Site or your downloading of any materials, data, text, images, video, or audio from the Site.
                            </Typography>
                            <Typography className="subtitle">
                            Subject to our Privacy Policy, if you send any communications or materials to the Site by electronic mail or otherwise, including any data, questions, comments, suggestions, or the like, all such communications are, and will be treated as, non-confidential and non-proprietary. Anything you transmit or post may be used by TERA (TERA VA Sdn Bhd) or its affiliates for any purpose, including, but not limited to, reproduction, disclosure, transmission, publication, broadcast, and posting. Furthermore, TERA (TERA VA Sdn Bhd) is free to use, without any compensation to you, any ideas, concepts, know-how, or techniques contained in any communication you send to the Site for any purpose whatsoever, including, but not limited to, developing, manufacturing, and marketing products using such information. TERA (TERA VA Sdn Bhd) shall assume no responsibility and shall not be liable for any unauthorised or unlawful interception or modification of any communication between you and TERA (TERA VA Sdn Bhd) (whether by electronic mail or otherwise), or for any loss or damage arising thereby (including but not limited to any loss or damage arising out of the unauthorised or fraudulent use of any information contained in or made accessible via such communication).
                            </Typography>
                            <Typography className="subtitle">
                            Materials displayed on the Site are either the property of, or used with permission by, TERA (TERA VA Sdn Bhd). The use of these materials by you, or anyone else authorised by you, is prohibited unless specifically permitted by these Terms and Conditions or specific permission is provided elsewhere on the Site. Any unauthorised use of the images may violate copyright laws, trademark laws, the laws of privacy and publicity, and civil and criminal statutes.
                            </Typography>
                            <Typography className="subtitle">
                            TERA (TERA VA Sdn Bhd) is not responsible for the content of any site linked to or from the Site. Your linking to any other site is entirely at your own risk. While TERA (TERA VA Sdn Bhd) may provide links on this Site to other sites, the inclusion of such links is for your convenience only and should not be interpreted as an endorsement of the owner/sponsor of the site or the content of the site. TERA (TERA VA Sdn Bhd) disclaims all warranties, express and implied, as to the accuracy, validity, legality or otherwise of any materials or information contained on such sites.
                            </Typography>
                            <Typography className="subtitle">
                            Although TERA (TERA VA Sdn Bhd) may from time to time monitor or review bulletin boards and the like on the Site, TERA (TERA VA Sdn Bhd) is under no obligation to do so and assumes no responsibility or liability arising from the contents of any such communications nor for any error, defamation, libel, obscenity, profanity, or inaccuracy contained in any such communication. TERA (TERA VA Sdn Bhd) will fully cooperate with any law enforcement authorities or court order requesting or directing TERA (TERA VA Sdn Bhd) to disclose the identity of anyone posting such materials.
                            </Typography>
                            <Typography className="subtitle">
                            These Terms and Conditions are to be governed by and construed in accordance with the law of Malaysia, without reference to its conflicts of law rules.
                            </Typography>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                </div>
                <div className="privacy-policy-sub-expand-bar">
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                         expandIcon={<ExpandMoreIcon className="expand-icon"/>}
                         aria-controls="panel1bh-content"
                         id="panel1bh-header"
                    >
                        <Typography className="big-title">Fulfilment Policy</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                        <FormControl component="fieldset">
                        <Typography className="subtitle">
                            i. Site Inspection, design compliances, authorities applications. (1 Month)
                            </Typography>
                            <Typography className="subtitle">
                            ii. Procurement and delivery (1 Month)
                            </Typography>
                            <Typography className="subtitle">
                            iii. Installation (1-2 Days)
                            </Typography>
                            <Typography className="subtitle">
                            iv. Tenaga Nasional Berhad meter change and NEM compliance (1 Weeks)
                            </Typography>
                            <Typography className="subtitle">
                            v. Testing, commissioning, energisation and handover to client (1-2 Days)
                            </Typography>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                </div>
                <div className="privacy-policy-sub-expand-bar">
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1bh-content"
                         id="panel1bh-header"
                    >
                        <Typography className="big-title">Refund Policy</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                        <FormControl component="fieldset">
                            <Typography className="subtitle">
                            i. For project cancellation Upon Confirmation:
                                <ul>
                                    <li>Deposit is refundable at 100% before document submission or application to any authorities.</li>
                                    <li>Deposit is refundable at 70% after document submission or application to any authorities.</li>
                                </ul>
                            </Typography>
                            <Typography className="subtitle">
                            ii.  For project cancellation Before Delivery of Materials, Upon Completion of System Installation:
                                <ul>
                                    <li>The fees to be invoiced shall be for the materials ordered, and work that has been carried out at the point of cancellation. </li>
                                    <li>Regarding the remaining project value, 20% of the remaining project value would be invoiced to customer.</li>
                                </ul>
                            </Typography>
                            <Typography className="subtitle">
                            iii. For project cancellation Upon System Energised:
                                <ul>
                                    <li>No refund would be provided. Customer would be invoiced the entire project value.</li>
                                </ul>
                            </Typography>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;