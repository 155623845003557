import { NavLink, Link, useHistory } from "react-router-dom";
import Logo from '../../images/tera-logo-full-colour-cropped.png'
import './TopNavigation.scss';
import SideDrawer from "../side-drawer/SideDrawer";
import { useState } from 'react';
import { Menu, MenuItem } from "@material-ui/core";

function TopNavigation() {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleNavigate = (path: string) => {
        handleClose();
        history.push(path);
    };
    return (
        <div className="top-navigation">
            <Link to="/" className="logo-container">
                <img className="logo" alt="tera-logo" src={Logo} />
            </Link>
            <div className="navigation-items">
                    <NavLink className="link" exact to="/residential">Residential</NavLink>
                    <NavLink className="link" exact to="/commercial-industrial">Commercial & Industrial</NavLink>
                    <NavLink className="link" exact to="/about">About</NavLink>
                    <NavLink className="link" exact to="/portfolios">Portfolios</NavLink>
                    <NavLink className="link" exact to="/services">Services</NavLink>
                    <NavLink className="link" exact to="/careers">Careers</NavLink>
                    <NavLink className="link" exact to="/news-events">News & Events</NavLink>
                    <a className="menu-button" onClick={handleClick}>Testimonials</a>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    style={{ marginTop: "40px" }}
                >
                    <MenuItem style={{ fontSize: "13px"}} onClick={() => handleNavigate("/testimonials/solar-panel-petaling-jaya")}>
                        Petaling Jaya
                    </MenuItem>
                    <MenuItem style={{ fontSize: "13px"}} onClick={() => handleNavigate("/testimonials/solar-panel-melaka")}>
                        Melaka
                    </MenuItem>
                    <MenuItem style={{ fontSize: "13px"}} onClick={() => handleNavigate("/testimonials/solar-panel-johor-bahru")}>
                        Johor Bahru
                    </MenuItem>
                </Menu>
                    <NavLink className="link" to="/malaysian-investment-development-authority">MIDA</NavLink>
            </div>
            <div className="hamburger-button">
                <SideDrawer />
            </div>
        </div>
    );
}

export default TopNavigation;
