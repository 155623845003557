import React from 'react';
import './AboutBanner.scss';
import ContactUsFloat from '../../components/contact-us-float/ContactUsFloat';

class AboutBanner extends React.Component {

    render() {
        return (
                <div className="about-banner">
                <div className="about-cover">
                    <div className="about-banner-container">
                    
                        <div className="left-node">
                        <ContactUsFloat />
                            <label className="title">One of the leading organizations that specialized in the field of renewable solar energy</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutBanner;