import "./CompanyDescriptionResidential.scss";
import Money from "../../images/money.svg";
import Meteorite from "../../images/meteorite.svg";
import Thunderbolt from "../../images/thunderbolt.svg";
import ContactUsFloat from "../../components/contact-us-float/ContactUsFloat";
import { ChangeEvent, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function CompanyDescriptionCommercial() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div>
      <ContactUsFloat />
      <div className="company-description-residential">
        <div className="company-description-residential-container">
          <div className="company-description">
            <h1 className="title">
              Empowering Businesses with Sustainable Energy
            </h1>
            <br />
            <label className="description">
              TERA is on an unyielding mission to help businesses adopt
              commercial solar throughout Petaling Jaya and beyond; we want to
              power your business endeavors with the sun's abundant energy,
              providing a dynamic solution that drives growth, bolsters savings,
              and embeds sustainability into the heart of your operations.
              Experience the myriad of benefits that come with embracing
              commercial solar panels and discover how they can revolutionize
              your enterprise.
            </label>
            <br />
            <h2 className="title">An Investment Like No Other</h2>
            <br />
            <label className="description">
              In the rapidly developing country of Malaysia, businesses are
              seizing the opportunity to harness solar energy as a cornerstone
              of their success. TERA's commercial solar panels empower
              enterprises to make a significant impact on both their bottom line
              and the environment. Our cutting-edge solar solutions are tailored
              to meet the unique energy demands of businesses, enabling you to
              take control of your energy consumption and future-proof your
              operations.
            </label>
            <br />
            <label className="description">
              With an eye for detail, our engineers will help evaluate your
              premises and suggest the best solar panel output that will be able
              to future-proof your company for countless years to come; ensuring
              you the most optimal return on investment possible today!
            </label>
            <br />
            <h1 className="title">
              Benefits of Adopting Commercial Solar Panels:
            </h1>
            <br />
            <div className="privacy-policy-expand-bar">
              <div className="privacy-policy-sub-expand-bar">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="big-title">
                      Reduce Electricity Bill
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <Typography className="subtitle">
                        Embrace a more cost-effective future. With commercial
                        solar panels, you can significantly reduce your energy
                        bills, freeing up vital resources for strategic
                        investments and growth initiatives.
                      </Typography>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="privacy-policy-sub-expand-bar">
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="big-title">Clean Energy</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <Typography className="subtitle">
                        By adopting solar panels, your business contributes to
                        reducing carbon emissions, demonstrating your commitment
                        to environmental responsibility. What better way to
                        contribute to your ESG scores than cutting off
                        non-renewable energy?
                      </Typography>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="privacy-policy-sub-expand-bar">
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="big-title">
                      Brand Advantage
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <Typography className="subtitle">
                        Sustainability is a business differentiator. Show your
                        customers, partners, and stakeholders that you're
                        dedicated to a greener future, enhancing your brand's
                        reputation and attracting environmentally conscious
                        clientele.
                      </Typography>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="privacy-policy-sub-expand-bar">
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="big-title">
                      Business Sustainability
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <Typography className="subtitle">
                        Shield your business from energy price fluctuations. As
                        energy costs rise, your solar panels provide a stable
                        and predictable energy source, safeguarding your
                        financial standing.
                      </Typography>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="privacy-policy-sub-expand-bar">
                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="big-title">
                      Corporate Tax Benefits
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <Typography className="subtitle">
                        Commercial solar panels offer long-term returns on
                        investment. Beyond cost savings, you can take advantage
                        of incentives, rebates, and tax benefits that amplify
                        your financial gains.
                      </Typography>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <br />
            <h2 className="title">Tailored for Your Business</h2>
            <br />
            <label className="description">
              At TERA, we understand that every business is unique, and
              that's why we take pride in our ability to engineer solar panel
              solutions that align seamlessly with your specific needs. With a
              deep appreciation for the intricacies of your operations, our
              expert team meticulously designs solar solutions that optimizes
              energy production, cost savings, and environmental impact. Whether
              it’s a bustling office complex, a manufacturing facility, or a
              retail space, our customized approach ensures that your solar
              panels provide the best return on investment for your company.
            </label>
            <br />
            <h2 className="title">
              Bring Your Business to the Next Level Today!
            </h2>
            <br />
            <label className="description">
              Are you ready to take the next revolutionary step for your
              business? TERA has been redefining the benefits of commercial
              solar in Petaling Jaya for over a decade, and we want to bring
              these straight to your company’s doorsteps. Contact us now to
              schedule a consultation and take the first step toward powering
              your business with clean, reliable solar energy.
            </label>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyDescriptionCommercial;
