import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Paper, InputBase, IconButton, Grid, Box, Chip } from "@material-ui/core";
// import { Pagination, PaginationItem } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import "./NewsEventList.scss";
import { FiCard, FiCardActions, FiCardMedia } from "./FullImageCard";
import { API, graphqlOperation } from 'aws-amplify';
import { listNewsAndEvents } from '../../graphql/queries'
// import Pagination from '../pagination/Pagination';
import validator from '../../utils/Validator'

function NewsEventList() {

  let history = useHistory();
  const bucketURL = "https://d12o165ilpw6mo.cloudfront.net/public/"
  
  const [newsList, setNewsList] = useState([])
  const [isTimeOut, setIsTimeOut] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const itemPerPage = 9
  const totalPage = Math.ceil(newsList.length / itemPerPage)
  const [searchFilter, setSearchFilter] = useState()
  const [search, setSearch] = useState()

  const getNewsAndEventLists = async (limit = itemPerPage, page = 0) => {
      try {
        setIsLoading(true)
        let skipRow = page * limit
        let res: any = null

        if(!validator.isEmpty(searchFilter)){
          res = await API.graphql(
            graphqlOperation(listNewsAndEvents, { 
              filter: searchFilter
            }))
        } else {
          res = await API.graphql(graphqlOperation(listNewsAndEvents))
        }

        if (res.data.listNewsAndEvents) {
          let data = res.data.listNewsAndEvents.items
          data.sort((a: any,b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
          data.reverse()
          setNewsList(data)
          
        } else {
          setNewsList([])
        }
        setIsTimeOut(false)
        setIsLoading(false)
      } catch(error){
        console.log(error)
        setIsLoading(false)
        setIsTimeOut(true)
      }
  }

  useEffect(() => {
    getNewsAndEventLists()
  }, [searchFilter])

  const elasticSearch = async (item: string) => {
    let currentSearchFilter: any = {}
    let keywordToSearch = ''
    if (item) {
      keywordToSearch = item
    }
    if (keywordToSearch && keywordToSearch.length > 0) {
      currentSearchFilter = { or: [] }
      let titleFilter: any = { and: [] }
      let splitKeywords: any = keywordToSearch.split(' ')
      splitKeywords.map((keyword: string) => {
        titleFilter['and'].push({ title: { contains: keyword } })
      })
      currentSearchFilter['or'].push(titleFilter)
    }
    setSearchFilter(currentSearchFilter)
  }

  const handleSearch = (event: any) => {
    setSearch(event.target.value)
    elasticSearch(event.target.value)
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    window.scrollTo(0, 0);
    setCurrentPage(value - 1)
  }

  // const handleChangePage = (newPage: any) => {
  //   setCurrentPage(newPage)
  // }

  const getDaysNum = (createdAt: string) => {
    var newsDate = new Date(createdAt)
    var today = new Date()
    var diff_in_time = today.getTime() - newsDate.getTime();
    var diff_in_days = diff_in_time / (1000 * 3600 * 24);
    return diff_in_days
  }

  const handleViewNewsDetails = (data: any) => {
    history.push({
      pathname: `/news-events/${data.newsAndEventId}`,
      state: newsList
    })
    window.scrollTo(0, 0);
  }


  return (
    <div className="news-description">
      <div className="news-description-container">
        <div className="news-event-description">
          <span className="news">NEWS & EVENTS</span>
          <Grid container spacing={4} style={{ marginTop: "20px" }}>
            <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
              <Paper component="form" className="search-bar">
                <InputBase
                  className="input-bar"
                  placeholder="Search "
                  onChange={handleSearch}
                  value={search || ''}
                  inputProps={{ "aria-label": "search " }} />
                <IconButton
                  className="search-icon"
                  type="submit"
                  aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            {!isTimeOut ? (
              <>
                <Grid className="grid-news-event" container spacing={4}>

                  {newsList.slice(currentPage * itemPerPage, currentPage * itemPerPage + itemPerPage).map((data: any, index: any) => {
                    const imageDisplay = bucketURL + data.imageLink
                    var diff_in_days = getDaysNum(data.createdAt)
                    return (
                      <Grid item xs={12} sm={9} md={6} lg={4} key={index}>
                        <Box component="span" >
                          <a style={{ textDecoration: "none", cursor: "pointer" }} onClick={() => handleViewNewsDetails(data)}>
                            <FiCard className="news-events-size">
                              <FiCardMedia
                                image={imageDisplay}
                              />
                              <FiCardActions>
                                <Chip style={{ color: 'white', textTransform: "none" }} className="label" label={diff_in_days.toFixed(0) + " Days Ago"} />
                              </FiCardActions>
                            </FiCard>
                          </a>
                          <span className="news-events-title">{data.title}</span><br />
                        </Box>
                      </Grid>
                    )

                  })}
                </Grid>
                <Grid container spacing={4} style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
                  {/* <Pagination  currentPage={currentPage} itemPerPage={itemPerPage} totalItems={newsList.length} paginate={handleChangePage}            */}
                  <Pagination
                    className="pagination"
                    count={totalPage}
                    page={currentPage + 1}
                    onChange={handleChange}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default NewsEventList;

