import React, { useState } from 'react';
import { FormControl, InputLabel, Select, InputBase, ButtonGroup, Button, MenuItem, FormHelperText } from '@material-ui/core';
import ArrowRight from '../../../images/arrow-right.png';
import './CalculatorBannerResidential.scss';
import { inputPreventDefault } from '../../../components/utils/util';


function CalculatorBannerResidential(props: any) {
    const { residentialCalcData, setResidentialCalcData, handleCalculate } = props
    let buildingType = ['Villa', 'Bungalow', 'Semi-D', 'Superlink', 'Terrace House', 'Townhouse']
    let modeOfPurchase = ['OutRight Purchase', 'Credit Card Installment']

    const [notify, setNotify] = useState({
        show: false,
        item: '',
        message: ''
    })

    const onChange = (e: any) => {
        if (e.target.value === ' ') {
            setResidentialCalcData({ ...residentialCalcData, [e.target.name]: '' });
        } else {
            setResidentialCalcData({ ...residentialCalcData, [e.target.name]: e.target.value });
        }

        if (e.target.name === 'buildingType' && e.target.value !== "") {
            setNotify({ ...notify, show: false, item: 'buildingType' })
        }
        if (e.target.name === 'avgElectricityBill' && e.target.value > 0) {
            setNotify({ ...notify, show: false, item: 'avgElectricityBill' })
        }

        if (e.target.name === 'modeOfPurchase' && e.target.value !== "") {
            setNotify({ ...notify, show: false, item: 'modeOfPurchase' })
        }
    }

    return (
        <div className="calculator-banner">
            <div className="cover">
                <div className="calculator-banner-container">
                    <div className="right-node">
                        <div className="calculator-form">
                            <div>
                                <FormControl variant="filled" className="select-main-theme-wrapper" style={{ float: "left" }}>
                                    <InputLabel className="select-label">Building Type</InputLabel>
                                    <Select
                                        name="buildingType"
                                        value={residentialCalcData.buildingType !== "" ? residentialCalcData.buildingType : "Select Building Type"}
                                        onChange={onChange}
                                        className="select-main-theme">
                                        <MenuItem value="Select Building Type">-- Select Building Type --</MenuItem>
                                        {buildingType.map((data: any, index: any) => {
                                            return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                        })}
                                    </Select>
                                    {notify.item === "buildingType" && notify.show ? (
                                        <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                    ) : null}

                                </FormControl>
                                <div className="custom-price-input select-main-theme-wrapper" style={{ float: "right" }}>
                                    <label className="unit">RM</label>
                                    <InputBase
                                        type="number"
                                        className="price hide-input-arrow"
                                        name="avgElectricityBill"
                                        onChange={(event: any) => {
                                            const regex = /^\d*\.?\d{0,2}$/;
                                            const { value } = event.target;
                                            if (regex.test(value)) {
                                                if (value < 1000000 ) {
                                                    setResidentialCalcData({
                                                        ...residentialCalcData,
                                                        avgElectricityBill: value
                                                    });
                                                }
                                            }
                                        }}
                                        onKeyDown={(evt: any) => inputPreventDefault(evt, false)}
                                        inputProps={{ maxLength: 6 }}
                                        value={residentialCalcData.avgElectricityBill || ''}
                                        placeholder="Average electricity bill (monthly)"
                                    />
                                </div>
                                <div style={{ marginBottom: "15px" }}>
                                    {notify.item === "avgElectricityBill" && notify.show ? (
                                        <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                    ) : null}
                                </div>
                            </div>
                            <div>
                                <FormControl variant="filled" className="select-main-theme-wrapper" style={{ float: "left", width: "290px" }}>
                                    <InputLabel className="select-label">Mode of Purchase</InputLabel>
                                    <Select
                                        name="modeOfPurchase"
                                        value={residentialCalcData.modeOfPurchase !== "" ? residentialCalcData.modeOfPurchase : "Select Mode of Purchase"}
                                        onChange={onChange}
                                        className="select-main-theme">
                                        <MenuItem value="Select Mode of Purchase">-- Select Mode of Purchase --</MenuItem>
                                        {modeOfPurchase.map((data: any, index: any) => {
                                            return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                        })}
                                    </Select>
                                    {notify.item === "modeOfPurchase" && notify.show ? (
                                        <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                    ) : null}
                                </FormControl>
                            </div>
                            <div>
                                <ButtonGroup size="large" variant="contained" className="select-main-theme-wrapper" style={{ float: "left", width: "280px", marginLeft: "10px" }}>
                                    <Button
                                        onClick={() => { setResidentialCalcData({ ...residentialCalcData, phaseSystem: 'Single Phase' }) }}
                                        className={['button-main-theme', 'type', residentialCalcData.phaseSystem === 'Single Phase' ? 'active' : 'passive'].join(' ')}
                                    >Single Phase</Button>
                                    <Button
                                        onClick={() => { setResidentialCalcData({ ...residentialCalcData, phaseSystem: 'Three Phase' }) }}
                                        className={['button-main-theme', 'type', residentialCalcData.phaseSystem === 'Three Phase' ? 'active' : 'passive'].join(' ')}
                                    >Three Phase</Button>
                                </ButtonGroup>
                            </div>
                            <div>
                                <Button variant="contained" size="large" className="button-main-theme calculate select-main-theme-wrapper" onClick={handleCalculate} disabled={!residentialCalcData.avgElectricityBill || residentialCalcData.avgElectricityBill <= 0}>
                                    <div className="wrapper">
                                        <label className="btn-title">Calculate</label>
                                        <label className="right-arrow">
                                            <img src={ArrowRight} alt="right-arrow" />
                                        </label>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalculatorBannerResidential;