import TopNavigation from '../../../components/top-navigation/TopNavigation'
import FooterOurLocation from '../../../components/footer-our-location/FooterOurLocation';
import NewsEventsDetail from '../NewsEventsDetail';

const NewsEventDetailPage = (props: any) => {

    return (
        <div className="news-event-detail-page" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
            <NewsEventsDetail {...props} />
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default NewsEventDetailPage;