import TopNavigation from '../../../components/top-navigation/TopNavigation'
import FooterOurLocation from '../../../components/footer-our-location/FooterOurLocation'
import Careers from '../Careers'

function CareersPage() {
    return (
        <div className="privacy-policy" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
                <Careers />
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default CareersPage;