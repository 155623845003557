import TopNavigation from "../../../components/top-navigation/TopNavigation";
import FooterOurLocation from "../../../components/footer-our-location/FooterOurLocation";
import Melaka from "../Melaka";

function SolarPanelMelaka() {
  return (
    <div className="about" style={{ overflow: "hidden" }}>
      <TopNavigation />
      <div>
        <Melaka />
      </div>
      <FooterOurLocation />
    </div>
  );
}

export default SolarPanelMelaka;
