import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography, FormControl, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowRight from '../../images/arrow-right.png';
import './Careers.scss';


function Careers() {

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const careerInfo = [
        {
            label: 'panel1',
            jobTitle: "Business Development Executive (Sales/Solar PV System)",
            jobResp: [
                `Generate New Sales Leads and Client Contracts for Residential Segment.`,
                `Identify Sales Leads, Pitch Goods or Services to New/Existing Clients.`,
                `Maintain a good working relationship with new/existing clients.`,
                `Follow up new business opportunities and setting up meetings.`,
                `Prepare presentation, proposal and quotation and sales follow-up with client.`,
                `Communicating new product developments to prospective clients.`,
                `Overseeing the development of marketing literature.`,
                `Write sales/ business development reports and keep track of proposed clients.`
            ],
            jobReq: [
                `Holders of SPM/ STPM/ Diploma/ Degree in Marketing/ Banking & Finance/ Engineering / Science or any related field.`,
                `Preferably with 2-3 years experience in selling solar systems. However, fresh graduates with the right attitude are welcome to apply too.`,
                `Able to converse in Bahasa Malaysia, Mandarin & English.`,
                `Passionate about environmental sustainability and renewable energy. Independent, self-initiative, responsible and committed, strong communication skills, negotiation skills, excellent presentation skills, good interpersonal skills, strong analytical mind, exemplary leadership skills and passionate desire for success.`,
                `Good command in Microsoft Office, especially PowerPoint & Excel.`,
                `Have pleasant personality, good interpersonal skills, communications skills, self-starter.`,
                `Possess own transport and willing to travel.`
            ],
        },
        {
            label: 'panel2',
            jobTitle: "Engineering Intern",
            jobDescr: "For students to experience a real working environment In the solar industry. The intern will be provided an opportunity to gain hands-on experience in assisting on-going projects and organising project execution activities.",
            jobResp: [],
            jobReq: [],
            jobDescrList: [
                `Minimum Internship Duration: 3 months`,
                `Diploma or Degree in Electrical Engineering`,
                `Likes challenges, not afraid of height especially!`,
                `Passion to explore Solar PV industry`,
                `Coupled with right work attitude`
            ],
            jobDescrText: "Please apply immediately if you fulfill the above requirements and state your internship duration. Submit your application now to kick start your fruitful internship at TERA VA."
        }
    ]

    const handleEmail = () => {
        window.open("mailto:hr@terava-group.com?subject=Subject&body=Body%20goes%20here")
    }

    let joblisting = [
        "Creative Designer",
        "Senior Business Development Manager (Commercial & Industrial)",
        "Senior Business Development Manager (PPA)",
        "Business Development Executive (Residential)",
        "Customer Service Executive",
        "Service & Warranty Manager",
        "Drafter",
        "Design & Costing Engineer",
        "Senior Accounts Executive (Payable)",
        "Land Surveyor"
    ]

    return (
        <div className="careers-descriptions">
            <div className="careers-description-container" >
                <div className="careers-description">
                    {/* <span className="careers">Career</span><br /> */}
                    <h1 className="title">Join Our Team</h1><br />
                    {/* <label className="description">Find the perfect role for you.</label><br /> */}
                </div>
                <div className="careers-expand-bar">
                    {/* {careerInfo.map((info, index) => {
                    return (
                    <div className="careers-sub-expand-bar" key={index}>
                        <Accordion expanded={expanded === info.label} onChange={handleChange(info.label)}>
                            <AccordionSummary
                                 expandIcon={<ExpandMoreIcon />}
                                 aria-controls="panel1bh-content"
                                 id="panel1bh-header"
                            >
                            <Typography className="big-title">{info.jobTitle}</Typography>
                            </AccordionSummary>
                                <AccordionDetails>
                                <FormControl component="fieldset">
                                {info.jobDescr && (
                                <>
                                    <Typography className="title">JOB DESCRIPTION</Typography>
                                    <Typography variant="body2">{info.jobDescr}</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <ul>
                                            {info.jobDescrList.map((resp, respIndex) => (
                                                <li key={respIndex}>{resp}</li>
                                            ))}
                                        </ul>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>{info.jobDescrText}</Typography>
                                </>
                                
                                )}
                                
                                {info.jobResp.length > 0 &&  (
                                <>
                                    <Typography className="title">JOB RESPONSIBILITIES</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <ul>
                                            {info.jobResp.map((resp, respIndex) => (
                                                <li key={respIndex}>{resp}</li>
                                            ))}
                                        </ul>
                                    </Typography>
                                </>
                                )}
                                
                                {info.jobReq.length > 0 &&  (
                                <>
                                    <Typography className="title">
                                    JOB REQUIREMENTS
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <ul>
                                            {info.jobReq.map((req, reqIndex) => (
                                                <li key={reqIndex}>{req}</li>
                                            ))}
                                        </ul>
                                    </Typography>
                                 </>
                                )}
                                <Typography  variant="body2" gutterBottom>
                                    Only shortlisted candidates will be notified.
                                </Typography>
                                <Typography variant="button" gutterBottom style={{margin: "20px 0 10px"}}>
                                <Button 
                                    variant="contained" 
                                    size="large" 
                                    className="button-main-theme"
                                    onClick={handleEmail}
                                >
                                    <div className="wrapper">
                                        <label className="btn-title">Apply</label>
                                        <label className="right-arrow">
                                            <img src={ArrowRight} alt="right-arrow" />
                                        </label>
                                    </div>
                                </Button>
                                </Typography>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    )
                })} */}
                    <ol>
                        {
                            joblisting.map(job => {
                                return <li style={{ fontSize: "22px" }}>{job}</li>
                            })
                        }
                    </ol>

                    <label style={{ fontSize: "22px" }}>Interested candidates, please email <b>hr@terava-group.com</b></label>
                </div>
            </div>
        </div>
    )
}

export default Careers;