//Any formula changes - change in this file

//Total Monthly Consumption (kWh)
function CalculateTotalConsumption(avgElectricityBill: number, type: string, pricing: any) {

    let totalRoundOffFloat = 0

    if (type === "Residential") {
        totalRoundOffFloat = avgElectricityBill

        //     let rate_below_200 = pricing[0].rate / 100
        //     let rate_above_200 = pricing[1].rate / 100
        //     let rate_above_300 = pricing[2].rate / 100
        //     let rate_above_600 = pricing[3].rate / 100
        //     let rate_above_900 = pricing[4].rate / 100


        //     let monthly_bill_200 = 200 * rate_below_200
        //     let monthly_bill_300 = monthly_bill_200 + (100 * rate_above_200)
        //     let monthly_bill_600 = monthly_bill_300 + (300 * rate_above_300)
        //     let monthly_bill_900 = monthly_bill_600 + (300 * rate_above_600)

        //     let minimal_usage_threshold = pricing[1].usageStart - 1
        //     let first_subsequent_threshold = pricing[2].usageStart - 1
        //     let second_subsequent_threshold = pricing[3].usageStart - 1
        //     let highest_usage_threshold = pricing[4].usageStart - 1

        //     if (avgElectricityBill > monthly_bill_900) {
        //         let total = (((avgElectricityBill - monthly_bill_900) / rate_above_900) + highest_usage_threshold)
        //         let totalRoundOff = total.toFixed(2)
        //         totalRoundOffFloat = parseFloat(totalRoundOff)
        //     }


        //     else if (avgElectricityBill > monthly_bill_600 && avgElectricityBill <= monthly_bill_900) {
        //         let total = (((avgElectricityBill - monthly_bill_600) / rate_above_600) + second_subsequent_threshold)
        //         let totalRoundOff = total.toFixed(2)
        //         totalRoundOffFloat = parseFloat(totalRoundOff)
        //     }

        //     else if (avgElectricityBill > monthly_bill_300 && avgElectricityBill <= monthly_bill_600) {
        //         let total = (((avgElectricityBill - monthly_bill_300) / rate_above_300) + first_subsequent_threshold)
        //         let totalRoundOff = total.toFixed(2)
        //         totalRoundOffFloat = parseFloat(totalRoundOff)
        //     }

        //     else if (avgElectricityBill > monthly_bill_200 && avgElectricityBill <= monthly_bill_300) {
        //         let total = (((avgElectricityBill - monthly_bill_200) / rate_above_200) + minimal_usage_threshold)
        //         let totalRoundOff = total.toFixed(2)
        //         totalRoundOffFloat = parseFloat(totalRoundOff)
        //     }

        //     else if (avgElectricityBill > 0 && avgElectricityBill <= monthly_bill_200) {
        //         let total = (avgElectricityBill / rate_below_200)
        //         let totalRoundOff = total.toFixed(2)
        //         totalRoundOffFloat = parseFloat(totalRoundOff)
        //     }

        //     else {
        //         totalRoundOffFloat = 0
        //     }

    }

    else {
        let total = avgElectricityBill / (pricing[0].rate / 100)
        let totalRoundOff = total.toFixed(2)
        totalRoundOffFloat = parseFloat(totalRoundOff)

    }
    return totalRoundOffFloat
}

//Formula change - 1
//Vasolar Capacity
function CalculateProposedInstalledCapacity(totalConsumption: any, type: string, cTRatio: any, maximumDemand: any, tariffGroup: any) {
    if (type === "Residential") {
        if (totalConsumption >= 1100)
            return 17.55;

        else if (totalConsumption >= 1000 && totalConsumption < 1100)
            return 15.795;

        else if (totalConsumption >= 900 && totalConsumption < 1000)
            return 14.625;

        else if (totalConsumption >= 800 && totalConsumption < 900)
            return 13.455;

        else if (totalConsumption >= 700 && totalConsumption < 800)
            return 11.7;

        else if (totalConsumption >= 600 && totalConsumption < 700)
            return 10.53;

        else if (totalConsumption >= 500 && totalConsumption < 600)
            return 8.19;

        else if (totalConsumption >= 400 && totalConsumption < 500)
            return 7.02;

        else if (totalConsumption >= 300 && totalConsumption < 400)
            return 5.85;

        else if (totalConsumption >= 250 && totalConsumption < 300)
            return 5.265;

        else if (totalConsumption >= 201 && totalConsumption < 250)
            return 4.095;
        else
            return 2.925;
    }
    else {
        if (maximumDemand === "" && cTRatio !== "" && totalConsumption > 0) {
            let totals = totalConsumption / 31 / 3.4
            let totalRoundOff = totals.toFixed(2)
            let totalRoundOffFloat = parseFloat(totalRoundOff)
            let total = CalculateCtRatio(cTRatio)
            // console.log("P1",total)
            if (total >= totalRoundOffFloat) {
                return totalRoundOffFloat
            }
            else if (total === 0) {
                return totalRoundOffFloat
            }
            return total
        }
        else if (maximumDemand !== "" && cTRatio === "" && totalConsumption > 0) {
            let totals = totalConsumption / 31 / 3.4
            let totalRoundOff = totals.toFixed(2)
            let totalRoundOffFloat = parseFloat(totalRoundOff)
            let total = CalculateMaximumDemand(maximumDemand)
            // console.log("P2",total)
            if (total >= totalRoundOffFloat) {
                return totalRoundOffFloat
            }
            else if (total === 0) {
                return totalRoundOffFloat
            }
            return total
        }
        if (maximumDemand !== "" && (tariffGroup === "C1" || tariffGroup === "C2" || tariffGroup === "E1" || tariffGroup === "E2" || tariffGroup === "E2s" || tariffGroup === "E3" || tariffGroup === "E3s" || tariffGroup === "F1" || tariffGroup === "F2" || tariffGroup === "H1" || tariffGroup === "H2")) {
            let totals = totalConsumption / 31 / 3.4
            let totalRoundOff = totals.toFixed(2)
            let totalRoundOffFloat = parseFloat(totalRoundOff)
            let cTRatiototal = CalculateCtRatio(cTRatio)
            let total = CalculateMaximumDemand(maximumDemand)
            // console.log("P3",total)
            if (total >= totalRoundOffFloat) {
                return totalRoundOffFloat
            }
            else if (total === 0) {
                if (cTRatiototal !== 0) {
                    return cTRatiototal
                }
                else {
                    return totalRoundOffFloat
                }

            }
            return total
        }
        else if (cTRatio !== "" && (tariffGroup === "B" || tariffGroup === "D" || tariffGroup === "F" || tariffGroup === "H")) {
            let totals = totalConsumption / 31 / 3.4
            let totalRoundOff = totals.toFixed(2)
            let totalRoundOffFloat = parseFloat(totalRoundOff)
            let maxtotal = CalculateMaximumDemand(maximumDemand)
            let total = CalculateCtRatio(cTRatio)
            // console.log("P4",total)
            if (total >= totalRoundOffFloat) {
                return totalRoundOffFloat
            }
            else if (total === 0) {
                if (maxtotal !== 0) {
                    return maxtotal
                }
                else {
                    return totalRoundOffFloat
                }
            }
            return total
        }
        else {
            let total = totalConsumption / 31 / 3.4
            let totalRoundOff = total.toFixed(2)
            let totalRoundOffFloat = parseFloat(totalRoundOff)
            // console.log("P5",total)
            return totalRoundOffFloat
        }
    }
}

//Formula change - 2
//Phase System
function DeterminePhaseSystem(proposedInstalledCapacity: any, type: string) {

    if (type === "Residential") {
        if (proposedInstalledCapacity === 17.55)
            return "Three Phase";

        else if (proposedInstalledCapacity === 15.795)
            return "Three Phase";

        else if (proposedInstalledCapacity === 14.625)
            return "Three Phase";

        else if (proposedInstalledCapacity === 13.455)
            return "Three Phase";

        else if (proposedInstalledCapacity === 11.7)
            return "Three Phase";

        else if (proposedInstalledCapacity === 10.53)
            return "Three Phase";

        else if (proposedInstalledCapacity === 8.19)
            return "Three Phase";

        else if (proposedInstalledCapacity === 7.02)
            return "Single Phase";

        else if (proposedInstalledCapacity === 5.85)
            return "Single Phase";

        else if (proposedInstalledCapacity === 5.265)
            return "Single Phase";

        else if (proposedInstalledCapacity === 4.095)
            return "Single Phase";

        else if (proposedInstalledCapacity === 2.925)
            return "Single Phase";

        else
            return "Single Phase";

    }

    else {
        return '';
    }

}

//Formula change - 4
//System Cost (Vasolar Package)
function CalculateBatterySystemCost(proposedInstalledCapacity: number, type: string) {

    if (type === "Residential") {
        if (proposedInstalledCapacity === 17.55)
            return 67400;

        else if (proposedInstalledCapacity === 15.795)
            return 61000;

        else if (proposedInstalledCapacity === 14.625)
            return 53800;

        else if (proposedInstalledCapacity === 13.455)
            return 50800;

        else if (proposedInstalledCapacity === 11.7)
            return 45000;

        else if (proposedInstalledCapacity === 10.53)
            return 41800;

        else if (proposedInstalledCapacity === 8.19)
            return 33300;

        else if (proposedInstalledCapacity === 7.02)
            return 29800;

        else if (proposedInstalledCapacity === 5.85)
            return 25200;

        else if (proposedInstalledCapacity === 5.265)
            return 23900;

        else if (proposedInstalledCapacity === 4.095)
            return 20900;

        else if (proposedInstalledCapacity === 2.925)
            return 17000;

        else
            return 0;
    }

    else {
        let total = 2650 * proposedInstalledCapacity
        let totalRoundOff = total.toFixed(2)
        let totalRoundOffFloat = parseFloat(totalRoundOff)
        return totalRoundOffFloat
    }
}


// Monthly Energy Generation
function CalculateMonthlyEnergyGeneration(proposedInstalledCapacity: number) {
    let total = (proposedInstalledCapacity * (365/12) * 3.5)
    return total;
}

// Balance Energy Import
function CalculateBalanceEnergyImport(totalConsumption: number, monthlyEnergyGeneration: number) {
    let total = totalConsumption - monthlyEnergyGeneration
    let totalRoundOff = total.toFixed(2)
    let totalRoundOffFloat = parseFloat(totalRoundOff)
    return totalRoundOffFloat
}

// Monthly Saving with VASOLAR
function CalculateMonthlyBillWithVASolar(avgElectricityBill: number, balanceEnergyImport: number, type: string, pricing: any) {
    let totalRoundOffFloat = 0;
    if (type === "Residential") {
        if (pricing && pricing.length > 0) {
            let rate_below_200 = pricing[0].rate / 100
            let rate_above_200 = pricing[1].rate / 100
            let rate_above_300 = pricing[2].rate / 100
            let rate_above_600 = pricing[3].rate / 100
            let rate_above_900 = pricing[4].rate / 100


            let monthly_bill_200 = 200 * rate_below_200
            let monthly_bill_300 = monthly_bill_200 + (100 * rate_above_200)
            let monthly_bill_600 = monthly_bill_300 + (300 * rate_above_300)
            let monthly_bill_900 = monthly_bill_600 + (300 * rate_above_600)

            let minimal_usage_threshold = pricing[1].usageStart - 1
            let first_subsequent_threshold = pricing[2].usageStart - 1
            let second_subsequent_threshold = pricing[3].usageStart - 1
            let highest_usage_threshold = pricing[4].usageStart - 1

            if (balanceEnergyImport >= highest_usage_threshold) {
                let total = (((balanceEnergyImport - highest_usage_threshold) * rate_above_900) + monthly_bill_900)
                let totalRoundOff = total.toFixed(2)
                totalRoundOffFloat = parseFloat(totalRoundOff)
            }
            else if (balanceEnergyImport >= second_subsequent_threshold && balanceEnergyImport < highest_usage_threshold) {
                let total = (((balanceEnergyImport - second_subsequent_threshold) * rate_above_600) + monthly_bill_600)
                let totalRoundOff = total.toFixed(2)
                totalRoundOffFloat = parseFloat(totalRoundOff)
            }
            else if (balanceEnergyImport >= first_subsequent_threshold && balanceEnergyImport < second_subsequent_threshold) {
                let total = (((balanceEnergyImport - first_subsequent_threshold) * rate_above_300) + monthly_bill_300)
                let totalRoundOff = total.toFixed(2)
                totalRoundOffFloat = parseFloat(totalRoundOff)

            }
            else if (balanceEnergyImport >= minimal_usage_threshold && balanceEnergyImport < first_subsequent_threshold) {
                let total = (((balanceEnergyImport - minimal_usage_threshold) * rate_above_200) + monthly_bill_200)
                let totalRoundOff = total.toFixed(2)
                totalRoundOffFloat = parseFloat(totalRoundOff)
            }
            else if (balanceEnergyImport >= 0 && balanceEnergyImport < minimal_usage_threshold) {
                let total = (balanceEnergyImport * rate_below_200)
                let totalRoundOff = total.toFixed(2)
                totalRoundOffFloat = parseFloat(totalRoundOff)
            }
            else {
                totalRoundOffFloat = 0
            }
        }
    }
    else {
        let rate = pricing[0].rate / 100
        let total = balanceEnergyImport * rate
        let totalRoundOff = total.toFixed(2)
        totalRoundOffFloat = Math.abs(parseFloat(totalRoundOff))
    }
    if (totalRoundOffFloat >= avgElectricityBill) {
        return avgElectricityBill
    } else if (totalRoundOffFloat < 0) {
        return 0
    }
    else {
        return totalRoundOffFloat
    }
    // return totalRoundOffFloat
}

//Monthly Bill 
function CalculateMonthlySaving(avgElectricityBill: number, monthlyBillWithVASolar: number) {
    let total = ((avgElectricityBill - monthlyBillWithVASolar))
    let totalRoundOff = total.toFixed(2)
    let totalRoundOffFloat = parseFloat(totalRoundOff)
    return Math.abs(totalRoundOffFloat)
}

function CalculateExtendWarranty(proposedInstalledCapacity: number, type: string) {

    if (type === "Residential") {
        if (proposedInstalledCapacity === 17.55)
            return "RM 723";

        else if (proposedInstalledCapacity === 15.795 || proposedInstalledCapacity === 14.625)
            return "RM 675";

        else if (proposedInstalledCapacity === 13.455 || proposedInstalledCapacity === 11.7)
            return "RM 675";

        else if (proposedInstalledCapacity === 10.53)
            return "RM 480";

        else if (proposedInstalledCapacity === 8.19)
            return "RM 384";

        else if (proposedInstalledCapacity === 7.02)
            return "RM 335"

        else if (proposedInstalledCapacity === 5.85)
            return "RM 287"

        else if (proposedInstalledCapacity === 5.265)
            return "RM 287"

        else if (proposedInstalledCapacity === 4.095)
            return "RM 287"

        else if (proposedInstalledCapacity === 2.925)
            return "RM 238"

        else
            return "RM 0";
    }
    else {
        return 0
    }
}

//Formula change - 5
//Insurance Amount & Premium Payable
function CalculateInsuranceCoverage(proposedInstalledCapacity: number, type: string, batterySystemCost: number) {

    if (type === "Residential") {
        if (proposedInstalledCapacity === 15.795 || proposedInstalledCapacity === 17.55)
            return "Insured Amount RM 50000 , Premium Payable RM 423.40";

        else if (proposedInstalledCapacity === 13.455 || proposedInstalledCapacity === 14.625)
            return "Insured Amount RM 40000 , Premium Payable RM 354.50"

        else if (proposedInstalledCapacity === 7.02 || proposedInstalledCapacity === 8.19 || proposedInstalledCapacity === 10.53 || proposedInstalledCapacity === 11.7)
            return "Insured Amount RM 30000 , Premium Payable RM 306.80"

        else if (proposedInstalledCapacity === 4.095 || proposedInstalledCapacity === 5.265 || proposedInstalledCapacity === 5.85)
            return "Insured Amount RM 20000, Premium Payable RM 237.90"

        else if (proposedInstalledCapacity === 2.925)
            return "Insured Amount RM 10000 , Premium Payable RM 147.80"

        else
            return 0;

    }
    else {
        let total = batterySystemCost * 0.006
        let totalRoundOff = total.toFixed(2)
        let totalRoundOffFloat = parseFloat(totalRoundOff)
        let insuredAmt = Math.abs(totalRoundOffFloat)
        return "RM" + insuredAmt.toString()

    }
}

//Formula change - 8
//Maintenance Price Display
function CalculateOperationAndMaintenance(proposedInstalledCapacity: number, type: string) {

    if (type === "Residential") {
        if (proposedInstalledCapacity === 17.55)
            return "RM 900";

        else if (proposedInstalledCapacity === 14.625 || proposedInstalledCapacity === 15.795)
            return "RM 800";

        else if (proposedInstalledCapacity === 13.455)
            return "RM 700";

        else if (proposedInstalledCapacity === 11.7)
            return "RM 600";

        else if (proposedInstalledCapacity === 8.19 || proposedInstalledCapacity === 10.53)
            return "RM 500";

        else if (proposedInstalledCapacity === 7.02)
            return "RM 400";

        else if (proposedInstalledCapacity === 4.095 || proposedInstalledCapacity === 5.265 || proposedInstalledCapacity === 5.85)
            return "RM 300";

        else if (proposedInstalledCapacity === 2.925)
            return "RM 200";

        else
            return 0;
    }
    else {
        return "RM2400/annum";
    }
}


function CalculateExtendWarrantyCost(proposedInstalledCapacity: number, type: string) {

    if (type === "Residential") {
        if (proposedInstalledCapacity === 17.55)
            return 723;

        else if (proposedInstalledCapacity === 14.625 || proposedInstalledCapacity === 15.795)
            return 675;

        else if (proposedInstalledCapacity === 13.455)
            return 675;

        else if (proposedInstalledCapacity === 11.7)
            return 480;

        else if (proposedInstalledCapacity === 8.19 || proposedInstalledCapacity === 10.53)
            return 384;

        else if (proposedInstalledCapacity === 7.02)
            return 335;

        else if (proposedInstalledCapacity === 4.095 || proposedInstalledCapacity === 5.265 || proposedInstalledCapacity === 5.85)
            return 287;

        else if (proposedInstalledCapacity === 2.925)
            return 238;

        else
            return 0;
    }
    else {
        return 0;
    }
}

//Formula change - 6
//Insurance Cost
function CalculateInsuranceCoverageCost(proposedInstalledCapacity: number, type: string, batterySystemCost: number) {

    if (type === "Residential") {
        if (proposedInstalledCapacity === 15.795 || proposedInstalledCapacity === 17.55)
            return 423.40;

        else if (proposedInstalledCapacity === 13.455 || proposedInstalledCapacity === 14.625)
            return 345.50;

        else if (proposedInstalledCapacity === 7.02 || proposedInstalledCapacity === 8.19 || proposedInstalledCapacity === 10.53 || proposedInstalledCapacity === 11.7)
            return 306.80;

        else if (proposedInstalledCapacity === 4.095 || proposedInstalledCapacity === 5.265 || proposedInstalledCapacity === 5.85)
            return 237.90;

        else if (proposedInstalledCapacity === 2.925)
            return 147.80;

        else
            return 0;

    }
    else {
        let total = batterySystemCost * 0.006
        let totalRoundOff = total.toFixed(2)
        let totalRoundOffFloat = parseFloat(totalRoundOff)
        return Math.abs(totalRoundOffFloat)
        // return 0;
    }
}

//Formula change - 7
//Maintenance Cost
function CalculateOperationAndMaintenanceCost(proposedInstalledCapacity: number, type: string) {

    if (type === "Residential") {
        if (proposedInstalledCapacity === 17.55)
            return 900;

        else if (proposedInstalledCapacity === 14.625 || proposedInstalledCapacity === 15.795)
            return 800;

        else if (proposedInstalledCapacity === 13.455)
            return 700;

        else if (proposedInstalledCapacity === 11.7)
            return 600;

        else if (proposedInstalledCapacity === 8.19 || proposedInstalledCapacity === 10.53)
            return 500;

        else if (proposedInstalledCapacity === 7.02)
            return 400;

        else if (proposedInstalledCapacity === 4.095 || proposedInstalledCapacity === 5.265 || proposedInstalledCapacity === 5.85)
            return 300;

        else if (proposedInstalledCapacity === 2.925)
            return 200;

        else
            return 0;
    }
    else {
        return 2400
    }
}

//Add Ons Cost
function CalculateAddOnCost(operationAndMaintenanceCost: number, insuranceCoverageCost: number, extendWarrantyCost: number) {
    let addOntotal = (operationAndMaintenanceCost + insuranceCoverageCost + extendWarrantyCost)
    return addOntotal
}

//Total Cost
function CalculateTotalCost(batterySystemCost: number, operationAndMaintenanceCost: number, insuranceCoverageCost: number, extendWarrantyCost: number) {
    let total = (batterySystemCost + operationAndMaintenanceCost + insuranceCoverageCost + extendWarrantyCost)
    let totalRoundOff = total.toFixed(2)
    let totalRoundOffFloat = parseFloat(totalRoundOff)
    return Math.abs(totalRoundOffFloat)
}


//ROI
function CalculateROI(batterySystemCost: number, monthSavings: number, discount: number) {
    let cost = batterySystemCost
    if (discount > 0) {
        cost = batterySystemCost - discount
    }
    if (monthSavings > 0) {
        let roi = (cost / monthSavings) / 12
        let totalRoundOff = roi.toFixed(2)
        let totalRoundOffFloat = parseFloat(totalRoundOff)
        return Math.abs(totalRoundOffFloat)
    } else {
        return 0
    }
}

//Panel
function CalculatePanel(proposedInstalledCapacity: number, type: string) {

    if (type === "Residential") {
        let panel = (proposedInstalledCapacity / 580) * 1000
        let totalRoundOff = panel.toFixed(2)
        let totalRoundOffFloat = parseFloat(totalRoundOff)
        return Math.abs(totalRoundOffFloat)
    }
    else {
        let panel = (proposedInstalledCapacity / 470) * 1000
        let totalRoundOff = panel.toFixed(2)
        let totalRoundOffFloat = parseFloat(totalRoundOff)
        return Math.abs(totalRoundOffFloat)
    }
}

//Roof
function CalculateRoof(panel: number) {
    let roof = panel * 2.6
    let totalRoundOff = roof.toFixed(2)
    let totalRoundOffFloat = parseFloat(totalRoundOff)
    return Math.abs(totalRoundOffFloat)
}

//C02
function CalculateCarbonAvoidance(monthlyEnergyGeneration: number) {
    let tree_2percent = monthlyEnergyGeneration * 0.02;
    let tree = (((monthlyEnergyGeneration*12)-0.02)/1000)*0.639;
    // let totalRoundOff = tree.toFixed(2);
    // let totalRoundOffFloat = parseFloat(totalRoundOff);
    return Math.abs(tree);
}

//Tree
function CalculateTreePlanted(carbonAvoidance: number) {
    let tree = carbonAvoidance * 1000 / 16
    let totalRoundOff = tree.toFixed(2)
    let totalRoundOffFloat = parseFloat(totalRoundOff)
    return Math.abs(totalRoundOffFloat)
}

//Car
function CalculateCarDistance(carbonAvoidance: number) {
    let car = carbonAvoidance * 1000000 / 112
    let totalRoundOff = car.toFixed(2)
    let totalRoundOffFloat = parseFloat(totalRoundOff)
    return Math.abs(totalRoundOffFloat)
}

//Formula change - 3
//PVModule
function DeterminePVModule(proposedInstalledCapacity: number, type: string) {
    const residentialPVModuleMapping: { [key: number]: string } = {
        17.55: "30",
        15.795: "27",
        14.625: "25",
        13.455: "23",
        11.7: "20",
        10.53: "18",
        8.19: "14",
        7.02: "12",
        5.85: "10",
        5.265: "9",
        4.095: "7",
        2.925: "5",
    };

    if (type === "Residential") {
        const pvModule = residentialPVModuleMapping[proposedInstalledCapacity];
        return pvModule || "";
    } else {
        return "";
    }
}

//ModuleCode
function DetermineModuleCode(proposedInstalledCapacity: number, type: string) {

    if (type === "Residential") {
        if (proposedInstalledCapacity === 15.795 || proposedInstalledCapacity === 17.55)
            return "HUAWEI 12KTL-M5";

        else if (proposedInstalledCapacity === 13.455 || proposedInstalledCapacity === 14.625)
            return "HUAWEI 10KTL-M1";

        else if (proposedInstalledCapacity === 10.53 || proposedInstalledCapacity === 11.7)
            return "HUAWEI 8KTL-M1";

        else if (proposedInstalledCapacity === 8.19)
            return "HUAWEI 6KTL";

        else if (proposedInstalledCapacity === 7.02)
            return "HUAWEI 5KTL";

        else if (proposedInstalledCapacity === 5.265 || proposedInstalledCapacity === 5.85)
            return "HUAWEI 4KTL-L1";

        else if (proposedInstalledCapacity === 4.095)
            return "HUAWEI 3KTL-L1";

        else if (proposedInstalledCapacity === 2.925)
            return "HUAWEI 2KTL-L1";

        else
            return "";
    }
    else {
        return '';
    }
}

//CT Ratio Calculation
function CalculateCtRatio(cTRatio: any) {
    if (cTRatio === "32")
        return 14.57;

    else if (cTRatio === "63")
        return 29.61;

    else if (cTRatio === "150/5")
        return 68.15;

    else if (cTRatio === "200/5")
        return 82.72;

    else if (cTRatio === "300/5")
        return 140.06;

    else if (cTRatio === "400/5")
        return 183.3;

    else if (cTRatio === "500/5")
        return 229.83;

    else if (cTRatio === "600/5")
        return 272.60;

    else if (cTRatio === "800/5")
        return 370.36;

    else if (cTRatio === "1000/5")
        return 459.66;

    else if (cTRatio === "1200/5")
        return 545.67;

    else if (cTRatio === "1600/5")
        return 732.73;

    else
        return 0;
}

//Maximum Demand Calculation
function CalculateMaximumDemand(maximumDemand: any) {
    let total = maximumDemand * 0.75 * 1.35 * 0.85
    let totalRoundOff = total.toFixed(2)
    let totalRoundOffFloat = parseFloat(totalRoundOff)
    return Math.abs(totalRoundOffFloat)
}

// Tax Savings 
function CalculateTaxSavings(batterySystemCost: number) {
    let total = batterySystemCost * 0.48
    let totalRoundOff = total.toFixed(2)
    let totalRoundOffFloat = parseFloat(totalRoundOff)
    return Math.abs(totalRoundOffFloat)
}

// ROI with Tax Savings
function CalculateROIWithTaxSavings(batterySystemCost: number, monthSavings: number, discount: number) {
    let cost = batterySystemCost
    if (discount > 0) {
        cost = batterySystemCost - discount
    }
    let total = cost * 0.52
    let totalROI = (total / monthSavings) / 12
    let totalRoundOff = totalROI.toFixed(2)
    let totalRoundOffFloat = parseFloat(totalRoundOff)
    return Math.abs(totalRoundOffFloat)
}


export {
    CalculateTotalConsumption,
    CalculateProposedInstalledCapacity,
    CalculateBatterySystemCost,
    CalculateMonthlyEnergyGeneration,
    CalculateBalanceEnergyImport,
    CalculateMonthlySaving,
    CalculateMonthlyBillWithVASolar,
    CalculateExtendWarranty,
    CalculateInsuranceCoverage,
    CalculateOperationAndMaintenance,
    CalculateExtendWarrantyCost,
    CalculateInsuranceCoverageCost,
    CalculateOperationAndMaintenanceCost,
    DeterminePhaseSystem,
    CalculateAddOnCost,
    CalculateTotalCost,
    CalculateROI,
    CalculatePanel,
    CalculateRoof,
    CalculateCarbonAvoidance,
    CalculateTreePlanted,
    CalculateCarDistance,
    DeterminePVModule,
    DetermineModuleCode,
    CalculateCtRatio,
    CalculateMaximumDemand,
    CalculateROIWithTaxSavings,
    CalculateTaxSavings,


}