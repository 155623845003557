import React, { useEffect, useState } from "react";
import './PaymentRedirect.scss';
import { Typography, Button } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { API, graphqlOperation } from "aws-amplify";
import { checkPaymentStatus } from "../../graphql/queries";
import { useHistory } from "react-router-dom";

function PaymentRedirect(props: any) {
    let redirectURL = props.location.search
    let history = useHistory();

    const [count, setCount] = useState(0)
    const [checked, setChecked] = useState(false)
    const [validRedirect, setValidRedirect] = useState(false)
    const [paymentStatus, setPaymentStatus] = useState('')
    const [checking, setChecking] = useState(false)
    
    useEffect(() => {
        setChecking(true)
        const orderNumber: string = checkRedirectURL()
        handleUpdatePaymentStatus(orderNumber)
    }, [])

    function checkRedirectURL() {
        redirectURL = redirectURL.replace("?", "")
        let parameter = redirectURL.split("&")
        let keyArr = []
        let invalid = false
        let orderNumber = ""
        for (let i=0; i<parameter.length; i++){
            let key = parameter[i].split("=")[0]
            keyArr.push(key)
            if (key === "orderNumber"){
                orderNumber = parameter[i].split("=")[1]
            }
        }
        
        if(keyArr.length !== 2 ){
            invalid = true
        }
        else {
            for(let item in keyArr){
                if (keyArr[item] !== "paymentNumber" && keyArr[item] !== "orderNumber"){
                    invalid = true
                    break  
                } 
            }
        } 
        if(!invalid){
            setValidRedirect(true)
            return orderNumber
        } else { 
            handleHomePage()
            return ""
        }
    }

    function startCountdown(seconds: number) {
        let counter = seconds;
          
        const interval = setInterval(() => {
          setCount(counter)
          counter--;
            
          if (counter < 0 ) {
            clearInterval(interval);
          }
        }, 1000);
    }
    
    
    const handleUpdatePaymentStatus = async (orderNumber: string) => {
        let params = { 
            proposalNumber: orderNumber
        }
        try{
            let res: any = await API.graphql(graphqlOperation(checkPaymentStatus, params));
            if (res){
                setChecking(false)
                setPaymentStatus(res.data.checkPaymentStatus.status)
                setChecked(true)
                startCountdown(3)
            }
            
            setTimeout(() => {
                history.push({
                    pathname: `/`
                });
                window.scrollTo(0, 0);
                window.location.reload()
                }, 5000);
        } catch(error){
            console.log(error)
        }
    }

    const handleHomePage = () => {
        history.push({
            pathname: `/`
        });
        window.scrollTo(0, 0);
        window.location.reload()
    }
    
    //Background full grey
    return (
        <>
        {validRedirect ? (
            <div className="payment-redirect-content">
              <div className="payment-redirect-descriptions">
                  {checking ? (
                    <Typography variant="h6">
                        Checking Payment Status ...
                    </Typography> 

                  ): paymentStatus === "Payment Success" ? (
                    <>
                        <CheckCircleIcon style={{color: "green", fontSize:"48px"}}/> 
                        <Typography variant="h6">
                            Deposit Paid
                        </Typography> 
                     </>
                  ) : paymentStatus === "Payment Failed" ? (
                    <>
                        <CancelIcon style={{color: "red", fontSize:"48px"}}/> 
                        <Typography variant="h6">
                            Payment Failed
                        </Typography> 
                     </>
                  ): paymentStatus === "Invalid Order Number" || paymentStatus === "Failed" ? (
                    <>
                        <CancelIcon style={{color: "red", fontSize:"48px"}}/> 
                        <Typography variant="h6">
                            Invalid Payment Id / Order Number
                        </Typography> 
                    </>
                  ): null}
              </div>
              <div>
                  {checking ? null : paymentStatus === "Payment Success" ? (
                    <>
                        <br></br>
                        <Typography variant="subtitle1">
                            Your proposal is submitted and will be reviewed accordingly
                        </Typography> 
                    </>
                  ) : paymentStatus === "Payment Failed" ? (
                    <>
                        <br></br>
                        <Typography variant="subtitle1">
                            Please contact TERA for further action
                        </Typography> 
                    </>
                  ) : null }

              </div>
              {!checking && checked ? (
                  <div>
                      <br></br>
                      <Button onClick={handleHomePage} variant="outlined">Back to Home Page 
                      {count > 0? (
                          <div>...{count}</div>
                      ): null}
                      </Button>
                  </div>
              ) : null}
            </div>
        ) : null}
      
        </>
        
    )
}

export default PaymentRedirect;