import React from 'react';
import './PortfoliosBanner.scss';
import ContactUsFloat from '../../components/contact-us-float/ContactUsFloat';

class PortfoliosBanner extends React.Component {

    render() {
        return (
                <div className="portfolio-banner">
                <div className="portfolio-cover">
                    <div className="portfolio-banner-container">
                        <div className="left-node">
                        <ContactUsFloat />
                            <h1 className="title">Check out our successful projects from residential, commercial and industrial</h1>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PortfoliosBanner;