import CommercialPortfolios from '../CommercialPortfolios';
import DetailCommercialPortfoliosBanner from '../DetailCommercialPortfoliosBanner';
import FooterOurLocation from '../../../../components/footer-our-location/FooterOurLocation'
import TopNavigation from '../../../../components/top-navigation/TopNavigation'

function DetailPageCommercialPortfolios () {
    return (
        <div className="portfolios" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
            <DetailCommercialPortfoliosBanner />
            <CommercialPortfolios />
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default DetailPageCommercialPortfolios;