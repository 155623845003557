import React from "react";
import "./CompletedProjectResidential.scss";
import P1 from "../../../images/residence-P1a.png";
import P2 from "../../../images/residence-P2a.png";
import P3 from "../../../images/residence-P3a.png";
import P4 from "../../../images/residence-P4a.png";
import P5 from "../../../images/residence-P5a.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

class CompletedProjectResidential extends React.Component {

  render() {
    return (
      <div className="completed-project-residential">
        <div className="completed-project-residential-container">
          <div className="left-node">
            <label className="small-heading">Trust us</label>
            <br />
            <label className="title">Completed Projects</label>
          </div>
          <div id="horizontal-scroll" className="right-node">
            <div className="content">
              <Carousel infiniteLoop useKeyboardArrows autoPlay>
                <div>
                  <img src={P1} alt=""/>
                </div>
                <div>
                  <img src={P2} alt=""/>
                </div>
                <div>
                  <img src={P3} alt=""/>
                </div>
                <div>
                  <img src={P4} alt=""/>
                </div>
                <div>
                  <img src={P5} alt=""/>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompletedProjectResidential;
