import React, { useState } from "react";
import Fab from "@material-ui/core/Fab";
import Popover from "@material-ui/core/Popover";
import Calender from "../../images/calendar-week-fill-red.svg";
import JoeSuriaChatbotLogo from "../../images/Joe_Suria_ChatBot_Img.png";
import {
  Checkbox,
  TextField,
  Typography,
  Paper,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import { addEnquiry } from "../../graphql/mutations";
import AlertBar from "../alert/Alert";
import X from "../../images/x.svg";
import "./ContactUsFloat.scss";
import { withStyles } from "@material-ui/core/styles";

const StyledFab = withStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    color: "#0A9DFF",
    "&:hover": {
      backgroundColor: "#fffff",
      color: "#0A9DFF",
    },
  },
}))(Fab);

function ContactUsFloat() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  var validEmailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var validPhonePattern = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]-*[0-9]{7,8}$/;

  let client = ["Residential", "Commercial"];
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    clientType: "",
    subscribe: true,
  });
  const [notify, setNotify] = useState({
    show: false,
    severity: "",
    message: "",
  });

  const [isSaving, setIsSaving] = useState(false);

  const onChange = (event: any) => {
    if (event.target.value === " ") {
      setContactData({ ...contactData, [event.target.name]: "" });
    } else {
      setContactData({
        ...contactData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubscribe = (event: any) => {
    setContactData({ ...contactData, subscribe: !contactData.subscribe });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (contactData.name === "") {
      setNotify({
        show: true,
        severity: "error",
        message: "Name Field Cannot Be Empty",
      });
    } else if (contactData.email === "") {
      setNotify({
        show: true,
        severity: "error",
        message: "Email Field Cannot Be Empty",
      });
    } else if (!contactData.email.match(validEmailPattern)) {
      setNotify({
        show: true,
        severity: "error",
        message: "Invalid Email",
      });
    } else if (contactData.phone === "") {
      setNotify({
        show: true,
        severity: "error",
        message: "Phone Number Field Cannot Be Empty",
      });
    } else if (!contactData.phone.match(validPhonePattern)) {
      setNotify({
        show: true,
        severity: "error",
        message: "Invalid Phone Numbers",
      });
    } else if (
      contactData.phone.length !== 10 &&
      contactData.phone.length !== 11
    ) {
      setNotify({
        show: true,
        severity: "error",
        message: "Invalid Phone Number",
      });
    } else if (
      contactData.clientType === "" ||
      contactData.clientType.includes("Select")
    ) {
      setNotify({
        show: true,
        severity: "error",
        message: "Please select a valid input",
      });
    } else {
      try {
        setIsSaving(true);
        const params = {
          name: contactData.name,
          phone: contactData.phone,
          email: contactData.email,
          clientType: contactData.clientType,
          subscribe: contactData.subscribe,
        };
        let res: any = await API.graphql(graphqlOperation(addEnquiry, params));
        if (res.data.addEnquiry && res.data.addEnquiry.statusCode === "200") {
          setNotify({
            show: true,
            severity: "success",
            message: "Info Submitted",
          });

          setTimeout(() => {
            setContactData({
              ...contactData,
              name: "",
              email: "",
              phone: "",
              clientType: "",
              subscribe: false,
            });
            setAnchorEl(null);
            setIsSaving(false);
          }, 2000);
        } else {
          setNotify({
            show: true,
            severity: "error",
            message: "Failed! Something went wrong, please try again later.",
          });
          setIsSaving(false);
        }
      } catch (err) {
        setNotify({
          show: true,
          severity: "error",
          message: "Failed! Something went wrong, please try again later.",
        });
        setIsSaving(false);
        console.log(err);
      }
    }
  };

  const fabStyle = {
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "rgba(0, 0, 0, 0)",
    boxShadow: "none",
    width: "56px",
    height: "56px",
  };

  return (
    <>
      <div className="fab-chatbot">
        <Fab
          style={fabStyle}
          aria-label="add"
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send/?phone=60197542386&text=Hi,%20I%20wish%20to%20know%20more%20about%20home%20solar%20PV%C2%A0system",
              "_blank"
            )
          }
        >
          <img
            src={JoeSuriaChatbotLogo}
            className="chatbot-logo"
            alt="joe-suria-chatbot-logo"
          />
        </Fab>
      </div>
      <div className="fab">
        <Fab
          style={{ backgroundColor: "#0A9DFF", color: "#ffffff" }}
          variant="extended"
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send/?phone=60197542386&text=Hi,%20I%20wish%20to%20know%20more%20about%20home%20solar%20PV%C2%A0system",
              "_blank"
            )
          }
        >
          <span>Chat With Us</span>
        </Fab>
      </div>
      <div className="fab-contact-us">
        <StyledFab variant="extended" onClick={handleClick}>
          <span className="contact-us-text">
            Contact Us
            <img src={Calender} className="calender-icon" alt="calender" />
          </span>
        </StyledFab>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Paper className="contact-us-mini-bar">
            <Typography className="mini-bar-title">
              Contact us for free consultation!
              <a href="#" style={{ cursor: "pointer" }} onClick={handleClose}>
                <img src={X} className="x-image" alt="" />
              </a>
            </Typography>
            <Paper className="contact-us-content">
              <TextField
                label="Name"
                required
                type="text"
                className="contact-us-mini-text"
                id="outlined-size-small"
                variant="outlined"
                inputProps={{ maxLength: 25 }}
                value={contactData.name || ""}
                onChange={onChange}
                name="name"
                size="small"
                disabled={isSaving ? true : false}
              />
              <FormControl
                variant="filled"
                className="select-main-theme-wrapper"
              >
                <InputLabel className="select-label">Client Type</InputLabel>
                <Select
                  name="clientType"
                  value={
                    contactData.clientType !== ""
                      ? contactData.clientType
                      : "Select Client Type"
                  }
                  onChange={onChange}
                  disabled={isSaving ? true : false}
                  className="select-main-theme"
                >
                  <MenuItem value="Select Client Type">
                    -- Select Client Type --
                  </MenuItem>
                  {client.map((data: any, index: any) => {
                    return (
                      <MenuItem key={index} value={data}>
                        {" "}
                        {data}{" "}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label="Phone Number"
                required
                type="number"
                className="contact-us-mini-text"
                id="outlined-size-small"
                variant="outlined"
                onChange={onChange}
                inputProps={{ maxLength: 13 }}
                value={contactData.phone || ""}
                name="phone"
                size="small"
                disabled={isSaving ? true : false}
              />
              <TextField
                label="Email Address"
                type="email"
                required
                className="contact-us-mini-text"
                id="outlined-size-small"
                variant="outlined"
                size="small"
                onChange={onChange}
                value={contactData.email || ""}
                inputProps={{
                  pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                }}
                name="email"
                disabled={isSaving ? true : false}
              />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="info-container__content"
              >
                <Box>
                  <Checkbox
                    color="primary"
                    checked={contactData.subscribe}
                    onChange={handleSubscribe}
                    disabled={isSaving ? true : false}
                  />
                  <span className="agreement-text-mini-bar">
                    I agree to receive TERA newsletters via email about updates
                    and promotions.
                  </span>
                </Box>
              </Grid>
              <button
                type="button"
                className="btn btn_submit_mini"
                disabled={isSaving ? true : false}
                onClick={handleSubmit}
                style={{ cursor: "pointer" }}
              >
                Submit
              </button>
            </Paper>
          </Paper>
        </Popover>
        <AlertBar notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
}

export default ContactUsFloat;
