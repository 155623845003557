import WebRouting from './infrastructure/routes'

function App() {
	return (
		<div>
			<WebRouting />
		</div>
	);
}

export default App;
