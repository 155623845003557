import { useState, useContext, useEffect } from 'react';
import { FormControl, InputLabel, Select, InputBase, Button, MenuItem, FormHelperText, CircularProgress, Tooltip } from '@material-ui/core';
import ArrowRight from '../../../images/arrow-right.png';
import CTRatio from '../../../images/ctRatio.png';
import MaxDemand from '../../../images/maxDemand.png';
import InfoIcon from '../../../images/info-circle.svg';
import { Path } from '../../../routes'
import { useHistory } from "react-router-dom";
import { DocumentContext } from '../../../context/DocumentContext';
import './IndustrialCalculator.scss';
import ContactUsFloat from '../../../components/contact-us-float/ContactUsFloat';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from "aws-amplify";
import { getPromotionPartner } from "../../../graphql/queries";
import styled from "styled-components";
import { inputPreventDefault } from '../../../components/utils/util';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    customWidth: {
        maxWidth: 500,
    },
}));

interface Props {
    backgroundImage: string;
}

const Wrap = styled.div<Pick<Props, 'backgroundImage'>>`
    background-image: url(${props => props.backgroundImage}) !important;
    background-color: #0a2540e6;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`

function IndustrialCalculator() {
    const {
        commercialCalcData,
        setCommercialCalcData,
        calculateSolarInvestment,
        isCalculate,
        setIsCalculate,
        clearDataIndustrial,
        getTarrifData,
        pricing,
        setPricing,
        landingPage,
        setLandingPage,
        hasPartner,
        setHasPartner
    } = useContext(DocumentContext)
    const classes = useStyles();
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const [tooltipIsOpenCT, setTooltipIsOpenCT] = useState(false);
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    let history = useHistory();
    let tariffGroup = ['B', 'C1', 'C2', 'D', 'E1', 'E2', 'E2s', 'E3', 'E3s', 'F', 'F1', 'F2', 'H', 'H1', 'H2']
    let buildingType = ['Manufacturing facility', 'Warehouse', 'Flex', 'Mall', 'Pad site', 'Office building', 'Service apartment', 'Medical facility',
        'Estate', 'Hotels', 'Restaurants', 'Office', 'Shop lots', 'Petrol Station']
    let modeOfPurchase = ['OutRight Purchase', 'Self Consumption (SelCo)', 'Power Purchase Agreement (PPA)']
    let cTRatioType = ["32", "63", "150/5", "200/5", "300/5", "400/5", "500/5", "600/5", "800/5", "1000/5", "1200/5", "1600/5"]
    const [notify, setNotify] = useState({
        show: false,
        item: '',
        message: ''
    })

    const CtRatioWithTooltip = () => (
        <LightTooltip
            placement="top"
            open={tooltipIsOpenCT}
            onOpen={() => setTooltipIsOpenCT(true)}
            onClose={() => setTooltipIsOpenCT(false)}
            title={<><img src={CTRatio} className="hover-image" alt="maxDemand"></img> </>}
            classes={{ tooltip: classes.customWidth }}>
            <img src={InfoIcon} style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => setTooltipIsOpenCT(!tooltipIsOpen)} alt="info"></img>
        </LightTooltip>
    );

    const MaxDemandWithTooltip = () => (
        <LightTooltip
            placement="top"
            open={tooltipIsOpen}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            title={<><img src={MaxDemand} className="hover-image" alt="maxDemand"></img> </>}
            classes={{ tooltip: classes.customWidth }}>
            <label><img src={InfoIcon} style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => setTooltipIsOpen(!tooltipIsOpen)} alt="info"></img></label>
        </LightTooltip>
    );

    useEffect(() => {
        clearDataIndustrial()
        getLandingPageByQueryURL()
    }, [])

    useEffect(() => {
        localStorage.setItem('document', JSON.stringify(commercialCalcData))
    }, [commercialCalcData])

    useEffect(() => {
        //query pricing data every time user change the value
        getTarrifData(commercialCalcData.tariffGroup).then((res: any) => {
            setPricing(res)
        })
    }, [commercialCalcData.tariffGroup])



    const getLandingPageByQueryURL = async () => {
        if (hasPartner) {
            setCommercialCalcData({ ...commercialCalcData, promoCode: landingPage.promoCode })
            return
        }
        const { pathname } = history.location
        const partner = pathname.split("/")
        if (partner.length > 2 && partner[partner.length - 1] !== "") {
            setIsLoading(true)
            setHasPartner(true)
            let partnerURL = "/" + partner[partner.length - 1]
            try {
                let res: any = await API.graphql(graphqlOperation(getPromotionPartner, { partnerURL: partnerURL }));
                if (res) {
                    let landingPageData = res.data.getPromotionPartner
                    setLandingPage({
                        ...landingPageData,
                        displaySecondPageText: "Fill up this form to be entitled for " + landingPageData.partnerName + "Points & a lower deposit rate"
                    })
                    setCommercialCalcData({ ...commercialCalcData, promoCode: landingPageData.promoCode })
                }

            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        } else {
            setHasPartner(false)
            setLandingPage({
                ...landingPage,
                backgroundImage: "https://d29xu9sbwklvj5.cloudfront.net/public/landing-page/default/commercial-background-image.jpg"
            })
        }

    }

    const onChange = (e: any) => {
        if (e.target.value === ' ') {
            setCommercialCalcData({ ...commercialCalcData, [e.target.name]: '' })
        } else {
            setCommercialCalcData({ ...commercialCalcData, [e.target.name]: e.target.value })
        }
        if (e.target.name === 'tariffGroup' && e.target.value !== "") {
            setNotify({ ...notify, show: false, item: 'tariffGroup' })
        }
        if (e.target.name === 'buildingType' && e.target.value !== "") {
            setNotify({ ...notify, show: false, item: 'buildingType' })
        }
        if (e.target.name === 'avgElectricityBill' && e.target.value > 0) {
            setNotify({ ...notify, show: false, item: 'avgElectricityBill' })
        }

        if (e.target.name === 'modeOfPurchase' && e.target.value !== "") {
            setNotify({ ...notify, show: false, item: 'modeOfPurchase' })
        }
    }

    const handleCalculate = (event: any) => {
        event.preventDefault();
        if (commercialCalcData.tariffGroup === "" || commercialCalcData.tariffGroup.includes("Select")) {
            setNotify({
                show: true,
                item: "tariffGroup",
                message: 'Please select a valid input'
            })
        }
        else if (commercialCalcData.buildingType === "" || commercialCalcData.buildingType.includes("Select")) {
            setNotify({
                show: true,
                item: "buildingType",
                message: 'Please select a valid input'
            })
        }
        else if (commercialCalcData.avgElectricityBill == 0) {
            setNotify({
                show: true,
                item: "avgElectricityBill",
                message: 'Please enter an amount more than 0'
            })
        }
        else if (commercialCalcData.modeOfPurchase === "" || commercialCalcData.modeOfPurchase.includes("Select")) {
            setNotify({
                show: true,
                item: "modeOfPurchase",
                message: 'Please select a valid input'
            })
        }
        else {
            setIsSaving(true)
            setIsCalculate(true)
            calculateSolarInvestment(commercialCalcData.avgElectricityBill, "Commercial", pricing, commercialCalcData.cTRatio, commercialCalcData.maximumDemand, commercialCalcData.tariffGroup)
            setTimeout(() => {
                setIsSaving(false)
                history.push(Path.FILLUPDETAILCOMMERCIAL)
                setIsCalculate(false)
            }, 1500)

        }
    }

    // console.log("Commercial", commercialCalcData)

    return (
        <>
            {isLoading ? null : (
                <Wrap className="industrial-calculator" backgroundImage={landingPage.backgroundImage}>
                    <div className="cover">
                        <div className="industrial-calculator-container">
                            <div className="left-node">
                                <ContactUsFloat />
                                {hasPartner ? (
                                    <img src={landingPage.partnerLogo} alt={landingPage.partnerName} width="500px" height="178.112px" />
                                ) : (
                                    <h1 className="title">Realise your earnings in green powered energy for your business</h1>
                                )}
                            </div>
                            <div className="right-node">
                                <div className="calculator-form">
                                    <div className="title-wrapper">
                                        {hasPartner ? (
                                            <label className="title">{landingPage.displayInputText} <span>TERA.</span></label>
                                        ) : (
                                            <label className="title">Find out how much you can earn with <span>TERA</span></label>
                                        )}
                                    </div>
                                    <div>
                                        <div>
                                            <FormControl variant="filled" className="select-main-theme-wrapper">
                                                <InputLabel className="select-label">Tariff Group</InputLabel>
                                                <Select
                                                    name="tariffGroup"
                                                    value={commercialCalcData.tariffGroup !== "" ? commercialCalcData.tariffGroup : "Select Tariff Group"}
                                                    onChange={onChange}
                                                    disabled={isCalculate ? true : false}
                                                    className="select-main-theme">
                                                    {tariffGroup.map((data: any, index: any) => {
                                                        return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                                    })}
                                                </Select>
                                                {notify.item === "tariffGroup" && notify.show ? (
                                                    <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                                ) : null}

                                            </FormControl>
                                            <FormControl variant="filled" className="select-main-theme-wrapper">
                                                <InputLabel className="select-label">Building Type</InputLabel>
                                                <Select
                                                    name="buildingType"
                                                    value={commercialCalcData.buildingType !== "" ? commercialCalcData.buildingType : "Select Building Type"}
                                                    onChange={onChange}
                                                    disabled={isCalculate ? true : false}
                                                    className="select-main-theme">
                                                    <MenuItem value="Select Building Type">-- Select Building Type --</MenuItem>
                                                    {buildingType.map((data: any, index: any) => {
                                                        return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                                    })}
                                                </Select>
                                                {notify.item === "buildingType" && notify.show ? (
                                                    <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                                ) : null}

                                            </FormControl>
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                            <div className="custom-price-input ">
                                                <label className="unit">RM</label>
                                                <InputBase
                                                    type="number"
                                                    className="price hide-input-arrow "
                                                    name="avgElectricityBill"
                                                    onChange={(event: any) => {
                                                        const regex = /^\d*\.?\d{0,2}$/;
                                                        const { value } = event.target;
                                                        if (regex.test(value)) {
                                                            if (value < 1000000) {
                                                                setCommercialCalcData({
                                                                    ...commercialCalcData,
                                                                    avgElectricityBill: value
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 10,
                                                    }}
                                                    value={commercialCalcData.avgElectricityBill}
                                                    placeholder="Average electricity bill (monthly)"
                                                    disabled={isCalculate ? true : false}
                                                    onKeyDown={(evt: any) => inputPreventDefault(evt, false)}
                                                />
                                            </div>
                                            <div style={{ marginBottom: "15px" }}>
                                                {notify.item === "avgElectricityBill" && notify.show ? (
                                                    <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                                ) : null}
                                            </div>

                                        </div>
                                        <div>
                                            <FormControl variant="filled" className="select-main-theme-wrapper">
                                                <InputLabel className="select-label">Mode of Purchase</InputLabel>
                                                <Select
                                                    name="modeOfPurchase"
                                                    value={commercialCalcData.modeOfPurchase !== "" ? commercialCalcData.modeOfPurchase : "Select Mode of Purchase"}
                                                    onChange={onChange}
                                                    disabled={isCalculate ? true : false}
                                                    className="select-main-theme">
                                                    <MenuItem value="Select Mode of Purchase">-- Select Mode of Purchase --</MenuItem>
                                                    {modeOfPurchase.map((data: any, index: any) => {
                                                        return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                                    })}
                                                </Select>
                                                {notify.item === "modeOfPurchase" && notify.show ? (
                                                    <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                                ) : null}

                                            </FormControl>
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                            <div className="custom-price-input ">
                                                <label className="unit">kW</label>
                                                <InputBase
                                                    type="number"
                                                    className="price hide-input-arrow "
                                                    name="maximumDemand"
                                                    onChange={(event: any) => {
                                                        const regex = /^\d*\.?\d{0,2}$/;
                                                        const { value } = event.target;
                                                        if (regex.test(value)) {
                                                            if (value <= 3600) {
                                                                setCommercialCalcData({
                                                                    ...commercialCalcData,
                                                                    maximumDemand: value
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 10,
                                                    }}
                                                    value={commercialCalcData.maximumDemand}
                                                    placeholder="Maximum Demand (Optional)"
                                                    disabled={isCalculate ? true : false}
                                                    onKeyDown={(evt: any) => inputPreventDefault(evt, false)}
                                                />
                                                <MaxDemandWithTooltip />
                                            </div>
                                            <FormHelperText>Disclaimer: average max demand / 12 months</FormHelperText>
                                            <FormHelperText>Applicable: Tariff C1, C2, E1, E2 & E2s</FormHelperText>
                                        </div>
                                        <FormControl variant="filled" className="select-main-theme-wrapper">
                                            <InputLabel style={{ pointerEvents: "auto" }} className="select-label">CT Ratio <CtRatioWithTooltip /></InputLabel>
                                            <Select
                                                name="cTRatio"
                                                value={commercialCalcData.cTRatio !== "" ? commercialCalcData.cTRatio : "Select CT Ratio"}
                                                onChange={onChange}
                                                disabled={isCalculate ? true : false}
                                                className="select-main-theme">
                                                <MenuItem value="Select CT Ratio">-- Select CT Ratio (Optional) --</MenuItem>
                                                {cTRatioType.map((data: any, index: any) => {
                                                    return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormHelperText>Applicable: Tariff B, D, F & H</FormHelperText>

                                        <div>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                className="button-main-theme calculate"
                                                // href={Path.FILLUPDETAIL}
                                                onClick={handleCalculate}
                                                disabled={isCalculate ? true : false}
                                            >
                                                <div className="wrapper">
                                                    <label className="btn-title">Calculate</label>
                                                    <label className="right-arrow">
                                                        {isSaving ? (
                                                            <CircularProgress style={{ color: "white" }} />
                                                        ) : (
                                                            <img src={ArrowRight} alt="right-arrow" />
                                                        )}
                                                    </label>
                                                </div>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wrap>
            )}
        </>
    )
}

export default IndustrialCalculator;

// function ms(res: (value: unknown) => void, ms: any): void {
//     throw new Error('Function not implemented.');
// }
