import './AboutUs.scss';

function AboutUs() {
    return (
        <div className="aboutuss-description">
            <div className="aboutus-description-container" >
                <div className="aboutus-description">
                <span className="aboutus">ABOUT US</span><br />
                    <label className="title">TERA VA SDN BHD </label><br />
                    <label className="description">Established in 2011 when the Malaysian solar industry was still at its infancy. Since then, we have grown to become a major player in the field of renewable solar energy. We are an Engineering, Procurement and Construction (EPC) Contractor registered with the Sustainable Energy Development Authority (SEDA) and with CIDB Malaysia.</label><br />
                    <label className="description">Being multi-racial and multi-ethnic, we are very proud to be truly Malaysian, not just in appearance but also in intent. Made up of professional, reliable, and honest futurists and innovators, our vision is to help Malaysia move towards competitive, greener Renewable Solar Energy. In this we are committed to be the industry leader in the field. Our mission is to enable both Residential Owners and Commercial Clients such as Manufacturers, Factories and Businesses enjoy the cost savings and benefits of solar energy. Commercial clients benefit by achieving unique cost competitiveness whereas residential owners get an opportunity to earn passive income.</label><br />
                    <label className="description">Our TERA Photovoltaic (PV) System is a result of our strategy in continuing to improve our work processes, products and services. The TERA PV System has been trademarked in 2018 as a 100% home-grown Malaysian Brand. Our capability in providing our clients with solar energy solutions that are of world-class standard has resulted in customer delight and has positioned us as a respected brand in the industry. We also offer consulting services in the form of site visits by our team of experts for the purpose of evaluation and to design a solar solution customized to your needs.</label><br /><br/><br />
                    
                    <label className="title">MISSION & VISION </label><br />
                    <label className="description"><b>MISSION</b></label><br />
                    <label className="description">"We, the <b><u>TERAVIAN</u></b> made it a  <b><u>MISSION</u></b> in our life that we  <b><u>STRIVE</u></b> to enable our Clients to achieve <b><u>UNIQUE BUSINESS COMPETITIVENESS</u></b> and <b><u>LIFESTYLE HAPPINESS</u></b>."</label><br/>
                    <label className="description"><b>VISION</b></label><br />
                     <label className="description">"We, the <b><u>TERAVIAN</u></b> en<b><u>VISION</u></b> to lead the Energy Sector Transformation from Fossil to Renewable Source."</label><br/>
                </div>
            </div>
            
        </div>
    )
}

export default AboutUs;