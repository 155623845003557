import Moment from 'moment'

export default {
  isEmptyString(str) {
    if (
      str === null ||
      str === undefined ||
      (str && str.toString().match(/^ *$/) !== null) ||
      str.toString().replace(/[\u200E\u200F\uFEFF\u00A0 ]/g, '') === ''
    ) {
      return true
    } else {
      return str.length < 1
    }
  },
  isBetweenTwoDates(start, end, date) {
    if (date === undefined) {
      const isOpen = Moment().isBetween(Moment(start), Moment(end), 'hour', [])
      return isOpen
    } else return Moment(date).isBetween(Moment(start), Moment(end))
  },
  isString(str) {
    return /^[a-zA-Z0-9\u200E\u200F\uFEFF\u00A0~!@#$%^&*()_+{}|:" <>?`=[\];',\\.//\n/-]*$/.test(str)
  },
  isDecimal(str) {
    return /^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/.test(str)
  },
  isDigit(str) {
    return /^\d+$/.test(str)
  },
  isEmail(str) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      str
    )
  },

  isSpecialChar(str) {
    return /^[\w&.,!'()&/-\s]*$/.test(str)
  },
  isUrl(str) {
    const regExQ = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:[+~%.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
    if (str.match(regExQ)) {
      return true
    } else {
      return false
    }
  },

  isEmpty(obj) {
    var hasOwnProperty = Object.prototype.hasOwnProperty
    if (obj === null) return true
    if (obj === undefined) return true
    if (obj.length > 0) return false
    if (obj.length === 0) return true
    if (typeof obj !== 'object') return true
    for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false
    }

    return true
  }
}
