import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  InputAdornment,
  InputBase,
  InputLabel,
  Paper,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import ArrowRight from "../../../images/arrow-right.png";
import CO2 from "../../../images/co2-cloud.svg";
import roof from "../../../images/roof.svg";
import { ACTIONS } from "../residential/page/SolarInvestmentCalculator";
import "./CalculatorDetail.scss";
import AlertBar from "../../../components/alert/Alert";
import { inputPreventDefault } from "../../../components/utils/util";

function CalculatorDetail(props: any) {
  const useStyles = makeStyles((theme) => ({
    inputOutline: {
      borderRadius: theme.shape.borderRadius,
      border: "1px solid #ced4da",
      padding: "8px 12px",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
    startAdornment: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const [notify, setNotify] = useState({
    show: false,
    severity: "",
    message: "",
  });

  const {
    residentialCalcData,
    setResidentialCalcData,
    state,
    dispatch,
    resetRadio,
    setResetRadio,
    handleback,
    handleConfirm,
    isSaving,
    setSlider,
    handleApplyPromoCode,
    handleApplySalesAgentCode,
    validPromoCode,
    applyPromoCode,
    validSalesAgentCode,
    applySalesAgentCode,
    salesAgentDiscountValue,
    salesAgentDiscountType,
    isValidating,
    isSalesAgentValidating,
    hasPartner,
    promoCodeApplied,
    setPromoCodeApplied,
    salesAgentCodeApplied,
    setSalesAgentCodeApplied,
    finalAmount,
    setFinalAmount,
    marks,
    setMarks,
  } = props;

  useEffect(() => {
    clearRadio();
  }, [resetRadio, promoCodeApplied, salesAgentCodeApplied]);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [tempValue, setTempValue] = useState({
    insuranceCoverage: "empty",
    operationAndMaintenance: "empty",
    extendWarranty: "empty",
  });

  const clearRadio = () => {
    if (resetRadio) {
      setTempValue({
        ...tempValue,
        insuranceCoverage: "empty",
        extendWarranty: "empty",
        operationAndMaintenance: "empty",
      });
      setResidentialCalcData({
        ...residentialCalcData,
        insuranceCoverage: false,
        extendWarranty: false,
        operationAndMaintenance: false,
        totalAddOnCost: 0,
      });
    }
    setResetRadio(false);
  };

  function roundUpToNearestHundred(value: number) {
    return Math.ceil(value / 100) * 100;
  }

  const handleRadioChange = (e: any) => {
    let reset = false;
    for (let [key, value] of Object.entries(tempValue)) {
      if (e.target.name === key) {
        if (value !== "empty") {
          //reset radio
          setTempValue({ ...tempValue, [e.target.name]: "empty" });
          setResidentialCalcData({
            ...residentialCalcData,
            [e.target.name]: false,
          });
          reset = true;
        } else {
          //select radio
          setTempValue({ ...tempValue, [e.target.name]: e.target.value });
          setResidentialCalcData({
            ...residentialCalcData,
            [e.target.name]: true,
          });
        }
      }
    }
    const {
      insuranceCoverageCost,
      operationAndMaintenanceCost,
      extendWarrantyCost,
      batterySystemCost,
    } = state.initialCalc;
    if (reset) {
      if (e.target.name === "insuranceCoverage") {
        dispatch({
          type: ACTIONS.INSURANCE,
          payload: {
            [e.target.name]: true,
            insuranceCoverageCost,
            operation: "minus",
          },
        });
      } else if (e.target.name === "extendWarranty") {
        dispatch({
          type: ACTIONS.WARRANTY,
          payload: {
            [e.target.name]: true,
            extendWarrantyCost,
            operation: "minus",
          },
        });
      } else if (e.target.name === "operationAndMaintenance") {
        dispatch({
          type: ACTIONS.MAINTENANCE,
          payload: {
            [e.target.name]: true,
            operationAndMaintenanceCost,
            operation: "minus",
          },
        });
      }
    } else {
      if (e.target.name === "insuranceCoverage") {
        dispatch({
          type: ACTIONS.INSURANCE,
          payload: {
            [e.target.name]: true,
            insuranceCoverageCost,
            operation: "add",
          },
        });
      } else if (e.target.name === "extendWarranty") {
        dispatch({
          type: ACTIONS.WARRANTY,
          payload: {
            [e.target.name]: true,
            extendWarrantyCost,
            operation: "add",
          },
        });
      } else if (e.target.name === "operationAndMaintenance") {
        dispatch({
          type: ACTIONS.MAINTENANCE,
          payload: {
            [e.target.name]: true,
            operationAndMaintenanceCost,
            operation: "add",
          },
        });
      }
    }
    dispatch({
      type: ACTIONS.CALCULATETOTALCOST,
      payload: {
        [e.target.name]: true,
        batterySystemCost,
        promoCodeApplied:
          promoCodeApplied || residentialCalcData.validReferralPromo,
      },
    });
    if (promoCodeApplied || residentialCalcData.validReferralPromo) {
      dispatch({ type: ACTIONS.RECALCULATEROI });
    }
    const addOnValue = state.initialCalc[e.target.name + "Cost"];
    if (residentialCalcData?.discountType === "Percentage") {
      setFinalAmount(
        reset
          ? roundUpToNearestHundred(finalAmount - addOnValue)
          : roundUpToNearestHundred(finalAmount + addOnValue)
      );
    } else {
      setFinalAmount(
        reset ? finalAmount - addOnValue : finalAmount + addOnValue
      );
    }
  };

  const onChange = (e: any) => {
    setResetRadio(true);
    if (e.target.name === "promoCode") {
      if (e.target.value.trim() === "") {
        setResidentialCalcData({ ...residentialCalcData, [e.target.name]: "" });
      } else {
        setResidentialCalcData({
          ...residentialCalcData,
          [e.target.name]: e.target.value.toUpperCase(),
        });
      }
      setPromoCodeApplied(false);
      dispatch({ type: ACTIONS.RESETDISCOUNT });
    } else if (e.target.name === "salesAgentCode") {
      if (e.target.value.trim() === "") {
        setResidentialCalcData({ ...residentialCalcData, [e.target.name]: "" });
        clearRadio();
      } else {
        setResidentialCalcData({
          ...residentialCalcData,
          [e.target.name]: e.target.value.toUpperCase(),
        });
      }
      setSalesAgentCodeApplied(false);
    } else {
      if (e.target.value === " ") {
        setResidentialCalcData({ ...residentialCalcData, [e.target.name]: "" });
      } else {
        setResidentialCalcData({
          ...residentialCalcData,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleTotalCostChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^\d*\.?\d{0,2}$/;
    const { value } = event.target;
    const inputValue = parseFloat(value);
    if (regex.test(value)) {
      if (!isNaN(inputValue) && isFinite(inputValue)) {
        const roundedValue = parseFloat(inputValue.toFixed(2));
        setFinalAmount(roundedValue);
        dispatch({
          type: ACTIONS.SETFINALAMOUNT,
          payload: { finalAmount: roundedValue },
        });
      } else {
        setFinalAmount(0);
        dispatch({ type: ACTIONS.SETFINALAMOUNT, payload: { finalAmount: 0 } });
      }
    }
  };

  const handleCheckIfTotalCostValid = (event: any) => {
    const inputValue = parseFloat(event.target.value);
    const totalAmount = renderTotalCost();
    let differenceInInputtedValue =
      Math.round((totalAmount - inputValue) * 100) / 100;

    if (salesAgentCodeApplied) {
      let salesAgentDiscount = 0;
      if (salesAgentDiscountType === "Amount") {
        salesAgentDiscount = salesAgentDiscountValue;
      } else if (
        salesAgentDiscountType === "Percentage" &&
        residentialCalcData?.discountType === "Percentage"
      ) {
        salesAgentDiscount = (totalAmount * salesAgentDiscountValue) / 100;
      } else {
        salesAgentDiscount = roundUpToNearestHundred(
          state.initialCalc.batterySystemCost * (salesAgentDiscountValue / 100)
        );
      }
      if (differenceInInputtedValue > salesAgentDiscount) {
        setNotify({
          show: true,
          severity: "error",
          message:
            "Discount given cannot exceed the max discount value valid to be given from the applied promo or referral code.",
        });
        if (
          residentialCalcData.discountType === "Percentage" &&
          salesAgentDiscountType === "Percentage"
        ) {
          setFinalAmount(totalAmount);
        } else if (residentialCalcData.discountType === "Percentage") {
          setFinalAmount(roundUpToNearestHundred(totalAmount));
        } else {
          setFinalAmount(totalAmount);
        }
      } else if (differenceInInputtedValue < 0) {
        setFinalAmount(totalAmount);
      }
    } else {
      setNotify({
        show: true,
        severity: "error",
        message: "Discount can only be given with salesagent code.",
      });
      if (residentialCalcData.discountType === "Percentage") {
        setFinalAmount(roundUpToNearestHundred(residentialCalcData.totalCost));
      } else {
        setFinalAmount(residentialCalcData.totalCost);
      }
    }
  };

  const handleSliderChange = (event: any, newValue: any) => {
    setSlider(true);
    setResetRadio(true);
    const { proposedInstalledCapacity } = state.initialCalc;
    const { avgElectricityBill, phaseSystem } = residentialCalcData;
    dispatch({
      type: ACTIONS.CALCULATEBYBILLANDCAPACITY,
      payload: {
        avgElectricityBill,
        proposedInstalledCapacity: newValue,
        totalProposedInstalledCapacity: newValue,
        phaseSystem,
      },
    });
    dispatch({
      type: ACTIONS.SLIDER,
      payload: { proposedInstalledCapacity: newValue },
    });
    setResidentialCalcData({
      ...residentialCalcData,
      proposedInstalledCapacity: newValue,
    });
    dispatch({ type: ACTIONS.RESET });
    dispatch({ type: ACTIONS.DISCOUNT });
  };

  useEffect(() => {
    let marksData = null;
    if (residentialCalcData.phaseSystem === "Single Phase") {
      marksData = [
        { value: 2.925 },
        { value: 4.095 },
        { value: 5.265 },
        { value: 5.85 },
        { value: 7.02 },
      ];
    } else {
      marksData = [
        { value: 2.925 },
        { value: 4.095 },
        { value: 5.265 },
        { value: 5.85 },
        { value: 7.02 },
        { value: 8.19 },
        { value: 10.53 },
        { value: 11.7 },
        { value: 13.455 },
        { value: 14.625 },
        { value: 15.795 },
        { value: 17.55 },
      ];
    }

    let selectedMarks = [];

    if (residentialCalcData.avgElectricityBill < 201) {
      selectedMarks = marksData.slice(0, 1);
    } else if (residentialCalcData.avgElectricityBill < 250) {
      selectedMarks = marksData.slice(0, 2);
    } else if (residentialCalcData.avgElectricityBill < 300) {
      selectedMarks = marksData.slice(0, 3);
    } else if (residentialCalcData.avgElectricityBill < 400) {
      selectedMarks = marksData.slice(0, 4);
    } else if (residentialCalcData.avgElectricityBill < 500) {
      selectedMarks = marksData.slice(0, 5);
    } else if (residentialCalcData.avgElectricityBill < 600) {
      selectedMarks = marksData.slice(0, 6);
    } else if (residentialCalcData.avgElectricityBill < 700) {
      selectedMarks = marksData.slice(0, 7);
    } else if (residentialCalcData.avgElectricityBill < 800) {
      selectedMarks = marksData.slice(0, 8);
    } else if (residentialCalcData.avgElectricityBill < 900) {
      selectedMarks = marksData.slice(0, 9);
    } else if (residentialCalcData.avgElectricityBill < 1000) {
      selectedMarks = marksData.slice(0, 10);
    } else if (residentialCalcData.avgElectricityBill < 1100) {
      selectedMarks = marksData.slice(0, 11);
    } else {
      selectedMarks = marksData.slice();
    }
    state.initialCalc.proposedInstalledCapacity =
      selectedMarks[selectedMarks.length - 1].value;
    setMarks(selectedMarks);
  }, [residentialCalcData.avgElectricityBill, residentialCalcData.phaseSystem]);

  //Number with Comma
  function NumberFormat(num: number) {
    return num?.toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  const renderDiscount = () => {
    if (promoCodeApplied || residentialCalcData?.validReferralPromo) {
      let totalDiscount =
        state.initialCalc.discountType === "Percentage"
          ? (state.initialCalc.discountValue / 100) *
            state.initialCalc.batterySystemCost
          : state.initialCalc.discountValue;
      return <h1>RM {NumberFormat(totalDiscount)}</h1>;
    } else {
      let totalDiscount = state.initialAddOnTotal.totalDiscount;
      return <h1>RM {NumberFormat(totalDiscount)}</h1>;
    }
  };

  const renderTotalCost = () => {
    if (promoCodeApplied || residentialCalcData?.validReferralPromo) {
      return state.initialCalc.discountType === "Percentage"
        ? ((100 - state.initialCalc.discountValue) / 100) *
            state.initialCalc.batterySystemCost +
            state.initialAddOnTotal.totalAddOnCost
        : state.initialCalc.batterySystemCost -
            state.initialCalc.discountValue +
            state.initialAddOnTotal.totalAddOnCost;
    } else {
      return state.initialAddOnTotal.totalCost;
    }
  };

  return (
    <div className="calculator-detail-descriptions">
      <div className="calculator-detail-container">
        <div className="calculator-detail-description">
          <span className="calculator-detail">Solar Investment Calculator</span>
          <br />
          <label className="title">
            Find out how much you can save with <span>TERA</span>
          </label>
          <br />
        </div>
        <Paper className="paper">
          <Grid container spacing={3} className="info-container">
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={3}
              className="info-container__content"
            >
              <Box component="span" className="info-container__content__box">
                <p>Your monthly savings with TERA</p>
                <h1>RM {NumberFormat(state.initialCalc.monthSavings)}</h1>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={3}
              className="info-container__content"
            >
              <Box component="span" className="info-container__content__box">
                <p>Your Current Monthly TNB Bill</p>
                <h1>RM {residentialCalcData.avgElectricityBill}</h1>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={3}
              className="info-container__content"
            >
              <Box component="span" className="info-container__content__box">
                <p>Your Monthly TNB Bill with TERA</p>
                <h1>
                  RM{" "}
                  {state.initialCalc.monthlyBillWithVASolar
                    ? NumberFormat(state.initialCalc.monthlyBillWithVASolar)
                    : NumberFormat(0)}
                </h1>
                <span style={{ textAlign: "left" }}>
                  *Based on monthly average energy generation
                </span>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={3}
              className="info-container__content"
            >
              <Box component="span" className="info-container__content__box">
                <p>Maximum installed capacity</p>
                <h1>{state.initialCalc.proposedInstalledCapacity} kWp </h1>
                <div>
                  <Grid item xs>
                    <Slider
                      style={{ cursor: "pointer" }}
                      name="proposedInstalledCapacity"
                      max={17.55}
                      step={null}
                      value={state.initialCalc.proposedInstalledCapacity || 0}
                      onChange={handleSliderChange}
                      aria-labelledby="discrete-slider-custom"
                      marks={marks}
                    />
                  </Grid>
                </div>
                {/* <span style={{textAlign: "left"}}>Please click the Calculate button to get the updated prices</span> */}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={3}
              className="info-container__content"
            >
              <Box component="span" className="info-container__content__box">
                <p>ROI</p>
                <h1>{state.initialCalc.paybackPeriod} years</h1>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={3}
              className="info-container__content"
            >
              <Box component="span" className="info-container__content__box">
                <p>System Cost</p>
                <h1>RM {NumberFormat(state.initialCalc.batterySystemCost)}</h1>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={3}
              className="info-container__content"
            >
              <Box component="span" className="info-container__content__box">
                <p>Total AddOns Cost</p>
                <h1>
                  RM{" "}
                  {NumberFormat(
                    salesAgentCodeApplied
                      ? state.initialAddOnTotal.totalAddOnCost
                      : 0
                  )}
                </h1>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={3}
              className="info-container__content"
            >
              <Box component="span" className="info-container__content__box">
                <p>Total Discount</p>
                {renderDiscount()}
              </Box>
            </Grid>
            {salesAgentCodeApplied ? (
              <Grid
                item
                xs={12}
                sm={9}
                md={6}
                lg={3}
                className="info-container__content"
              >
                <Box component="span" className="info-container__content__box">
                  <p>Total Cost</p>
                  <InputBase
                    type="number"
                    startAdornment={
                      <InputAdornment
                        position="start"
                        className={classes.startAdornment}
                      >
                        RM
                      </InputAdornment>
                    }
                    className={classes.inputOutline}
                    name="finalAmount"
                    onChange={handleTotalCostChange}
                    onBlur={handleCheckIfTotalCostValid}
                    value={finalAmount}
                    placeholder="Total Cost"
                    onKeyDown={(evt: any) => inputPreventDefault(evt, false)}
                  />
                </Box>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={9}
                md={6}
                lg={3}
                className="info-container__content"
              >
                <Box component="span" className="info-container__content__box">
                  <p>Total Cost</p>
                  <h1>RM {NumberFormat(renderTotalCost())}</h1>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>

        <Paper className="paper">
          <Grid container spacing={3} className="info-container">
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={5}
              alignItems="center"
              justifyContent="center"
              className="info-container__content"
            >
              <Box>
                <img
                  src={roof}
                  className="iconDetails"
                  alt="roof"
                  style={{ float: "left", marginRight: "5px" }}
                />
              </Box>
              <Box component="span" className="info-container__content__box">
                <span>
                  Rooftop Area
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {NumberFormat(state.initialCalc.rooftopArea)}
                    <span
                      style={{ flexDirection: "row", alignItems: "flex-start" }}
                    >
                      <span>m</span>
                      <sup style={{ fontSize: "10px" }}>2</sup>
                    </span>
                  </span>
                  <br></br>
                  <span>*1pv module approximately to 2.6</span>
                  <span>m</span>
                  <sup style={{ fontSize: "10px" }}>2</sup>
                </span>
                {/*<Slider
                                style={{cursor:"pointer"}}
                                name="proposedInstalledCapacity"
                                max={14.25}
                                step={null}
                                value={state.initialCalc.proposedInstalledCapacity || 0}
                                onChange={handleSliderChange}
                                aria-labelledby="discrete-slider-custom"
                                marks={marks}
                              /> */}
              </Box>
              {/* <Hidden only={["xs", "sm", "md"]}>
                <Divider style={{ marginLeft: "10px" }} orientation="vertical" flexItem />
              </Hidden> */}
            </Grid>
            <Hidden only={["xs", "sm", "md"]}>
              <Grid
                item
                xs={12}
                sm={9}
                md={6}
                lg={2}
                alignItems="center"
                justifyContent="center"
                className="info-container__content"
              >
                <Divider orientation="vertical" flexItem />
              </Grid>
            </Hidden>
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={5}
              alignItems="center"
              justifyContent="center"
              className="info-container__content"
            >
              <Box>
                <img
                  src={CO2}
                  className="iconDetails"
                  alt="CO2"
                  style={{ float: "left", marginRight: "5px" }}
                />
              </Box>
              <Box component="span" className="info-container__content__box">
                <span>
                  <span>Carbon Dioxide(</span>
                  <span style={{ alignItems: "flex-end" }}>CO</span>
                  <span style={{ alignItems: "flex-start", fontSize: "10px" }}>
                    2
                  </span>
                  <span>)</span>
                  <br></br>
                  <span>Avoidance </span>
                  <span style={{ fontWeight: "bold" }}>
                    {NumberFormat(state.initialCalc.carbonAvoidance)} tonne
                    <span style={{ alignItems: "flex-end" }}> CO</span>
                    <span
                      style={{ alignItems: "flex-start", fontSize: "10px" }}
                    >
                      2
                    </span>
                  </span>
                </span>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={5} className="info-container insurance">
          {salesAgentCodeApplied && (
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={4}
              className="info-container__content"
            >
              <Box>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    className="insurance"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="expand-calculator-detail">
                      Special All Risk PV Insurance
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="insuranceCoverage"
                        value={tempValue.insuranceCoverage}
                        onClick={handleRadioChange}
                      >
                        <FormControlLabel
                          className="expand-calculator-detail"
                          value={state.initialCalc.insuranceCoveragePackage}
                          control={
                            <Radio
                              checked={tempValue.insuranceCoverage !== "empty"}
                            />
                          }
                          label={state.initialCalc.insuranceCoveragePackage}
                        />
                      </RadioGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          )}
          {salesAgentCodeApplied && (
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={4}
              className="info-container__content"
            >
              <Box>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    className="comprehensive"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="expand-calculator-detail">
                      Comprehensive Maintenance
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="operationAndMaintenance"
                        value={tempValue.operationAndMaintenance}
                        onClick={handleRadioChange}
                      >
                        <FormControlLabel
                          className="expand-calculator-detail"
                          value={
                            state.initialCalc.operationAndMaintenancePackage
                          }
                          control={
                            <Radio
                              checked={
                                tempValue.operationAndMaintenance !== "empty"
                              }
                            />
                          }
                          label={
                            state.initialCalc.operationAndMaintenancePackage
                          }
                        />
                      </RadioGroup>
                      <Typography className="expand-rates">
                        *Rates above are only applicable for Selangor, Negeri
                        Sembilan, Melaka, Perak, Kuala Lumpur, Putrajaya areas
                        *Not including skylift
                      </Typography>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          )}
          {salesAgentCodeApplied && (
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={4}
              className="info-container__content"
            >
              <Box>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    className="extended-warranty"
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="expand-calculator-detail">
                      Extended Inverter Warranty (10 years)
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="extendWarranty"
                        value={tempValue.extendWarranty}
                        onClick={handleRadioChange}
                      >
                        <FormControlLabel
                          className="expand-calculator-detail"
                          value={state.initialCalc.extendWarrantyPackage}
                          control={
                            <Radio
                              checked={tempValue.extendWarranty !== "empty"}
                            />
                          }
                          label={state.initialCalc.extendWarrantyPackage}
                        />
                      </RadioGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={9}
            md={6}
            lg={4}
            className="info-container__content"
          >
            <Box>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  className="other-request"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="expand-calculator-detail">
                    Other Request
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Message"
                    name="otherRequestPackage"
                    multiline
                    value={residentialCalcData.otherRequestPackage}
                    onChange={onChange}
                    variant="outlined"
                    className="message"
                    inputProps={{ maxLength: 300 }}
                  />
                </AccordionDetails>
                <Typography className="expand-rates">
                  *Maximum 300 chars
                </Typography>
              </Accordion>
            </Box>
          </Grid>
        </Grid>

        {!residentialCalcData.validReferralPromo ? (
          <>
            <Grid container spacing={5}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="promo-code"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Box className="left promo-code-sections">
                  <InputLabel style={{ paddingBottom: "5px" }}>
                    Promo code (if any)
                  </InputLabel>
                  <TextField
                    placeholder="Promo code (if any)"
                    name="promoCode"
                    value={residentialCalcData.promoCode}
                    onChange={onChange}
                    variant="outlined"
                    className="promo-code-text"
                    disabled={hasPartner}
                    inputProps={{ maxLength: 30 }}
                  />
                </Box>
                <Box
                  className="right promo-code-sections"
                  style={{ marginTop: "15px" }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    className="button-main-theme calculate back"
                    disabled={
                      isValidating ||
                      hasPartner ||
                      promoCodeApplied ||
                      isSalesAgentValidating
                    }
                    onClick={handleApplyPromoCode}
                  >
                    <div className="wrapper">
                      {isValidating ? (
                        <CircularProgress style={{ color: "white" }} />
                      ) : (
                        <label className="btn-title">Apply</label>
                      )}
                    </div>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            {isValidating ? null : applyPromoCode && !validPromoCode ? (
              <Typography
                variant="caption"
                style={{ color: "red", marginLeft: "10px" }}
              >
                {residentialCalcData.promoCode === ""
                  ? "Please enter a valid input"
                  : "Invalid promo code"}
              </Typography>
            ) : null}
          </>
        ) : null}

        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={12} className="promo-code">
            <Box className="left promo-code-sections">
              <InputLabel style={{ paddingBottom: "5px" }}>
                Sales Agent Code (if any)
              </InputLabel>
              <TextField
                placeholder="Sales Agent Code (if any)"
                name="salesAgentCode"
                value={residentialCalcData.salesAgentCode}
                onChange={onChange}
                variant="outlined"
                className="promo-code-text"
                inputProps={{ maxLength: 30 }}
              />
            </Box>
            <Box
              className="right promo-code-sections"
              style={{ marginTop: "15px" }}
            >
              <Button
                variant="contained"
                size="small"
                className="button-main-theme calculate back"
                disabled={
                  isSalesAgentValidating ||
                  salesAgentCodeApplied ||
                  isValidating
                }
                onClick={handleApplySalesAgentCode}
              >
                <div className="wrapper">
                  {isSalesAgentValidating ? (
                    <CircularProgress style={{ color: "white" }} />
                  ) : (
                    <label className="btn-title">Apply</label>
                  )}
                </div>
              </Button>
            </Box>
          </Grid>
        </Grid>
        {isSalesAgentValidating ? null : applySalesAgentCode &&
          !validSalesAgentCode ? (
          <Typography
            variant="caption"
            style={{ color: "red", marginLeft: "10px" }}
          >
            {residentialCalcData.salesAgentCode === ""
              ? "Please enter a valid input"
              : "Invalid sales agent code"}
          </Typography>
        ) : null}
        <div>
          <Button
            variant="contained"
            size="large"
            className="button-main-theme calculate"
            onClick={handleConfirm}
            disabled={
              (applyPromoCode && !validPromoCode) ||
              (applySalesAgentCode && !validSalesAgentCode)
            }
          >
            <div className="wrapper">
              <label className="btn-title">
                Confirm & Signup Now with A Discounted Deposit Payment
              </label>
              {/* <label className="btn-title">Confirm & Signup Now</label> */}
              <label className="right-arrow">
                {isSaving ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  <img src={ArrowRight} alt="right-arrow" />
                )}
              </label>
            </div>
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleback}
            className="button-main-theme calculate back"
          >
            <div className="wrapper">
              <label className="btn-title">Back</label>
            </div>
          </Button>
        </div>
      </div>
      <AlertBar notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default CalculatorDetail;
