import React from "react";
import "./OurService.scss";
import { Grid, Box} from '@material-ui/core'
import RectangleLine from '../../assets/img/Rectangle 2861/Rectangle-white.png'

class OurService extends React.Component {
  render() {
    return (
      // <div className="our-service">
      //   <div className="our-service-content">
      //     <div className="service left-background">
      //       <div className="title-container-left">
              
      //         {/* <p>
      //           <label className="left-content">1 <br />Customised Solution with Free Consultation</label>
      //           <label className="left-content">2 <br />Hassle- Free Application with Authorities and Licensing process</label>
      //           <label className="left-content">3 <br />Personalised System Design using Net Energy Metering (NEM) and calculate the cost saving for you</label>
      //           <label className="left-content">4 <br />Dedicated Performance Monitoring & System Maintenance</label>
      //         </p> */}
      //       </div>
      //     </div>
      //     <div className="service right-background">
      //       <div className="title-container-right">
      //         <p className="right-title">Our Service</p>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div className="our-service">
        <Grid container spacing={1} className="our-service-content">
          <Grid container className="service left-background">
             <div className="title-container-left">
              <Grid item className="title-container-left__grid">
                <Box component="span" className="title-container-left__box"><img className="border" src={RectangleLine} alt=""/><label>1 <br />Customised Solution with Free Consultation</label></Box>
                <Box component="span" className="title-container-left__box"><img className="border" src={RectangleLine} alt=""/><label>2 <br />Hassle- Free Application with Authorities and Licensing process</label></Box>
              </Grid>
              <Grid item className="title-container-left__grid">
                <Box component="span" className="title-container-left__box"><img className="border" src={RectangleLine} alt=""/><label>3 <br />Personalised System Design using Net Energy Metering</label></Box>
                <Box component="span" className="title-container-left__box"><img className="border" src={RectangleLine} alt=""/><label>4 <br />Dedicated Performance Monitoring & System Maintenance</label></Box>
              </Grid>
             </div>
          </Grid>
          <Grid container className="service right-background">
            <div className="title-container-right">
              <p className="right-title">Our Service</p>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default OurService;
