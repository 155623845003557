import React, { useContext, useState, useEffect } from 'react';
import { Button, TextField, CircularProgress, Modal, Checkbox, Link, MenuItem } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from '@material-ui/core/styles';
import ArrowRight from '../../../images/arrow-right.png';
import { Path } from '../../../routes'
import { useHistory } from "react-router-dom";
import { DocumentContext } from '../../../context/DocumentContext';
import { API, graphqlOperation } from "aws-amplify";
import { addProposal } from "../../../graphql/mutations";
import './InsertDataIndustrial.scss';
import './IndustrialCalculator';
import styled from "styled-components";
import MalaysianStatesJSON from "../../../components/json/MalaysianStates.json"

const helperTextStyles = makeStyles(theme => ({
    root: {
        color: 'red'
    },
}));


interface Props {
    backgroundImage: string;
}

const Wrap = styled.div<Pick<Props, 'backgroundImage'>>`
    background-image: url(${props => props.backgroundImage}) !important;
    background-color: #0a2540e6;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`

function InsertDataIndustrial() {
    const { commercialCalcData, setCommercialCalcData, getLocalStorageData, landingPage, hasPartner } = useContext(DocumentContext)
    const [isSaving, setIsSaving] = useState(false)
    const [isSaving2, setIsSaving2] = useState(false)
    const [message, setMessage] = useState("")
    const helpertextstyle = helperTextStyles();
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    var date = new Date(); // get current date
    date.setDate(date.getDate() + 3); // add two days to it

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        null
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    let history = useHistory();
    var validEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validPhonePattern = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/;

    const states = [
        { label: "Johor", value: "Johor" },
        { label: "Kuala Lumpur", value: "Kuala Lumpur" },
        { label: "Negeri Sembilan", value: "Negeri Sembilan" },
        { label: "Perlis", value: "Perlis" },
        { label: "Sabah", value: "Sabah" },
        { label: "Terengganu", value: "Terengganu" },
        { label: "Kedah", value: "Kedah" },
        { label: "Labuan", value: "Labuan" },
        { label: "Pahang", value: "Pahang" },
        { label: "Penang", value: "Penang" },
        { label: "Sarawak", value: "Sarawak" },
        { label: "Kelantan", value: "Kelantan" },
        { label: "Malacca", value: "Malacca" },
        { label: "Perak", value: "Perak" },
        { label: "Putrajaya", value: "Putrajaya" },
        { label: "Selangor", value: "Selangor" },
        { label: "Other", value: "Other" },
    ];

    const malaysianStates = { ...MalaysianStatesJSON };

    type State = keyof typeof malaysianStates;

    const checkStateHasCities = (state: string) => {
        switch (state) {
            case "Other":
                return false;

            case "":
                return false;

            default:
                return true;
        }
    };

    const formFields = ['clientName', 'clientPhone', 'clientEmail', 'clientAddress', 'state', 'town', 'postCode']
    const [notify, setNotify] = useState({
        show: false,
        item: '',
        message: ''
    })

    // Disable Weekend
    function disableWeekends(date: any) {
        return date.getDay() === 0 || date.getDay() === 6;
    }

    useEffect(() => {
        getLocalStorageData().then((res: any) => {
            setCommercialCalcData(res)
        })
    }, [])

    useEffect(() => {
        localStorage.setItem('document', JSON.stringify(commercialCalcData))
    }, [commercialCalcData])

    // Reusable Function to Enforce MaxLength
    function enforce_maxlength(event: any) {
        var t = event.target;
        if (t.hasAttribute('maxLength')) {
            t.value = t.value.slice(0, t.getAttribute('maxLength'));
        }
    }

    // Global Listener for anything with an maxLength attribute.
    // I put the listener on the body, put it on whatever.
    document.body.addEventListener('TextField', enforce_maxlength);

    const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (checkStateHasCities(event.target.value)) {
            setCommercialCalcData({
                ...commercialCalcData,
                town: malaysianStates[event.target.value as State][0],
                state: event.target.value,
            });
        } else {
            setCommercialCalcData({
                ...commercialCalcData,
                town: event.target.value,
                state: event.target.value,
            });
        }
    };

    const handleTownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommercialCalcData({
            ...commercialCalcData,
            town: event.target.value,
        });
    };

    const postcodeRegex =  /^[0-9]{5,6}$/; //Support both Singapore and Malaysia postcodes


    const handleTermsAndCondition = (event: any) => {
        setCommercialCalcData({ ...commercialCalcData, termsAndConditionStatus: !commercialCalcData.termsAndConditionStatus })
    }

    const onChange = (e: any) => {

        if (e.target.name === "referralCode") {
            if (e.target.value === ' ') {
                setCommercialCalcData({ ...commercialCalcData, [e.target.name]: '' })
            } else {
                setCommercialCalcData({ ...commercialCalcData, [e.target.name]: e.target.value.toUpperCase() })
            }
        } else {
            if (e.target.value === ' ') {
                setCommercialCalcData({ ...commercialCalcData, [e.target.name]: '' })
            } else {
                setCommercialCalcData({ ...commercialCalcData, [e.target.name]: e.target.value })
            }
        }

        formFields.map((field) => {
            if (e.target.name === field && e.target.value !== "") {
                setNotify({ ...notify, show: false, item: field })
            }
        })

    }

    const handleSubmit = async (event: any) => {
        setIsSaving(true)
        event.preventDefault();
        if (commercialCalcData.clientName === "") {
            setNotify({
                show: true,
                item: "clientName",
                message: 'Name Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (commercialCalcData.clientPhone === "") {
            setNotify({
                show: true,
                item: "clientPhone",
                message: 'Phone Number cannot be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (!commercialCalcData.clientPhone.match(validPhonePattern)) {
            setNotify({
                show: true,
                item: "clientPhone",
                message: 'Invalid Phone Number'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (commercialCalcData.clientPhone.length !== 10 && commercialCalcData.clientPhone.length !== 11) {
            setNotify({
                show: true,
                item: "clientPhone",
                message: 'Invalid Phone Number'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (commercialCalcData.clientEmail === "") {
            setNotify({
                show: true,
                item: "clientEmail",
                message: 'Email Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (!commercialCalcData.clientEmail.match(validEmailPattern)) {
            setNotify({
                show: true,
                item: "clientEmail",
                message: 'Invalid Email'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (commercialCalcData.clientAddress === "") {
            setNotify({
                show: true,
                item: "clientAddress",
                message: 'Address Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 300);
        }
        else if (commercialCalcData.companyName === "") {
            setNotify({
                show: true,
                item: "companyName",
                message: 'Company Name Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 300);
        }
        else if (commercialCalcData.industryType === "") {
            setNotify({
                show: true,
                item: "industryType",
                message: 'Industry Type Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 300);
        }
        else if (commercialCalcData.state === "") {
            setNotify({
                show: true,
                item: "state",
                message: 'State Field Cannot Be Blank'
            })
            setIsSaving(false)
        }
        else if (commercialCalcData.town === "") {
            setNotify({
                show: true,
                item: "town",
                message: 'Town Field Cannot Be Blank'
            })
            setIsSaving(false)
        }
        else if (commercialCalcData.postCode === "") {
            setNotify({
                show: true,
                item: "postCode",
                message: 'Postcode Field Cannot Be Blank'
            })
            setIsSaving(false)
        }
        else if (!postcodeRegex.test(commercialCalcData.postCode)) {
            setNotify({
                show: true,
                item: "postCode",
                message: 'Invalid Postcode'
            })
            setIsSaving(false)
        }
        else if (commercialCalcData.termsAndConditionStatus === false) {
            setMessage('Please check this box if u want to proceed')
            setIsSaving(false)
        }
        else {
            // setTimeout(() => {
            //     setIsSaving(false)
            //     history.push(Path.SOLARINVESTIMENTCALCULATORCOMMERCIAL)
            // }, 1500)  
            try {
                setIsSaving(true)
                let tempCTRatio = commercialCalcData.cTRatio
                let tempMaxDemand = commercialCalcData.maximumDemand
                if (tempCTRatio === "") {
                    tempCTRatio = 0
                }
                if (tempMaxDemand === "") {
                    tempMaxDemand = 0
                }

                var params = {
                    // proposalId: uuidv4(),
                    clientName: commercialCalcData.clientName,
                    clientPhone: commercialCalcData.clientPhone,
                    clientType: "Commercial",
                    clientAddress: commercialCalcData.clientAddress,
                    clientAddress2: commercialCalcData.clientAddress2,
                    clientAddress3: commercialCalcData.clientAddress3,
                    postCode: commercialCalcData.postCode,
                    town: commercialCalcData.town,
                    state: commercialCalcData.state,
                    clientEmail: commercialCalcData.clientEmail,
                    buildingType: commercialCalcData.buildingType,
                    referralCode: commercialCalcData.referralCode,
                    referralName: commercialCalcData.referralName,
                    modeOfPurchase: commercialCalcData.modeOfPurchase,
                    tariffGroup: commercialCalcData.tariffGroup,
                    avgElectricityBill: commercialCalcData.avgElectricityBill,
                    companyName: commercialCalcData.companyName,
                    industryType: commercialCalcData.industryType,
                    termsAndConditionStatus: commercialCalcData.termsAndConditionStatus,
                    cTRatio: tempCTRatio,
                    maximumDemand: tempMaxDemand,
                    phaseSystem: '',
                    batterySystemCost: commercialCalcData.batterySystemCost,
                    proposedInstalledCapacity: commercialCalcData.proposedInstalledCapacity,
                    totalProposedInstalledCapacity: commercialCalcData.totalProposedInstalledCapacity,
                    paybackPeriod: commercialCalcData.paybackPeriod,
                    totalCost: commercialCalcData.batterySystemCost,
                    totalAddOnCost: 0,
                    monthlyEnergyGeneration: commercialCalcData.monthlyEnergyGeneration,
                    balanceEnergyImport: commercialCalcData.balanceEnergyImport,
                    monthSavings: commercialCalcData.monthSavings,
                    monthlyBillWithVASolar: commercialCalcData.monthlyBillWithVASolar,
                    extendWarrantyPackage: '',
                    insuranceCoveragePackage: '',
                    operationAndMaintenancePackage: '',
                    extendWarrantyCost: 0,
                    insuranceCoverageCost: 0,
                    operationAndMaintenanceCost: 0,
                    otherRequestPackage: '',
                    appointment: '',
                    appointmentMessage: '',
                    insuranceCoverage: false,
                    operationAndMaintenance: false,
                    extendWarranty: false,
                    panel: commercialCalcData.panel,
                    rooftopArea: commercialCalcData.rooftopArea,
                    carbonAvoidance: commercialCalcData.carbonAvoidance,
                    treesPlanted: commercialCalcData.treesPlanted,
                    carDistance: commercialCalcData.carDistance,
                    taxSavings: commercialCalcData.taxSavings,
                    roiWithTaxSavings: commercialCalcData.roiWithTaxSavings,
                    maxCapacity: commercialCalcData.maxCapacity,
                    finalAmount: 0,
                    status: "Unpaid",
                };
                // console.log("PARAM ", params);
                let res: any = await API.graphql(graphqlOperation(addProposal, params));
                // console.log("check ", res);
                console.log("No error")
                if (res.data.addProposal) {
                    if (res.data.addProposal.proposalId !== null) {
                        // console.log("not null")
                        let proposalData = res.data.addProposal
                        setCommercialCalcData({
                            ...commercialCalcData, proposalId: res.data.addProposal.proposalId, validReferralPromo: proposalData.validReferralPromo,
                            discountPercentage: proposalData.discountDetail?.discountPercentage ? proposalData.discountDetail.discountPercentage : commercialCalcData.discountPercentage,
                            totalCost: proposalData.discountDetail?.totalCost ? proposalData.discountDetail.totalCost : commercialCalcData.totalCost,
                            totalDiscount: proposalData.discountDetail?.totalDiscount ? proposalData.discountDetail.totalDiscount : commercialCalcData.totalDiscount,
                            discountType: proposalData.discountDetail?.discountType ? proposalData.discountDetail.discountType : commercialCalcData.discountType
                        })
                        getLocalStorageData().then((res: any) => {
                            setCommercialCalcData(res)
                            let newLocalData = {
                                ...res,
                                proposalId: proposalData.proposalId,
                                validReferralPromo: proposalData.validReferralPromo,
                            }
                            if (!!proposalData.discountDetail) {
                                newLocalData['discountPercentage'] = proposalData.discountDetail.discountPercentage
                                newLocalData['totalCost'] = proposalData.discountDetail.totalCost
                                newLocalData['totalDiscount'] = proposalData.discountDetail.totalDiscount
                                newLocalData['discountType'] = proposalData.discountDetail.discountType
                            }
                            localStorage.setItem('document', JSON.stringify(newLocalData))
                        })
                    }
                    if (res.data.addProposal.statusCode == 200) {
                        // console.log("No error")
                        setTimeout(() => {
                            setIsSaving(false)
                            history.push(Path.SOLARINVESTIMENTCALCULATORCOMMERCIAL)
                        }, 1500)
                    } else {
                        setIsSaving(false)
                    }
                } else {
                    setIsSaving(false)
                }
            } catch (error) {
                console.log(error)
            }

        }
    }

    const handleClose = () => {
        setTimeout(() => {
            setOpen(false)
            setIsSaving(false)
            window.scrollTo(0, 0);
        }, 2000);
    };

    function handleAppointment(event: any) {
        setIsSaving(true)
        event.preventDefault();
        if (commercialCalcData.clientName === "") {
            setNotify({
                show: true,
                item: "clientName",
                message: 'Name Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (commercialCalcData.clientPhone === "") {
            setNotify({
                show: true,
                item: "clientPhone",
                message: 'Phone Number cannot be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (!commercialCalcData.clientPhone.match(validPhonePattern)) {
            setNotify({
                show: true,
                item: "clientPhone",
                message: 'Invalid Phone Number'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (commercialCalcData.clientPhone.length !== 10 && commercialCalcData.clientPhone.length !== 11) {
            setNotify({
                show: true,
                item: "clientPhone",
                message: 'Invalid Phone Number'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (commercialCalcData.clientEmail === "") {
            setNotify({
                show: true,
                item: "clientEmail",
                message: 'Email Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (!commercialCalcData.clientEmail.match(validEmailPattern)) {
            setNotify({
                show: true,
                item: "clientEmail",
                message: 'Invalid Email'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (commercialCalcData.clientAddress === "") {
            setNotify({
                show: true,
                item: "clientAddress",
                message: 'Address Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 300);
        }
        else if (commercialCalcData.companyName === "") {
            setNotify({
                show: true,
                item: "companyName",
                message: 'Company Name Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 300);
        }
        else if (commercialCalcData.industryType === "") {
            setNotify({
                show: true,
                item: "industryType",
                message: 'Industry Type Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 300);
        }
        else if (commercialCalcData.state === "") {
            setNotify({
                show: true,
                item: "state",
                message: 'State Field Cannot Be Blank'
            })
            setIsSaving(false)
        }
        else if (commercialCalcData.town === "") {
            setNotify({
                show: true,
                item: "town",
                message: 'Town Field Cannot Be Blank'
            })
            setIsSaving(false)
        }
        else if (commercialCalcData.postCode === "") {
            setNotify({
                show: true,
                item: "postCode",
                message: 'Postcode Field Cannot Be Blank'
            })
            setIsSaving(false)
        }
        else if (!postcodeRegex.test(commercialCalcData.postCode)) {
            setNotify({
                show: true,
                item: "postCode",
                message: 'Invalid Postcode'
            })
            setIsSaving(false)
        }
        else if (commercialCalcData.termsAndConditionStatus === false) {
            setMessage('Please check this box if u want to proceed')
            setIsSaving(false)
        }
        else {
            setOpen(true);
            setIsSaving(false)
        }
    }
    let body = (
        <div className="modal-appointment">
            <h2 id="simple-modal-title" className="modal-title">
                Set an Appointment with us!
            </h2>
            <p className="modal-subtitle">Our team will contact you to confirm on the appointment.</p>
            <div style={{ marginBottom: "10px", color: "red" }}>{errorMessage}</div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    minDate={date}
                    shouldDisableDate={disableWeekends}
                    className="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputVariant="outlined"
                    id="date-picker-dialog"
                    label="Select Date"
                    format="dd/MM/yyyy"
                    clearable
                    value={selectedDate}
                    onChange={handleDateChange}
                />
            </MuiPickersUtilsProvider>
            <TextField
                label="Message"
                type="text"
                name="appointmentMessage"
                multiline
                value={commercialCalcData.appointmentMessage}
                onChange={onChange}
                className="appointment-message"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Button
                variant="contained"
                size="large"
                className="button-main-theme calculate"
                onClick={handleConfirm}>
                <div className="wrapper">
                    <label className="btn-title">Confirm Appointment</label>
                    <label className="right-arrow">
                        {isSaving2 ? (
                            <CircularProgress style={{ color: "white" }} />
                        ) : (
                            <img src={ArrowRight} alt="right-arrow" />
                        )}
                    </label>
                </div>
            </Button>
            <p className="cancel" onClick={handleClose}>
                Cancel
            </p>
        </div>
    );

    async function handleConfirm(event: any) {
        if (selectedDate === null) {
            setErrorMessage('Please select a date')
            setIsSaving2(false)
        } else {
            try {
                setIsSaving2(true)
                let tempCTRatio = commercialCalcData.cTRatio
                let tempMaxDemand = commercialCalcData.maximumDemand
                if (tempCTRatio === "") {
                    tempCTRatio = 0
                }
                if (tempMaxDemand === "") {
                    tempMaxDemand = 0
                }
                var params = {
                    clientName: commercialCalcData.clientName,
                    clientPhone: commercialCalcData.clientPhone,
                    clientType: "Commercial",
                    clientAddress: commercialCalcData.clientAddress,
                    clientAddress2: commercialCalcData.clientAddress2,
                    clientAddress3: commercialCalcData.clientAddress3,
                    clientEmail: commercialCalcData.clientEmail,
                    buildingType: commercialCalcData.buildingType,
                    referralCode: commercialCalcData.referralCode,
                    referralName: commercialCalcData.referralName,
                    modeOfPurchase: commercialCalcData.modeOfPurchase,
                    tariffGroup: commercialCalcData.tariffGroup,
                    avgElectricityBill: commercialCalcData.avgElectricityBill,
                    companyName: commercialCalcData.companyName,
                    industryType: commercialCalcData.industryType,
                    termsAndConditionStatus: commercialCalcData.termsAndConditionStatus,
                    cTRatio: tempCTRatio,
                    maximumDemand: tempMaxDemand,
                    phaseSystem: '',
                    batterySystemCost: commercialCalcData.batterySystemCost,
                    proposedInstalledCapacity: commercialCalcData.proposedInstalledCapacity,
                    totalProposedInstalledCapacity: commercialCalcData.totalProposedInstalledCapacity,
                    paybackPeriod: commercialCalcData.paybackPeriod,
                    totalCost: commercialCalcData.batterySystemCost,
                    totalAddOnCost: 0,
                    monthlyEnergyGeneration: commercialCalcData.monthlyEnergyGeneration,
                    balanceEnergyImport: commercialCalcData.balanceEnergyImport,
                    monthSavings: commercialCalcData.monthSavings,
                    monthlyBillWithVASolar: commercialCalcData.monthlyBillWithVASolar,
                    extendWarrantyPackage: '',
                    insuranceCoveragePackage: '',
                    operationAndMaintenancePackage: '',
                    extendWarrantyCost: 0,
                    insuranceCoverageCost: 0,
                    operationAndMaintenanceCost: 0,
                    otherRequestPackage: '',
                    insuranceCoverage: false,
                    operationAndMaintenance: false,
                    extendWarranty: false,
                    panel: commercialCalcData.panel,
                    rooftopArea: commercialCalcData.rooftopArea,
                    carbonAvoidance: commercialCalcData.carbonAvoidance,
                    treesPlanted: commercialCalcData.treesPlanted,
                    carDistance: commercialCalcData.carDistance,
                    taxSavings: commercialCalcData.taxSavings,
                    roiWithTaxSavings: commercialCalcData.roiWithTaxSavings,
                    appointment: selectedDate,
                    appointmentMessage: commercialCalcData.appointmentMessage,
                    status: "Unpaid",
                };
                //  console.log("PARAM ", params);
                let res: any = await API.graphql(graphqlOperation(addProposal, params));
                //  console.log("check ", res);
                if (res && res.data.addProposal.statusCode == 200) {
                    setTimeout(() => {
                        history.push({
                            pathname: `/commercial-industrial`
                        });
                        setIsSaving2(false)
                        window.location.reload()
                    }, 2000);
                } else {
                    setIsSaving2(false)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    //  console.log(commercialCalcData)

    return (
        <Wrap className="commercial-calculator" backgroundImage={landingPage.backgroundImage}>
            <div className="cover">
                <div className="residential-calculator-container">
                    <div className="left-node">
                        <h1 className="title">Realise your earnings in green powered energy for your business</h1>
                    </div>
                    <div className="right-node">
                        <div className="calculator-form">
                            <div className="title-wrapper">
                                {hasPartner ? (
                                    <label className="title">{landingPage.displaySecondPageText}</label>
                                ) : (
                                    <label className="title">Fill up this form for us to serve you better</label>
                                )}
                            </div>
                            <div>
                                <div>
                                    <TextField
                                        label="Name"
                                        name="clientName"
                                        inputProps={{ maxLength: 25 }}
                                        variant="outlined"
                                        onChange={onChange}
                                        value={commercialCalcData.clientName || ''}
                                        helperText={notify.item === "clientName" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        className="select-main-theme-wrapper" />
                                    <TextField
                                        label="Phone number"
                                        name="clientPhone"
                                        type="number"
                                        variant="outlined"
                                        inputProps={{ maxLength: 15 }}
                                        onChange={onChange}
                                        value={commercialCalcData.clientPhone || ''}
                                        helperText={notify.item === "clientPhone" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        className="select-main-theme-wrapper" />
                                    <TextField
                                        label="Email Address"
                                        name="clientEmail"
                                        type="email"
                                        inputProps={{
                                            maxLength: 25,
                                            pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                                        }}
                                        variant="outlined"
                                        onChange={onChange}
                                        value={commercialCalcData.clientEmail || ''}
                                        helperText={notify.item === "clientEmail" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        className="select-main-theme-wrapper" />
                                    <TextField
                                        label="Address Line 1"
                                        name="clientAddress"
                                        multiline
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        onChange={onChange}
                                        value={commercialCalcData.clientAddress || ''}
                                        helperText={notify.item === "clientAddress" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        className="select-main-theme-wrapper" />
                                    <TextField
                                        label="Address Line 2"
                                        name="clientAddress2"
                                        multiline
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        onChange={onChange}
                                        value={commercialCalcData.clientAddress2 || ''}
                                        className="select-main-theme-wrapper" />
                                    <TextField
                                        label="Address Line 3"
                                        name="clientAddress3"
                                        multiline
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        onChange={onChange}
                                        helperText="Optional"
                                        value={commercialCalcData.clientAddress3 || ''}
                                        className="select-main-theme-wrapper" />
                                    <TextField
                                        label="Company Name"
                                        name="companyName"
                                        multiline
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        onChange={onChange}
                                        helperText={notify.item === "companyName" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        value={commercialCalcData.companyName || ''}
                                        className="select-main-theme-wrapper" />
                                    <TextField
                                        label="Industry Type"
                                        name="industryType"
                                        multiline
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        onChange={onChange}
                                        helperText={notify.item === "industryType" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        value={commercialCalcData.industryType || ''}
                                        className="select-main-theme-wrapper" />
                                    <TextField
                                        id="standard-select-state"
                                        select
                                        label="Select State"
                                        value={commercialCalcData.state || ''}
                                        onChange={handleStateChange}
                                        style={{ textAlign: "left" }}
                                        variant="outlined"
                                        helperText={notify.item === "state" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        className="select-main-theme-wrapper"
                                    >
                                        {states.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <TextField
                                        id="standard-select-town"
                                        select
                                        label="Select City"
                                        value={commercialCalcData.town || ''}
                                        onChange={handleTownChange}
                                        style={{ textAlign: "left" }}
                                        variant="outlined"
                                        helperText={notify.item === "town" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        className="select-main-theme-wrapper"
                                    >
                                        {checkStateHasCities(commercialCalcData.state as State)
                                            ? malaysianStates[commercialCalcData.state as State]?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            )) : <MenuItem disabled key={commercialCalcData.state} value={commercialCalcData.state}>
                                                {commercialCalcData.state}
                                            </MenuItem>}
                                    </TextField>

                                    <TextField
                                        label="Postcode"
                                        name="postCode"
                                        variant="outlined"
                                        type="number"
                                        inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*', }}
                                        onChange={onChange}
                                        helperText={notify.item === "postCode" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        value={commercialCalcData.postCode || ''}
                                        className="select-main-theme-wrapper" />
                                    <TextField
                                        label={hasPartner && landingPage.partnerName === "BonusLink" ? "BonusLink Card Number" : "Referral Code"}
                                        name="referralCode"
                                        variant="outlined"
                                        inputProps={{ maxLength: 16 }}
                                        onChange={onChange}
                                        helperText="Optional"
                                        value={commercialCalcData.referralCode || ''}
                                        className="select-main-theme-wrapper" />
                                    {!hasPartner && (
                                        <TextField
                                            label="Referral Name"
                                            name="referralName"
                                            variant="outlined"
                                            inputProps={{ maxLength: 25 }}
                                            onChange={onChange}
                                            helperText="Optional"
                                            value={commercialCalcData.referralName || ''}
                                            className="select-main-theme-wrapper" />
                                    )}
                                    <div className="terms-check-container">
                                        <Checkbox
                                            color="primary"
                                            checked={commercialCalcData.termsAndConditionStatus}
                                            onChange={handleTermsAndCondition}
                                        />
                                        <span className="agreement-text">I agree to the terms of service and </span>
                                        <Link href="./privacy-policy" style={{ color: "#0A2540" }}>privacy policy</Link>
                                        <div className="error-message">
                                            {message}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {commercialCalcData.modeOfPurchase === "OutRight Purchase" || commercialCalcData.modeOfPurchase == "Self Consumption (SelCo)" ?
                                        <Button
                                            variant="contained"
                                            size="large"
                                            className="button-main-theme calculate"
                                            onClick={handleSubmit}
                                        // href={Path.SOLARINVESTIMENTCALCULATOR}
                                        >
                                            <div className="wrapper">
                                                <label className="btn-title">Show Calculation</label>
                                                <label className="right-arrow">
                                                    {isSaving ? (
                                                        <CircularProgress style={{ color: "white" }} />

                                                    ) : (
                                                        <img src={ArrowRight} alt="right-arrow" />
                                                    )}
                                                </label>
                                            </div>
                                        </Button>
                                        :
                                        <Button
                                            variant="contained"
                                            size="large"
                                            className="button-main-theme calculate"
                                            onClick={handleAppointment}
                                        // href={Path.SOLARINVESTIMENTCALCULATOR}
                                        >
                                            <div className="wrapper">
                                                <label className="btn-title">Make an Appointment</label>
                                                <label className="right-arrow">
                                                    {isSaving ? (
                                                        <CircularProgress style={{ color: "white" }} />
                                                    ) : (
                                                        <img src={ArrowRight} alt="right-arrow" />
                                                    )}
                                                </label>
                                            </div>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                            >
                                                {body}
                                            </Modal>
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrap>
    )
}

export default InsertDataIndustrial;