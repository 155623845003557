import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Home from "../pages/Module_1_Home/page/Home";
import Residential from "../pages/Module_2_Residential_Commercial/residential/page/Residential";
import CommercialIndustrial from "../pages/Module_2_Residential_Commercial/commercial/page/CommericalIndustrial";
import About from "../pages/Module_3_About/page/About";
import Portfolios from "../pages/Module_4_Portfolios/page/Portfolios";
import Services from "../pages/Module_5_Services/page/Services";
import NewsEvents from "../pages/Module_6_News_Events/page/NewsEvents";
import ProposalOverviewResidential from "../pages/Module_2_Residential_Commercial/residential/page/ProposalOverviewResidential";
import SolarInvestmentCalculator from "../pages/Module_2_Residential_Commercial/residential/page/SolarInvestmentCalculator";
import FillUpDetailResidential from "../pages/Module_2_Residential_Commercial/residential/page/FillUpDetailResidential";
import PrivacyPolicyPage from "../pages/Module_7_Privacy_Policy/page/PrivacyPolicyPage";
import DetailPageResidentialPortfolios from "../pages/Module_4_Portfolios/residential/page/DetailPageResidentialPortfolios";
import DetailPageCommercialPortfolios from "../pages/Module_4_Portfolios/commercial/page/DetailPageCommercialPortfolios";
import NewsEventDetailPage from "../pages/Module_6_News_Events/page/NewsEventDetailPage";
import FillUpDetailCommercial from "../pages/Module_2_Residential_Commercial/commercial/page/FillUpDetailCommercial";
import SolarInvestmentCalculatorCommercial from "../pages/Module_2_Residential_Commercial/commercial/page/SolarInvestmentCalculatorCommercial";
import PaymentRedirectPage from "../pages/Module_8_Payment/page/PaymentRedirectPage";
import CareersPage from "../pages/Module_9_Career/page/CareersPage";
import ScopeQMSPage from "../pages/Module_10_QMS_Quality_Policy/page/ScopeQMSPage";
import QualityPolicyPage from "../pages/Module_10_QMS_Quality_Policy/page/QualityPolicyPage";
import Helmet, { Helmet as HelmedNamedExport, HelmetData } from "react-helmet";
import ComingSoon from "../pages/Module_11_Testimonial/page/ComingSoonPage";
import SolarPanelPetalingJaya from "../pages/Module_11_Testimonial/page/SolarPanelPetalingJaya";
import SolarPanelMelaka from "../pages/Module_11_Testimonial/page/SolarPanelMelaka";
import SolarPanelJohorBahru from "../pages/Module_11_Testimonial/page/SolarPanelJohorBahru";
import MalaysianInvestmentDevelopmentAuthority from "../pages/Module_12_MIDA/page/MalaysianInvestmentDevelopmentAuthority";
import ProsperityDragonDanceTnC from "../pages/Module_6_News_Events/ProsperityDragonDanceTnC";

const DefaultMetaTag = () => {
  return (
    <Helmet>
      <meta
        name="description"
        content="Looking for a reliable solar company in Malaysia? Discover TERA, your trusted partner for top-quality residential,commercial, and industrial solar panel solutions. Discover the latest solar panel price in Malaysia
"
      />
      <title>
        Best Solar Panel Solutions in Malaysia | Save on Energy Bills with TERA
        VA Sdn. Bhd
      </title>
    </Helmet>
  );
};

export default function WebRouting() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <>
            <DefaultMetaTag />
            <Home />
          </>
        </Route>
        <Route exact path="/residential/:id?">
          <>
            <Helmet>
              <meta
                name="description"
                content="We install home solar panels system in Malaysia. We are specialized in residential solar panel for homes with an affordable cost. Discover the benefits of solar electricity in Malaysia"
              />
              <title>
                Best Home Solar Panels in Malaysia | Get Residential Solar
                Solutions By TERA
              </title>
            </Helmet>
            <Residential />
          </>
        </Route>
        <Route exact path="/commercial-industrial/:id?">
          <>
            <Helmet>
              <meta
                name="description"
                content="Rooftop solar installation service for commercial and industrial businesses. Buy commercial solar power panels in Malaysia at the best prices and with great quality and durability."
              />
              <title>
                Commercial Solar Panel Installers | Solar Company in Petaling
                Jaya
              </title>
            </Helmet>
            <CommercialIndustrial />
          </>
        </Route>
        <Route exact path="/about">
          <>
            <Helmet>
              <meta
                name="description"
                content="TERA VA provides green energy solutions with alternative renewable energy resources for solar panels (Solar PV system). As a leading solar company in Malaysia, we provide a cleaner energy solar system for commercial and residential needs."
              />
              <title>
                About us | Best Solar Panel Company in Subang Jaya Malaysia
              </title>
            </Helmet>
            <About />
          </>
        </Route>
        <Route exact path="/portfolios">
          <>
            <Helmet>
              <meta
                name="description"
                content="Discover TERA’s comprehensive solar panel solutions for both residential solar and commercial solar projects. Whether you're looking to enhance your home’s energy efficiency or power up your business, our expert team delivers top-notch installations and support for all your solar needs"
              />
              <title>
                TERA Solar Panels: Expert Solutions for Residential, Commercial
                & Industrial Solar Projects
              </title>
            </Helmet>
            <Portfolios />
          </>
        </Route>
        <Route exact path="/portfolios/residential">
          <>
            <Helmet>
              <meta
                name="description"
                content="We install home solar panels system in Malaysia. We are specialized in residential solar panel for homes with an affordable cost. Discover the benefits of solar electricity in Malaysia"
              />
              <title>
                Best Home Solar Panels in Malaysia | Get Residential Solar
                Solutions By TERA
              </title>
            </Helmet>
            <DetailPageResidentialPortfolios />
          </>
        </Route>
        <Route exact path="/portfolios/commercial">
          <>
            <Helmet>
              <meta
                name="description"
                content="Rooftop solar installation service for commercial and industrial businesses. Buy commercial solar power panels in Malaysia at the best prices and with great quality and durability."
              />
              <title>
                Commercial Solar Panel Installers | Solar Company in Petaling
                Jaya
              </title>
            </Helmet>
            <DetailPageCommercialPortfolios />
          </>
        </Route>
        <Route exact path="/services">
          <>
            <Helmet>
              <meta
                name="description"
                content="Discover TERA's top-tier solar panel solutions for residential and commercial needs. Our innovative solar systems offer energy efficiency, cost savings, and sustainable power for homes and businesses. Explore our comprehensive solar solutions today!"
              />
              <title>
                TERA Solar Solutions | Residential & Commercial Solar Panels for
                Sustainable Energy
              </title>
            </Helmet>
            <Services />
          </>
        </Route>
        <Route exact path="/news-events">
          <>
            <Helmet>
              <meta
                name="description"
                content="EStay updated with TERA’s latest news and events! Discover the best solar power Malaysia price options for residential, commercial, and industrial needs. Explore the latest advancements in solar technology with our high-efficiency solar cell Malaysia solutions"
              />
              <title>
                Latest News & Events | TERA Solar Panels for Residential,
                Commercial & Industrial Solutions
              </title>
            </Helmet>
            <NewsEvents />
          </>
        </Route>
        <Route
          exact
          path="/news-events/:id"
          render={(props) => {
            return (
              <>
                <Helmet>
                  <meta
                    name="description"
                    content="Stay updated with TERA’s latest news and events! Discover the best solar power Malaysia price options for residential, commercial, and industrial needs. Explore the latest advancements in solar technology with our high-efficiency solar cell Malaysia solutions"
                  />
                  <title>
                    Latest News & Events | TERA Solar Panels for Residential,
                    Commercial & Industrial Solutions
                  </title>
                </Helmet>
                <NewsEventDetailPage {...props} />
              </>
            );
          }}
        />
        <Route exact path="/fill-up-detail">
          <FillUpDetailResidential />
        </Route>
        <Route exact path="/fill-up-detail-commercial">
          <FillUpDetailCommercial />
        </Route>
        <Route exact path="/solar-investment-calculator">
          <SolarInvestmentCalculator />
        </Route>
        <Route exact path="/solar-investment-calculator-commercial">
          <SolarInvestmentCalculatorCommercial />
        </Route>
        <Route
          exact
          path="/proposal-overview/:id?"
          render={(props) => {
            return (
              <>
                <DefaultMetaTag />
                <ProposalOverviewResidential {...props} />
              </>
            );
          }}
        />
        <Route exact path="/privacy-policy">
          <>
            <DefaultMetaTag />
            <PrivacyPolicyPage />
          </>
        </Route>
        <Route exact path="/scope-qms">
          <>
            <DefaultMetaTag />
            <ScopeQMSPage />
          </>
        </Route>
        <Route exact path="/quality-policy">
          <>
            <DefaultMetaTag />
            <QualityPolicyPage />
          </>
        </Route>
        <Route
          exact
          path="/payment-success"
          render={(props) => {
            return <PaymentRedirectPage {...props} />;
          }}
        />
        <Route
          exact
          path="/payment-failed"
          render={(props) => {
            return <PaymentRedirectPage {...props} />;
          }}
        />
        <Route exact path="/careers">
          <>
            <Helmet>
              <meta
                name="description"
                content="Explore career opportunities with TERA, a leading provider of solar panels for residential, commercial, and industrial needs in Malaysia. Discover a rewarding career in the renewable energy sector (Solar Installation) today!"
              />
              <title>
                Join the TERA Team | Careers in Solar Panel Installations
              </title>
            </Helmet>
            <CareersPage />
          </>
        </Route>
        <Route exact path="/testimonials/solar-panel-melaka">
          <>
            <Helmet>
              <meta
                name="description"
                content="Discover how our residential, commercial, and industrial solar installation solutions have transformed energy efficiency and savings for local clients in Melaka, Malaysia"
              />
              <title>
                Testimonials | TERA Solar Panels Melaka | Commercial &
                Residential Solar
              </title>
            </Helmet>
            <SolarPanelMelaka />
          </>
        </Route>
        <Route exact path="/testimonials/solar-panel-johor-bahru">
          <>
            <Helmet>
              <meta
                name="description"
                content="Discover how our residential, commercial, and industrial solar installation solutions have transformed energy efficiency and savings for local clients in Johor Bahru, Malaysia"
              />
              <title>
                Testimonials | TERA Solar Panels Johor Bahru | Commercial & Home
                Solar
              </title>
            </Helmet>
            <SolarPanelJohorBahru />
          </>
        </Route>
        <Route exact path="/testimonials/solar-panel-petaling-jaya">
          <>
            <Helmet>
              <meta
                name="description"
                content="Discover how our residential, commercial, and industrial solar installation solutions have transformed energy efficiency and savings for local clients in Petaling Jaya, Malaysia"
              />
              <title>
                Testimonials | TERA Solar Panels Petaling Jaya | Residential &
                Commercial Solar
              </title>
            </Helmet>
            <SolarPanelPetalingJaya />
          </>
        </Route>
        <Route exact path="/malaysian-investment-development-authority">
          <>
            <Helmet>
              <meta
                name="description"
                content="Explore solar energy opportunities with the Malaysian Investment Development Authority (MIDA). Discover how our innovative solutions and government incentives can help your business thrive in Malaysia’s growing solar industry."
              />
              <title>
                Malaysian Investment Development Authority (MIDA) | Solar
                Industry
              </title>
            </Helmet>
            <MalaysianInvestmentDevelopmentAuthority />
          </>
        </Route>
        <Route exact path="/terms-and-conditions">
          <ProsperityDragonDanceTnC />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}
