import TopNavigation from "../../components/top-navigation/TopNavigation";
import FooterOurLocation from "../../components/footer-our-location/FooterOurLocation";

const ProsperityDragonDanceTnC = () => {
  return (
    <div className="news-event-detail-page" style={{ overflow: "hidden" }}>
      <TopNavigation />
      <div>
        <div className="news-events-details-description">
          <div className="news-events-detail-description-container">
            <div className="news-events-detail-description">
              <label className="title">
                Prosperity Dragon Dance <br />
              </label>
              <img
                src="https://d12o165ilpw6mo.cloudfront.net/public/news-events/8700aaf7-4b39-41d4-a0eb-775fbd7b6e45.jpg"
                className="news-event-image"
                alt="news"
              ></img>

              <div>
                <p className="ql-align-justify">
                  <strong style={{ color: "black" }}>
                    TERMS AND CONDITIONS{" "}
                  </strong>
                </p>
                <p className="ql-align-justify">&nbsp;</p>
                <p className="ql-align-justify">
                  <strong style={{ color: "black" }}>
                    Organiser and Eligibility{" "}
                  </strong>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    The “Prosperity Dragon Dance” (“Campaign”) is organised by
                    Tera Va Sdn Bhd [201101001113 (929247-P)] (“Organiser”).
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    By participating in this Campaign, you agree to comply with
                    all terms and conditions set forth herein.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    1. This Campaign is open to all Malaysians who are aged
                    eighteen (18) years old and above (“Participants”).
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    2. The following categories of Participants are NOT ELIGIBLE
                    to participate in this Campaign:
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    a. Permanent/contract employees of the Organiser and/or its
                    Affiliates;
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    b. The immediate family members (i.e. father, mother,
                    spouses and or child/children) of the Non-Eligible
                    Participants. “Affiliate” shall mean each of its
                    subsidiaries, holding companies and subsidiaries of such
                    holding companies.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    3. The Campaign period runs from 8 Jan 2024 to 4 Feb 2024.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    4. The Organiser reserves the right to terminate, change,
                    amend, delete and or add to these Terms and Conditions at
                    any time without prior notice.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    5. The Organiser shall not be held liable for any loss,
                    damage and or expense for any reason(s) whatsoever.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    6. The Organiser’s decision shall be final and binding. No
                    correspondence will be entertained.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    7. By entering into the Campaign and by accepting the prize,
                    all Participants agree that the Organiser has the right to
                    photograph, picture, voice record and or portray them as
                    Participants in any manner whatsoever as the Organiser may
                    in its absolute discretion decide, and to exhibit these
                    materials for all contest-related advertising, marketing and
                    or publicity purpose(s) without any notice, payment, fee,
                    compensation or consideration whatsoever, subject to the
                    applicable laws and regulations.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    8. Participants of the Campaign are deemed to consent to the
                    Organiser’s right to use their name, voice, photograph,
                    footage and or the content of their entries solely for the
                    above-mentioned purposes.
                  </span>
                </p>
                <p className="ql-align-justify">&nbsp;&nbsp;</p>
                <p className="ql-align-justify">
                  <strong style={{ color: "black" }}>
                    How to Participate{" "}
                  </strong>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    1. To participate in the Campaign, Participants are required
                    to:
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    a. Refer a friend to sign up (with deposit) during campaign
                    period
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    b. Fill up form provided by the Organiser
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    2. The Organiser shall not be held responsible for any
                    Internet connection error that may affect the Campaign.
                  </span>
                </p>
                <p className="ql-align-justify">&nbsp;</p>
                <p className="ql-align-justify">
                  <strong style={{ color: "black" }}>Prizes</strong>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    1. Winner will get one time Dragon Dance performance (worth
                    of RM1,388) at house during Chinese New Year
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    2. The Organiser has the right to change any prize without
                    prior notice,
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    3. In the event that any Participants entry or prize is
                    awarded to a person who:
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    a. is not eligible for the Campaign; and/or
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    b. has committed or is suspected of committing any
                    fraudulent or wrongful acts, the Organiser reserves the
                    right to disqualify such person from participating in the
                    Campaign and/or from receiving the prize.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    4. The Organiser makes no warranties and or representations
                    whatsoever with respect to the prizes and will not be
                    responsible nor liable for any problems and/or damage
                    thereto or arising there from.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    5. The Organiser reserves the right to replace the said
                    prize with another prize of equal or lesser value.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    6. Participants hereby irrevocably agree and give their
                    consent to the Organiser to publish any related matter to
                    the entries including and not limited to the Participants’
                    names and or interviews and photographs for current and or
                    future advertising and promotional purposes.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    7. All Participants recorded by the Organiser shall be final
                    and conclusive.
                  </span>
                </p>
                <p className="ql-align-justify">&nbsp;</p>
                <p className="ql-align-justify">
                  <strong style={{ color: "black" }}>
                    Collection of Prizes{" "}
                  </strong>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    1. Participants are required to include the following
                    personal details upon request: name, contact number and
                    email address. The Organiser and any party acting on the
                    Organiser’s behalf may use such details to communicate with
                    the winners on matters relating to the Campaign. Unless
                    required by law, the Organiser will not provide such
                    information to any third party without permission.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    2. Prizes will be arranged by organiser at Winner’s house.
                    Date and timing of the Dragon Dance Performance will be
                    subject to the availability.
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    3. The Organiser reserves the right to use the names,
                    addresses, photographs, and testimonies of the Participants
                    in advertisements and other forms of publicity in
                    conjunction with the Campaign. The participation in the
                    Campaign and agreement to be bound by the terms and
                    conditions herein shall be deemed to indicate their implied
                    consent to such use, without any notice, payment, fee,
                    compensation, or consideration whatsoever.
                  </span>
                </p>
                <p className="ql-align-justify">&nbsp;</p>
                <p className="ql-align-justify">
                  <strong style={{ color: "black" }}>Liability </strong>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    The Organiser will not be responsible or liable for any
                    problem, damage and or loss of whatsoever nature suffered by
                    any Participants or their authorised representatives
                    resulting from their participation in the Campaign, any
                    error, omission, interruption, deletion, defect, delay in
                    operation or transmission, Internet line failure, theft,
                    destruction of, or unauthorised access to entries, system
                    unresponsiveness whether or not arising during operation or
                    transmission as a result of server functions, viruses, bugs
                    or other causes outside its control.
                  </span>
                </p>
                <p className="ql-align-justify">&nbsp;</p>
                <p className="ql-align-justify">
                  <strong style={{ color: "black" }}>Disqualification</strong>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    The Organiser reserves the right at its sole discretion to
                    disqualify anyone deemed to be tampering with the entry
                    process or the operation of the Participants, to be acting
                    in breach or potential breach of these Terms and Conditions.
                    No correspondence in relation to the above will be
                    entertained.
                  </span>
                </p>
                <p className="ql-align-justify">&nbsp;</p>
                <p className="ql-align-justify">
                  <strong style={{ color: "black" }}>Disclaimer </strong>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    The Organiser receives and processes the Participant’s
                    personal data in accordance with Organiser’s privacy policy
                    and the Personal Data Protection Act 2010 (PDPA) when the
                    Participants provide their personal data to the Organiser.
                    The Participants acknowledge that they have agreed to the
                    Organiser’s terms and conditions upon participation in the
                    Campaign by allowing Organiser to collect, use and process
                    the Participant’s personal data in accordance with the
                    Organiser’s privacy policy and the PDPA for the following
                    purposes:
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    - Sending the Participants information related to online
                    promotions, contests and events and related to Organiser’s
                    marketing activities, unless the Participants have indicated
                    to the Organiser that they do not wish for the Organiser to
                    process their personal data for such purposes;
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    - Using the Participants’ information for marketing and
                    statistical analysis for planning and demographical use;
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    - Complying with local laws and regulatory requirements and
                    or to assist in law enforcement and investigations by
                    relevant authorities;
                  </span>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    - Any other purposes related to Organiser’s marketing
                    activities.
                  </span>
                </p>
                <p className="ql-align-justify">&nbsp;</p>
                <p className="ql-align-justify">
                  <strong style={{ color: "black" }}>
                    DATA PROTECTION AND PRIVACY POLICY NOTICE{" "}
                  </strong>
                </p>
                <p className="ql-align-justify">
                  <span style={{ color: "black" }}>
                    The Participants’ personal data including their names and
                    contact details (“Personal Data”) which they have provided
                    to the Organiser in their application to participate in the
                    Campaign will be recorded, stored or otherwise processed by
                    the Organiser for purposes relating to the administration of
                    the Campaign and for related advertising, publicity,
                    marketing and the Organiser’s other legitimate business
                    purpose (“Purpose”). Please be informed that the
                    Participants’ Personal Data may be disclosed to the
                    Organiser’s Affiliates, service providers and relevant
                    business partners such as public relations agencies, market
                    research firms, advertising agencies (if any) for the
                    Purpose and as permitted by applicable data privacy laws.
                  </span>
                </p>
                <p className="ql-align-justify">&nbsp;</p>
                <p className="ql-align-justify">&nbsp;</p>
                <p>
                  <br />
                </p>
              </div>
              <br />
            </div>
          </div>
        </div>
        <FooterOurLocation />
      </div>
    </div>
  );
};

export default ProsperityDragonDanceTnC;
