/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkPaymentStatus = /* GraphQL */ `
  query CheckPaymentStatus(
    $paymentId: String
    $paymentNumber: String
    $proposalNumber: String
  ) {
    checkPaymentStatus(
      paymentId: $paymentId
      paymentNumber: $paymentNumber
      proposalNumber: $proposalNumber
    ) {
      status
      statusCode
    }
  }
`;
export const generatePaymentLink = /* GraphQL */ `
  query GeneratePaymentLink($proposalId: String, $token: String) {
    generatePaymentLink(proposalId: $proposalId, token: $token) {
      paymentURL
    }
  }
`;
export const generateProposalPDF = /* GraphQL */ `
  query GenerateProposalPDF(
    $mode: String
    $proposalData: ProposalData
    $token: String
  ) {
    generateProposalPDF(
      mode: $mode
      proposalData: $proposalData
      token: $token
    ) {
      url
    }
  }
`;
export const getEnquiry = /* GraphQL */ `
  query GetEnquiry($enquiryId: ID!) {
    getEnquiry(enquiryId: $enquiryId) {
      clientType
      createdAt
      email
      enquiry
      enquiryId
      name
      owner
      phone
      subscribe
      updatedAt
    }
  }
`;
export const getNewsAndEvent = /* GraphQL */ `
  query GetNewsAndEvent($newsAndEventId: ID!) {
    getNewsAndEvent(newsAndEventId: $newsAndEventId) {
      createdAt
      createdBy
      description
      imageLink
      newsAndEventId
      owner
      title
      updatedAt
      updatedBy
    }
  }
`;
export const getPromoCodeCampaign = /* GraphQL */ `
  query GetPromoCodeCampaign($promoCodeCampaignId: ID!) {
    getPromoCodeCampaign(promoCodeCampaignId: $promoCodeCampaignId) {
      createdAt
      createdBy
      discountPercentage
      discountType
      effectiveEndDateTime
      effectiveStartDateTime
      owner
      promoCode
      promoCodeCampaignId
      promoCodeType
      salesAgentName
      salesAgentPhoneNo
      status
      title
      updatedAt
      updatedBy
    }
  }
`;
export const getPromotionPartner = /* GraphQL */ `
  query GetPromotionPartner($partnerURL: String) {
    getPromotionPartner(partnerURL: $partnerURL) {
      backgroundImage
      createdAt
      createdBy
      discountPercentage
      displayInputText
      owner
      partnerLogo
      partnerName
      partnerURL
      promoCode
      promotionPartnerId
      updatedAt
      updatedBy
    }
  }
`;
export const getProposal = /* GraphQL */ `
  query GetProposal($proposalId: ID!) {
    getProposal(proposalId: $proposalId) {
      appointment
      appointmentMessage
      avgElectricityBill
      batterySystem
      batterySystemCost
      batterySystemPackage
      buildingType
      cTRatio
      carDistance
      carbonAvoidance
      channelEnquiry
      clientAddress
      clientAddress2
      clientAddress3
      clientEmail
      clientName
      clientPhone
      clientType
      companyName
      createdAt
      currentMonthlyBill
      deposit
      discountPercentage
      discountType
      documentLink
      extendWarranty
      extendWarrantyCost
      extendWarrantyPackage
      extendWorkmanshipWarranty
      extendWorkmanshipWarrantyCost
      extendWorkmanshipWarrantyPackage
      finalAmount
      industryType
      insuranceCoverage
      insuranceCoverageCost
      insuranceCoveragePackage
      maxCapacity
      maximumDemand
      minUpfrontCost
      modeOfPurchase
      modelCode
      monthSavings
      monthlyBillWithVASolar
      operationAndMaintenance
      operationAndMaintenanceCost
      operationAndMaintenancePackage
      otherRequest
      otherRequestCost
      otherRequestPackage
      owner
      panel
      paybackPeriod
      paymentLink
      phaseSystem
      postCode
      promoCode
      proposalId
      proposalNumber
      proposedInstalledCapacity
      pvQuantity
      referralCode
      referralName
      roiWithTaxSavings
      rooftopArea
      salesAgentCode
      salesAgentName
      salesAgentPhoneNo
      sharedMaintenance
      sharedMaintenanceCost
      sharedMaintenancePackage
      signature
      state
      status
      submissionDate
      tariffGroup
      taxSavings
      termsAndConditionStatus
      totalAddOnCost
      totalCost
      totalDiscount
      totalProposedInstalledCapacity
      town
      treesPlanted
      updatedAt
      updatedBy
      upgradeInverter
      upgradeInverterCost
      upgradeInverterPackage
      validReferralPromo
    }
  }
`;
export const getReferral = /* GraphQL */ `
  query GetReferral($referralId: ID!) {
    getReferral(referralId: $referralId) {
      amount
      createdAt
      date
      owner
      proposalId
      refereeName
      refereePhone
      referralCode
      referralId
      referralName
      referralPhone
      status
      updatedAt
    }
  }
`;
export const getReferralList = /* GraphQL */ `
  query GetReferralList($limit: Int, $nextToken: String, $status: String) {
    getReferralList(limit: $limit, nextToken: $nextToken, status: $status) {
      items {
        amount
        appointment
        clientName
        clientPhone
        clientType
        createdAt
        date
        modeOfPurchase
        refereeName
        refereePhone
        referralCode
        referralId
        referralName
        referralPhone
        status
        updatedAt
      }
      nextToken
    }
  }
`;
export const getS3FileUrl = /* GraphQL */ `
  query GetS3FileUrl($fileName: String, $folderName: String, $token: String) {
    getS3FileUrl(fileName: $fileName, folderName: $folderName, token: $token) {
      url
    }
  }
`;
export const getTariff = /* GraphQL */ `
  query GetTariff($tariffId: ID!) {
    getTariff(tariffId: $tariffId) {
      category
      createdAt
      owner
      rate
      tariffId
      updatedAt
      usageStart
    }
  }
`;
export const listEnquirys = /* GraphQL */ `
  query ListEnquirys(
    $enquiryId: ID
    $filter: ModelEnquiryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEnquirys(
      enquiryId: $enquiryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        clientType
        createdAt
        email
        enquiry
        enquiryId
        name
        owner
        phone
        subscribe
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNewsAndEvents = /* GraphQL */ `
  query ListNewsAndEvents(
    $filter: ModelNewsAndEventFilterInput
    $limit: Int
    $newsAndEventId: ID
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNewsAndEvents(
      filter: $filter
      limit: $limit
      newsAndEventId: $newsAndEventId
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        createdBy
        description
        imageLink
        newsAndEventId
        owner
        title
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const listPromoCodeCampaigns = /* GraphQL */ `
  query ListPromoCodeCampaigns(
    $filter: ModelPromoCodeCampaignFilterInput
    $limit: Int
    $nextToken: String
    $promoCodeCampaignId: ID
    $sortDirection: ModelSortDirection
  ) {
    listPromoCodeCampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      promoCodeCampaignId: $promoCodeCampaignId
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        createdBy
        discountPercentage
        discountType
        effectiveEndDateTime
        effectiveStartDateTime
        owner
        promoCode
        promoCodeCampaignId
        promoCodeType
        salesAgentName
        salesAgentPhoneNo
        status
        title
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const listPromotionPartners = /* GraphQL */ `
  query ListPromotionPartners(
    $filter: ModelPromotionPartnerFilterInput
    $limit: Int
    $nextToken: String
    $promotionPartnerId: ID
    $sortDirection: ModelSortDirection
  ) {
    listPromotionPartners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      promotionPartnerId: $promotionPartnerId
      sortDirection: $sortDirection
    ) {
      items {
        backgroundImage
        createdAt
        createdBy
        discountPercentage
        displayInputText
        owner
        partnerLogo
        partnerName
        partnerURL
        promoCode
        promotionPartnerId
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const listProposals = /* GraphQL */ `
  query ListProposals(
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
    $proposalId: ID
    $sortDirection: ModelSortDirection
  ) {
    listProposals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      proposalId: $proposalId
      sortDirection: $sortDirection
    ) {
      items {
        appointment
        appointmentMessage
        avgElectricityBill
        batterySystem
        batterySystemCost
        batterySystemPackage
        buildingType
        cTRatio
        carDistance
        carbonAvoidance
        channelEnquiry
        clientAddress
        clientAddress2
        clientAddress3
        clientEmail
        clientName
        clientPhone
        clientType
        companyName
        createdAt
        currentMonthlyBill
        deposit
        discountPercentage
        discountType
        documentLink
        extendWarranty
        extendWarrantyCost
        extendWarrantyPackage
        extendWorkmanshipWarranty
        extendWorkmanshipWarrantyCost
        extendWorkmanshipWarrantyPackage
        finalAmount
        industryType
        insuranceCoverage
        insuranceCoverageCost
        insuranceCoveragePackage
        maxCapacity
        maximumDemand
        minUpfrontCost
        modeOfPurchase
        modelCode
        monthSavings
        monthlyBillWithVASolar
        operationAndMaintenance
        operationAndMaintenanceCost
        operationAndMaintenancePackage
        otherRequest
        otherRequestCost
        otherRequestPackage
        owner
        panel
        paybackPeriod
        paymentLink
        phaseSystem
        postCode
        promoCode
        proposalId
        proposalNumber
        proposedInstalledCapacity
        pvQuantity
        referralCode
        referralName
        roiWithTaxSavings
        rooftopArea
        salesAgentCode
        salesAgentName
        salesAgentPhoneNo
        sharedMaintenance
        sharedMaintenanceCost
        sharedMaintenancePackage
        signature
        state
        status
        submissionDate
        tariffGroup
        taxSavings
        termsAndConditionStatus
        totalAddOnCost
        totalCost
        totalDiscount
        totalProposedInstalledCapacity
        town
        treesPlanted
        updatedAt
        updatedBy
        upgradeInverter
        upgradeInverterCost
        upgradeInverterPackage
        validReferralPromo
      }
      nextToken
    }
  }
`;
export const listReferrals = /* GraphQL */ `
  query ListReferrals(
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
    $referralId: ID
    $sortDirection: ModelSortDirection
  ) {
    listReferrals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      referralId: $referralId
      sortDirection: $sortDirection
    ) {
      items {
        amount
        createdAt
        date
        owner
        proposalId
        refereeName
        refereePhone
        referralCode
        referralId
        referralName
        referralPhone
        status
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTariffs = /* GraphQL */ `
  query ListTariffs(
    $filter: ModelTariffFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $tariffId: ID
  ) {
    listTariffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      tariffId: $tariffId
    ) {
      items {
        category
        createdAt
        owner
        rate
        tariffId
        updatedAt
        usageStart
      }
      nextToken
    }
  }
`;
export const obtainTariffData = /* GraphQL */ `
  query ObtainTariffData($category: String) {
    obtainTariffData(category: $category) {
      items {
        category
        rate
        usageStart
      }
    }
  }
`;
export const validatePromoCode = /* GraphQL */ `
  query ValidatePromoCode(
    $promoCode: String
    $promoCodeType: String
    $token: String
  ) {
    validatePromoCode(
      promoCode: $promoCode
      promoCodeType: $promoCodeType
      token: $token
    ) {
      discountType
      discountValue
      status
      statusCode
      title
      validSalesAgent
    }
  }
`;
