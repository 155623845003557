import React from "react";
import "./PortfoliosResidentialCommercial.scss";
import { useHistory } from "react-router-dom";
import { Grid, Box, Button} from '@material-ui/core';
import Residential from "../../images/residential-banner.jpg";
import Commercial from "../../images/ngeam-shah-alam.jpg";
import ArrowRight from '../../images/arrow-right.png';

function PortfoliosResidentialCommercial() {

  let history = useHistory();

  const handleViewMore = (type: string) => {
    history.push({
      pathname: `/portfolios/${type}`
    })
  }
  
  return (
    <div className="portfolios-residential-commercial">
      <Grid container spacing={1} className="our-service-content">
        <Grid container className="service left-background">
            <div className="title-container-left">
            <Box
            component="span"
            m={1}
            width="100%"
            style={{margin: 0}}>
            <img src={Residential} className="portfolios-images" alt="Residential"/>
            <div className="portfolios-box-title">RESIDENTIAL PROJECTS</div>
            <div className="portfolios-box-subtitle">Tera VA is a subsidiary of Boilermech Holdings Berhad, a wholly-owned unit of QL Resources Berhad.</div>
            <Button className="right-nodes" onClick={() => handleViewMore('residential')}>View More<div className="right-arrows"><img src={ArrowRight} alt="right-arrow" /></div>
            </Button>
          </Box>
            </div>
        </Grid>
        <Grid container className="service right-background">
          <div className="title-container-right">
          <Box
            component="span"
            m={1} 
            width="100%"
            style={{margin: 0}}>
              <img src={Commercial} className="portfolios-images" alt="Commercial"/>
              <div className="portfolios-box-title">COMMERCIAL & INDUSTRIAL PROJECTS</div>
              <span className="portfolios-box-subtitle">Tera VA is a subsidiary of Boilermech Holdings Berhad, a wholly-owned unit of QL Resources Berhad.</span>
              <Button className="right-nodes"  onClick={() => handleViewMore('commercial')}>View More<div className="right-arrows"><img src={ArrowRight} alt="right-arrow" /></div>
              </Button>
            </Box>
          </div>
        </Grid>
      </Grid>
      </div>
    );
  
}

export default PortfoliosResidentialCommercial;
