import { useContext } from 'react';
import TopNavigation from '../../../../components/top-navigation/TopNavigation'
import FooterOurLocation from '../../../../components/footer-our-location/FooterOurLocation';
import ContactUs from '../../../../components/contact-us/ContactUs';
import IndustrialCalculator from '../IndustrialCalculator';
import CompletedProjectCommercial from '../CompletedProjectCommercial';
import CommercialBanner from '../CommercialBanner';
import { DocumentContext } from '../../../../context/DocumentContext';
import CompanyDescriptionCommercial from '../../CompanyDescriptionCommercial';

function CommercialIndustrial() {
    const { hasPartner } = useContext(DocumentContext)
    return (
        <div className="commercial-industrial" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
                <IndustrialCalculator />
                {!hasPartner && (
                    <>
                    <CompanyDescriptionCommercial />
                    <CompletedProjectCommercial />
                    <CommercialBanner />
                    <ContactUs />
                    </>
                )}
              
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default CommercialIndustrial;