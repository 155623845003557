import { createContext, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  CalculateTotalConsumption,
  CalculateProposedInstalledCapacity,
  CalculateBatterySystemCost,
  CalculateMonthlyEnergyGeneration,
  CalculateBalanceEnergyImport,
  CalculateMonthlySaving,
  CalculateMonthlyBillWithVASolar,
  CalculateExtendWarranty,
  CalculateInsuranceCoverage,
  CalculateOperationAndMaintenance,
  CalculateExtendWarrantyCost,
  CalculateInsuranceCoverageCost,
  CalculateOperationAndMaintenanceCost,
  DeterminePhaseSystem,
  CalculatePanel,
  CalculateRoof,
  CalculateCarbonAvoidance,
  CalculateTreePlanted,
  CalculateCarDistance,
  CalculateROI,
  DeterminePVModule,
  DetermineModuleCode,
  CalculateROIWithTaxSavings,
  CalculateTaxSavings,
} from "../pages/Module_2_Residential_Commercial/calculator/Calculator";
import { obtainTariffData, getProposal } from "../graphql/queries";

export const DocumentContext = createContext();

const DocumentContextProvider = (props) => {
  const [proposal, setProposal] = useState({
    clientType: "",
    clientName: "",
    clientPhone: "",
    clientEmail: "",
    clientAddress: "",
    clientAddress2: "",
    clientAddress3: "",
    referralCode: "",
    referralName: "",
    buildingType: "",
    tariffGroup: "A",
    avgElectricityBill: "",
    termsAndConditionStatus: false,
    batterySystemCost: 0,
    modeOfPurchase: "",
    paybackPeriod: 0,
    phaseSystem: "",
    totalConsumption: 0,
    proposedInstalledCapacity: 0,
    totalProposedInstalledCapacity: 0,
    totalCost: 0,
    totalAddOnCost: 0,
    monthlyEnergyGeneration: 0,
    balanceEnergyImport: 0,
    monthSavings: 0,
    monthlyBillWithVASolar: 0,
    extendWarrantyPackage: "",
    insuranceCoveragePackage: "",
    operationAndMaintenancePackage: "",
    extendWarrantyCost: 0,
    insuranceCoverageCost: 0,
    operationAndMaintenanceCost: 0,
    otherRequestPackage: "",
    appointment: "",
    industryType: "",
    companyName: "",
    appointmentMessage: "",
    insuranceCoverage: false,
    operationAndMaintenance: false,
    extendWarranty: false,
    panel: 0,
    rooftopArea: 0,
    carbonAvoidance: 0,
    treesPlanted: 0,
    carDistance: 0,
    SolarPVModule: "",
    ModelCode: "",
    maximumDemand: 0,
    cTRatio: 0,
    taxSavings: 0,
    roiWithTaxSavings: 0,
    maxCapacity: 0,
    finalAmount: 0,
    promoCode: "",
    totalDiscount: 0,
  });
  const [initialProposalData, setInitialProposalData] = useState({});

  const [residentialCalcData, setResidentialCalcData] = useState({
    clientType: "Residential",
    clientName: "",
    clientPhone: "",
    clientEmail: "",
    clientAddress: "",
    clientAddress2: "",
    clientAddress3: "",
    postCode: "",
    town: "",
    state: "",
    referralCode: "",
    referralName: "",
    buildingType: "",
    tariffGroup: "A",
    avgElectricityBill: "",
    termsAndConditionStatus: false,
    batterySystemCost: 0,
    modeOfPurchase: "",
    paybackPeriod: 0,
    phaseSystem: "Single Phase",
    totalConsumption: 0,
    proposedInstalledCapacity: 0,
    totalProposedInstalledCapacity: 0,
    totalCost: 0,
    totalAddOnCost: 0,
    monthlyEnergyGeneration: 0,
    balanceEnergyImport: 0,
    monthSavings: 0,
    monthlyBillWithVASolar: 0,
    extendWarrantyPackage: "",
    insuranceCoveragePackage: "",
    operationAndMaintenancePackage: "",
    extendWarrantyCost: 0,
    insuranceCoverageCost: 0,
    operationAndMaintenanceCost: 0,
    otherRequestPackage: "",
    appointment: "",
    industryType: "",
    companyName: "",
    appointmentMessage: "",
    insuranceCoverage: false,
    operationAndMaintenance: false,
    extendWarranty: false,
    panel: 0,
    rooftopArea: 0,
    carbonAvoidance: 0,
    treesPlanted: 0,
    carDistance: 0,
    SolarPVModule: "",
    ModelCode: "",
    maximumDemand: 0,
    cTRatio: 0,
    taxSavings: 0,
    roiWithTaxSavings: 0,
    maxCapacity: 0,
    finalAmount: 0,
    promoCode: "",
    totalDiscount: 0,
    validReferralPromo: false,
  });

  const [commercialCalcData, setCommercialCalcData] = useState({
    clientType: "Commercial",
    clientName: "",
    clientPhone: "",
    clientEmail: "",
    clientAddress: "",
    clientAddress2: "",
    clientAddress3: "",
    postCode: "",
    town: "",
    state: "",
    referralCode: "",
    referralName: "",
    buildingType: "",
    tariffGroup: "B",
    avgElectricityBill: "",
    termsAndConditionStatus: false,
    batterySystemCost: 0,
    modeOfPurchase: "",
    totalConsumption: 0,
    proposedInstalledCapacity: 0,
    totalProposedInstalledCapacity: 0,
    totalCost: 0,
    totalAddOnCost: 0,
    paybackPeriod: 0,
    monthlyEnergyGeneration: 0,
    balanceEnergyImport: 0,
    monthSavings: 0,
    monthlyBillWithVASolar: 0,
    extendWarrantyPackage: "",
    insuranceCoveragePackage: "",
    operationAndMaintenancePackage: "",
    extendWarrantyCost: 0,
    industryType: "",
    companyName: "",
    insuranceCoverageCost: 0,
    operationAndMaintenanceCost: 0,
    otherRequestPackage: "",
    appointment: "",
    appointmentMessage: "",
    insuranceCoverage: false,
    operationAndMaintenance: false,
    extendWarranty: false,
    panel: 0,
    rooftopArea: 0,
    carbonAvoidance: 0,
    treesPlanted: 0,
    carDistance: 0,
    maximumDemand: "",
    cTRatio: "",
    taxSavings: 0,
    roiWithTaxSavings: 0,
    maxCapacity: 0,
    finalAmount: 0,
    promoCode: "",
    totalDiscount: 0,
    validReferralPromo: false,
  });

  const [promotion, setPromotion] = useState({
    promoCode: "",
    discountValue: 0,
    title: "",
  });

  const [landingPage, setLandingPage] = useState({
    backgroundImage: "",
    partnerLogo: "",
    promoCode: "",
    discountPercentage: 0,
    displayInputText: "",
    partnerURL: "",
    displaySecondPageText: "",
  });

  const [hasPartner, setHasPartner] = useState(false);

  async function getLocalStorageData() {
    const temp = localStorage.getItem("document");
    const tempJSON = JSON.parse(temp);
    return tempJSON;
  }

  async function getProposalData(proposalId) {
    let params = {
      proposalId: proposalId,
    };
    //query
    try {
      const response = await API.graphql(graphqlOperation(getProposal, params));
      if (response.data.getProposal) {
        const data = response.data.getProposal;
        return data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function clearDataResidential() {
    setResidentialCalcData({
      clientType: "Residential",
      clientName: "",
      clientPhone: "",
      clientEmail: "",
      clientAddress: "",
      clientAddress2: "",
      clientAddress3: "",
      postCode: "",
      town: "",
      state: "",
      referralCode: "",
      referralName: "",
      buildingType: "",
      tariffGroup: "A",
      avgElectricityBill: "",
      termsAndConditionStatus: false,
      batterySystemCost: 0,
      modeOfPurchase: "",
      phaseSystem: "Single Phase",
      totalConsumption: 0,
      proposedInstalledCapacity: 0,
      totalProposedInstalledCapacity: 0,
      totalCost: 0,
      industryType: "",
      companyName: "",
      totalAddOnCost: 0,
      monthlyEnergyGeneration: 0,
      balanceEnergyImport: 0,
      monthSavings: 0,
      monthlyBillWithVASolar: 0,
      extendWarrantyPackage: "",
      insuranceCoveragePackage: "",
      operationAndMaintenancePackage: "",
      extendWarrantyCost: 0,
      insuranceCoverageCost: 0,
      operationAndMaintenanceCost: 0,
      otherRequestPackage: "",
      appointment: "",
      appointmentMessage: "",
      insuranceCoverage: false,
      operationAndMaintenance: false,
      extendWarranty: false,
      panel: 0,
      rooftopArea: 0,
      carbonAvoidance: 0,
      treesPlanted: 0,
      carDistance: 0,
      SolarPVModule: "",
      ModelCode: "",
      maximumDemand: 0,
      cTRatio: 0,
      taxSavings: 0,
      roiWithTaxSavings: 0,
      maxCapacity: 0,
      finalAmount: 0,
      promoCode: "",
      totalDiscount: 0,
      validReferralPromo: false,
    });
  }

  async function clearDataIndustrial() {
    setCommercialCalcData({
      clientType: "Commercial",
      clientName: "",
      clientPhone: "",
      clientEmail: "",
      clientAddress: "",
      clientAddress2: "",
      clientAddress3: "",
      postCode: "",
      town: "",
      state: "",
      referralCode: "",
      referralName: "",
      buildingType: "",
      tariffGroup: "B",
      avgElectricityBill: "",
      batterySystemCost: 0,
      termsAndConditionStatus: false,
      modeOfPurchase: "",
      industryType: "",
      companyName: "",
      totalConsumption: 0,
      proposedInstalledCapacity: 0,
      totalProposedInstalledCapacity: 0,
      totalCost: 0,
      totalAddOnCost: 0,
      paybackPeriod: 0,
      monthlyEnergyGeneration: 0,
      balanceEnergyImport: 0,
      monthSavings: 0,
      monthlyBillWithVASolar: 0,
      extendWarrantyPackage: "",
      insuranceCoveragePackage: "",
      operationAndMaintenancePackage: "",
      extendWarrantyCost: 0,
      insuranceCoverageCost: 0,
      operationAndMaintenanceCost: 0,
      otherRequestPackage: "",
      appointment: "",
      appointmentMessage: "",
      insuranceCoverage: false,
      operationAndMaintenance: false,
      extendWarranty: false,
      panel: 0,
      rooftopArea: 0,
      carbonAvoidance: 0,
      treesPlanted: 0,
      carDistance: 0,
      maximumDemand: "",
      cTRatio: "",
      taxSavings: 0,
      roiWithTaxSavings: 0,
      maxCapacity: 0,
      finalAmount: 0,
      promoCode: "",
      totalDiscount: 0,
      validReferralPromo: false,
    });
  }

  const [isCalculate, setIsCalculate] = useState(false);
  const [pricing, setPricing] = useState();

  const getTarrifData = async (tariffGroup) => {
    let params = {
      category: tariffGroup,
    };

    //query
    try {
      const response = await API.graphql(
        graphqlOperation(obtainTariffData, params)
      );
      const data = response.data.obtainTariffData.items;
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  function calculateSolarInvestment(
    avgElectricityBill,
    type,
    pricing,
    cTRatio,
    maximumDemand,
    tariffGroup
  ) {
    let totalConsumptionTemp = CalculateTotalConsumption(
      avgElectricityBill,
      type,
      pricing
    );

    let proposedInstalledCapacity = CalculateProposedInstalledCapacity(
      totalConsumptionTemp,
      type,
      cTRatio,
      maximumDemand,
      tariffGroup
    );
    let totalProposedInstalledCapacity = CalculateProposedInstalledCapacity(
      totalConsumptionTemp,
      type,
      cTRatio,
      maximumDemand,
      tariffGroup
    );
    let phaseSystem = DeterminePhaseSystem(proposedInstalledCapacity, type);

    //Remain single phase and capped at 7.02 kwp capacity
    if (
      type === "Residential" &&
      residentialCalcData.phaseSystem === "Single Phase"
    ) {
      if (proposedInstalledCapacity > 7.02) {
        proposedInstalledCapacity = 7.02;
        totalProposedInstalledCapacity = 7.02;
      }
      phaseSystem = residentialCalcData.phaseSystem;
    } else if (
      type === "Residential" &&
      residentialCalcData.phaseSystem === "Three Phase"
    ) {
      phaseSystem = residentialCalcData.phaseSystem;
    }

    let batterySystemCost = CalculateBatterySystemCost(
      proposedInstalledCapacity,
      type
    );
    let monthlyEnergyGen = CalculateMonthlyEnergyGeneration(
      proposedInstalledCapacity
    );
    let balanceEnergyImport = CalculateBalanceEnergyImport(
      totalConsumptionTemp,
      monthlyEnergyGen
    );
    let monthlyBillWithVASolar = CalculateMonthlyBillWithVASolar(
      avgElectricityBill,
      balanceEnergyImport,
      type,
      pricing
    );
    let monthSavings = CalculateMonthlySaving(
      avgElectricityBill,
      monthlyBillWithVASolar
    );
    let extendedWarranty = CalculateExtendWarranty(
      proposedInstalledCapacity,
      type
    );
    let insuranceCoveragePackage = CalculateInsuranceCoverage(
      proposedInstalledCapacity,
      type,
      batterySystemCost
    );
    let opsAndMaintenance = CalculateOperationAndMaintenance(
      proposedInstalledCapacity,
      type
    );
    let extendedWarrantyCost = CalculateExtendWarrantyCost(
      proposedInstalledCapacity,
      type
    );
    let insuranceCoverageCost = CalculateInsuranceCoverageCost(
      proposedInstalledCapacity,
      type,
      batterySystemCost
    );
    let opsAndMaintenanceCost = CalculateOperationAndMaintenanceCost(
      proposedInstalledCapacity,
      type
    );
    let paybackPeriod = CalculateROI(batterySystemCost, monthSavings, 0);
    let panel = CalculatePanel(proposedInstalledCapacity, type);
    let rooftopArea = CalculateRoof(panel);
    let carbonAvoidance = CalculateCarbonAvoidance(monthlyEnergyGen);
    let treesPlanted = CalculateTreePlanted(carbonAvoidance);
    let carDistance = CalculateCarDistance(carbonAvoidance);
    let SolarPVModule = DeterminePVModule(proposedInstalledCapacity, type);
    let ModelCode = DetermineModuleCode(proposedInstalledCapacity, type);
    let taxSavings = CalculateTaxSavings(batterySystemCost);
    let roiWithTaxSavings = CalculateROIWithTaxSavings(
      batterySystemCost,
      monthSavings
    );
    let maxCapacity = CalculateProposedInstalledCapacity(
      totalConsumptionTemp,
      type,
      cTRatio,
      maximumDemand,
      tariffGroup
    );

    let totalCost = batterySystemCost;
    // let totalAddOnCost = CalculateAddOnCost(insuranceCoverageCost , opsAndMaintenanceCost, extendedWarrantyCost)

    if (type === "Residential") {
      setResidentialCalcData({
        ...residentialCalcData,
        totalConsumption: totalConsumptionTemp,
        proposedInstalledCapacity: proposedInstalledCapacity,
        totalProposedInstalledCapacity: totalProposedInstalledCapacity,
        phaseSystem: phaseSystem,
        batterySystemCost: batterySystemCost,
        monthlyEnergyGeneration: monthlyEnergyGen,
        balanceEnergyImport: monthlyEnergyGen,
        monthSavings: monthSavings,
        monthlyBillWithVASolar: monthlyBillWithVASolar,
        extendWarrantyPackage: extendedWarranty,
        insuranceCoveragePackage: insuranceCoveragePackage,
        operationAndMaintenancePackage: opsAndMaintenance,
        extendWarrantyCost: extendedWarrantyCost,
        insuranceCoverageCost: insuranceCoverageCost,
        operationAndMaintenanceCost: opsAndMaintenanceCost,
        // totalAddOnCost : totalAddOnCost,
        paybackPeriod: paybackPeriod,
        totalCost: totalCost,
        panel: panel,
        rooftopArea: rooftopArea,
        carbonAvoidance: parseFloat(carbonAvoidance.toFixed(2), 2),
        treesPlanted: treesPlanted,
        carDistance: carDistance,
        SolarPVModule: SolarPVModule,
        ModelCode: ModelCode,
        taxSavings: taxSavings,
        roiWithTaxSavings: roiWithTaxSavings,
        maxCapacity: maxCapacity,
      });
    } else {
      setCommercialCalcData({
        ...commercialCalcData,
        totalConsumption: totalConsumptionTemp,
        proposedInstalledCapacity: proposedInstalledCapacity,
        totalProposedInstalledCapacity: totalProposedInstalledCapacity,
        phaseSystem: phaseSystem,
        batterySystemCost: batterySystemCost,
        monthlyEnergyGeneration: monthlyEnergyGen,
        balanceEnergyImport: monthlyEnergyGen,
        monthSavings: monthSavings,
        monthlyBillWithVASolar: monthlyBillWithVASolar,
        extendWarrantyPackage: extendedWarranty,
        insuranceCoveragePackage: insuranceCoveragePackage,
        operationAndMaintenancePackage: opsAndMaintenance,
        extendWarrantyCost: extendedWarrantyCost,
        insuranceCoverageCost: insuranceCoverageCost,
        operationAndMaintenanceCost: opsAndMaintenanceCost,
        // totalAddOnCost : totalAddOnCost,
        paybackPeriod: paybackPeriod,
        totalCost: totalCost,
        panel: panel,
        rooftopArea: rooftopArea,
        carbonAvoidance: parseFloat(carbonAvoidance.toFixed(2), 2),
        treesPlanted: treesPlanted,
        carDistance: carDistance,
        maximumDemand: maximumDemand,
        cTRatio: cTRatio,
        taxSavings: taxSavings,
        roiWithTaxSavings: roiWithTaxSavings,
        maxCapacity: maxCapacity,
      });
    }
  }

  function calculateSolarInvestmentWithReturn(
    avgElectricityBill,
    type,
    pricing,
    inputPhaseSystem,
    cTRatio,
    maximumDemand,
    tariffGroup
  ) {
    let totalConsumption = CalculateTotalConsumption(
      avgElectricityBill,
      type,
      pricing
    );

    let proposedInstalledCapacity = CalculateProposedInstalledCapacity(
      totalConsumption,
      type,
      cTRatio,
      maximumDemand,
      tariffGroup
    );
    let totalProposedInstalledCapacity = CalculateProposedInstalledCapacity(
      totalConsumption,
      type,
      cTRatio,
      maximumDemand,
      tariffGroup
    );
    let phaseSystem = DeterminePhaseSystem(proposedInstalledCapacity, type);

    //Remain single phase and capped at 7.02 kwp capacity
    if (type === "Residential" && inputPhaseSystem === "Single Phase") {
      if (proposedInstalledCapacity > 7.02) {
        proposedInstalledCapacity = 7.02;
        totalProposedInstalledCapacity = 7.02;
      }
      phaseSystem = inputPhaseSystem;
    } else if (
      type === "Residential" &&
      residentialCalcData.phaseSystem === "Three Phase"
    ) {
      phaseSystem = inputPhaseSystem;
    }

    let batterySystemCost = CalculateBatterySystemCost(
      proposedInstalledCapacity,
      type
    );
    let monthlyEnergyGeneration = CalculateMonthlyEnergyGeneration(
      proposedInstalledCapacity
    );
    let balanceEnergyImport = CalculateBalanceEnergyImport(
      totalConsumption,
      monthlyEnergyGeneration
    );
    let monthlyBillWithVASolar = CalculateMonthlyBillWithVASolar(
      avgElectricityBill,
      balanceEnergyImport,
      type,
      pricing
    );
    let monthSavings = CalculateMonthlySaving(
      avgElectricityBill,
      monthlyBillWithVASolar
    );
    let extendWarrantyPackage = CalculateExtendWarranty(
      proposedInstalledCapacity,
      type
    );
    let insuranceCoveragePackage = CalculateInsuranceCoverage(
      proposedInstalledCapacity,
      type,
      batterySystemCost
    );
    let operationAndMaintenancePackage = CalculateOperationAndMaintenance(
      proposedInstalledCapacity,
      type
    );
    let extendWarrantyCost = CalculateExtendWarrantyCost(
      proposedInstalledCapacity,
      type
    );
    let insuranceCoverageCost = CalculateInsuranceCoverageCost(
      proposedInstalledCapacity,
      type,
      batterySystemCost
    );
    let operationAndMaintenanceCost = CalculateOperationAndMaintenanceCost(
      proposedInstalledCapacity,
      type
    );
    let paybackPeriod = CalculateROI(batterySystemCost, monthSavings, 0);
    let totalCost = batterySystemCost;
    let panel = CalculatePanel(proposedInstalledCapacity, type);
    let rooftopArea = CalculateRoof(panel);
    let carbonAvoidance = CalculateCarbonAvoidance(monthlyEnergyGeneration);
    let treesPlanted = CalculateTreePlanted(carbonAvoidance);
    let carDistance = CalculateCarDistance(carbonAvoidance);
    let SolarPVModule = DeterminePVModule(proposedInstalledCapacity, type);
    let ModelCode = DetermineModuleCode(proposedInstalledCapacity, type);
    let taxSavings = CalculateTaxSavings(batterySystemCost);
    let roiWithTaxSavings = CalculateROIWithTaxSavings(
      batterySystemCost,
      monthSavings
    );
    let maxCapacity = CalculateProposedInstalledCapacity(
      totalConsumption,
      type,
      cTRatio,
      maximumDemand,
      tariffGroup
    );

    return {
      totalConsumption,
      proposedInstalledCapacity,
      totalProposedInstalledCapacity,
      batterySystemCost,
      phaseSystem,
      monthlyEnergyGeneration,
      balanceEnergyImport,
      monthlyBillWithVASolar,
      monthSavings,
      extendWarrantyPackage,
      insuranceCoveragePackage,
      operationAndMaintenancePackage,
      extendWarrantyCost,
      insuranceCoverageCost,
      operationAndMaintenanceCost,
      totalCost,
      paybackPeriod,
      panel,
      rooftopArea,
      carbonAvoidance,
      treesPlanted,
      carDistance,
      SolarPVModule,
      ModelCode,
      taxSavings,
      roiWithTaxSavings,
      maxCapacity,
    };
  }

  function calculateSolarInvestmentByBillAndCapacityWithReturn(
    avgElectricityBill,
    proposedInstalledCapacity,
    totalProposedInstalledCapacity,
    type,
    pricing,
    inputPhaseSystem,
    cTRatio,
    maximumDemand,
    tariffGroup
  ) {
    let totalConsumption = CalculateTotalConsumption(
      avgElectricityBill,
      type,
      pricing
    );
    let batterySystemCost = CalculateBatterySystemCost(
      proposedInstalledCapacity,
      type
    );
    let phaseSystem = DeterminePhaseSystem(proposedInstalledCapacity, type);

    //Remain single phase and capped at 7.02 kwp capacity
    if (type === "Residential" && inputPhaseSystem === "Single Phase") {
      if (proposedInstalledCapacity > 7.02) {
        proposedInstalledCapacity = 7.02;
        totalProposedInstalledCapacity = 7.02;
      }
      phaseSystem = inputPhaseSystem;
    } else if (
      type === "Residential" &&
      residentialCalcData.phaseSystem === "Three Phase"
    ) {
      phaseSystem = inputPhaseSystem;
    }

    let monthlyEnergyGeneration = CalculateMonthlyEnergyGeneration(
      proposedInstalledCapacity
    );
    let balanceEnergyImport = CalculateBalanceEnergyImport(
      totalConsumption,
      monthlyEnergyGeneration
    );
    let monthlyBillWithVASolar = CalculateMonthlyBillWithVASolar(
      avgElectricityBill,
      balanceEnergyImport,
      type,
      pricing
    );
    let monthSavings = CalculateMonthlySaving(
      avgElectricityBill,
      monthlyBillWithVASolar
    );
    let extendWarrantyPackage = CalculateExtendWarranty(
      proposedInstalledCapacity,
      type
    );
    let insuranceCoveragePackage = CalculateInsuranceCoverage(
      proposedInstalledCapacity,
      type,
      batterySystemCost
    );
    let operationAndMaintenancePackage = CalculateOperationAndMaintenance(
      proposedInstalledCapacity,
      type
    );
    let extendWarrantyCost = CalculateExtendWarrantyCost(
      proposedInstalledCapacity,
      type
    );
    let insuranceCoverageCost = CalculateInsuranceCoverageCost(
      proposedInstalledCapacity,
      type,
      batterySystemCost
    );
    let operationAndMaintenanceCost = CalculateOperationAndMaintenanceCost(
      proposedInstalledCapacity,
      type
    );
    let paybackPeriod = CalculateROI(batterySystemCost, monthSavings, 0);
    let totalCost = batterySystemCost;
    let panel = CalculatePanel(proposedInstalledCapacity, type);
    let rooftopArea = CalculateRoof(panel);
    let carbonAvoidance = CalculateCarbonAvoidance(monthlyEnergyGeneration);
    let treesPlanted = CalculateTreePlanted(carbonAvoidance);
    let carDistance = CalculateCarDistance(carbonAvoidance);
    let SolarPVModule = DeterminePVModule(proposedInstalledCapacity, type);
    let ModelCode = DetermineModuleCode(proposedInstalledCapacity, type);
    let taxSavings = CalculateTaxSavings(batterySystemCost);
    let roiWithTaxSavings = CalculateROIWithTaxSavings(
      batterySystemCost,
      monthSavings
    );
    let maxCapacity = CalculateProposedInstalledCapacity(
      totalConsumption,
      type,
      cTRatio,
      maximumDemand,
      tariffGroup
    );

    return {
      totalConsumption,
      phaseSystem,
      proposedInstalledCapacity,
      totalProposedInstalledCapacity,
      batterySystemCost,
      monthlyEnergyGeneration,
      balanceEnergyImport,
      monthlyBillWithVASolar,
      monthSavings,
      extendWarrantyPackage,
      insuranceCoveragePackage,
      operationAndMaintenancePackage,
      extendWarrantyCost,
      insuranceCoverageCost,
      operationAndMaintenanceCost,
      totalCost,
      paybackPeriod,
      panel,
      rooftopArea,
      carbonAvoidance,
      treesPlanted,
      carDistance,
      SolarPVModule,
      ModelCode,
      taxSavings,
      roiWithTaxSavings,
      maxCapacity,
    };
  }

  return (
    <DocumentContext.Provider
      value={{
        proposal,
        setProposal,
        setInitialProposalData,
        initialProposalData,
        commercialCalcData,
        setCommercialCalcData,
        residentialCalcData,
        setResidentialCalcData,
        clearDataResidential,
        clearDataIndustrial,
        calculateSolarInvestment,
        // calculateSolarInvestmentByBillAndCapacity,
        calculateSolarInvestmentWithReturn,
        calculateSolarInvestmentByBillAndCapacityWithReturn,
        isCalculate,
        setIsCalculate,
        getTarrifData,
        pricing,
        setPricing,
        getLocalStorageData,
        promotion,
        setPromotion,
        landingPage,
        setLandingPage,
        hasPartner,
        setHasPartner,
        getProposalData,
      }}
    >
      {props.children}
    </DocumentContext.Provider>
  );
};

export default DocumentContextProvider;
