import { useContext } from 'react';
import TopNavigation from '../../../../components/top-navigation/TopNavigation';
import FooterOurLocation from '../../../../components/footer-our-location/FooterOurLocation';
import ContactUs from '../../../../components/contact-us/ContactUs';
import ResidentialBanner from '../ResidentialBanner';
import CompanyDescriptionResidential from '../../CompanyDescriptionResidential';
import CompletedProjectResidential from '../CompletedProjectResidential';
import InsertDataCalculator from '../InsertDataCalculator';
import { DocumentContext } from '../../../../context/DocumentContext';


function FillUpDetailResidential() {
    const { hasPartner } = useContext(DocumentContext)
    return (
        <div className="residential" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
                <InsertDataCalculator />
                {!hasPartner && (
                    <>
                        <CompanyDescriptionResidential />
                        <CompletedProjectResidential />
                        <ResidentialBanner />
                        <ContactUs />
                    </>
                )}
              
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default FillUpDetailResidential;