import TopNavigation from '../../../components/top-navigation/TopNavigation'
import FooterOurLocation from '../../../components/footer-our-location/FooterOurLocation';
import AboutBanner from '../AboutBanner';
import AboutUs from '../AboutUs';
import TeraVaLeadership from '../TeraVaLeadership';
import CertificateAwards from '../CertificateAwards';
import DirectorSpeech from '../DirectorSpeech';
import ScopeQMS from '../../Module_10_QMS_Quality_Policy/ScopeQMS'
import QualityPolicy from '../../Module_10_QMS_Quality_Policy/QualityPolicy'

function About() {
    return (
        <div className="about" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
                <AboutBanner />
                <AboutUs />
                <TeraVaLeadership />
                <DirectorSpeech />
                <ScopeQMS whiteBackground={true} />
                <QualityPolicy />
                <CertificateAwards />
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default About;