import React from 'react';
import './DetailCommercialPortfoliosBanner.scss';


class DetailCommercialPortfoliosBanner extends React.Component {

    render() {
        return (
                <div className="detail-commercial-banner">
                <div className="detail-commercial-cover">
                    <div className="detail-commercial-banner-container">
                        <div className="left-node">
                        
                            <label className="title">One of the leading organizations that specialized in the field of renewable solar energy</label>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default DetailCommercialPortfoliosBanner;