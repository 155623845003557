import React from "react";
import "./ServiceBanner.scss";
import ContactUsFloat from "../../components/contact-us-float/ContactUsFloat";

class ServiceBanner extends React.Component {
  render() {
    return (
      <div className="service-banner">
        <div className="service-cover">
          <div className="service-banner-container">
            <div className="left-node">
              <ContactUsFloat />
              <h1 className="title">
                Discover Malaysian Renewable Energy with TERA
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceBanner;
