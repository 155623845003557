import TopNavigation from '../../../components/top-navigation/TopNavigation'
import FooterOurLocation from '../../../components/footer-our-location/FooterOurLocation';
import NewsEventBanner from '../NewsEventBanner'
import NewsEventList from '../NewsEventList';

function NewsEvents() {
    return (
        <div className="news-events" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
                <NewsEventBanner />
                <NewsEventList />
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default NewsEvents;