import React from 'react';
import './NewsEventBanner.scss';
import ContactUsFloat from '../../components/contact-us-float/ContactUsFloat';

class NewsEventBanner extends React.Component {

    render() {
        return (
                <div className="news-banner">
                <div className="news-cover">
                    <div className="news-banner-container">
                        <div className="left-node">
                        <ContactUsFloat />
                            <h1 className="title">Creating Energy Solutions for Malaysia</h1>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsEventBanner;