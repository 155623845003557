import { Link, useHistory } from "react-router-dom";
import Logo from '../../images/tera-logo-white-cropped.png';
import './HomeNavigation.scss';
import SideDrawer from "../../components/side-drawer/SideDrawer";
import { useState } from 'react';
import { Menu, MenuItem } from "@material-ui/core";

function HomeNavigation() {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleNavigate = (path: string) => {
        handleClose();
        history.push(path);
    };
    return (
        <div className="home-navigation">
            <div style={{marginBottom: "15px"}}>
                <img className="logo" alt="tera-logo" src={Logo} /> <br/>
                <span style={{color: "#fff", fontSize: "12px"}}>Copyright © 2024 by TERA VA SDN BHD 201101001113 (929247-P)</span>
            </div>
            <div className="link-wrapper">
                <Link className="link" to="/residential">Residential</Link>
                <Link className="link" to="/commercial-industrial">Commercial & Industrial</Link>
                <Link className="link" to="/about">About</Link>
                <Link className="link" to="/portfolios">Portfolios</Link>
                <Link className="link" to="/services">Services</Link>
                <Link className="link" to="/careers">Careers</Link>
                <Link className="link" to="/news-events">News & Events</Link>
                <a className="menu-button" onClick={handleClick}>Testimonials</a>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    style={{ marginTop: "40px" }}
                >
                    <MenuItem style={{ fontSize: "13px"}} onClick={() => handleNavigate("/testimonials/solar-panel-petaling-jaya")}>
                        Petaling Jaya
                    </MenuItem>
                    <MenuItem style={{ fontSize: "13px"}} onClick={() => handleNavigate("/testimonials/solar-panel-melaka")}>
                        Melaka
                    </MenuItem>
                    <MenuItem style={{ fontSize: "13px"}} onClick={() => handleNavigate("/testimonials/solar-panel-johor-bahru")}>
                        Johor Bahru
                    </MenuItem>
                </Menu>
                <Link className="link" to="/malaysian-investment-development-authority">MIDA</Link>
            </div>
            <div className="hamburger-button">
                <SideDrawer />
            </div>
        </div>
    );
}

export default HomeNavigation;
