import FooterOurLocation from '../../../components/footer-our-location/FooterOurLocation';
import TopNavigation from '../../../components/top-navigation/TopNavigation';
import './ComingSoonPage.scss';

function About() {
    return (
        <div className="about" style={{ overflow: "hidden" }}>
            <TopNavigation />
            <div>
                <div className='coming-soon-background'>
                    <h1 className='coming-soon-title'>Coming Soon</h1>
                    <p className='coming-soon-text'>Stay tuned for more exciting content.</p>
                </div>
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default About;