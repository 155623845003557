import "./Portfolio.scss";

function Portfolio() {
  return (
    <div className="portfolios-description">
      <div className="portfolio-description-container">
        <div className="portfolio-description">
          <span className="portfolio">PORTFOLIOS</span>
          <br />
          <label className="title">Completed Projects</label>
          <br />
          {/* <Box 
            display="flex"
            flexDirection="row"
            p={0} 
            bgcolor="background.paper"
          >
            <Box
              component="span"
              m={1}
              width="50%"
              style={{margin: 0}}>
              <img src={Residential} className="portfolios-images"/>
              <div className="portfolios-box-title">RESIDENTIAL PROJECTS</div>
              <div className="portfolios-box-subtitle">Tera VA is a subsidiary of Boilermech Holdings Berhad, a wholly-owned unit of QL Resources Berhad.</div>
              <Button className="left-node">View More</Button>
            </Box>
            <Box
              component="span"
              m={1} 
              width="50%"
              style={{margin: 0}}>
                <img src={Commercial} className="portfolios-images"/>
                <div className="portfolios-box-title">COMMERCIAL & INDUSTRIAL PROJECTS</div>
                <div className="portfolios-box-subtitle">Tera VA is a subsidiary of Boilermech Holdings Berhad, a wholly-owned unit of QL Resources Berhad.</div>
                <Button className="right-node" >View More</Button>
              </Box>
          </Box> */}
          </div>
      </div>
    </div>
  );
}

export default Portfolio;
