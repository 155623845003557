import React, { useContext, useState, useEffect } from "react";
import './CheckoutProposal.scss';
import { Paper, Grid, Box, Divider, Button, TextField, Modal, CircularProgress, Hidden, Link, Typography, FormControl } from '@material-ui/core'
import AlertBar from '../../../components/alert/Alert';
import CO2 from "../../../images/co2-cloud.svg"
import tree from "../../../images/palm-tree.svg"
import roof from "../../../images/roof.svg"
import surface from "../../../images/surface1.svg"
import ArrowRight from '../../../images/arrow-right.png';
import { DocumentContext } from '../../../context/DocumentContext';
import { API, graphqlOperation } from "aws-amplify";
import { addProposal } from "../../../graphql/mutations";
import { generateProposalPDF, generatePaymentLink } from "../../../graphql/queries";
import { CalculateROI, CalculateROIWithTaxSavings } from '../calculator/Calculator';
// import { getS3FileUrl } from "../../../graphql/queries";

function CheckoutProposal(props: any) {
  const {
    proposal,
    setProposal,
    getProposalData,
  } = useContext(DocumentContext)
  const [removedPromoCode, setRemovedPromoCode] = useState(false)
  // const {documentLink} = proposal

  function getProposal() {
    let { id } = props.match.params
    if (id !== undefined) {
      getProposalData(id).then((res: any) => {
        setProposal(res)
      })
    }

  }

  useEffect(() => {
    getProposal()
    // getLocalStorageData().then((res: any) => {
    //   setProposal(res)
    // })
  }, [])

  useEffect(() => {
    if (removedPromoCode) {
      updateProposal()
    }

  }, [removedPromoCode])

  // useEffect(() => {
  //   localStorage.setItem('document', JSON.stringify(proposal))
  // }, [proposal])

  const [isSaving, setIsSaving] = useState(false)
  const [isDownload, setIsDownload] = useState(false)
  const [isSending, setIsSending] = useState(false)


  function handleback() {
    window.history.back();
    window.scrollTo(0, 0);
  }

  function downloadFile(url: any, filename: String) {
    const a: any = document.createElement('a');
    a.href = url;
    a.download = filename;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  const [notify, setNotify] = useState({
    show: false,
    severity: '',
    message: ''
  })

  async function handleDownload(event: any) {
    var proposalData = {
      proposalId: proposal.proposalId,
      clientName: proposal.clientName,
      clientPhone: proposal.clientPhone,
      clientType: proposal.clientType,
      tariffGroup: proposal.tariffGroup,
      buildingType: proposal.buildingType,
      clientAddress: proposal.clientAddress,
      clientAddress2: proposal.clientAddress2,
      clientAddress3: proposal.clientAddress3,
      clientEmail: proposal.clientEmail,
      referralCode: proposal.referralCode,
      referralName: proposal.referralName,
      modeOfPurchase: proposal.modeOfPurchase,
      phaseSystem: proposal.phaseSystem,
      avgElectricityBill: proposal.avgElectricityBill,
      insuranceCoverage: proposal.insuranceCoverage,
      operationAndMaintenance: proposal.operationAndMaintenance,
      extendWarranty: proposal.extendWarranty,
      otherRequestPackage: proposal.otherRequestPackage,
      status: proposal.status,
    }

    var params = {
      proposalData,
      token: "1234",
      mode: "Download"
    }
    try {
      setIsDownload(true)
      setNotify({
        show: true,
        severity: 'success',
        message: 'Proposal is Downloading'
      })
      let res: any = await API.graphql(graphqlOperation(generateProposalPDF, params));
      const s3FileUrl = res.data.generateProposalPDF.url
      downloadFile(s3FileUrl, "TERA_Proposal.pdf")
      setIsDownload(false)


    } catch (error) {
      console.log(error)
    }



  }

  async function handleConfirm(event: any) {
    event.preventDefault();
    try {
      setIsSaving(true)
      var params = {
        proposalId: proposal.proposalId,
        token: '1234'
      };
      let res: any = await API.graphql(graphqlOperation(generatePaymentLink, params));
      if (res && res.data.generatePaymentLink.paymentURL !== null) {
        setIsSaving(false)
        window.location.assign(res.data.generatePaymentLink.paymentURL)
      }
      else {
        setIsSaving(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onChange = (e: any) => {
    if (e.target.value === ' ') {
      setProposal({ ...proposal, [e.target.name]: '' })
    } else {
      setProposal({ ...proposal, [e.target.name]: e.target.value })
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function handleRemovePromoCode() {
    setRemovedPromoCode(true)
    setProposal({
      ...proposal,
      promoCode: "",
      totalCost: calculateTotalCost(proposal.batterySystemCost, proposal.totalAddOnCost),
      paybackPeriod: CalculateROI(proposal.batterySystemCost, proposal.monthSavings, 0),
      roiWithTaxSavings: CalculateROIWithTaxSavings(proposal.batterySystemCost, proposal.monthSavings, 0),
      totalDiscount: 0
    })
  }

  function calculateTotalCost(batterySystemCost: number, addOnCost: number) {
    return batterySystemCost + addOnCost
  }

  async function updateProposal() {
    setIsSaving(true)
    let res: any = await API.graphql(graphqlOperation(addProposal, proposal));
    if (res && (res.data.addProposal.statusCode === 200 || res.data.addProposal.statusCode === "200")) {
      setIsSaving(false)
      setNotify({
        show: true,
        severity: 'success',
        message: 'Successfully removed promo code'
      })
    } else {
      setIsSaving(false)
      setNotify({
        show: true,
        severity: 'error',
        message: 'Failed to remove promo code'
      })
    }
  }

  // function getClientEmail(event: any){
  //   setOpen(true);
  // }
  // async function handleSendEmail(event : any) {
  //   var proposalData = {
  //     proposalId: proposal.proposalId,
  //     clientName: proposal.clientName,
  //     clientPhone: proposal.clientPhone,
  //     clientType: proposal.clientType,
  //     tariffGroup:  proposal.tariffGroup,
  //     buildingType: proposal.buildingType,
  //     clientAddress: proposal.clientAddress,
  //     clientAddress2: proposal.clientAddress2,
  //     clientAddress3: proposal.clientAddress3,
  //     clientEmail: proposal.clientEmail,
  //     referralCode: proposal.referralCode,
  //     referralName: proposal.referralName,
  //     modeOfPurchase: proposal.modeOfPurchase,
  //     phaseSystem: proposal.phaseSystem,
  //     avgElectricityBill: proposal.avgElectricityBill,
  //     insuranceCoverage: proposal.insuranceCoverage,
  //     operationAndMaintenance: proposal.operationAndMaintenance,
  //     extendWarranty: proposal.extendWarranty,
  //     otherRequestPackage: proposal.otherRequestPackage,
  //     status:  proposal.status,
  //   }

  //   var params = {
  //     proposalData,
  //     token: "1234",
  //     mode: "Email"
  //   }
  //   try{
  //     setIsSending(true)
  //     let res: any = await API.graphql(graphqlOperation(generateProposalPDF, params));
  //     // console.log("res: ", res)
  //     setIsSending(false)
  //     handleClose()

  //   } catch(error){
  //     console.log(error)
  //   }
  // }

  // let body = (
  //   <div className="modal-appointment">
  //     <h2 id="simple-modal-title" className="modal-title">
  //     Get your Proposal through Email
  //     </h2>
  //     <p className="modal-subtitle">Please insert your email</p>
  //     <TextField 
  //       label="Email" 
  //       type="email"
  //       name="email"
  //       value={proposal.clientEmail}
  //       onChange={onChange}
  //       className="date"
  //       variant="outlined" 
  //       InputLabelProps={{
  //         shrink: true,
  //       }}/>
  //        <Button 
  //         variant="contained" 
  //         size="large" 
  //         className="button-main-theme calculate"
  //         onClick={handleSendEmail}>
  //           <div className="wrapper">
  //               <label className="btn-title">Send to your Email</label>
  //                 <label className="right-arrow">
  //                 {isSending ? (
  //                   <CircularProgress style={{color: "white"}} />
  //                 ) : (
  //                   <img src={ArrowRight} alt="right-arrow" />
  //                 )}
  //                 </label>
  //             </div>
  //         </Button>
  //     <p className="cancel" onClick={handleClose}>
  //       Cancel
  //     </p>
  //   </div>
  // );

  //Number with Comma
  function NumberFormat(num: number) {
    return num?.toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return (
    <div className="checkout-descriptions">
      <div className="checkout-container" >
        <div className="checkout-description">
          <span className="checkout">Checkout</span><br />
          <label className="title">Proposal Overview</label><br />
        </div>
        <Paper className="paper">
          <Grid container spacing={3} className="info-container">
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Your monthly savings with TERA</p>
                <h1>RM {NumberFormat(proposal.monthSavings)}</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Your current monthly bill</p>
                <h1>RM {proposal.avgElectricityBill}</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Your monthly bill with TERA</p>
                <h1>RM {NumberFormat(proposal.monthlyBillWithVASolar)}</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Maximum installed capacity</p>
                <h1>{NumberFormat(proposal.proposedInstalledCapacity)} kWp </h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>ROI</p>
                <h1>{proposal.paybackPeriod} years</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>System Cost</p>
                <h1>RM {NumberFormat(proposal.batterySystemCost)}</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Total AddOns Cost</p>
                <h1>RM {NumberFormat(proposal.totalAddOnCost)}</h1>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={6}
              lg={3}
              className="info-container__content"
            >
              <Box component="span" className="info-container__content__box">
                <p>Total Discount</p>
                <h1>RM {NumberFormat(proposal.totalDiscount)}</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Total Cost</p>
                <h1>RM {proposal.totalCost}</h1>
              </Box>
            </Grid>
            {
              proposal.finalAmount > 0 && (
                <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
                  <Box component="span" className="info-container__content__box">
                    <p>Final Amount</p>
                    <h1>RM {NumberFormat(proposal.finalAmount)}</h1>
                  </Box>
                </Grid>
              )
            }
          </Grid>
        </Paper>

        <Paper className="paper">
          <Grid container spacing={3} className="info-container">
            <Grid item xs={12} sm={9} md={6} lg={5} alignItems="center" justifyContent="center" className="info-container__content">
              <Box>
                <img
                  src={roof}
                  className="iconDetails"
                  alt="roof"
                  style={{ float: "left", marginRight: "5px" }}
                />
              </Box>
              <Box component="span" className="info-container__content__box">
                <span>
                  Rooftop Area
                  <span style={{ fontWeight: "bold" }}> {NumberFormat(proposal.rooftopArea)}
                    <span style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                      <span>m</span>
                      <sup style={{ fontSize: "10px" }}>2</sup>
                    </span>
                  </span><br></br>
                  <span>*1pv module approximately to 2.6</span>
                  <span>m</span>
                  <sup style={{ fontSize: "10px" }}>2</sup>
                </span>
              </Box>
            </Grid>
            <Hidden only={["xs", "sm", "md"]}>
              <Grid item xs={12} sm={9} md={6} lg={2} alignItems="center" justifyContent="center" className="info-container__content">
                <Divider orientation="vertical" flexItem />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={9} md={6} lg={5} alignItems="center" justifyContent="center" className="info-container__content">
              <Box>
                <img
                  src={CO2}
                  className="iconDetails"
                  alt="CO2"
                  style={{ float: "left", marginRight: "5px" }} />
              </Box>
              <Box component="span" className="info-container__content__box">
                <span>
                  <span>Carbon Dioxide(</span>
                  <span style={{ alignItems: 'flex-end' }}>CO</span>
                  <span style={{ alignItems: 'flex-start', fontSize: "10px" }}>2</span>
                  <span>)</span><br></br>
                  <span>Avoidance </span>
                  <span style={{ fontWeight: "bold" }}>
                    {NumberFormat(proposal.carbonAvoidance)} tonne
                    <span style={{ alignItems: 'flex-end' }}> CO</span>
                    <span style={{ alignItems: 'flex-start', fontSize: "10px" }}>2</span>
                  </span>
                </span>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {!proposal.insuranceCoverage && !proposal.operationAndMaintenance ? null : (
          <Paper className="paper">
            <Grid container spacing={3} className="info-container">
              <Grid item xs={12} sm={9} md={6} lg={6} className="info-container__content">
                <Box component="span" className="info-container__content__box">
                  <p>Special All Risk PV Insurance</p>
                  {proposal.insuranceCoverage ? (
                    <>
                      <h2>{proposal.insuranceCoveragePackage}</h2>
                    </>
                  ) : null}

                </Box>
              </Grid>
              <Grid item xs={12} sm={9} md={6} lg={6} className="info-container__content">
                <Box component="span" className="info-container__content__box">
                  <p>Comprehensive Maintenance</p>
                  {proposal.operationAndMaintenance ? (
                    <>

                      <h2>{proposal.operationAndMaintenancePackage}</h2>
                    </>
                  ) : null}

                </Box>
              </Grid>
              {/*<Grid item xs={12} sm={9} md={6} lg={4} className="info-container__content">
                        <Box component="span" className="info-container__content__box">
                        <p>Extended Inverter Warranty (10 years)</p>
                          {proposal.extendWarranty ? (
                            <>
                              <h2>{proposal.extendWarrantyPackage}</h2>
                            </>
                          ) : null}
                        
                        </Box>    
                      </Grid> */}
            </Grid>
          </Paper>
        )}


        {proposal.otherRequestPackage === "" ? (
          ""
        ) : (
          <>
            <Paper className="paper">
              <Grid container spacing={3} className="info-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="info-container__content">
                  <Box component="span" className="info-container__content__box">
                    <p>Other Requirement</p>
                    <h2>{proposal.otherRequestPackage}</h2>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}

        <div className="checkout_detail_location">
          <div className="row">
            <div className="col-md-12">
              <p>Summary :</p>
            </div>
            <div className="col-md-12">
              <p>
                <span className="checkout_detail_location">- Solar PV Monocyrstalline PV Module (585 Wp) – {proposal.pvQuantity}pcs Half-cell layout Monocrystalline</span><br />
                <span className="checkout_detail_location">- HUAWEI {proposal.modelCode} Inverter {proposal.phaseSystem}</span><br />
                <span className="checkout_detail_location">- Free Online Monitoring System</span><br />
                <span className="checkout_detail_location">- Roof Racking System</span><br />
                <span className="checkout_detail_location">- TERA system Installation</span><br />
                <span className="checkout_detail_location">- Roof Racking System Installation</span><br />
                <span className="checkout_detail_location">- Lifting Services (if applicable)</span><br />
                <span className="checkout_detail_location">- Roof Racking System Installation</span><br />
                <span className="checkout_detail_location">- Material Transportation</span><br />
                <span className="checkout_detail_location">- Installation of Surface Mounted Casing DC & AC Wiring</span><br />
                <span className="checkout_detail_location">- Accessories</span>
              </p>
            </div>
            <Divider variant="middle" />
            <div className="col-md-12">
              <p>
                <span className="checkout_detail_location">Name: </span>
                {proposal.clientName}
              </p>
            </div>
            <div className="col-md-12">
              <p>
                <span className="checkout_detail_location">Phone Number: </span>
                {proposal.clientPhone}
              </p>
            </div>
            <div className="col-md-12">
              <p>
                <span className="checkout_detail_location">Mode of Purchase: </span>
                {proposal.modeOfPurchase}
              </p>
            </div>
            <div className="col-md-12">
              <p>
                <span className="checkout_detail_location">Three Phase / Single Phase: </span>
                {proposal.phaseSystem}
              </p>
            </div>
            <Divider variant="middle" />
            <div className="col-md-12">
              <p>
                <span className="checkout_detail_location">Deposit: </span>
                <span style={{ fontFamily: "Proxima Nova Bold", float: "right" }}> RM 1000 </span>
              </p>
            </div>

            <Divider variant="middle" />
            <div className="col-md-12">
              <p>
                <span className="checkout_detail_location" style={{ fontFamily: "Proxima Nova Bold" }}>Total: </span>
                <span style={{ fontFamily: "Proxima Nova Bold", float: "right" }}> RM 1000 </span>
              </p>
            </div>
          </div>
          <Divider variant="middle" />
        </div>

        <div style={{ marginTop: '10px' }}>
          <Typography style={{ fontFamily: "Proxima Nova Bold", fontSize: "14px" }}>Refund Policy</Typography>
          <FormControl component="fieldset" style={{ marginTop: '10px' }}>
            <Typography style={{ fontFamily: "Proxima Nova", fontSize: "14px" }}>
              i. For project cancellation Upon Confirmation:
              <ul style={{ marginTop: 0 }}>
                <li>Deposit is refundable at 100% before document submission or application to any authorities.</li>
                <li>Deposit is refundable at 70% after document submission or application to any authorities.</li>
              </ul>
            </Typography>
            <Typography style={{ fontFamily: "Proxima Nova", fontSize: "14px" }}>
              ii.  For project cancellation Before Delivery of Materials, Upon Completion of System Installation:
              <ul style={{ marginTop: 0 }}>
                <li>The fees to be invoiced shall be for the materials ordered, and work that has been carried out at the point of cancellation. </li>
                <li>Regarding the remaining project value, 20% of the remaining project value would be invoiced to customer.</li>
              </ul>
            </Typography>
            <Typography style={{ fontFamily: "Proxima Nova", fontSize: "14px" }}>
              iii. For project cancellation Upon System Energised:
              <ul style={{ marginTop: 0 }}>
                <li>No refund would be provided. Customer would be invoiced the entire project value.</li>
              </ul>
            </Typography>
          </FormControl>
        </div>

        <div>
          <Button
            variant="contained"
            size="large"
            className="button-main-theme calculate"
            onClick={handleConfirm}
            disabled={isSaving || isDownload ? true : false}>
            <div className="wrapper">
              <label className="btn-title">Confirm & Signup Now with A Discounted Deposit Payment</label>
              {/* <label className="btn-title">Confirm & Signup Now</label> */}
              <label className="right-arrow">
                {isSaving ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  <img src={ArrowRight} alt="right-arrow" />
                )}
              </label>
            </div>
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleDownload}
            className="button-main-theme calculate download"
            disabled={isSaving || isDownload ? true : false}
          >
            <div className="wrapper">
              <label className="btn-title">Download</label>
            </div>
          </Button>
          {isDownload ? (
            <div className="detail_container">
              <div className="row">
                <div className="col-md-12">
                  <p><CircularProgress /></p>
                </div>
              </div>
            </div>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={handleback}
              className="button-main-theme calculate back"
              disabled={isSaving || isDownload ? true : false}
            >
              <div className="wrapper">
                <label className="btn-title">Back</label>
              </div>
            </Button>
          )}
        </div>
        {/* <div className="detail_container">
              <div className="row">
                <div className="col-md-12">
                  <p>
                  {isDownload ? (
                    <CircularProgress />
                  ) : (
                  <Button 
                    variant="contained" 
                    size="large" 
                    onClick={handleback}
                    className="button-main-theme calculate back"
                    disabled={isSaving || isDownload ? true : false}
                  >
                  <div className="wrapper">
                <label className="btn-title">Back</label>
              </div>
          </Button> */}
        {/* <span> or </span>
                  <Link href="#!" onClick={getClientEmail}>
                    send to my email
                  </Link> */}
        {/* </p>
                </div>
              </div>
          </div> */}
        {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
          >
          {body}
        </Modal> */}
        <AlertBar notify={notify} setNotify={setNotify} />
      </div>
    </div>
  )
}

export default CheckoutProposal;

