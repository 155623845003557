import "./ServicesDescription.scss";
import FiveYear from "../../images/5year.svg";
import FiveYears from "../../images/5year-s.svg";
import TwelveYear from "../../images/12year.svg";
import TwentyFiveYear from "../../images/25year.svg";
import { ChangeEvent, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function ServicesDescription() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className="service-descriptions">
      <div className="service-description-container">
        <div className="service-description">
          <h1 className="title">Your Leading EPC Solar Company</h1>
          <br />
          <label className="description">
            Humbly established in 2011, TERA is your premier partner in the
            world of renewable energy in Malaysia. Our beliefs are deeply rooted
            in energizing buildings while championing the cause of
            sustainability. We constantly strive to become and maintain our
            image as a renowned solar company in Malaysia, achieving this
            through high quality solar panel solutions for both residential and
            commercial customers. Save mother nature and embark on your own
            green journey through the use of solar panels in Malaysia.
          </label>
          <br />
          <label className="title">Empowering Malaysia with Solar Energy</label>
          <br />
          <label className="description">
            “You reap what you sow” is a famous saying illustrating the outcome
            of our actions; you can reap the benefits of green energy by sowing
            the seeds through solar panels. We're not just about selling solar
            panels across Malaysia; we're about revolutionizing the way Malaysia
            consumes energy. As the demand for clean and sustainable energy has
            been steadily increasing, solar energy in Malaysia is becoming ever
            more attractive. Our solar panel installation services to focus on
            quality for Malaysians to effectively harness this green source of
            energy.
          </label>
          <br />
          <h2 className="title">Residential Solar Solutions</h2>
          <br />
          <label className="description">
            Experience the difference of going solar with TERA's top-notch
            residential solar solutions. Get ready to turn your landed
            property’s rooftop into a thriving solar energy farm and watch it
            whittle away your electric bill! Don’t worry if you end up
            generating more than you can use, the extra energy can be sent right
            back to the grid and will be offset one-to-one on your subsequent
            consumption; all this without you having to lift a finger. Being a
            trusted solar energy company in Malaysia means being committed to
            delivering excellence, ensuring your transition to solar power is
            completely seamless.
          </label>
          <br />
          <h2 className="title">Commercial Solar Solutions</h2>
          <br />
          <label className="description">
            Elevate your business with TERA's commercial solar solutions.
            Being a forward-thinking entrepreneur entails embracing
            sustainability that can set your business apart. Our solar panel
            installation expertise ensures that your business operations remain
            uninterrupted while you enjoy significant cost savings. Be a
            trailblazer in your industry with our help as your premier EPC
            contractor in Petaling Jaya, Malaysia.
          </label>
          <br />
          <label className="title">Why TERA?</label>
          <br />
          <label className="description">
            Here's why we stand out as the preferred choice for solar panel
            installations throughout Malaysia:
          </label>
          <br />
          <div className="privacy-policy-expand-bar">
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Expertise Beyond Compare
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      With over a decade of experience, our solar company in
                      Malaysia boasts specialist drafters, engineers, and
                      installation teams ensure flawless solar panel
                      installation and peak performance.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Tailored for You
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      Recognizing that every customer is unique, we tailor our
                      solar solutions to your energy needs and preferences,
                      ensuring the perfect fit.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Uncompromising Quality
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      At TERA, quality is non-negotiable. We source the
                      finest solar panels, inverters and equipment from top tier
                      global manufacturers, ensuring your investment delivers
                      optimal efficiency and longevity.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Customer-Centric Approach
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      Our commitment to you goes beyond installation. We provide
                      unwavering support from the first consultation to
                      post-installation service and maintenance.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Championing Sustainability
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      By choosing us, you're not just opting for solar panels;
                      you're contributing to a more sustainable Malaysia and a
                      greener planet.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <br />
          <label className="title">Embrace Solar Power Today!</label>
          <br />
          <label className="description">
            Ready to embrace the solar revolution in Malaysia? Experience the
            power, the savings, and the sustainability with TERA. Whether
            you're a homeowner seeking to save on electric bills or a business
            aiming for eco-friendly prominence, our solar solutions have you
            covered. Contact us today to schedule your consultation and take the
            first step towards a brighter, greener future with TERA – your
            trusted solar energy company and EPC contractor in PJ, Malaysia.
          </label>
          <span className="service">SERVICES</span>
          <br />
          <label className="title">TERA PV System</label>
          <br />

          <label className="description">
            Our proprietary system is designed to meet the needs of buildings
            and homes in the Asian region. The system integrates design and
            build, installation, testing and commissioning together with
            service, operation and maintenance.
          </label>
          <br />
          <label className="description">
            TERA PV is created to generate electrical energy from the sun to
            power daily tasks in households, offices and factories. The system
            is hassle-free, needing only minimal operation, maintenance and
            service.
          </label>
          <br />
        </div>
        <Grid container spacing={4} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={9} md={6} lg={3} className="logo-box">
            <Box component="span">
              <img src={FiveYears} alt="Five Year Logo" />
              <p className="title-service-icon">Workmanship warranty</p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={3} className="logo-box">
            <Box component="span">
              <img src={FiveYear} alt="Five Year Logo" />
              <p className="title-service-icon">
                Inverter manufacturer defect warranty
              </p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={3} className="logo-box">
            <Box component="span">
              <img src={TwelveYear} alt="Twelve Year Logo" />
              <p className="title-service-icon">
                PV module manufacturer defect warranty
              </p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={3} className="logo-box">
            <Box component="span">
              <img src={TwentyFiveYear} alt="Twenty Five Year Logo" />
              <p className="title-service-icon">
                PV module performance manufacturer warranty
              </p>
            </Box>
          </Grid>
        </Grid>
        <label className="sub-description">
          * 5 Years workmanship warranty is applicable for Commercial and
          Industrial buildings only
        </label>
        <br />
        <label className="sub-description">
          * 3 Years workmanship warranty for Residential buildings
        </label>
        <br />
      </div>
    </div>
  );
}

export default ServicesDescription;
