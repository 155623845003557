import './VasolarInsuranceCoverage.scss';
import AffordablePrice from '../../images/affordable-prices.svg';
import ComprehensiveProtection from '../../images/comprehensive-protection.svg';
import IncomeProtection from '../../images/income-protection.svg';
import ProductAssurance from '../../images/product-assurance.svg';


function VasolarInsuranceCoverage() {
    return (
        <div className="insurance-descriptions">
            <div className="insurance-description-container" >
                <div className="insurance-description">
                    <label className="title">TERA Insurance Coverage</label><br />
                </div>
                <div className="benefits">
                    <div className="small-card-container">
                        <div className="small-card">
                            <img src={ComprehensiveProtection} alt="Comprehensive Protection Logo" />
                            <label className="title">Comprehensive Protection</label>
                            <label className="description">The freedom of knowing your solar PV is completely protected</label>
                        </div>
                    </div>
                    <div className="small-card-container middle">
                        <div className="small-card">
                            <img src={ProductAssurance} alt="Product Assurance Logo" />
                            <label className="title">Product Assurance</label>
                            <label className="description">With this insurance package, you can avoid stress and financial drains due to faulty Solar PV or breakdown of components</label>   
                        </div>
                    </div>
                    <div className="small-card-container">
                        <div className="small-card">
                            <img src={IncomeProtection} alt="Income Protection Logo" />
                            <label className="title">Income Protection</label>
                            <label className="description">For those under NEM and self-consumption, income/saving generated by the solar system will be protected if system breakdown</label>
                        </div>
                    </div>
                    <div className="small-card-container">
                        <div className="small-card">
                            <img src={AffordablePrice} alt="Affordable Price Logo" />
                            <label className="title">Affordable Price</label>
                            <label className="description">The annual premium and insurance package is highly affordable and reasonable</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VasolarInsuranceCoverage;