import { ChangeEvent, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Testimonial.scss";

function JohorBahru() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className="aboutuss-description">
      <div className="aboutus-description-container">
        <div className="aboutus-description">
          <span className="aboutus">TESTIMONIALS</span>
          <br />
          <h1 className="title" style={{ marginTop: 0, marginBottom: 0, fontWeight:"normal"}}>The Keys to a Greener Earth</h1>
          <br />
          <label className="description">
            In the bustling and rapidly developing city of Johor Bahru, keeping
            up with progress is key to staying competitive.Solar panels in Johor
            Bahru can play a pivotal role in propelling the city into a greener
            and more prosperous future.
          </label>
          <br />
          <h1 className="title">
            Why Choose Solar Panels in Johor Bahru?
          </h1>
          <br />
          <label className="description">
            Johor Bahru's transformation from a quiet town to a thriving
            metropolis has been nothing short of remarkable. As the city grows,
            so do its energy needs. Traditional energy sources not only
            contribute to environmental degradation but also strain resources
            and money. With how bright and sunny Johor Bahru is, It's time to
            look toward a more sustainable future, and solar panels are the way
            forward.
          </label>
          <br />
          <label className="title">Our Promises to You</label>
          <br />
          <label className="description">
            TERA is your trusted solar panel provider in harnessing the
            benefits of solar energy. As a full end-to-end solutions provider
            for solar panels in Malaysia, our commitment to excellence ensures
            that you receive top-notch service every step of the way.
          </label>
          <br />
          <div className="privacy-policy-expand-bar">
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Tailored Solutions
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      Each and every building is unique, hence it takes proper
                      planning and design to efficiently harness solar power.
                      That's why we offer customized solar panel solutions
                      tailored to the specific needs of Johor Bahru residents
                      and businesses. Whether you require a residential solar
                      installation or a commercial-scale project, TERA’s team
                      has you covered from start to finish.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Cutting-Edge Technology
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      Staying ahead of the curve is essential in Johor Bahru's
                      rapidly developing landscape. Our solar panels utilize the
                      latest technology, ensuring optimal energy generation and
                      efficiency. By choosing TERA, you're investing in the
                      most advanced solar solutions in the market.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">Sustainability</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      In the race for progress, don't let sustainability fall by
                      the wayside. Solar panels not only reduce your carbon
                      footprint but also lower your energy bills. This dual
                      benefit makes choosing solar panels in Johor Bahru a smart
                      financial decision, ensuring you don't fall behind the
                      curve.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">Reliability</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      With TERA, you can trust that your solar panels will
                      provide reliable energy for years to come. Solar systems
                      generally require minimal maintenance. Routine tasks
                      include cleaning the panels to remove debris, and
                      monitoring system performance. For optimal performance,
                      clients may opt for a professional cleaning, system health
                      check and maintenance service every 2 to 3 years.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <br />
          <label className="title">Embracing the Future</label>
          <br />
          <label className="description">
            Solar panels can not only make your city more sustainable but also
            enhance your competitiveness on a regional and global scale, not to
            mention provide huge cost savings for their owners. TERA is your
            partner in this journey, committed to helping you harness the
            abundant power of the sun for a brighter, greener future.
          </label>
          <br />
          <label className="description">
            As Johor Bahru continues to flourish, staying ahead in terms of
            sustainability is crucial. Embrace the sun's power today, and ensure
            that you remain at the forefront of progress, leaving no room for
            regrets. Contact TERA now to take the first step toward a
            brighter and more sustainable tomorrow.
          </label>
          <br />
          <ul>
            <li className="description">
              <div className="description-bold">Chua Kim San</div>
              <div>
                “The sales incharge was efficient and polite, after sales
                service was attentive and effective”
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default JohorBahru;
