import "./Testimonial.scss";

function MIDA() {
  return (
    <div className="aboutuss-description">
      <div className="aboutus-description-container">
        <div className="aboutus-description">
          <h1 className="title" style={{ marginTop: 0, marginBottom: 0, fontWeight:"normal"}}>
            Unlocking Investment Opportunities: The Role of the Malaysian
            Investment Development Authority (MIDA)
          </h1>
          <br />
          <label className="description">
            In the pursuit of economic growth and prosperity, the Malaysian
            Investment Development Authority (MIDA) stands as a beacon of
            opportunity. As a pivotal agency in Malaysia's economic landscape,
            MIDA plays a vital role in attracting both local and foreign
            investments, fostering innovation, and propelling industrial
            advancement through investments for the manufacturing and services
            industry.
          </label>
          <br />
          <label className="title">
            MIDA: Our Gateway to Investment Excellence
          </label>
          <br />
          <label className="description">
            MIDA's mission is clear: to position Malaysia as the preferred
            manufacturing and services investment destination in the region.
            With a strategic vision to elevate the nation into a high-income
            economy, MIDA empowers businesses and investors to realize their
            ambitions while contributing to Malaysia's development goals.
          </label>
          <br />
          <br />
          <span className="aboutus">MIDA’s Guiding Principles</span>
          <br />
          <label className="title">Fostering Investment Excellence:</label>
          <br />
          <label className="description">
            Acting as a cornerstone of Malaysia's economic development, while
            taking on a diverse set of functions to catalyze investment growth
            and promote sustainable progress. MIDA’s multifaceted role
            encompasses:
          </label>
          <ul>
            <li>
              <label className="description">
                Investment Promotion: MIDA actively promotes investments, both
                domestic and foreign, within the manufacturing and services
                sectors. We act as a catalyst, attracting capital and expertise
                that contribute to Malaysia's industrial growth.
              </label>
            </li>
            <li>
              <label className="description">
                Strategic Industrial Planning: A key function of MIDA is to
                engage in meticulous planning for the nation's industrial
                development. We chart the course for economic progress,
                identifying opportunities, and aligning strategies for industry
                advancement.
              </label>
            </li>
            <li>
              <label className="description">
                Policy Recommendations: MIDA plays a crucial role in
                recommending industrial promotion and development policies to
                the Minister of International Trade and Industry. This ensures a
                cohesive and strategic approach to economic progress.
              </label>
            </li>
          </ul>
          <br />
          <label className="title">Nurturing a Favorable Environment:</label>
          <ul>
            <li>
              <label className="description">
                Evaluation of Applications: MIDA evaluates applications for
                various incentives, licenses, and exemptions. This includes
                assessing manufacturing licenses, expatriate posts, tax
                incentives, and duty exemptions on raw materials, components,
                and machinery. By streamlining these processes, we facilitate
                business operations.
              </label>
            </li>
            <li>
              <label className="description">
                Implementation Assistance: MIDA stands by companies throughout
                their investment journey. We offer hands-on assistance during
                project implementation and operation, ensuring seamless
                coordination with federal and state-level authorities.
              </label>
            </li>
            <li>
              <label className="description">
                Information Exchange: A fundamental function is to foster
                communication and coordination among institutions involved in or
                connected with industrial development. This enhances
                collaboration, resulting in a more robust business ecosystem.
              </label>
            </li>
          </ul>
          <br />
          <label className="title">
            Providing Industry Guidance to Investors:
          </label>
          <br />
          <label className="description">
            To provide investors with confidence to invest in our market, MIDA's
            headquarters in Kuala Lumpur house senior representatives from key
            agencies. These officials from various departments, such as the
            Ministry of Human Resources, Immigration Department, Royal Customs
            Malaysia, Department of Environment, Tenaga Nasional Berhad, and
            Telekom Malaysia Berhad, offer invaluable guidance on matters
            relating to tax, labor, immigration, customs, utilities,
            occupational safety and health, and environment.
          </label>
        </div>
      </div>
    </div>
  );
}

export default MIDA;
