import React, { useState, useContext, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import TopNavigation from '../../../../components/top-navigation/TopNavigation';
import FooterOurLocation from '../../../../components/footer-our-location/FooterOurLocation';
import CalculatorBannerCommercial from '../CalculatorBannerCommercial';
import CalculatorDetailCommercial from '../CalculatorDetailCommercial';
import { DocumentContext } from '../../../../context/DocumentContext';
import { API, graphqlOperation } from "aws-amplify";
import { validatePromoCode } from "../../../../graphql/queries";
import AlertBar from "../../../../components/alert/Alert"
import { CalculateROI, CalculateROIWithTaxSavings } from '../../calculator/Calculator';

export const ACTIONS = {
    CALCULATEBYBILL: 'CALCULATEBYBILL',
    CALCULATEBYBILLANDCAPACITY: 'CALCULATEBYBILLANDCAPACITY',
    INSURANCE: 'INSURANCE',
    WARRANTY: 'WARRANTY',
    MAINTENANCE: 'MAINTENANCE',
    CALCULATETOTALCOST: 'CALCULATETOTALCOST',
    CALCULATEINITIALTOTAL: 'CALCULATEINITIALTOTAL',
    RESET: 'RESET',
    SLIDER: 'SLIDER',
    DISCOUNT: 'DISCOUNT',
    VALIDATEDISCOUNT: 'VALIDATEDISCOUNT',
    RESETDISCOUNT: 'RESETDISCOUNT',
    RECALCULATEROI: 'RECALCULATEROI',
    REFERRALDISCOUNT: 'REFERRALDISCOUNT',
    SETFINALAMOUNT: 'SETFINALAMOUNT'
}

function reducer(state: any, action: any) {
    switch (action.type) {

        case ACTIONS.CALCULATEBYBILL:
            return { ...state, initialCalc: Recalculate(
                    action.payload.avgElectricityBill, 
                    0, 
                    0, 
                    action.payload.cTRatio, 
                    action.payload.maximumDemand, 
                    action.payload.tariffGroup,
                    state.initialCalc.discountValue,
                    state.initialCalc.discountType,
                ) 
            }

        case ACTIONS.CALCULATEBYBILLANDCAPACITY:
            return { ...state, initialCalc: Recalculate(
                    action.payload.avgElectricityBill, 
                    action.payload.proposedInstalledCapacity, 
                    action.payload.totalProposedInstalledCapacity, 
                    action.payload.cTRatio, 
                    action.payload.maximumDemand, 
                    action.payload.tariffGroup,
                    state.initialCalc.discountValue,
                    state.initialCalc.discountType,
                ) 
            }

        case ACTIONS.INSURANCE:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: calculateAddOnPackage(
                        state.initialAddOnTotal.totalAddOnCost,
                        action.payload.insuranceCoverageCost,
                        action.payload.operation),
                    totalCost: state.initialAddOnTotal.totalCost,
                    totalDiscount: state.initialAddOnTotal.totalDiscount

                }
            }

        case ACTIONS.WARRANTY:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: calculateAddOnPackage(
                        state.initialAddOnTotal.totalAddOnCost,
                        action.payload.extendWarrantyCost,
                        action.payload.operation),
                    totalCost: state.initialAddOnTotal.totalCost,
                    totalDiscount: state.initialAddOnTotal.totalDiscount
                }
            }

        case ACTIONS.MAINTENANCE:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: calculateAddOnPackage(
                        state.initialAddOnTotal.totalAddOnCost,
                        action.payload.operationAndMaintenanceCost,
                        action.payload.operation),
                    totalCost: state.initialAddOnTotal.totalCost,
                    totalDiscount: state.initialAddOnTotal.totalDiscount
                }
            }

        case ACTIONS.CALCULATETOTALCOST:
            let result_total = calculateTotalCost(state.initialAddOnTotal.totalAddOnCost, action.payload.batterySystemCost, state.initialCalc, false, action.payload.applyPromoCode)
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
                    totalCost: result_total.calculatedCost,
                    totalDiscount: result_total.calculatedDiscount,
                }
            }
            
        case ACTIONS.SETFINALAMOUNT:
             return {
                 ...state,
                 initialCalc: {
                     ...state.initialCalc,
                     finalAmount: action.payload.finalAmount
                 }
             }

        case ACTIONS.SLIDER:
            return {
                ...state,
                initialCalc: {
                    ...state.initialCalc,
                    proposedInstalledCapacity: action.payload.proposedInstalledCapacity
                }
            }

        case ACTIONS.RESET:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: 0,
                    totalCost: state.initialCalc.batterySystemCost,
                    totalDiscount: 0
                }
            }

        case ACTIONS.DISCOUNT:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
                    totalCost: calculateDiscount(state.initialCalc.discountValue, state.initialCalc.discountType, state.initialAddOnTotal.totalCost),
                    totalDiscount: getDiscount(state.initialCalc.discountValue, state.initialCalc.discountType, state.initialAddOnTotal.totalCost)
                }
            }

        case ACTIONS.VALIDATEDISCOUNT:
            return {
                ...state,
                initialCalc: {
                    ...state.initialCalc,
                    discountValue: action.payload.discountValue,
                    discountType: action.payload.discountType
                }
            }

        case ACTIONS.RESETDISCOUNT:
            let result_reset = calculateTotalCost(state.initialAddOnTotal.totalAddOnCost, state.initialCalc.batterySystemCost, state.initialCalc, true)
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
                    totalCost: result_reset.calculatedCost,
                    totalDiscount: 0
                }
            }

        case ACTIONS.REFERRALDISCOUNT:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
                    totalCost: action.payload.totalCost,
                    totalDiscount: action.payload.totalDiscount,
                }
            }

        case ACTIONS.RECALCULATEROI:
            return {
                ...state,
                initialCalc: {
                    ...state.initialCalc,
                    paybackPeriod: CalculateROI(state.initialCalc.batterySystemCost, state.initialCalc.monthSavings, state.initialAddOnTotal.totalDiscount),
                    roiWithTaxSavings: CalculateROIWithTaxSavings(state.initialCalc.batterySystemCost, state.initialCalc.monthSavings, state.initialAddOnTotal.totalDiscount)
                }
            }

        default:
            return {
                initialCalc: state.initialCalc,
                initialAddOnTotal: state.initialAddOnTotal
            }
    }
}

function Recalculate(avgElectricityBill: number, proposedInstalledCapacity: number, totalProposedInstalledCapacity: number, cTRatio: any, maximumDemand: any, tariffGroup: any, discountValue: string, discountType: string) {
    const { calculateSolarInvestmentWithReturn, calculateSolarInvestmentByBillAndCapacityWithReturn, pricing } = useContext(DocumentContext)
    let result
    if (proposedInstalledCapacity == 0) {
        result = calculateSolarInvestmentWithReturn(avgElectricityBill, "Commercial", pricing, null, cTRatio, maximumDemand, tariffGroup)
    } else {
        result = calculateSolarInvestmentByBillAndCapacityWithReturn(avgElectricityBill, proposedInstalledCapacity, totalProposedInstalledCapacity, "Commercial", pricing, null, cTRatio, maximumDemand, tariffGroup)
    }
    return { ...result, discountValue: discountValue, discountType: discountType }
}

function calculateAddOnPackage(totalAddOnCost: number, cost: number, operation: string) {
    if (operation === "add") {
        totalAddOnCost = totalAddOnCost + cost
    } else {
        totalAddOnCost = parseFloat((totalAddOnCost - cost).toFixed(2))
    }
    return totalAddOnCost

}

function calculateTotalCost(totalAddOnCost: number, initialTotal: number, initialCalc: any, reset: boolean, promoCodeApplied: boolean = false) {
    const { discountValue, discountType } = initialCalc
    
    //system cost + add on
    initialTotal = initialTotal + totalAddOnCost
    
    //total cost minus discount
    let calculatedCost = initialTotal
    let calculatedDiscount = 0
    if (!reset && promoCodeApplied && discountValue && discountValue > 0){
        calculatedDiscount = getDiscount(discountValue, discountType, initialTotal)
        calculatedCost = calculateDiscount(discountValue, discountType, initialTotal)
    }
     
    return {
        calculatedCost,
        calculatedDiscount
    }
}

function calculateDiscount(discountValue: number, discountType: string, initialTotal: number) {
    let discountAmount = null
    if (discountType == "Amount") {
        discountAmount = discountValue
    }
    else {
        discountAmount = initialTotal * (discountValue / 100)
    }
    initialTotal = initialTotal - discountAmount
    if (discountType == "Amount"){
        return initialTotal
    } else {
        return roundUpToNearestHundred(initialTotal)
    }
    
}

function getDiscount(discountValue: number, discountType: string, initialTotal: number) {
    if (discountType == "Amount") {
        return discountValue
    }
    else {
        return initialTotal * (discountValue / 100)
    }
}

function roundUpToNearestHundred(value: number) {
    return Math.ceil(value / 100) * 100
}

function SolarInvestmentCalculatorCommercial() {

    const { commercialCalcData, setCommercialCalcData, getTarrifData, setPricing, getLocalStorageData, landingPage, hasPartner } = useContext(DocumentContext)

    const [isLoading, setIsLoading] = useState(false)

    const [isSaving, setIsSaving] = useState(false)
    let [resetRadio, setResetRadio] = useState(false)
    let [slider, setSlider] = useState(false)
    const [validPromoCode, setValidPromoCode] = useState(false)
    const [applyPromoCode, setApplyPromoCode] = useState(false)
    const [validSalesAgentCode, setValidSalesAgentCode] = useState(false)
    const [applySalesAgentCode, setApplySalesAgentCode] = useState(false)
    const [notify, setNotify] = useState({
        show: false,
        severity: '',
        message: ''
    })
    const [isValidating, setIsValidating] = useState(false)
    const [isSalesAgentValidating, setIsSalesAgentValidating] = useState(false)
    const [finalAmount, setFinalAmount] = useState(0);
    const [salesAgentDiscountType, setSalesAgentDiscountType] = useState("");
    const [salesAgentDiscountValue, setSalesAgentDiscountValue] = useState(0);

    useEffect(() => {
        setIsLoading(true)
        getLocalStorageData().then((res: any) => {
            setCommercialCalcData(res)
        })
        setIsLoading(false)
    }, [])

    useEffect(() => {
        //query pricing data every time user change the value
        setIsLoading(true)
        getTarrifData(commercialCalcData.tariffGroup).then((res: any) => {
            setPricing(res)
            applyPartnerPromoCode()
            setIsLoading(false)
        })
    }, [commercialCalcData.tariffGroup])
    

    useEffect(() => {
         if(commercialCalcData.promoCode === ""){
             setApplyPromoCode(false)
             dispatch({ type: ACTIONS.RESETDISCOUNT })
             
         }
     }, [commercialCalcData.promoCode])


    useEffect(() => {
        if (commercialCalcData.validReferralPromo){
            dispatch({ type: ACTIONS.VALIDATEDISCOUNT, payload: { discountValue: commercialCalcData.discountPercentage, discountType: commercialCalcData.discountType } })
            dispatch({ type: ACTIONS.DISCOUNT })
            dispatch({ type: ACTIONS.RECALCULATEROI })
        } else if (commercialCalcData.promoCode === "" && !commercialCalcData.validReferralPromo) {
            setApplyPromoCode(false)
            dispatch({ type: ACTIONS.RESETDISCOUNT })
            dispatch({ type: ACTIONS.RECALCULATEROI })

        }
    }, [commercialCalcData.promoCode, commercialCalcData.validReferralPromo])

    useEffect(() => {
        if (commercialCalcData.salesAgentCode === "") {
            setApplySalesAgentCode(false)

        }
    }, [commercialCalcData.salesAgentCode])

    useEffect(() => {
        setFinalAmount(state.initialAddOnTotal.totalCost)
    }, [applySalesAgentCode])

    const initialCalc: any = {
        batterySystemCost: commercialCalcData.batterySystemCost,
        totalConsumption: commercialCalcData.totalConsumption,
        tariffGroup: commercialCalcData.tariffGroup,
        proposedInstalledCapacity: commercialCalcData.proposedInstalledCapacity,
        totalProposedInstalledCapacity: commercialCalcData.totalProposedInstalledCapacity,
        monthlyEnergyGeneration: commercialCalcData.monthlyEnergyGeneration,
        balanceEnergyImport: commercialCalcData.balanceEnergyImport,
        monthSavings: commercialCalcData.monthSavings,
        monthlyBillWithVASolar: commercialCalcData.monthlyBillWithVASolar,
        extendWarrantyPackage: commercialCalcData.extendWarrantyPackage,
        insuranceCoveragePackage: commercialCalcData.insuranceCoveragePackage,
        operationAndMaintenancePackage: commercialCalcData.operationAndMaintenancePackage,
        extendWarrantyCost: commercialCalcData.extendWarrantyCost,
        insuranceCoverageCost: commercialCalcData.insuranceCoverageCost,
        operationAndMaintenanceCost: commercialCalcData.operationAndMaintenanceCost,
        paybackPeriod: commercialCalcData.paybackPeriod,
        carbonAvoidance: commercialCalcData.carbonAvoidance,
        treesPlanted: commercialCalcData.treesPlanted,
        carDistance: commercialCalcData.carDistance,
        panel: commercialCalcData.panel,
        rooftopArea: commercialCalcData.rooftopArea,
        cTRatio: commercialCalcData.cTRatio,
        maximumDemand: commercialCalcData.maximumDemand,
        taxSavings: commercialCalcData.taxSavings,
        maxCapacity: commercialCalcData.maxCapacity,
        roiWithTaxSavings: commercialCalcData.roiWithTaxSavings,
        discountValue: 0,
        finalAmount: 0,
    }

    const initialAddOnTotal: any = {
        totalCost: commercialCalcData.batterySystemCost,
        totalAddOnCost: 0,
        totalDiscount: 0,
    }

    const [state, dispatch] = useReducer(reducer, { initialCalc, initialAddOnTotal })

    const handleCalculate = (event: any) => {
        event.preventDefault();
        setResetRadio(true)
        const { avgElectricityBill, cTRatio, maximumDemand, tariffGroup } = commercialCalcData
        dispatch({ type: ACTIONS.CALCULATEBYBILL, payload: { avgElectricityBill, cTRatio, maximumDemand, tariffGroup } })
        dispatch({ type: ACTIONS.RESET })

        //recalculate referral discount
        if (commercialCalcData.validReferralPromo) {
            dispatch({ type: ACTIONS.VALIDATEDISCOUNT, payload: { discountValue: commercialCalcData.discountPercentage, discountType: commercialCalcData.discountType } })
            dispatch({ type: ACTIONS.DISCOUNT })
            dispatch({ type: ACTIONS.RECALCULATEROI })
        }

        setSlider(false)
    }

    function handleback() {
        window.history.back();
        window.scrollTo(0, 0);
    }

    function handleConfirm() {
        setIsSaving(true)
        const {
            batterySystemCost,
            totalConsumption,
            proposedInstalledCapacity,
            totalProposedInstalledCapacity,
            monthlyEnergyGeneration,
            balanceEnergyImport,
            monthSavings,
            monthlyBillWithVASolar,
            extendWarrantyPackage,
            insuranceCoveragePackage,
            operationAndMaintenancePackage,
            extendWarrantyCost,
            insuranceCoverageCost,
            operationAndMaintenanceCost,
            paybackPeriod,
            panel,
            rooftopArea,
            carbonAvoidance,
            treesPlanted,
            carDistance,
            cTRatio,
            maximumDemand,
            taxSavings,
            roiWithTaxSavings,
            maxCapacity,
            finalAmount
        } = state.initialCalc
        let payload = {
            ...commercialCalcData,
            totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
            totalCost: state.initialAddOnTotal.totalCost,
            batterySystemCost,
            totalConsumption,
            proposedInstalledCapacity,
            totalProposedInstalledCapacity,
            monthlyEnergyGeneration,
            balanceEnergyImport,
            monthSavings,
            monthlyBillWithVASolar,
            extendWarrantyPackage,
            insuranceCoveragePackage,
            operationAndMaintenancePackage,
            extendWarrantyCost,
            insuranceCoverageCost,
            operationAndMaintenanceCost,
            paybackPeriod,
            panel,
            rooftopArea,
            carbonAvoidance,
            treesPlanted,
            carDistance,
            cTRatio,
            maximumDemand,
            taxSavings,
            roiWithTaxSavings,
            maxCapacity,
            finalAmount
        }
        
        setCommercialCalcData(payload)

    }

    async function handleApplyPromoCode() {
        setIsValidating(true)
        if (commercialCalcData.promoCode === "") {
            setValidPromoCode(false)
            setNotify({
                show: true,
                severity: 'error',
                message: 'Promo code field is empty'
            })
            setIsValidating(false)
            return
        }

        //Reset calculation for replace promo code case
        dispatch({ type: ACTIONS.RESETDISCOUNT })
        dispatch({ type: ACTIONS.RECALCULATEROI })

        try {
            setApplyPromoCode(true)
            let res: any = await API.graphql(graphqlOperation(validatePromoCode, { promoCode: commercialCalcData.promoCode, promoCodeType: "PromoCode" }));
            if (res && (res.data.validatePromoCode.statusCode === 200 || res.data.validatePromoCode.statusCode === "200")) {
                setValidPromoCode(true)
                let data = res.data.validatePromoCode
                dispatch({ type: ACTIONS.VALIDATEDISCOUNT, payload: { discountValue: data.discountValue, discountType: data.discountType } })
                dispatch({ type: ACTIONS.DISCOUNT })
                dispatch({ type: ACTIONS.RECALCULATEROI })
                setNotify({
                    show: true,
                    severity: 'success',
                    message: res.data.validatePromoCode.status
                })

            } else {
                setValidPromoCode(false)
                dispatch({ type: ACTIONS.RESETDISCOUNT })
                dispatch({ type: ACTIONS.RECALCULATEROI })
                setNotify({
                    show: true,
                    severity: 'error',
                    message: res.data.validatePromoCode.status
                })

            }
            setIsValidating(false)
        } catch (error) {
            console.log(error)
            setValidPromoCode(false)
            dispatch({ type: ACTIONS.RESETDISCOUNT })
            dispatch({ type: ACTIONS.RECALCULATEROI })
            setNotify({
                show: true,
                severity: 'error',
                message: 'Error occurred while applying promo code'
            })
            setIsValidating(false)

        }
    }

    async function handleApplySalesAgentCode() {
        setIsSalesAgentValidating(true)
        if (commercialCalcData.salesAgentCode === "") {
            setValidSalesAgentCode(false)
            setNotify({
                show: true,
                severity: 'error',
                message: 'Sales agent code field is empty'
            })
            setIsSalesAgentValidating(false)
            return
        }

        try {
            setApplySalesAgentCode(true)
            let res: any = await API.graphql(graphqlOperation(validatePromoCode, { promoCode: commercialCalcData.salesAgentCode, promoCodeType: "SalesAgentCode" }));
            if (res && (res.data.validatePromoCode.validSalesAgent === true)) {
                setValidSalesAgentCode(true)
                let data = res.data.validatePromoCode
                setSalesAgentDiscountType(data.discountType);
                setSalesAgentDiscountValue(data.discountValue)
                setNotify({
                    show: true,
                    severity: 'success',
                    message: res.data.validatePromoCode.status
                })
            } else {
                setApplySalesAgentCode(false)
                setValidSalesAgentCode(false)
                setNotify({
                    show: true,
                    severity: 'error',
                    message: res.data.validatePromoCode.status
                })
            }
            setIsSalesAgentValidating(false)
        } catch (error) {
            console.log(error)
            setApplySalesAgentCode(false)
            setValidSalesAgentCode(false)
            setNotify({
                show: true,
                severity: 'error',
                message: 'Error occurred while applying sales agent code'
            })
            setIsSalesAgentValidating(false)

        }
    }

    function applyPartnerPromoCode() {
        if (commercialCalcData.promoCode && commercialCalcData.promoCode !== "") {
            setApplyPromoCode(true)
            setValidPromoCode(true)
            const { discountPercentage } = landingPage
            dispatch({ type: ACTIONS.VALIDATEDISCOUNT, payload: { discountValue: discountPercentage, discountType: null } })
            dispatch({ type: ACTIONS.DISCOUNT })
            dispatch({ type: ACTIONS.RECALCULATEROI })
            setNotify({
                show: true,
                severity: 'success',
                message: 'Successfully applied promo code'
            })
        }
    }

    return (
        <>
            <div className="commercial" style={{ overflow: "hidden" }}>
                <TopNavigation />
                <div>
                    <CalculatorBannerCommercial
                        commercialCalcData={commercialCalcData}
                        setCommercialCalcData={setCommercialCalcData}
                        state={state}
                        dispatch={dispatch}
                        handleCalculate={handleCalculate}
                    />
                    <CalculatorDetailCommercial
                        commercialCalcData={commercialCalcData}
                        setCommercialCalcData={setCommercialCalcData}
                        state={state}
                        dispatch={dispatch}
                        resetRadio={resetRadio}
                        setResetRadio={setResetRadio}
                        handleback={handleback}
                        handleConfirm={handleConfirm}
                        isSaving={isSaving}
                        slider={slider}
                        setSlider={setSlider}
                        handleApplyPromoCode={handleApplyPromoCode}
                        handleApplySalesAgentCode={handleApplySalesAgentCode}
                        applyPromoCode={applyPromoCode}
                        validPromoCode={validPromoCode}
                        setApplyPromoCode={setApplyPromoCode}
                        setApplySalesAgentCode={setApplySalesAgentCode}
                        applySalesAgentCode={applySalesAgentCode}
                        salesAgentDiscountValue={salesAgentDiscountValue}
                        salesAgentDiscountType={salesAgentDiscountType}
                        validSalesAgentCode={validSalesAgentCode}
                        isValidating={isValidating}
                        isSalesAgentValidating={isSalesAgentValidating}
                        hasPartner={hasPartner}
                        finalAmount={finalAmount}
                        setFinalAmount={setFinalAmount}
                    />
                </div>
                <FooterOurLocation />
            </div>
            <AlertBar notify={notify} setNotify={setNotify} />
        </>
    )
}

export default SolarInvestmentCalculatorCommercial;