import TopNavigation from "../../../components/top-navigation/TopNavigation";
import FooterOurLocation from "../../../components/footer-our-location/FooterOurLocation";
import MIDA from "../MIDA";

function MalaysianInvestmentDevelopmentAuthority() {
  return (
    <div className="about" style={{ overflow: "hidden" }}>
      <TopNavigation />
      <div>
        <MIDA />
      </div>
      <FooterOurLocation />
    </div>
  );
}

export default MalaysianInvestmentDevelopmentAuthority;
