import './ResidentialPortfolios.scss';
import {Grid, Box} from '@material-ui/core'
import Aircond from "../../../images/aircond-blue-icon.png"
import ResidentKajang from "../../../images/residential-kajang.jpg"
import ResidentCheras from "../../../images/residential-cheras.png"
import ResidentRawang from "../../../images/residential-rawang.png"
import ResidentKuang from "../../../images/residential-Kuang.png"
import ResidentMenglembu from "../../../images/residential-menglembu.png"


function ResidentialPortfolios() {
    return (
        <div className="residential-portfolios-description">
                <div className="residential-portfolios-description">
                <span className="residential-portfolios">PORTFOLIOS</span><br />
                    <label className="title">Residential Projects</label><br />
                <Grid container spacing={4} style={{marginTop:"20px"}}>
                  <Grid item xs={12} sm={9} md={6} lg={4} >
                    <Box component="span" >
                    <img src={ResidentKuang} className="picture-size" alt="Residential"/>
                        <p>Kuang, Selangor</p>
                        <span className="picture-description">12.6kWp (Estimated savings: RM740/month)</span><br />
                        <span className="picture-description">Saved 63 Trees/year</span><br />
                        <span className="picture-description">1303kWh Generation/month*</span><br />
                        <span className="picture-description"><img src={Aircond} className="aircond-icon" alt="aircond"></img> ≈ 1748 Hours Non-stop*</span><br />
                    </Box>    
                  </Grid>
                  <Grid item xs={12} sm={9} md={6} lg={4} >
                  <Box component="span" >
                    <img src={ResidentCheras} className="picture-size" alt="Residential"/>
                        <p>Cheras, Selangor</p>
                        <span className="picture-description">10.8kWp (Estimated savings: RM640/month)</span><br />
                        <span className="picture-description">Saved 54 Trees/year</span><br />
                        <span className="picture-description">1116.9kWh Generation/month*</span><br />
                        <span className="picture-description"><img src={Aircond} className="aircond-icon" alt="aircond"></img> ≈ 1498 Hours Non-stop*</span><br />
                    </Box>       
                  </Grid>
                  <Grid item xs={12} sm={9} md={6} lg={4} >
                  <Box component="span" >
                    <img src={ResidentMenglembu} className="picture-size" alt="Residential"/>
                        <p>Menglembu, Perak</p>
                        <span className="picture-description">9.8kWp (Estimated savings: RM610/month)</span><br />
                        <span className="picture-description">Saved 49 Trees/year</span><br />
                        <span className="picture-description">1043kWh Generation/month*</span><br />
                        <span className="picture-description"><img src={Aircond} className="aircond-icon" alt="aircond"></img> ≈ 1398 Hours Non-stop*</span><br />
                    </Box>      
                  </Grid>
                  <Grid item xs={12} sm={9} md={6} lg={4} >
                    <Box component="span" >
                    <img src={ResidentRawang} className="picture-size" alt="Residential"/>
                        <p>Rawang, Selangor</p>
                        <span className="picture-description">21.6kWp (Estimated savings: RM1181/month)</span><br />
                        <span className="picture-description">Saved 109 Trees/year</span><br />
                        <span className="picture-description">2233kWh Generation/month*</span><br />
                        <span className="picture-description"><img src={Aircond} className="aircond-icon" alt="aircond"></img> ≈ 2996 Hours Non-stop*</span><br />
                    </Box>    
                  </Grid>
                  <Grid item xs={12} sm={9} md={6} lg={4} >
                  <Box component="span" >
                    <img src={ResidentKajang} className="picture-size" alt="Residential"/>
                        <p>Kajang, Selangor</p>
                        <span className="picture-description">5.4kWp (Estimated savings: RM 310/month)</span><br />
                        <span className="picture-description">Saved 27 Trees/year</span><br />
                        <span className="picture-description">558kWh Generation/month*</span><br />
                        <span className="picture-description"><img src={Aircond} className="aircond-icon" alt="aircond"></img> ≈ 748 Hours Non-stop*</span><br />
                    </Box>       
                  </Grid>
                  </Grid>
                  
            </div>
        </div>
    )
}

export default ResidentialPortfolios;