import './AboutUs.scss';

function DirectorSpeech() {
    return (
        <div className="aboutuss-description">
            <div className="aboutus-description-container" >
                <div className="aboutus-description">
                    <label className="title">Director Speech</label><br />
                    <label className="description">Since TERA VA’s inception in 2011, we have set a strict standard for ourselves to become Malaysia’s leading solar company by transforming our nation’s energy sector from using fossil fuels to renewable sources.</label><br />
                    <label className="description">Throughout our journey, we have witnessed the enactment of the Renewable Energy Act 2011, the Sustainable Energy Development Authority Act 2011 and our active involvement in the Feed-in Tariff Program (2011 – 2016), Net Energy Metering (NEM 1.0, NEM 2.0, NEM 3.0), SELCO (Self Consumption) and Large Scale Solar (LSS). Vigilantly observing these laws and best industrial practices hasenabled us to broaden our depth of knowledge and expertise.</label><br />
                    <label className="description">Our TERA solutions bring us closer to achieve our goals and meet the Environmental, Social and Governance (ESG) criteria. Together, our partnership with Boilermech Group will build Malaysia into a nation that consists of competitive commercial businesses and a greener country with lifestyle happiness for all to live in.</label><br />
                </div>
            </div>
        </div>
    )
}

export default DirectorSpeech;