import "../residential/ResidentialPortfolios.scss";
import { Grid, Box } from "@material-ui/core";
import Brake from "../../../images/brakes-blue-icon.png";
import Japanese from "../../../images/japanese-blue-icon.png";
import Paint from "../../../images/paint-blue-icon.png";
import Parit from "../../../images/parit-blue-icon.png";
import Sepang from "../../../images/sepang-blue-icon.png";
import Steel from "../../../images/stell-blue-icon.png";
import Supermarket from "../../../images/supermarket-blue-icon.png";
import Temple from "../../../images/temple-blue-icon.png";
import Terrengganu from "../../../images/terrengganu-blue-icon.png";
import Klang from "../../../images/klang-blue-icon.png";
import Woven from "../../../images/woven-blue-icon.png";
import Georgetown from "../../../images/georgetown.png";
import Kapar from "../../../images/kapar.png";
import KualaBerang from "../../../images/kuala-berang.png";
import KualaTerrengganu from "../../../images/kuala-terengganu.png";
import Ngeam from "../../../images/ngeam-shah-alam.jpg";
import Nilai from "../../../images/nilai.jpg";
import ParitMelaka from "../../../images/parit-melaka.png";
import PortKlang from "../../../images/port-klang.png";
import SepangSelangor from "../../../images/sepang-selangor.jpg";
import ShahAlam from "../../../images/shah-alam.png";
import SimpangAmpat from "../../../images/simpang-ampat.png";

function CommercialPortfolios() {
  return (
    <div className="residential-portfolios-description">
      <div className="residential-portfolios-description">
        <span className="residential-portfolios">PORTFOLIOS</span>
        <br />
        <label className="title">Commercial Projects</label>
        <br />
        <Grid container spacing={4} style={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img src={Nilai} className="picture-size" alt="commercial" />
              <p>Nilai, Negeri Sembilan</p>
              <span className="picture-description">2029.5 kWp Savings</span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM 907,975.00
              </span>
              <br />
              <span className="picture-description">
                216057 kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Woven} className="aircond-icon" alt=""></img> Woven &
                Non-woven Manufacturer
              </span>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img
                src={KualaTerrengganu}
                className="picture-size"
                alt="commercial"
              />
              <p>Kuala Terengganu, Terengganu</p>
              <span className="picture-description">506.955kWp </span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM 184337.68
              </span>
              <br />
              <span className="picture-description">
                52890.19 kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Brake} className="aircond-icon" alt=""></img> Brakes
                Manufacturer
              </span>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img src={ShahAlam} className="picture-size" alt="commercial" />
              <p>Shah Alam, Selangor</p>
              <span className="picture-description">288.3kWp 29815kWh</span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM 157,099.00
              </span>
              <br />
              <span className="picture-description">
                29815kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Japanese} className="aircond-icon" alt=""></img>{" "}
                Japanese Convenience Store Supplier
              </span>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img src={Kapar} className="picture-size" alt="commercial" />
              <p>Kapar, Selangor</p>
              <span className="picture-description">904.5kWp</span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM 397,077.00
              </span>
              <br />
              <span className="picture-description">
                93540kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Paint} className="aircond-icon" alt=""></img> Paint
                Manufacturer
              </span>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img src={PortKlang} className="picture-size" alt="commercial" />
              <p>Port Klang, Selangor</p>
              <span className="picture-description">1186.2kWp</span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM 554,828.00
              </span>
              <br />
              <span className="picture-description">
                122673kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Klang} className="aircond-icon" alt=""></img>{" "}
                Synthetic Fishing Nets, Ropes and Twines Manufacturer
              </span>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img
                src={SepangSelangor}
                className="picture-size"
                alt="commercial"
              />
              <p>Sepang, Selangor</p>
              <span className="picture-description">133kWp</span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM 98,568.00
              </span>
              <br />
              <span className="picture-description">
                13754kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Sepang} className="aircond-icon" alt=""></img>{" "}
                Property Sales Gallery​
              </span>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img
                src={SimpangAmpat}
                className="picture-size"
                alt="commercial"
              />
              <p>Simpang Ampat, Pulau Pinang</p>
              <span className="picture-description">224.55kWp</span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM 141,189.00
              </span>
              <br />
              <span className="picture-description">
                24588kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Supermarket} className="aircond-icon" alt=""></img>{" "}
                Mini Supermarket​
              </span>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img src={Georgetown} className="picture-size" alt="commercial" />
              <p>Georgetown, Pulau Pinang</p>
              <span className="picture-description">12kWp</span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM 8,026.00
              </span>
              <br />
              <span className="picture-description">
                1314kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Temple} className="aircond-icon" alt=""></img> Temple
              </span>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img
                src={ParitMelaka}
                className="picture-size"
                alt="commercial"
              />
              <p>Parit, Melaka</p>
              <span className="picture-description">25.74kWp</span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM 18,961.00
              </span>
              <br />
              <span className="picture-description">
                2740kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Parit} className="aircond-icon" alt=""></img> Bird’s
                Nest Supplier
              </span>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img
                src={KualaBerang}
                className="picture-size"
                alt="commercial"
              />
              <p>Kuala Berang, Terengganu</p>
              <span className="picture-description">455.4kWp</span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM 281,908.00
              </span>
              <br />
              <span className="picture-description">
                43481kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Terrengganu} className="aircond-icon" alt=""></img>{" "}
                Convenience Store
              </span>
              <br />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <Box component="span">
              <img src={Ngeam} className="picture-size" alt="commercial" />
              <p>Shah Alam, Selangor</p>
              <span className="picture-description">379.44kWp</span>
              <br />
              <span className="picture-description">
                Savings Per Annum: RM159,317.00
              </span>
              <br />
              <span className="picture-description">
                39240kWh Generation/month*
              </span>
              <br />
              <span className="picture-description">
                <img src={Steel} className="aircond-icon" alt="stell"></img>{" "}
                Steel Fabricator
              </span>
              <br />
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CommercialPortfolios;
