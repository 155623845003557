import React from 'react';
import './DetailResidentialPortfoliosBanner.scss';

class DetailResidentialPortfoliosBanner extends React.Component {

    render() {
        return (
                <div className="detail-residential-banner">
                <div className="detail-residential-cover">
                    <div className="detail-residential-banner-container">
                        <div className="left-node">
                            <label className="title">One of the leading organizations that specialized in the field of renewable solar energy</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DetailResidentialPortfoliosBanner;