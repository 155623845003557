import React from "react";
import './QMSQualityPolicy.scss';
import {Accordion, AccordionDetails, AccordionSummary, Typography, FormControl} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function ScopeQMS(props: any) {
    const { whiteBackground } = props

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    return (
        <div className="privacy-policy-descriptions" style={{backgroundColor: whiteBackground ? "#ffffff": "#f5f6f9"}}>
            <div className="privacy-policy-description-container" >
                <div className="privacy-policy-description">
                <span className="privacy-policy">SCOPE OF QMS</span><br />
                    <label className="title">TERA SCOPE OF QMS</label><br />
                    {/* <label className="description">This page explains our privacy policies which include the use and protection of information submitted by visitors. If you make a transaction or send an e-mail containing personal information, this information may be shared with other public agencies to serve you in a more efficient and effective manner. For example, in resolving a complaint that requires feedback from other agencies.</label><br /> */}
                </div>
                <div className="privacy-policy-expand-bar">
                <div className="privacy-policy-sub-expand-bar">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1bh-content"
                         id="panel1bh-header"
                    >
                    <Typography className="big-title">Standard for Certification</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                        <FormControl component="fieldset">
                            <Typography className="subtitle">
                            Tera Va Sdn Bhd’s Quality Management System is developed based on:
                            </Typography>
                            <Typography variant="h5" gutterBottom component="div" className="font-style">
                            ISO 9001:2015 Quality Management System
                            </Typography>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                </div>
                <div className="privacy-policy-sub-expand-bar">
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                         expandIcon={<ExpandMoreIcon />}
                         aria-controls="panel1bh-content"
                         id="panel1bh-header"
                    >
                        <Typography className="big-title">Scope of Certification</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                        <FormControl component="fieldset">
                            <Typography variant="h5" gutterBottom component="div" className="font-style">
                            DESIGN AND INSTALLATION OF SOLAR GREEN POWER ENERGY PRODUCT 
                            </Typography>
                            <Typography className="title">
                            Address:
                            </Typography>
                            <Typography className="subtitle">
                            Lot 3A-2, Street Wing Sunsuria Avenue<br/>Persiaran Mahagoni Kota Damansara PJU 5<br/>47810 Petaling Jaya, Selangor Darul Ehsan.<br/>Tel: 03- 6151-8236
                            </Typography>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                </div>
                <div className="privacy-policy-sub-expand-bar">
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                         expandIcon={<ExpandMoreIcon className="expand-icon"/>}
                         aria-controls="panel1bh-content"
                         id="panel1bh-header"
                    >
                        <Typography className="big-title">Non Applicability</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                        <FormControl component="fieldset">
                            <Typography className="subtitle">
                            Requirements  of  ISO9001:2015  that  is  (are)  Not  Applicable  are  indicated throughout this Manual.
                            </Typography>
                            <Typography className="subtitle" style={{fontStyle: 'italic'}}>
                            ** These Non Applicability shall in no way affect our ability or absolve our responsibility to ensure the conformity of our services and the enhancement of customer satisfaction.
                            </Typography>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ScopeQMS;