/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:6c8effae-9110-4578-9f1c-47cf35927d2a",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_wXfXpFQvE",
    "aws_user_pools_web_client_id": "67r36nh8oc28t81ifkjoc54rso",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://yf4ihp3xerdphlzjtppk6z63xi.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uk2bn6eehrd6dmdi4sdkxlmv7m",
    "aws_user_files_s3_bucket": "vasolar-webassets-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};

export default awsmobile;
