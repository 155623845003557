import React, { useState, useContext, useEffect } from "react";
import '../residential/CalculatorDetail.scss';
import { Paper, Slider, Grid, Box, Accordion, AccordionDetails, AccordionSummary, Typography, InputLabel, Radio, RadioGroup, FormControl, FormControlLabel, TextField, Button, Divider, CircularProgress, Hidden, Modal, InputBase, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AlertBar from '../../../components/alert/Alert';
import CO2 from "../../../images/co2-cloud.svg"
import tree from "../../../images/palm-tree.svg"
import roof from "../../../images/roof.svg"
import surface from "../../../images/surface1.svg"
import ArrowRight from '../../../images/arrow-right.png';
import './CalculatorDetailCommercial.scss';
import { API, graphqlOperation } from "aws-amplify";
import { addProposal } from "../../../graphql/mutations";
import { ACTIONS } from '../commercial/page/SolarInvestmentCalculatorCommercial'
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { inputPreventDefault } from "../../../components/utils/util";

function CalculatorDetailCommercial(props: any) {
  const {
    commercialCalcData,
    setCommercialCalcData,
    state,
    dispatch,
    resetRadio,
    setResetRadio,
    slider,
    setSlider,
    handleApplyPromoCode,
    handleApplySalesAgentCode,
    validPromoCode,
    validSalesAgentCode,
    applyPromoCode,
    applySalesAgentCode,
    salesAgentDiscountValue,
    salesAgentDiscountType,
    setApplyPromoCode,
    setApplySalesAgentCode,
    isValidating,
    isSalesAgentValidating,
    hasPartner,
    finalAmount,
    setFinalAmount
  } = props

  const useStyles = makeStyles((theme) => ({
    inputOutline: {
      borderRadius: theme.shape.borderRadius,
      border: '1px solid #ced4da',
      padding: '8px 12px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
    startAdornment: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const [isSaving, setIsSaving] = useState(false)
  const [isSaving2, setIsSaving2] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  let history = useHistory();

  const [notify, setNotify] = useState({
    show: false,
    severity: '',
    message: ''
  })


  useEffect(() => {
    clearRadio()
    setMax(state.initialCalc.totalProposedInstalledCapacity)
  }, [resetRadio])


  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };


  const [tempValue, setTempValue] = useState({
    insuranceCoverage: 'empty',
    operationAndMaintenance: 'empty',
  })

  const [tempExtend, setTempExtend] = useState("empty")

  const clearRadio = () => {
    if (resetRadio) {
      setTempValue({
        ...tempValue,
        insuranceCoverage: "empty",
        operationAndMaintenance: "empty"
      })
      setTempExtend("empty")
      setCommercialCalcData({
        ...commercialCalcData,
        insuranceCoverage: false,
        extendWarranty: false,
        operationAndMaintenance: false
      })
    }
    setResetRadio(false)
  }

  function roundUpToNearestHundred(value: number) {
    return Math.ceil(value / 100) * 100
  }

  const handleRadioChangeExtendedWarranty = (e: any) => {
    let reset = false
    if (tempExtend === e.target.value) {
      reset = true
      setTempExtend("empty")
    } else {
      setTempExtend(e.target.value)
    }

    if (!reset) {
      setCommercialCalcData({ ...commercialCalcData, [e.target.name]: true, extendWarrantyPackage: e.target.value })
    } else {
      setCommercialCalcData({ ...commercialCalcData, [e.target.name]: false, extendWarrantyPackage: "" })
    }
  }

  const handleRadioChange = (e: any) => {
    let reset = false
    for (let [key, value] of Object.entries(tempValue)) {
      if (e.target.name == key) {
        if (value != 'empty') {
          //reset radio
          setTempValue({ ...tempValue, [e.target.name]: "empty" })
          setCommercialCalcData({ ...commercialCalcData, [e.target.name]: false })
          reset = true
        } else {
          //select radio
          setTempValue({ ...tempValue, [e.target.name]: e.target.value })
          setCommercialCalcData({ ...commercialCalcData, [e.target.name]: true })
        }
      }
    }
    const { insuranceCoverageCost, operationAndMaintenanceCost, extendWarrantyCost, batterySystemCost } = state.initialCalc
    if (reset) {
      if (e.target.name === "insuranceCoverage") {
        dispatch({ type: ACTIONS.INSURANCE, payload: { [e.target.name]: true, insuranceCoverageCost, operation: "minus" } })
      }

      else if (e.target.name === "operationAndMaintenance") {
        dispatch({ type: ACTIONS.MAINTENANCE, payload: { [e.target.name]: true, operationAndMaintenanceCost, operation: "minus" } })
      }

    } else {

      if (e.target.name === "insuranceCoverage") {
        dispatch({ type: ACTIONS.INSURANCE, payload: { [e.target.name]: true, insuranceCoverageCost, operation: "add" } })
      }

      else if (e.target.name === "operationAndMaintenance") {
        dispatch({ type: ACTIONS.MAINTENANCE, payload: { [e.target.name]: true, operationAndMaintenanceCost, operation: "add" } })
      }
    }
    dispatch({ type: ACTIONS.CALCULATETOTALCOST, payload: { [e.target.name]: true, batterySystemCost, applyPromoCode: (applyPromoCode || commercialCalcData.validReferralPromo) } })
  }

  const onChange = (e: any) => {
    if (e.target.name === "promoCode") {
      if (e.target.value === ' ') {
        setCommercialCalcData({ ...commercialCalcData, [e.target.name]: '' })
      } else {
        setCommercialCalcData({ ...commercialCalcData, [e.target.name]: e.target.value.toUpperCase() })
      }
      setApplyPromoCode(false)
      dispatch({ type: ACTIONS.RESETDISCOUNT })
    } else if (e.target.name === "salesAgentCode") {
      if (e.target.value === ' ') {
        setCommercialCalcData({ ...commercialCalcData, [e.target.name]: '' })
      } else {
        setCommercialCalcData({ ...commercialCalcData, [e.target.name]: e.target.value.toUpperCase() })
      }
      setApplySalesAgentCode(false)
    } else {
      if (e.target.value === ' ') {
        setCommercialCalcData({ ...commercialCalcData, [e.target.name]: '' })
      } else {
        setCommercialCalcData({ ...commercialCalcData, [e.target.name]: e.target.value })
      }
    }
  }

  const [max, setMax] = useState(state.initialCalc.totalProposedInstalledCapacity)

  function getMaxValue() {
    const { cTRatio, maximumDemand } = commercialCalcData
    const { totalProposedInstalledCapacity, maxCapacity } = state.initialCalc
    if (cTRatio === "" && maximumDemand === "") {
      setMax(totalProposedInstalledCapacity)
    }
    else {
      setMax(maxCapacity)
    }
  }

  const handleTotalCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^\d*\.?\d{0,2}$/;
    const { value } = event.target;
    const inputValue = parseFloat(value)
    if (regex.test(value)) {
      if (!isNaN(inputValue) && isFinite(inputValue)) {
        const roundedValue = parseFloat(inputValue.toFixed(2));
        setFinalAmount(roundedValue);
        dispatch({ type: ACTIONS.SETFINALAMOUNT, payload: { finalAmount: roundedValue } });
      } else {
        setFinalAmount(0);
        dispatch({ type: ACTIONS.SETFINALAMOUNT, payload: { finalAmount: 0 } });
      }
    }
  };

  const handleCheckIfTotalCostValid = (event: any) => {

    const inputValue = parseFloat(event.target.value)
    let maxDiscountedValue = state.initialAddOnTotal.totalAddOnCost > 0 ? (
      (state.initialCalc.batterySystemCost + state.initialAddOnTotal.totalAddOnCost) - state.initialAddOnTotal.totalCost
    ) : (
      state.initialCalc.batterySystemCost - state.initialAddOnTotal.totalCost
    )

    let differenceInInputtedValue = state.initialCalc.batterySystemCost - inputValue

    if (applySalesAgentCode) {
      let salesAgentDiscount = 0
      if (salesAgentDiscountType == "Amount") {
        salesAgentDiscount = salesAgentDiscountValue
      } else {
        salesAgentDiscount = roundUpToNearestHundred(state.initialCalc.batterySystemCost * (salesAgentDiscountValue / 100))
      }

      if (!(differenceInInputtedValue <= (maxDiscountedValue + salesAgentDiscount))) {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Discount given cannot exceed the max discount value valid to be given from the applied promo or referral code.'
        })
        setFinalAmount(state.initialCalc.batterySystemCost);
      }
    } else {
      if (!(differenceInInputtedValue <= maxDiscountedValue)) {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Discount given cannot exceed the max discount value valid to be given from the applied promo or referral code.'
        })
        setFinalAmount(state.initialCalc.batterySystemCost);
      }
    }
  };

  const handleSliderChange = (event: any, newValue: any) => {
    setSlider(true)
    setResetRadio(true)
    const { proposedInstalledCapacity, totalProposedInstalledCapacity } = state.initialCalc
    const { avgElectricityBill } = commercialCalcData
    getMaxValue()
    dispatch({ type: ACTIONS.CALCULATEBYBILLANDCAPACITY, payload: { avgElectricityBill, proposedInstalledCapacity, totalProposedInstalledCapacity } })
    dispatch({ type: ACTIONS.SLIDER, payload: { proposedInstalledCapacity: newValue } })
    setCommercialCalcData({ ...commercialCalcData, proposedInstalledCapacity: newValue })
    dispatch({ type: ACTIONS.RESET })
    dispatch({ type: ACTIONS.DISCOUNT })

  }

  var date = new Date(); // get current date
  date.setDate(date.getDate() + 3); // add two days to it

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    null
  );
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };


  function disableWeekends(date: any) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  function handleback() {
    window.history.back();
  }

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function handleAppointment(event: any) {
    setOpen(true);
  }
  let body = (
    <div className="modal-appointment">
      <h2 id="simple-modal-title" className="modal-title">
        Set an Appointment with us!
      </h2>
      <p className="modal-subtitle">Our team will contact you to confirm on the appointment.</p>
      <div style={{ marginBottom: "10px", color: "red" }}>{errorMessage}</div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          minDate={date}
          shouldDisableDate={disableWeekends}
          className="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputVariant="outlined"
          id="date-picker-dialog"
          label="Select Date"
          format="dd/MM/yyyy"
          clearable
          value={selectedDate}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>

      <TextField
        label="Message"
        type="text"
        name="appointmentMessage"
        multiline
        value={commercialCalcData.appointmentMessage}
        onChange={onChange}
        className="appointment-message"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        variant="contained"
        size="large"
        className="button-main-theme calculate"
        onClick={handleConfirm}>
        <div className="wrapper">
          <label className="btn-title">Confirm Appointment</label>
          <label className="right-arrow">
            {isSaving2 ? (
              <CircularProgress style={{ color: "white" }} />
            ) : (
              <img src={ArrowRight} alt="right-arrow" />
            )}
          </label>
        </div>
      </Button>
      <p className="cancel" onClick={handleClose}>
        Cancel
      </p>
    </div>
  );

  async function handleConfirm(event: any) {
    if (selectedDate === null) {
      setErrorMessage('Please select a date')
      setIsSaving2(false)
    } else {
      try {
        setIsSaving2(true)

        // var params = null;
        let tempCTRatio = state.initialCalc.cTRatio
        let tempMaxDemand = state.initialCalc.maximumDemand

        if (tempCTRatio === "" || tempCTRatio === undefined) {
          tempCTRatio = state.initialCalc.cTRatio
        }
        if (tempMaxDemand === "" || tempMaxDemand === undefined) {
          tempMaxDemand = 0
        }

        var params = {
          // proposalId: uuidv4(),
          proposalId: commercialCalcData.proposalId,
          clientName: commercialCalcData.clientName,
          clientPhone: commercialCalcData.clientPhone,
          clientType: "Commercial",
          clientAddress: commercialCalcData.clientAddress,
          clientAddress2: commercialCalcData.clientAddress2,
          clientAddress3: commercialCalcData.clientAddress3,
          clientEmail: commercialCalcData.clientEmail,
          buildingType: commercialCalcData.buildingType,
          referralCode: commercialCalcData.referralCode,
          referralName: commercialCalcData.referralName,
          modeOfPurchase: commercialCalcData.modeOfPurchase,
          companyName: commercialCalcData.companyName,
          industryType: commercialCalcData.industryType,
          tariffGroup: commercialCalcData.tariffGroup,
          monthlyBillWithVASolar: state.initialCalc.monthlyBillWithVASolar,
          avgElectricityBill: commercialCalcData.avgElectricityBill,
          monthSavings: state.initialCalc.monthSavings,
          paybackPeriod: state.initialCalc.paybackPeriod,
          proposedInstalledCapacity: state.initialCalc.proposedInstalledCapacity,
          batterySystemCost: state.initialCalc.batterySystemCost,
          insuranceCoverage: commercialCalcData.insuranceCoverage,
          insuranceCoveragePackage: state.initialCalc.insuranceCoveragePackage,
          insuranceCoverageCost: commercialCalcData.insuranceCoverageCost,
          operationAndMaintenance: commercialCalcData.operationAndMaintenance,
          operationAndMaintenancePackage: state.initialCalc.operationAndMaintenancePackage,
          operationAndMaintenanceCost: state.initialCalc.operationAndMaintenanceCost,
          extendWarranty: commercialCalcData.extendWarranty,
          extendWarrantyPackage: commercialCalcData.extendWarrantyPackage,
          extendWarrantyCost: state.initialCalc.extendWarrantyCost,
          otherRequestPackage: commercialCalcData.otherRequestPackage,
          totalCost: state.initialAddOnTotal.totalCost,
          totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
          appointment: selectedDate,
          appointmentMessage: commercialCalcData.appointmentMessage,
          termsAndConditionStatus: commercialCalcData.termsAndConditionStatus,
          panel: state.initialCalc.panel,
          rooftopArea: state.initialCalc.rooftopArea,
          carbonAvoidance: state.initialCalc.carbonAvoidance,
          treesPlanted: state.initialCalc.treesPlanted,
          carDistance: state.initialCalc.carDistance,
          cTRatio: tempCTRatio,
          maximumDemand: tempMaxDemand,
          taxSavings: state.initialCalc.taxSavings,
          roiWithTaxSavings: state.initialCalc.roiWithTaxSavings,
          finalAmount: state.initialCalc.finalAmount,
          promoCode: commercialCalcData.promoCode,
          totalDiscount: state.initialAddOnTotal.totalDiscount,
          status: "Unpaid",
          salesAgentCode: commercialCalcData.salesAgentCode
        };
        console.log("PARAM ", params);
        let res: any = await API.graphql(graphqlOperation(addProposal, params));
        //  console.log("check ", res);
        if (res && res.data.addProposal.statusCode == 200) {
          setNotify({
            show: true,
            severity: 'success',
            message: 'Proposal Submitted'
          })
          setTimeout(() => {
            history.push({
              pathname: `/commercial-industrial`
            });
            setIsSaving(false)
            window.scrollTo(0, 0);
            window.location.reload()
          }, 2000);
        } else {
          setNotify({
            show: true,
            severity: 'error',
            message: 'Failed! Something went wrong, please try again.'
          })
          setIsSaving2(false)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  //Number with Comma
  function NumberFormat(num: number) {
    return num?.toFixed(2)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return (
    <div className="calculator-detail-descriptions">
      <div className="calculator-detail-container" >
        <div className="calculator-detail-description">
          <span className="calculator-detail">Solar Investment Calculator</span><br />
          <label className="title">Find out how much you can save with <span>TERA</span></label><br />
        </div>
        <Paper className="paper">
          <Grid container spacing={3} className="info-container">
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Your monthly savings with TERA</p>
                <h1>RM {NumberFormat(state.initialCalc.monthSavings)}</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Your Current Monthly TNB Bill</p>
                <h1>RM {commercialCalcData.avgElectricityBill}</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Your Monthly TNB Bill with TERA</p>
                <h1>RM {state.initialCalc.monthlyBillWithVASolar}</h1>
                <span style={{ textAlign: "left" }}>*Minimum TNB charges applies based on tariff group rates</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Maximum installed capacity</p>
                <h1>{state.initialCalc.proposedInstalledCapacity} kWp </h1>
                <div>
                  <Grid item xs>
                    <Slider
                      style={{ cursor: "pointer" }}
                      name="proposedInstalledCapacity"
                      min={1}
                      max={max}
                      // step={10}
                      value={state.initialCalc.proposedInstalledCapacity || 0}
                      onChange={handleSliderChange}
                      aria-labelledby="discrete-slider-custom"
                    />
                    <div style={{ textAlign: "left" }}>*According to CT ratio and maximum demand</div>
                  </Grid>
                </div>
                {/* <caption style={{textAlign: "left"}}>Please click the Calculate button to get the updated prices</caption> */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>ROI</p>
                <h1>{state.initialCalc.paybackPeriod} years</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>System Cost</p>
                <h1>RM {NumberFormat(state.initialCalc.batterySystemCost)}</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Total AddOns Cost</p>
                <h1>RM {NumberFormat(state.initialAddOnTotal.totalAddOnCost)}</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Total Discount</p>
                {
                  state.initialAddOnTotal.totalAddOnCost > 0 ? (
                    <h1>RM {NumberFormat((state.initialCalc.batterySystemCost + state.initialAddOnTotal.totalAddOnCost) - state.initialAddOnTotal.totalCost)}</h1>
                  ) : (
                    <h1>RM {NumberFormat(state.initialCalc.batterySystemCost - state.initialAddOnTotal.totalCost)}</h1>
                  )
                }
              </Box>
            </Grid>
            {
              applySalesAgentCode ?
                <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
                  <Box component="span" className="info-container__content__box">
                    <p>Total Cost (RM)</p>
                    <InputBase
                      type="number"
                      startAdornment={<InputAdornment position="start" className={classes.startAdornment}>RM</InputAdornment>}
                      className={classes.inputOutline}
                      name="finalAmount"
                      onChange={handleTotalCostChange}
                      onBlur={handleCheckIfTotalCostValid}
                      value={finalAmount || ''}
                      placeholder="Total Cost"
                      onKeyDown={(evt: any) => inputPreventDefault(evt, false)}
                    />
                  </Box>
                </Grid> :
                <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
                  <Box component="span" className="info-container__content__box">
                    <p>Total Cost</p>
                    <h1>RM {NumberFormat(state.initialAddOnTotal.totalCost)}</h1>
                  </Box>
                </Grid>
            }
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>Tax Saving up to 48%</p>
                <h1>RM {NumberFormat(state.initialCalc.taxSavings)}</h1>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box component="span" className="info-container__content__box">
                <p>ROI with Tax Savings (years)</p>
                <h1>{NumberFormat(state.initialCalc.roiWithTaxSavings)} years</h1>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Paper className="paper">
          <Grid container spacing={3} className="info-container">
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box>
                <img
                  src={roof}
                  className="iconDetails"
                  alt="roof"
                  style={{ float: "left", marginRight: "5px" }}
                />
              </Box>
              <Box component="span" className="info-container__content__box">
                <span>
                  Rooftop Area
                  <span style={{ fontWeight: "bold" }}> {NumberFormat(state.initialCalc.rooftopArea)}
                    <span style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                      <span>m</span>
                      <sup style={{ fontSize: "10px" }}>2</sup>
                    </span><br />
                  </span>*1pv
                  module approximately to 2.3
                  <span>m</span>
                  <sup style={{ fontSize: "10px" }}>2</sup>
                </span>
                {/* <Slider
                  style={{ cursor: "pointer" }}
                  name="proposedInstalledCapacity"
                  min={1}
                  max={max}
                  // step={10}
                  value={state.initialCalc.proposedInstalledCapacity || 0}
                  onChange={handleSliderChange}
                  aria-labelledby="discrete-slider-custom"
                /> */}
              </Box>
              <Hidden only={["xs", "sm", "md"]}>
                <Divider orientation="vertical" flexItem />
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box>
                <img
                  src={CO2}
                  className="iconDetails"
                  alt="CO2"
                  style={{ float: "left", marginRight: "5px" }} />
              </Box>
              <Box component="span" className="info-container__content__box">
                <span>
                  <span>Carbon Dioxide(</span>
                  <span style={{ alignItems: 'flex-end' }}>CO</span>
                  <span style={{ alignItems: 'flex-start', fontSize: "10px" }}>2</span>
                  <span>) Avoidance </span>
                  <span style={{ fontWeight: "bold" }}>
                    {NumberFormat(state.initialCalc.carbonAvoidance)} tonne
                    <span style={{ alignItems: 'flex-end' }}> CO</span>
                    <span style={{ alignItems: 'flex-start', fontSize: "10px" }}>2</span>
                  </span>
                </span>
              </Box>
              <Hidden only={["xs", "sm", "md"]}>
                <Divider orientation="vertical" flexItem />
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box>
                <img
                  src={surface}
                  className="iconDetails"
                  alt="Car"
                  style={{ float: "left", marginRight: "5px" }}
                />
              </Box>
              <Box component="span" className="info-container__content__box">
                <span>
                  Distance travel avoidance by car(petrol)
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {NumberFormat(state.initialCalc.carDistance)} million km
                  </span>
                </span>
              </Box>
              <Hidden only={["xs", "sm", "md"]}>
                <Divider orientation="vertical" flexItem />
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={9} md={6} lg={3} className="info-container__content">
              <Box>
                <img
                  src={tree}
                  className="iconDetails"
                  alt="palm tree"
                  style={{ float: "left", marginRight: "5px" }} />
              </Box>
              <Box component="span" className="info-container__content__box">
                <span>
                  No. of tree seedlings grown for 10 years to absorb the
                  <span style={{ alignItems: 'flex-end' }}> CO</span>
                  <span style={{ alignItems: 'flex-start', fontSize: "10px" }}>2</span><br />
                  <span style={{ fontWeight: "bold" }}>
                    {NumberFormat(state.initialCalc.treesPlanted)} trees
                  </span>
                </span>
              </Box>
            </Grid>
          </Grid>
        </Paper>


        <Grid container spacing={5} className="info-container insurance">
          <Grid item xs={12} sm={9} md={6} lg={4} className="info-container__content">
            <Box>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  className="insurance"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="expand-calculator-detail">Insurance All Risk Coverage</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="insuranceCoverage"
                      value={tempValue.insuranceCoverage}
                      onClick={handleRadioChange}>
                      <FormControlLabel
                        className="expand-calculator-detail"
                        value={state.initialCalc.insuranceCoveragePackage}
                        control={<Radio checked={tempValue.insuranceCoverage !== 'empty'} />}
                        label={state.initialCalc.insuranceCoveragePackage} />
                    </RadioGroup>
                    <Typography className="expand-rates">
                      *Estimated cost(RM) on average, subjected to industry
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4} className="info-container__content">
            <Box>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  className="comprehensive"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="expand-calculator-detail">Comprehensive Maintenance</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="operationAndMaintenance"
                      value={tempValue.operationAndMaintenance}
                      onClick={handleRadioChange}>
                      <FormControlLabel
                        className="expand-calculator-detail"
                        value={state.initialCalc.operationAndMaintenancePackage}
                        control={<Radio checked={tempValue.operationAndMaintenance !== 'empty'} />}
                        label={state.initialCalc.operationAndMaintenancePackage} />
                    </RadioGroup>
                    <Typography className="expand-rates">
                      *Not including skylift, annual cleaning, subject to availability to access rooftop. To be Revised.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4} className="info-container__content">
            <Box>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                  className="extended-warranty"
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="expand-calculator-detail">Extended Inverter Warranty (10 years)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="extendWarranty"
                      value={tempExtend}
                      onClick={handleRadioChangeExtendedWarranty}>
                      <FormControlLabel
                        className="expand-calculator-detail"
                        value="5+5"
                        control={<Radio checked={tempExtend == '5+5'} />}
                        label="5+5" />

                      <FormControlLabel
                        className="expand-calculator-detail"
                        value="5+10"
                        control={<Radio checked={tempExtend == '5+10'} />}
                        label="5+10" />

                      <FormControlLabel
                        className="expand-calculator-detail"
                        value="5+15"
                        control={<Radio checked={tempExtend == '5+15'} />}
                        label="5+15" />

                      <FormControlLabel
                        className="expand-calculator-detail"
                        value="5+20"
                        control={<Radio checked={tempExtend == '5+20'} />}
                        label="5+20" />
                    </RadioGroup>
                    <Typography className="expand-rates">
                      *To be quoted subjected to engineering design and number of inverters
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4} className="info-container__content">
            <Box>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                  className="other-request"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="expand-calculator-detail">Other Request</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Message"
                    name="otherRequestPackage"
                    multiline
                    value={commercialCalcData.otherRequestPackage}
                    onChange={onChange}
                    variant="outlined"
                    className="message"
                    inputProps={{ maxLength: 300 }} />
                </AccordionDetails>
                <Typography className="expand-rates">
                  *Maximum 300 chars
                </Typography>
              </Accordion>
            </Box>
          </Grid>
        </Grid>

        {!commercialCalcData.validReferralPromo ? (
          <>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={12} lg={12} className="promo-code" style={{ display: 'flex', alignItems: 'center' }}>
                <Box className="left promo-code-sections">
                  <InputLabel style={{ paddingBottom: "5px" }}>
                    Promo code (if any)
                  </InputLabel>
                  <TextField
                    placeholder="Promo code (if any)"
                    name="promoCode"
                    value={commercialCalcData.promoCode}
                    onChange={onChange}
                    variant="outlined"
                    className="promo-code-text"
                    disabled={hasPartner}
                    inputProps={{ maxLength: 30 }} />
                </Box>
                <Box className="right promo-code-sections" style={{ marginTop: "15px" }}>
                  <Button
                    variant="contained"
                    size="small"
                    className="button-main-theme calculate back"
                    disabled={isValidating || hasPartner || applyPromoCode || isSalesAgentValidating}
                    onClick={handleApplyPromoCode}>
                    <div className="wrapper">
                      {isValidating ? (
                        <CircularProgress style={{ color: "white" }} />
                      ) : (
                        <label className="btn-title">Apply</label>
                      )}
                    </div>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            {isValidating ? null : (applyPromoCode && !validPromoCode) ? (
              <Typography variant="caption" style={{ color: "red", marginLeft: "10px" }}>
                {commercialCalcData.promoCode === "" ? "Please enter a valid input" : "Invalid promo code"}
              </Typography>
            ) : null}
          </>
        ) : null}

        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={12} className="promo-code">
            <Box className="left promo-code-sections">
              <InputLabel style={{ paddingBottom: "5px" }}>
                Sales Agent Code (if any)
              </InputLabel>
              <TextField
                placeholder="Sales Agent Code (if any)"
                name="salesAgentCode"
                value={commercialCalcData.salesAgentCode}
                onChange={onChange}
                variant="outlined"
                className="promo-code-text"
                inputProps={{ maxLength: 30 }} />
            </Box>
            <Box className="right promo-code-sections" style={{ marginTop: "15px" }}>
              <Button
                variant="contained"
                size="small"
                className="button-main-theme calculate back"
                disabled={isSalesAgentValidating || applySalesAgentCode || isValidating}
                onClick={handleApplySalesAgentCode}>
                <div className="wrapper">
                  {isSalesAgentValidating ? (
                    <CircularProgress style={{ color: "white" }} />
                  ) : (
                    <label className="btn-title">Apply</label>
                  )}
                </div>
              </Button>
            </Box>
          </Grid>
        </Grid>
        {isSalesAgentValidating ? null : (applySalesAgentCode && !validSalesAgentCode) ? (
          <Typography variant="caption" style={{ color: "red", marginLeft: "10px" }}>
            {commercialCalcData.salesAgentCode === "" ? "Please enter a valid input" : "Invalid sales agent code"}
          </Typography>
        ) : null}

        <div>
          <Button
            variant="contained"
            size="large"
            className="button-main-theme calculate"
            disabled={(applyPromoCode && !validPromoCode) || (applySalesAgentCode && !validSalesAgentCode) || isSaving ? true : false}
            onClick={handleAppointment}>
            <div className="wrapper">
              <label className="btn-title">Confirm & Set an Appointment</label>
              <label className="right-arrow">
                {isSaving ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  <img src={ArrowRight} alt="right-arrow" />
                )}
              </label>
            </div>
          </Button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
        <Button
          variant="contained"
          size="large"
          onClick={handleback}
          className="button-main-theme calculate back"
        >
          <div className="wrapper">
            <label className="btn-title">Back</label>
          </div>
        </Button>
      </div>
      <AlertBar notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default CalculatorDetailCommercial;
