import React, { useState, useContext, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import TopNavigation from '../../../../components/top-navigation/TopNavigation';
import FooterOurLocation from '../../../../components/footer-our-location/FooterOurLocation';
import CalculatorBannerResidential from '../CalculatorBannerResidential';
import CalculatorDetail from '../CalculatorDetail';
import { DocumentContext } from '../../../../context/DocumentContext';
import { CalculateROI, CalculateROIWithTaxSavings } from '../../calculator/Calculator';
import { CircularProgress } from '@material-ui/core'
import { API, graphqlOperation } from "aws-amplify";
import { addProposal } from "../../../../graphql/mutations";
import { validatePromoCode } from "../../../../graphql/queries";
import AlertBar from "../../../../components/alert/Alert"

export const ACTIONS = {
    CALCULATEBYBILL: 'CALCULATEBYBILL',
    CALCULATEBYBILLANDCAPACITY: 'CALCULATEBYBILLANDCAPACITY',
    INSURANCE: 'INSURANCE',
    WARRANTY: 'WARRANTY',
    MAINTENANCE: 'MAINTENANCE',
    CALCULATETOTALCOST: 'CALCULATETOTALCOST',
    MODIFYTOTALCOST: 'MODIFYTOTALCOST',
    CALCULATEINITIALTOTAL: 'CALCULATEINITIALTOTAL',
    RESET: 'RESET',
    SLIDER: 'SLIDER',
    DISCOUNT: 'DISCOUNT',
    VALIDATEDISCOUNT: 'VALIDATEDISCOUNT',
    RESETDISCOUNT: 'RESETDISCOUNT',
    RECALCULATEROI: 'RECALCULATEROI',
    REFERRALDISCOUNT: 'REFERRALDISCOUNT',
    SETFINALAMOUNT: 'SETFINALAMOUNT'
}

function reducer(state: any, action: any) {
    switch (action.type) {

        case ACTIONS.CALCULATEBYBILL:
            return {
                ...state, initialCalc: Recalculate(
                    action.payload.avgElectricityBill,
                    0,
                    0,
                    state.initialCalc.discountValue,
                    action.payload.phaseSystem,
                    state.initialCalc.discountType,
                )
            }

        case ACTIONS.CALCULATEBYBILLANDCAPACITY:
            return {
                ...state,
                initialCalc: Recalculate(
                    action.payload.avgElectricityBill,
                    action.payload.proposedInstalledCapacity,
                    action.payload.totalProposedInstalledCapacity,
                    state.initialCalc.discountValue,
                    action.payload.phaseSystem,
                    state.initialCalc.discountType,
                ),
            }

        case ACTIONS.INSURANCE:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: calculateAddOnPackage(
                        state.initialAddOnTotal.totalAddOnCost,
                        action.payload.insuranceCoverageCost,
                        action.payload.operation),
                    totalCost: state.initialAddOnTotal.totalCost,
                    totalDiscount: state.initialAddOnTotal.totalDiscount

                }
            }

        case ACTIONS.WARRANTY:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: calculateAddOnPackage(
                        state.initialAddOnTotal.totalAddOnCost,
                        action.payload.extendWarrantyCost,
                        action.payload.operation),
                    totalCost: state.initialAddOnTotal.totalCost,
                    totalDiscount: state.initialAddOnTotal.totalDiscount
                }
            }

        case ACTIONS.MAINTENANCE:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: calculateAddOnPackage(
                        state.initialAddOnTotal.totalAddOnCost,
                        action.payload.operationAndMaintenanceCost,
                        action.payload.operation),
                    totalCost: state.initialAddOnTotal.totalCost,
                    totalDiscount: state.initialAddOnTotal.totalDiscount
                }
            }

        case ACTIONS.CALCULATETOTALCOST:
            let result_total = calculateTotalCost(state.initialAddOnTotal.totalAddOnCost, action.payload.batterySystemCost, state.initialCalc, false, action.payload.promoCodeApplied)
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
                    totalCost: result_total.calculatedCost,
                    totalDiscount: result_total.calculatedDiscount
                }
            }

        case ACTIONS.SETFINALAMOUNT:
            return {
                ...state,
                initialCalc: {
                    ...state.initialCalc,
                    finalAmount: action.payload.finalAmount
                }
            }

        case ACTIONS.SLIDER:
            return {
                ...state,
                initialCalc: {
                    ...state.initialCalc,
                    proposedInstalledCapacity: action.payload.proposedInstalledCapacity
                }
            }

        case ACTIONS.RESET:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: 0,
                    totalCost: state.initialCalc.batterySystemCost,
                    totalDiscount: 0
                }
            }

        case ACTIONS.DISCOUNT:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
                    totalCost: calculateDiscount(state.initialCalc.discountValue, state.initialCalc.discountType, state.initialAddOnTotal.totalCost),
                    totalDiscount: getDiscount(state.initialCalc.discountValue, state.initialCalc.discountType, state.initialAddOnTotal.totalCost)
                }
            }


        case ACTIONS.REFERRALDISCOUNT:
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
                    totalCost: action.payload.totalCost,
                    totalDiscount: action.payload.totalDiscount,
                }
            }

        case ACTIONS.VALIDATEDISCOUNT:
            return {
                ...state,
                initialCalc: {
                    ...state.initialCalc,
                    discountValue: action.payload.discountValue,
                    discountType: action.payload.discountType
                }
            }

        case ACTIONS.RESETDISCOUNT:
            let result_reset = calculateTotalCost(state.initialAddOnTotal.totalAddOnCost, state.initialCalc.batterySystemCost, state.initialCalc, true)
            return {
                ...state,
                initialAddOnTotal: {
                    totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
                    totalCost: result_reset.calculatedCost,
                    totalDiscount: 0
                }
            }
        case ACTIONS.RECALCULATEROI:
            return {
                ...state,
                initialCalc: {
                    ...state.initialCalc,
                    paybackPeriod: CalculateROI(state.initialCalc.batterySystemCost, state.initialCalc.monthSavings, state.initialAddOnTotal.totalDiscount),
                    roiWithTaxSavings: CalculateROIWithTaxSavings(state.initialCalc.batterySystemCost, state.initialCalc.monthSavings, state.initialAddOnTotal.totalDiscount)
                }

            }
        default:
            return {
                initialCalc: state.initialCalc,
                initialAddOnTotal: state.initialAddOnTotal
            }
    }
}

function Recalculate(avgElectricityBill: number, proposedInstalledCapacity: number, totalProposedInstalledCapacity: number, discountValue: number, phaseSystem: string, discountType: string) {
    const { calculateSolarInvestmentWithReturn, calculateSolarInvestmentByBillAndCapacityWithReturn, pricing } = useContext(DocumentContext)
    let result
    if (proposedInstalledCapacity == 0) {
        result = calculateSolarInvestmentWithReturn(avgElectricityBill, "Residential", pricing, phaseSystem)
    } else {
        result = calculateSolarInvestmentByBillAndCapacityWithReturn(avgElectricityBill, proposedInstalledCapacity, totalProposedInstalledCapacity, "Residential", pricing, phaseSystem)
    }
    result = { ...result, discountValue: discountValue, discountType: discountType }
    return result
}

function calculateAddOnPackage(totalAddOnCost: number, cost: number, operation: string) {
    if (operation === "add") {
        totalAddOnCost = totalAddOnCost + cost
    } else {
        totalAddOnCost = parseFloat((totalAddOnCost - cost).toFixed(2))
    }
    return totalAddOnCost
}

function calculateTotalCost(totalAddOnCost: number, initialTotal: number, initialCalc: any, reset: boolean, promoCodeApplied: boolean = false) {
    const { discountValue, discountType } = initialCalc
    //system cost + add on
    initialTotal = initialTotal + totalAddOnCost

    //total cost minus discount
    let calculatedCost = initialTotal
    let calculatedDiscount = 0
    if (!reset && promoCodeApplied && discountValue && discountValue > 0) {
        calculatedDiscount = getDiscount(discountValue, discountType, initialTotal)
        calculatedCost = calculateDiscount(discountValue, discountType, initialTotal)
    }

    return {
        calculatedCost,
        calculatedDiscount
    }
}

function calculateDiscount(discountValue: number, discountType: string, initialTotal: number) {
    let discountAmount = null
    if (discountType == "Amount") {
        discountAmount = discountValue
    }
    else {
        discountAmount = initialTotal * (discountValue / 100)
    }
    initialTotal = initialTotal - discountAmount
    if (discountType == "Amount") {
        return initialTotal
    } else {
        return roundUpToNearestHundred(initialTotal)
    }
}

function getDiscount(discountValue: number, discountType: string, initialTotal: number) {
    if (discountType == "Amount") {
        return discountValue
    }
    else {
        return initialTotal * (discountValue / 100)
    }
}

function roundUpToNearestHundred(value: number) {
    return Math.ceil(value / 100) * 100
}

function SolarInvestmentCalculator() {

    const {
        residentialCalcData,
        setResidentialCalcData,
        getTarrifData,
        setPricing,
        getLocalStorageData,
        landingPage,
        hasPartner
    } = useContext(DocumentContext)

    const [isLoading, setIsLoading] = useState(false)

    let history = useHistory();
    const [isSaving, setIsSaving] = useState(false)
    let [resetRadio, setResetRadio] = useState(false)
    let [slider, setSlider] = useState(false)
    const [validPromoCode, setValidPromoCode] = useState(false)
    const [applyPromoCode, setApplyPromoCode] = useState(false)
    const [promoCodeApplied, setPromoCodeApplied] = useState(false)
    const [validSalesAgentCode, setValidSalesAgentCode] = useState(false)
    const [applySalesAgentCode, setApplySalesAgentCode] = useState(false)
    const [salesAgentCodeApplied, setSalesAgentCodeApplied] = useState(false)
    const [notify, setNotify] = useState({
        show: false,
        severity: '',
        message: ''
    })
    const [isValidating, setIsValidating] = useState(false)
    const [isSalesAgentValidating, setIsSalesAgentValidating] = useState(false)
    const [finalAmount, setFinalAmount] = useState(0);
    const [salesAgentDiscountType, setSalesAgentDiscountType] = useState("");
    const [salesAgentDiscountValue, setSalesAgentDiscountValue] = useState(0);

    

    
    useEffect(() => {
        //query pricing data every time user change the value
        setIsLoading(true)
        getTarrifData(residentialCalcData.tariffGroup).then((res: any) => {
            setPricing(res)
            applyPartnerPromoCode()
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {
        setResidentialCalcData({ ...residentialCalcData, insuranceCoverage: false, operationAndMaintenance: false, extendWarranty: false })
    }, [salesAgentCodeApplied])

    useEffect(() => {
        if (residentialCalcData.validReferralPromo) {
            dispatch({ type: ACTIONS.VALIDATEDISCOUNT, payload: { discountValue: residentialCalcData.discountPercentage, discountType: residentialCalcData.discountType } })
            dispatch({ type: ACTIONS.DISCOUNT })
            dispatch({ type: ACTIONS.RECALCULATEROI })
        } else if (residentialCalcData.promoCode === "" && !residentialCalcData.validReferralPromo) {
            setApplyPromoCode(false)
            setPromoCodeApplied(false)
            dispatch({ type: ACTIONS.RESETDISCOUNT })
            dispatch({ type: ACTIONS.RECALCULATEROI })

        }
    }, [residentialCalcData.promoCode, residentialCalcData.referralCode])

    useEffect(() => {
        if (residentialCalcData.salesAgentCode === "") {
            setApplySalesAgentCode(false)
            setSalesAgentCodeApplied(false)
        }
    }, [residentialCalcData.salesAgentCode])

    const initialCalc: any = {
        batterySystemCost: residentialCalcData.batterySystemCost,
        totalConsumption: residentialCalcData.totalConsumption,
        tariffGroup: residentialCalcData.tariffGroup,
        phaseSystem: residentialCalcData.phaseSystem,
        proposedInstalledCapacity: residentialCalcData.proposedInstalledCapacity,
        totalProposedInstalledCapacity: residentialCalcData.totalProposedInstalledCapacity,
        monthlyEnergyGeneration: residentialCalcData.monthlyEnergyGeneration,
        balanceEnergyImport: residentialCalcData.balanceEnergyImport,
        monthSavings: residentialCalcData.monthSavings,
        monthlyBillWithVASolar: residentialCalcData.monthlyBillWithVASolar,
        extendWarrantyPackage: residentialCalcData.extendWarrantyPackage,
        insuranceCoveragePackage: residentialCalcData.insuranceCoveragePackage,
        operationAndMaintenancePackage: residentialCalcData.operationAndMaintenancePackage,
        extendWarrantyCost: residentialCalcData.extendWarrantyCost,
        insuranceCoverageCost: residentialCalcData.insuranceCoverageCost,
        operationAndMaintenanceCost: residentialCalcData.operationAndMaintenanceCost,
        paybackPeriod: residentialCalcData.paybackPeriod,
        carbonAvoidance: residentialCalcData.carbonAvoidance,
        treesPlanted: residentialCalcData.treesPlanted,
        carDistance: residentialCalcData.carDistance,
        panel: residentialCalcData.panel,
        rooftopArea: residentialCalcData.rooftopArea,
        SolarPVModule: residentialCalcData.SolarPVModule,
        ModelCode: residentialCalcData.ModelCode,
        taxSavings: residentialCalcData.taxSavings,
        roiWithTaxSavings: residentialCalcData.roiWithTaxSavings,
        discountValue: 0,
        finalAmount: 0,
    }

    const initialAddOnTotal: any = {
        totalCost: residentialCalcData.batterySystemCost,
        totalAddOnCost: 0,
        totalDiscount: 0,
    }

    const [state, dispatch] = useReducer(reducer, { initialCalc, initialAddOnTotal })
    const [marks, setMarks] = useState([
        {
            value: 17.55,
        },
        {
            value: 15.795,
        },
        {
            value: 14.625,
        },
        {
            value: 13.455,
        },
        {
            value: 11.7,
        },
        {
            value: 10.53,
        },
        {
            value: 8.19,
        },
        {
            value: 7.02,
        },
        {
            value: 5.85,
        },
        {
            value: 5.265,
        },
        {
            value: 4.095,
        },
        {
            value: 2.925,
        }
    ]);

    const handleCalculate = (event: any) => {
        event.preventDefault();
        setResetRadio(true)
        const { avgElectricityBill, phaseSystem } = residentialCalcData
        dispatch({ type: ACTIONS.CALCULATEBYBILL, payload: { avgElectricityBill, phaseSystem } })
        dispatch({ type: ACTIONS.RESET })

        //recalculate referral discount
        if (residentialCalcData.validReferralPromo) {
            dispatch({ type: ACTIONS.VALIDATEDISCOUNT, payload: { discountValue: residentialCalcData.discountPercentage, discountType: residentialCalcData.discountType } })
            dispatch({ type: ACTIONS.DISCOUNT })
            dispatch({ type: ACTIONS.RECALCULATEROI })
        }
        setSlider(false)
    }

    function handleback() {
        window.history.back();
        window.scrollTo(0, 0);
    }

    async function handleConfirm() {
        setIsSaving(true)
        try {
            const {
                batterySystemCost,
                totalConsumption,
                proposedInstalledCapacity,
                totalProposedInstalledCapacity,
                monthlyEnergyGeneration,
                balanceEnergyImport,
                monthSavings,
                monthlyBillWithVASolar,
                extendWarrantyPackage,
                insuranceCoveragePackage,
                operationAndMaintenancePackage,
                extendWarrantyCost,
                insuranceCoverageCost,
                operationAndMaintenanceCost,
                paybackPeriod,
                panel,
                rooftopArea,
                carbonAvoidance,
                treesPlanted,
                carDistance,
                SolarPVModule,
                ModelCode,
                taxSavings,
                roiWithTaxSavings,
            } = state.initialCalc

            let payload = {
                ...residentialCalcData,
                totalAddOnCost: state.initialAddOnTotal.totalAddOnCost,
                totalCost: state.initialAddOnTotal.totalCost,
                totalDiscount: state.initialAddOnTotal.totalDiscount,
                batterySystemCost,
                phaseSystem: residentialCalcData.phaseSystem,
                totalConsumption,
                proposedInstalledCapacity,
                totalProposedInstalledCapacity,
                monthlyEnergyGeneration,
                balanceEnergyImport,
                monthSavings,
                monthlyBillWithVASolar,
                extendWarrantyPackage,
                insuranceCoveragePackage,
                operationAndMaintenancePackage,
                extendWarranty: salesAgentCodeApplied ? residentialCalcData?.extendWarranty : false,
                insuranceCoverage: salesAgentCodeApplied ? residentialCalcData?.insuranceCoverage : false,
                operationAndMaintenance: salesAgentCodeApplied ? residentialCalcData?.operationAndMaintenance : false,
                extendWarrantyCost,
                insuranceCoverageCost,
                operationAndMaintenanceCost,
                paybackPeriod,
                panel,
                rooftopArea,
                carbonAvoidance,
                treesPlanted,
                carDistance,
                SolarPVModule,
                ModelCode,
                taxSavings,
                roiWithTaxSavings,
                finalAmount
            }

            let res: any = await API.graphql(graphqlOperation(addProposal, payload));
            if (res && (res.data.addProposal.statusCode === 200 || res.data.addProposal.statusCode === "200")) {
                setTimeout(() => {
                    setIsSaving(false)
                    history.push({
                        pathname: `/proposal-overview/${res.data.addProposal.proposalId}`
                    })
                    window.scrollTo(0, 0);
                }, 5000)
            } else {
                setIsSaving(false)
                setNotify({
                show: true,
                severity: 'error',
                message: 'Cannot submit proposal, pleaase try again later'
            })
            }
            setResidentialCalcData(payload)

        } catch (error) {
            console.log(error)
        }
    }

    async function handleApplyPromoCode() {
        setIsValidating(true)
        if (residentialCalcData.promoCode === "") {
            setValidPromoCode(false)
            setNotify({
                show: true,
                severity: 'error',
                message: 'Promo code field is empty'
            })
            setIsValidating(false)
            return
        }
        //Reset calculation for replace promo code case
        dispatch({ type: ACTIONS.RESETDISCOUNT })
        dispatch({ type: ACTIONS.RECALCULATEROI })

        try {
            setApplyPromoCode(true)
            let res: any = await API.graphql(graphqlOperation(validatePromoCode, { promoCode: residentialCalcData.promoCode, promoCodeType: "PromoCode" }));
            if (res && (res.data.validatePromoCode.statusCode === 200 || res.data.validatePromoCode.statusCode === "200")) {
                setValidPromoCode(true)
                let data = res.data.validatePromoCode
                dispatch({ type: ACTIONS.VALIDATEDISCOUNT, payload: { discountValue: data.discountValue, discountType: data.discountType } })
                dispatch({ type: ACTIONS.DISCOUNT })
                dispatch({ type: ACTIONS.RECALCULATEROI })
                setNotify({
                    show: true,
                    severity: 'success',
                    message: res.data.validatePromoCode.status
                })
                setPromoCodeApplied(true)

            } else {
                setValidPromoCode(false)
                dispatch({ type: ACTIONS.RESETDISCOUNT })
                dispatch({ type: ACTIONS.RECALCULATEROI })
                setNotify({
                    show: true,
                    severity: 'error',
                    message: res.data.validatePromoCode.status
                })
                setPromoCodeApplied(false)

            }
            setIsValidating(false)
        } catch (error) {
            console.log(error)
            setValidPromoCode(false)
            dispatch({ type: ACTIONS.RESETDISCOUNT })
            dispatch({ type: ACTIONS.RECALCULATEROI })
            setNotify({
                show: true,
                severity: 'error',
                message: 'Error occurred while applying promo code'
            })
            setIsValidating(false)
            setPromoCodeApplied(false)

        }
    }

    async function handleApplySalesAgentCode() {
        setIsSalesAgentValidating(true)
        if (residentialCalcData.salesAgentCode === "") {
            setValidSalesAgentCode(false)
            setNotify({
                show: true,
                severity: 'error',
                message: 'Sales agent code field is empty'
            })
            setIsSalesAgentValidating(false)
            return
        }
        try {
            setApplySalesAgentCode(true)
            let res: any = await API.graphql(graphqlOperation(validatePromoCode, { promoCode: residentialCalcData.salesAgentCode, promoCodeType: "SalesAgentCode" }));
            if (res && (res.data.validatePromoCode.validSalesAgent === true)) {
                setValidSalesAgentCode(true)
                let data = res.data.validatePromoCode;
                setSalesAgentDiscountType(data.discountType);
                setSalesAgentDiscountValue(data.discountValue);
                setNotify({
                    show: true,
                    severity: 'success',
                    message: res.data.validatePromoCode.status
                })
                setResidentialCalcData({ ...residentialCalcData, extendWarranty: false, insuranceCoverage: false, operationAndMaintenance: false});
                setSalesAgentCodeApplied(true)
            } else {
                setValidSalesAgentCode(false)
                setNotify({
                    show: true,
                    severity: 'error',
                    message: res.data.validatePromoCode.status
                })
                setResidentialCalcData({ ...residentialCalcData, extendWarranty: false, insuranceCoverage: false, operationAndMaintenance: false});
                setSalesAgentCodeApplied(false)
            }
            setIsSalesAgentValidating(false)
        } catch (error) {
            console.log(error)
            setValidSalesAgentCode(false)
            setNotify({
                show: true,
                severity: 'error',
                message: 'Error occurred while applying promo code'
            })
            setIsSalesAgentValidating(false)
            setSalesAgentCodeApplied(false)
        }
    }

    function applyPartnerPromoCode() {
        if (residentialCalcData.promoCode && residentialCalcData.promoCode !== "") {
            setApplyPromoCode(true)
            setValidPromoCode(true)
            const { discountPercentage } = landingPage
            if (discountPercentage > 0) {
                dispatch({ type: ACTIONS.VALIDATEDISCOUNT, payload: { discountValue: discountPercentage, discountType: null } })
                dispatch({ type: ACTIONS.DISCOUNT })
                dispatch({ type: ACTIONS.RECALCULATEROI })
            }
            setNotify({
                show: true,
                severity: 'success',
                message: 'Successfully applied promo code'
            })
        }
    }

    useEffect(() => {
        setIsLoading(true)
        getLocalStorageData().then((res: any) => {
            const { avgElectricityBill, phaseSystem, proposedInstalledCapacity, totalProposedInstalledCapacity } = res;
            if (proposedInstalledCapacity > 0) {
                dispatch({ type: ACTIONS.CALCULATEBYBILLANDCAPACITY, payload: { avgElectricityBill, proposedInstalledCapacity, totalProposedInstalledCapacity } })
                setResidentialCalcData({ ...res, proposedInstalledCapacity: proposedInstalledCapacity })
                dispatch({ type: ACTIONS.RESET })
            }
        })
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (Boolean(residentialCalcData.salesAgentCode) && residentialCalcData.salesAgentCode.trim() !== "") handleApplySalesAgentCode();
        if (Boolean(residentialCalcData.promoCode) && residentialCalcData.promoCode.trim() !== "" && !residentialCalcData.validReferralPromo) handleApplyPromoCode();
    },[residentialCalcData.clientName])

    useEffect(() => {
        //save changed value to local storage
        if (state.initialCalc.batterySystemCost > 0) {
            let payload = {
                ...residentialCalcData,
                ...state.initialAddOnTotal,
                ...state.initialCalc,
            }
            localStorage.setItem('document', JSON.stringify(payload))
        }
    }, [residentialCalcData, state.initialCalc, state.initialAddOnTotal])

    useEffect(() => {
        dispatch({ type: ACTIONS.RESET })
        if (salesAgentCodeApplied) {
            if (promoCodeApplied || residentialCalcData?.validReferralPromo) {
                const total = getTotalCost()
                setFinalAmount(total)
            }  else {
                setFinalAmount(residentialCalcData.totalCost)
            }
        } else {
            setFinalAmount(residentialCalcData.totalCost)
        }
    }, [salesAgentCodeApplied, promoCodeApplied, residentialCalcData?.validatePromoCode])

    const getTotalCost = () => {
        if (promoCodeApplied || residentialCalcData?.validReferralPromo) {
            return state.initialCalc.discountType === "Percentage" 
            ? ((100 - state.initialCalc.discountValue) / 100) *
                state.initialCalc.batterySystemCost +
                state.initialAddOnTotal.totalAddOnCost
            : state.initialCalc.batterySystemCost -
                state.initialCalc.discountValue +
                state.initialAddOnTotal.totalAddOnCost;
        } else {
          return state.initialAddOnTotal.totalCost;
        }
      };
   
    return (
        <>
            <div className="residential" style={{ overflow: "hidden" }}>
                <TopNavigation />
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <div>
                        <CalculatorBannerResidential
                            residentialCalcData={residentialCalcData}
                            setResidentialCalcData={setResidentialCalcData}
                            state={state}
                            dispatch={dispatch}
                            handleCalculate={handleCalculate}
                        />
                        <CalculatorDetail
                            residentialCalcData={residentialCalcData}
                            setResidentialCalcData={setResidentialCalcData}
                            state={state}
                            dispatch={dispatch}
                            resetRadio={resetRadio}
                            setResetRadio={setResetRadio}
                            handleback={handleback}
                            handleConfirm={handleConfirm}
                            isSaving={isSaving}
                            slider={slider}
                            setSlider={setSlider}
                            handleApplyPromoCode={handleApplyPromoCode}
                            handleApplySalesAgentCode={handleApplySalesAgentCode}
                            validPromoCode={validPromoCode}
                            setValidPromoCode={setValidPromoCode}
                            applyPromoCode={applyPromoCode}
                            validSalesAgentCode={validSalesAgentCode}
                            setValidSalesAgentCode={setValidSalesAgentCode}
                            applySalesAgentCode={applySalesAgentCode}
                            salesAgentDiscountValue={salesAgentDiscountValue}
                            salesAgentDiscountType={salesAgentDiscountType}
                            isValidating={isValidating}
                            isSalesAgentValidating={isSalesAgentValidating}
                            hasPartner={hasPartner}
                            promoCodeApplied={promoCodeApplied}
                            setPromoCodeApplied={setPromoCodeApplied}
                            salesAgentCodeApplied={salesAgentCodeApplied}
                            setSalesAgentCodeApplied={setSalesAgentCodeApplied}
                            finalAmount={finalAmount}
                            setFinalAmount={setFinalAmount}
                            marks={marks}
                            setMarks={setMarks}
                        />
                    </div>
                )}

                <FooterOurLocation />
            </div>
            <AlertBar notify={notify} setNotify={setNotify} />
        </>
    )
}

export default SolarInvestmentCalculator;