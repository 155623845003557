import { ChangeEvent, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Testimonial.scss";

function Melaka() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className="aboutuss-description">
      <div className="aboutus-description-container">
        <div className="aboutus-description">
          <span className="aboutus">TESTIMONIALS</span>
          <br />
          <h1 className="title" style={{ marginTop: 0, marginBottom: 0, fontWeight:"normal"}}>
            Experience a new Energy Future with Solar Panels In Melaka
          </h1>
          <br />
          <label className="description">
            In the heart of the culturally rich and enchanting city of Melaka,
            TERA welcomes you to a brighter energy horizon. As the leading
            name in solar panel solutions tailored for Melaka's unique
            landscape, we are committed to providing you with sustainable energy
            choices that resonate with the beauty and heritage of this
            remarkable city.
          </label>
          <br />
          <label className="description">
            Imagine a culturally rich city that is completely self-sufficient
            through the power of green energy. Solar panels are a huge step in
            that direction and one that has big benefits for everyone.
            Contribute to a greener and beautiful Melaka by scheduling your
            solar panel consultation with TERA, now! The best part is our
            site visit consultation is FREE, there is no obligation after our
            visit.
          </label>
          <br />
          <label className="title">
            Harvesting Solar Energy Amidst Melaka's Beauty
          </label>
          <br />
          <label className="description">
            Amidst Melaka's captivating blend of history and natural splendor,
            harnessing the abundant sunlight for clean energy has never been
            more fitting. TERA empowers you to embrace solar panels, not only
            to lower your energy costs but also to contribute to the
            preservation of Melaka's pristine environment. Here are the
            compelling advantages of choosing solar energy through TERA:
          </label>
          <br />
          <ul>
            <li>
              <span className="list-description-bold">
                Harmonious Savings:{" "}
              </span>
              <span className="list-description">
                Rely on the expertise of our drafters to find the perfect
                obscure location for your solar panel installation, optimizing
                power generation while maintaining the beautiful aesthetic of
                buildings in Melaka.
              </span>
            </li>
            <li>
              <span className="list-description-bold">
                Caring for Culture:{" "}
              </span>
              <span className="list-description">
                By adopting solar power, you're making a conscientious choice to
                protect Melaka's cultural heritage through sustainable energy
                practices.
              </span>
            </li>
            <li>
              <span className="list-description-bold">
                Empowerment through Innovation:{" "}
              </span>
              <span className="list-description">
                Solar energy provides you with control over your energy destiny,
                helping you to stand out amongst the crowd in this idyllic city.
              </span>
            </li>
          </ul>
          <br />
          <h2 className="title">
            Why TERA is Your Definitive Solar Solution in Melaka
          </h2>
          <br />
          <div className="privacy-policy-expand-bar">
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">Local Insight</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      Our deep-rooted familiarity with Melaka's energy dynamics
                      ensures a solar solution tailored to match your
                      geographical landscape.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Personalized Approach
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      TERA's solar solutions are meticulously customized to
                      align with your energy requirements helping you to achieve
                      optimal energy generation without forking out exorbitant
                      prices.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Quality at the Core
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      We source premium solar components, upholding TERA's
                      commitment to delivering reliable, enduring performance
                      that will last you a lifetime.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Seamless Journey
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      From permits to installation, our team arranges the entire
                      process, making your transition to solar effortless.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Customer-Centric Philosophy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      Your needs guide us. We provide unwavering customer
                      support, ensuring your solar journey mirrors Melaka's warm
                      hospitality.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <br />
          <label className="title">
            Join the Solar Renaissance in Melaka with TERA
          </label>
          <br />
          <label className="description">
            TERA invites Melaka's residents and businesses to embark on a
            solar renaissance, bridging the gap between Melaka's cultural
            richness and its modern aspirations. By choosing TERA's solar
            panels in Melaka, you become part of a movement that not only
            benefits you economically but also contributes to a more sustainable
            and culturally enriched Melaka.
          </label>
          <br />
          <label className="title">Hear from the Community</label>
          <br />
          <label className="description">
            Here’s what our previous clients had to say about their experience
            with us:
          </label>
          <br />
          <ul>
            <li className="description">
              <div className="description-bold">
                En. Shaiful , Desa Bertam, Melaka
              </div>
              <div>
                “Saya pilih TERA kerana harga yang agak mampu milik dan
                faedah 0%”
              </div>
            </li>
          </ul>
          <br />
          <label className="title">
            Shine Brightly Throughout Melaka with TERA
          </label>
          <br />
          <label className="description">
            Experience the harmony of heritage and innovation by embracing solar
            energy with TERA. Contribute to a sustainable Melaka as you
            explore the possibilities of a cleaner and brighter energy future.
          </label>
        </div>
      </div>
    </div>
  );
}

export default Melaka;
