import React, { useContext, useState, useEffect } from 'react';
import { Button, TextField, CircularProgress, Checkbox, Link, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRight from '../../../images/arrow-right.png';
import { useHistory } from "react-router-dom";
import { DocumentContext } from '../../../context/DocumentContext';
import { API, graphqlOperation } from "aws-amplify";
import { addProposal } from "../../../graphql/mutations";
import './InsertDataCalculator.scss';
import './ResidentialCalculator.scss';
import styled from "styled-components";
import MalaysianStatesJSON from "../../../components/json/MalaysianStates.json"
import { inputPreventDefault } from '../../../components/utils/util';

const helperTextStyles = makeStyles(theme => ({
    root: {
        color: 'red'
    },
}));

interface Props {
    backgroundImage: string;
}

const Wrap = styled.div<Pick<Props, 'backgroundImage'>>`
    background-image: url(${props => props.backgroundImage}) !important;
    background-color: #0a2540e6;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`

function InsertDataCalculator() {
    const { residentialCalcData, setResidentialCalcData, getLocalStorageData, landingPage, hasPartner } = useContext(DocumentContext)
    const [isSaving, setIsSaving] = useState(false)
    const [message, setMessage] = useState("")
    const helpertextstyle = helperTextStyles();
    let history = useHistory();
    var validEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validPhonePattern = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]-*[0-9]{7,8}$/;

    const states = [
        { label: "Johor", value: "Johor" },
        { label: "Kuala Lumpur", value: "Kuala Lumpur" },
        { label: "Negeri Sembilan", value: "Negeri Sembilan" },
        { label: "Perlis", value: "Perlis" },
        { label: "Sabah", value: "Sabah" },
        { label: "Terengganu", value: "Terengganu" },
        { label: "Kedah", value: "Kedah" },
        { label: "Labuan", value: "Labuan" },
        { label: "Pahang", value: "Pahang" },
        { label: "Penang", value: "Penang" },
        { label: "Sarawak", value: "Sarawak" },
        { label: "Kelantan", value: "Kelantan" },
        { label: "Malacca", value: "Malacca" },
        { label: "Perak", value: "Perak" },
        { label: "Putrajaya", value: "Putrajaya" },
        { label: "Selangor", value: "Selangor" },
        { label: "Other", value: "Other" },
    ];

    const malaysianStates = { ...MalaysianStatesJSON };

    type State = keyof typeof malaysianStates;

    const checkStateHasCities = (state: string) => {
        switch (state) {
            case "Other":
                return false;

            case "":
                return false;

            default:
                return true;
        }
    };

    const formFields = ['clientName', 'clientPhone', 'clientEmail', 'clientAddress', 'state', 'town', 'postCode']

    const [notify, setNotify] = useState({
        show: false,
        item: '',
        message: ''
    })

    useEffect(() => {
        getLocalStorageData().then((res: any) => {
            setResidentialCalcData(res)
        })
    }, [])

    useEffect(() => {
        localStorage.setItem('document', JSON.stringify(residentialCalcData))
    }, [residentialCalcData])


    const handleTermsAndCondition = (event: any) => {
        setResidentialCalcData({ ...residentialCalcData, termsAndConditionStatus: !residentialCalcData.termsAndConditionStatus })
    }

    const onChange = (e: any) => {
        if (e.target.name === "referralCode") {
            if (e.target.value === ' ') {
                setResidentialCalcData({ ...residentialCalcData, [e.target.name]: '' })
            } else {
                setResidentialCalcData({ ...residentialCalcData, [e.target.name]: e.target.value.toUpperCase() })
            }
        } else if (e.target.name === "postCode") {
            if (e.target.value === ' ') {
                setResidentialCalcData({ ...residentialCalcData, [e.target.name]: '' })
            } else {
                setResidentialCalcData({ ...residentialCalcData, [e.target.name]: e.target.value.replace(/\D/g, '') })
            }
        } else {
            if (e.target.value === ' ') {
                setResidentialCalcData({ ...residentialCalcData, [e.target.name]: '' })
            } else {
                setResidentialCalcData({ ...residentialCalcData, [e.target.name]: e.target.value })
            }
        }

        formFields.map((field) => {
            if (e.target.name === field && e.target.value !== "") {
                setNotify({ ...notify, show: false, item: field })
            }
        })

    }

    // Reusable Function to Enforce MaxLength
    function enforce_maxlength(event: any) {
        var t = event.target;
        if (t.hasAttribute('maxlength')) {
            t.value = t.value.slice(0, t.getAttribute('maxlength'));
        }
    }

    // Global Listener for anything with an maxlength attribute.
    // I put the listener on the body, put it on whatever.
    document.body.addEventListener('TextField', enforce_maxlength);

    const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (checkStateHasCities(event.target.value)) {
            setResidentialCalcData({
                ...residentialCalcData,
                town: malaysianStates[event.target.value as State][0],
                state: event.target.value,
                postCode: residentialCalcData.postCode?.slice(0, 5)
            });
        } else {
            setResidentialCalcData({
                ...residentialCalcData,
                town: event.target.value,
                state: event.target.value,
            });
        }
    };

    const handleTownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResidentialCalcData({
            ...residentialCalcData,
            town: event.target.value,
        });
    };

    const postcodeRegex = /^[0-9]{5,6}$/; //Support both Singapore and Malaysia postcodes
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (residentialCalcData.clientName === "") {
            setNotify({
                show: true,
                item: "clientName",
                message: 'Name Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (residentialCalcData.clientPhone === "") {
            setNotify({
                show: true,
                item: "clientPhone",
                message: 'Phone Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (!residentialCalcData.clientPhone.match(validPhonePattern)) {
            setNotify({
                show: true,
                item: "clientPhone",
                message: 'Invalid Phone Number'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (residentialCalcData.clientPhone.length !== 10 && residentialCalcData.clientPhone.length !== 11) {
            setNotify({
                show: true,
                item: "clientPhone",
                message: 'Invalid Phone Number'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (residentialCalcData.clientEmail === "") {
            setNotify({
                show: true,
                item: "clientEmail",
                message: 'Email Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (!residentialCalcData.clientEmail.match(validEmailPattern)) {
            setNotify({
                show: true,
                item: "clientEmail",
                message: 'Invalid email address'
            })
            setIsSaving(false)
            window.scrollTo(0, 200);
        }
        else if (residentialCalcData.clientAddress === "") {
            setNotify({
                show: true,
                item: "clientAddress",
                message: 'Address Field Cannot Be Blank'
            })
            setIsSaving(false)
            window.scrollTo(0, 300);
        }
        else if (residentialCalcData.state === "") {
            setNotify({
                show: true,
                item: "state",
                message: 'State Field Cannot Be Blank'
            })
            setIsSaving(false)
        }
        else if (residentialCalcData.town === "") {
            setNotify({
                show: true,
                item: "town",
                message: 'Town Field Cannot Be Blank'
            })
            setIsSaving(false)
        }
        else if (residentialCalcData.postCode === "") {
            setNotify({
                show: true,
                item: "postCode",
                message: 'Postcode Field Cannot Be Blank'
            })
            setIsSaving(false)
        }
        else if (!postcodeRegex.test(residentialCalcData.postCode)) {
            setNotify({
                show: true,
                item: "postCode",
                message: 'Invalid Postcode'
            })
            setIsSaving(false)
        }
        else if (residentialCalcData.termsAndConditionStatus === false) {
            setMessage('Please check this box if u want to proceed')
            setIsSaving(false)
        }
        else {
            try {
                setIsSaving(true)
                var params = {
                    // proposalId: uuidv4(),
                    clientName: residentialCalcData.clientName,
                    clientPhone: residentialCalcData.clientPhone,
                    clientType: "Residential",
                    tariffGroup: "A",
                    buildingType: residentialCalcData.buildingType,
                    clientAddress: residentialCalcData.clientAddress,
                    clientAddress2: residentialCalcData.clientAddress2,
                    clientAddress3: residentialCalcData.clientAddress3,
                    postCode: residentialCalcData.postCode,
                    town: residentialCalcData.town,
                    state: residentialCalcData.state,
                    clientEmail: residentialCalcData.clientEmail,
                    referralCode: residentialCalcData.referralCode,
                    referralName: residentialCalcData.referralName,
                    modeOfPurchase: residentialCalcData.modeOfPurchase,
                    phaseSystem: residentialCalcData.phaseSystem,
                    avgElectricityBill: residentialCalcData.avgElectricityBill,
                    termsAndConditionStatus: residentialCalcData.termsAndConditionStatus,
                    batterySystemCost: residentialCalcData.batterySystemCost,
                    proposedInstalledCapacity: residentialCalcData.proposedInstalledCapacity,
                    paybackPeriod: residentialCalcData.paybackPeriod,
                    totalCost: residentialCalcData.batterySystemCost,
                    totalAddOnCost: 0,
                    monthlyEnergyGeneration: residentialCalcData.monthlyEnergyGeneration,
                    balanceEnergyImport: residentialCalcData.balanceEnergyImport,
                    monthSavings: residentialCalcData.monthSavings,
                    monthlyBillWithVASolar: residentialCalcData.monthlyBillWithVASolar,
                    extendWarrantyPackage: '',
                    insuranceCoveragePackage: '',
                    operationAndMaintenancePackage: '',
                    extendWarrantyCost: 0,
                    insuranceCoverageCost: 0,
                    operationAndMaintenanceCost: 0,
                    otherRequestPackage: '',
                    appointment: '',
                    appointmentMessage: '',
                    insuranceCoverage: false,
                    operationAndMaintenance: false,
                    extendWarranty: false,
                    panel: residentialCalcData.panel,
                    rooftopArea: residentialCalcData.rooftopArea,
                    carbonAvoidance: residentialCalcData.carbonAvoidance,
                    treesPlanted: residentialCalcData.treesPlanted,
                    carDistance: residentialCalcData.carDistance,
                    taxSavings: residentialCalcData.taxSavings,
                    roiWithTaxSavings: residentialCalcData.roiWithTaxSavings,
                    cTRatio: 0,
                    maximumDemand: 0,
                    finalAmount: 0,
                    status: "Unpaid",
                };
                // console.log("PARAM ", params);
                let res: any = await API.graphql(graphqlOperation(addProposal, params));
                // console.log("check ", res);
                if (res.data.addProposal) {
                    if (res.data.addProposal.proposalId !== null) {
                        // console.log("not null")
                        let proposalData = res.data.addProposal
                        setResidentialCalcData({
                            ...residentialCalcData,
                            proposalId: proposalData.proposalId,
                            validReferralPromo: proposalData.validReferralPromo,
                            discountPercentage: proposalData.discountDetail?.discountPercentage ? proposalData.discountDetail.discountPercentage : residentialCalcData.discountPercentage,
                            totalCost: proposalData.discountDetail?.totalCost ? proposalData.discountDetail.totalCost : residentialCalcData.totalCost,
                            totalDiscount: proposalData.discountDetail?.totalDiscount ? proposalData.discountDetail.totalDiscount : residentialCalcData.totalDiscount,
                            discountType: proposalData.discountDetail?.discountType ? proposalData.discountDetail.discountType : residentialCalcData.discountType

                        })
                        getLocalStorageData().then((res: any) => {
                            setResidentialCalcData(res)
                            let newLocalData = {
                                ...res,
                                proposalId: proposalData.proposalId,
                                validReferralPromo: proposalData.validReferralPromo,
                            }
                            if (!!proposalData.discountDetail) {
                                newLocalData['discountPercentage'] = proposalData.discountDetail.discountPercentage
                                newLocalData['totalCost'] = proposalData.discountDetail.totalCost
                                newLocalData['totalDiscount'] = proposalData.discountDetail.totalDiscount
                                newLocalData['discountType'] = proposalData.discountDetail.discountType
                            }
                            localStorage.setItem('document', JSON.stringify(newLocalData))
                        })
                    }
                    if (res.data.addProposal.statusCode == 200) {
                        // console.log("No error")
                        setTimeout(() => {
                            setIsSaving(false)
                            history.push({
                                pathname: `/solar-investment-calculator`
                            })
                        }, 1500)
                    } else {
                        setIsSaving(false)
                    }
                } else {
                    setIsSaving(false)
                }
            } catch (error) {
                console.log(error)
            }

        }
    }

    return (
        <Wrap className="residential-calculator" backgroundImage={landingPage.backgroundImage}>
            <div className="cover">
                <div className="residential-calculator-container">
                    <div className="left-node">
                        <h1 className="title">Better green energy to power your home and business</h1>
                    </div>
                    <div className="right-node">
                        <div className="calculator-form">
                            <div className="title-wrapper">
                                {hasPartner ? (
                                    <label className="title">{landingPage.displaySecondPageText}</label>
                                ) : (
                                    <label className="title">Fill up this form to enjoy a lower deposit rate</label>
                                )}
                            </div>
                            <div>
                                <div>
                                    <TextField
                                        label="Name"
                                        name="clientName"
                                        inputProps={{ maxLength: 25 }}
                                        variant="outlined"
                                        onChange={onChange}
                                        value={residentialCalcData.clientName || ''}
                                        helperText={notify.item === "clientName" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    />
                                    <TextField
                                        label="Phone number"
                                        name="clientPhone"
                                        type="number"
                                        variant="outlined"
                                        inputProps={{ maxLength: 13 }}
                                        onChange={onChange}
                                        value={residentialCalcData.clientPhone || ''}
                                        helperText={notify.item === "clientPhone" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    />
                                    <TextField
                                        label="Email Address"
                                        name="clientEmail"
                                        type="email"
                                        inputProps={{
                                            pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                                        }}
                                        variant="outlined"
                                        onChange={onChange}
                                        value={residentialCalcData.clientEmail || ''}
                                        helperText={notify.item === "clientEmail" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    />
                                    <TextField
                                        label="Address Line 1"
                                        name="clientAddress"
                                        multiline
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        onChange={onChange}
                                        value={residentialCalcData.clientAddress || ''}
                                        helperText={notify.item === "clientAddress" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    />
                                    <TextField
                                        label="Address Line 2"
                                        name="clientAddress2"
                                        multiline
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        onChange={onChange}
                                        value={residentialCalcData.clientAddress2 || ''}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    />
                                    <TextField
                                        label="Address Line 3"
                                        name="clientAddress3"
                                        multiline
                                        variant="outlined"
                                        inputProps={{ maxLength: 50 }}
                                        helperText="Optional"
                                        onChange={onChange}
                                        value={residentialCalcData.clientAddress3 || ''}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    />
                                    <TextField
                                        id="standard-select-state"
                                        select
                                        label="Select State"
                                        value={residentialCalcData.state || ''}
                                        onChange={handleStateChange}
                                        variant="outlined"
                                        helperText={notify.item === "state" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        fullWidth
                                        style={{ textAlign: "left", marginBottom: "10px" }}
                                    >
                                        {states.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <TextField
                                        id="standard-select-town"
                                        select
                                        label="Select City"
                                        value={residentialCalcData.town || ''}
                                        onChange={handleTownChange}
                                        variant="outlined"
                                        helperText={notify.item === "town" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        fullWidth
                                        style={{ textAlign: "left", marginBottom: "10px" }}
                                    >
                                        {checkStateHasCities(residentialCalcData.state as State)
                                            ? malaysianStates[residentialCalcData.state as State]?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            )) : <MenuItem disabled key={residentialCalcData.state} value={residentialCalcData.state}>
                                                {residentialCalcData.state}
                                            </MenuItem>}
                                    </TextField>

                                    <TextField
                                        label="Postcode"
                                        name="postCode"
                                        variant="outlined"
                                        type="text"
                                        inputProps={{ maxLength: residentialCalcData.state === "Other" ? 6 : 5, inputMode: 'numeric', pattern: '[0-9]*', }}
                                        onChange={onChange}
                                        helperText={notify.item === "postCode" && notify.show ? notify.message : ''}
                                        FormHelperTextProps={{ classes: helpertextstyle }}
                                        onKeyDown={(evt: any) => inputPreventDefault(evt, true)}
                                        value={residentialCalcData.postCode || ''}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    />

                                    <TextField
                                        label={hasPartner && landingPage.partnerName === "BonusLink" ? "BonusLink Card Number" : "Referral Code"}
                                        name="referralCode"
                                        variant="outlined"
                                        inputProps={{ maxLength: 16 }}
                                        onChange={onChange}
                                        helperText="Optional"
                                        value={residentialCalcData.referralCode || ''}
                                        fullWidth
                                        style={{ marginBottom: "10px" }}
                                    />
                                    {!hasPartner && (
                                        <TextField
                                            label="Referral Name"
                                            name="referralName"
                                            variant="outlined"
                                            inputProps={{ maxLength: 25 }}
                                            onChange={onChange}
                                            helperText="Optional"
                                            value={residentialCalcData.referralName || ''}
                                            fullWidth
                                            style={{ marginBottom: "10px" }}
                                        />
                                    )}
                                    <div className="terms-check-container">
                                        <Checkbox
                                            color="primary"
                                            checked={residentialCalcData.termsAndConditionStatus}
                                            onChange={handleTermsAndCondition}
                                        />
                                        <span className="agreement-text">I agree to the terms of service and </span>
                                        <Link href="./privacy-policy" style={{ color: "#0A2540" }}>privacy policy</Link>
                                        <div className="error-message">
                                            {message}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className="button-main-theme calculate"
                                        onClick={handleSubmit}
                                    >
                                        <div className="wrapper">
                                            <label className="btn-title">Show Calculation</label>
                                            <label className="right-arrow">
                                                {isSaving ? (
                                                    <CircularProgress style={{ color: "white" }} />
                                                ) : (
                                                    <img src={ArrowRight} alt="right-arrow" />
                                                )}
                                            </label>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrap>
    )
}

export default InsertDataCalculator;