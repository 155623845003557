import './CertificateAwards.scss';
import {Grid, Box} from '@material-ui/core'
import AsiaHonestyEnterprise from "../../images/asia-honesty-enterprise-award-2018.png";
import AsiaHonestyProduct from "../../images/asia-honesty-product-award-2018.png";
import AsiaTopBrand from "../../images/asia-top-100-brands-honesty-award-2018.png";
import Cidb from "../../images/cidb-logo.png";
import Iso from "../../images/iso-9001-certified-logo.png";
import SuruhanjayaTenage from "../../images/suruhanjaya-tenaga.png";
import Tuv from "../../images/tuv-sud-2.jpg";
import Seda from "../../images/seda-logo.png";
import Manufacturer from "../../images/manufacturer.png";
import Mbpj from "../../images/mbpj.png";
import Mof from "../../images/mof.png";
import Mpia from "../../images/mpia.png";
import MyHijau from "../../images/myhijau.png";
import Sfeia from "../../images/sfeia.png";
import Upen from "../../images/upen.png";
import Vasolar from "../../images/tera-logo-full-colour-cropped.png";
import AsiaEntrepreneur from "../../images/asia-honesty-entrepreneur-award-2018.png"

function CertificateAwards() {
  return (
      <div className="certificate-description">
          <div className="certificate-description-container" >
              <div className="certificate-description">
              <span className="certificate">CERTIFICATE & AWARDS</span><br />
                  <label className="title">Certificate & Awards</label><br />
                  <label className="description">Over the years, we have won several awards and have been certified by major bodies which we are proud to showcase here.</label><br />
                  <Grid container spacing={4} style={{marginTop:"20px"}}>
                  <Grid item xs={12} sm={9} md={6} lg={2} className="logo-box">
                    <Box component="span" >
                    <img src={AsiaEntrepreneur} className="ent-logo-size" alt=""/>
                    <p className="logo-description">Asia Honesty Entrepreneur Awards - 2018 Best Choice</p>
                    </Box>    
                  </Grid>
                <Grid item xs={12} sm={9} md={6} lg={2} className="logo-box" >
                  <Box component="span">
                  <img src={AsiaHonestyProduct} className="logo-size" alt=""/>
                  <p className="logo-description">Asia Honesty Product Awards - 2018 Best Choice</p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={2} className="logo-box" >
                  <Box component="span" >
                  <img src={AsiaTopBrand} className="logo-size" alt=""/>
                  <p className="logo-description">Asia 100 Top Brand Honesty Awards - 2018 Best Choice</p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={2} className="logo-box" >
                  <Box component="span" >
                  <img src={AsiaHonestyEnterprise} className="logo-size" alt=""/>
                  <p className="logo-description">Asia Honesty Enterprise Awards - 2018 Best Choice</p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={2} className="logo-box">
                  <Box component="span">
                  <img src={Tuv} className="tuv-logo-size" alt=""/>
                      <p className="logo-description">Certified by TUV SUD Asia Pacific TUV SUD Group Quality Management System for Design and Installation of Solar Green Power Energy Product</p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={2} className="logo-box">
                  <Box component="span">
                  <img src={Manufacturer} className="manufacturer-logo-size" alt=""/>
                  <p className="logo-description">Membership of The Federation of Malaysian Manufacturers</p>
                  </Box>    
                </Grid>
                




                <Grid item xs={12} sm={9} md={6} lg={3} className="logo-box" >
                  <Box component="span">
                  <img src={Mbpj} className="mbpj-logo-size" alt=""/>
                  <p className="logo-description">Business Trade License under the Majlis Bandar Raya Petaling Jaya City Council</p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={3} className="logo-box">
                  <Box component="span">
                  <img src={Sfeia} className="sfeia-logo-size" alt=""/>
                      <p className="logo-description">Subscribing Permanent Membership of The Selangor & Kuala Lumpur Foundry & Engineering Industries Association</p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={2} className="logo-box" >
                  <Box component="span" >
                  <img src={MyHijau} className="iso-logo-size" alt=""/>
                  <p className="logo-description">Certified to officially use the MyHijau mark</p>
                  </Box>    
                </Grid>

                <Grid item xs={12} sm={9} md={6} lg={4} className="logo-box">
                  <Box component="span">
                  <img src={Seda} className="long-logo-size first" alt=""/>
                  <p className="logo-description">Registered under the Sustainable Energy Development Authority Malaysia </p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={4} className="logo-box">
                  <Box component="span">
                  <img src={Cidb} className="long-logo-size" alt=""/>
                  <p className="logo-description">Registered under the Construction Industry Development Board Malaysia</p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={4} className="logo-box">
                  <Box component="span">
                  <img src={SuruhanjayaTenage} className="long-logo-size" alt=""/>
                  <p className="logo-description">Registered under the Malaysian Energy Commission</p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={4} className="logo-box" >
                  <Box component="span">
                  <img src={Mof} className="mof-logo-size" alt=""/>
                  <p className="logo-description">Registered under the Ministry of Finance in the Electrical Engineering sector</p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={4} className="logo-box">
                  <Box component="span">
                  <img src={Mpia} className="mpia-logo-size" alt=""/>
                  <p className="logo-description">Full member of the Malaysian Photovoltaic Industry Association</p>
                  </Box>    
                </Grid>
                <Grid item xs={12} sm={9} md={6} lg={4} className="logo-box">
                  <Box component="span">
                  <img src={Vasolar} className="tera-logo-size" alt=""/>
                  <p className="logo-description">Trademarked Brand by Intellectual Property Corporation Malaysia</p>
                  </Box>    
                </Grid>
              
                </Grid>
              </div>
          </div>
      </div>
  )
}

export default CertificateAwards;