import FooterOurLocation from '../../../components/footer-our-location/FooterOurLocation';
import TopNavigation from '../../../components/top-navigation/TopNavigation';
import ServiceBanner from '../ServiceBanner';
import ServicesDescription from '../ServicesDescription';
import VasolarInsuranceCoverage from '../VasolarInsuranceCoverage';
import OurService from '../OurService';

function Services() {
    return (
        <div className="services-page" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
                <ServiceBanner />
                <ServicesDescription />
                <OurService />
                <VasolarInsuranceCoverage />
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default Services;