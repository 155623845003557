import PaymentRedirect from '../PaymentRedirect'
import Logo from '../../../images/tera-logo-full-colour.png'
import '../PaymentRedirect.scss'

function PaymentRedirectPage(props: any) {
    return (
        <div className="payment-redirect">
            <div className="payment-redirect-logo">
                 <img className="logo" alt="tera-logo" src={Logo} />
                 <br/><br/>
            </div>
            <PaymentRedirect {...props} />
        </div>
    )
}

export default PaymentRedirectPage;