import { ChangeEvent, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Testimonial.scss";

function PetalingJaya() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className="aboutuss-description">
      <div className="aboutus-description-container">
        <div className="aboutus-description">
          <span className="aboutus">TESTIMONIALS</span>
          <br />
          <h1 className="title" style={{ marginTop: 0, marginBottom: 0, fontWeight:"normal"}}>
            Your One-stop Provider of Solar Services in Petaling Jaya
          </h1>
          <br />
          <label className="description">
            Are you ready to take charge of your energy future while making a
            positive impact on the environment? Look no further than TERA,
            your trusted partner for all things solar in Petaling Jaya. As our
            country continues to push and advocate for renewable energy, we are
            here to bring you innovative and reliable solar panel solutions that
            are tailored to meet the specific needs of the Petaling Jaya
            community.
          </label>
          <br />
          <h2 className="title">
            Why Choose Solar Energy in Petaling Jaya?
          </h2>
          <br />
          <label className="description">
            Petaling Jaya's sunny climate makes it an ideal location for
            harnessing electricity from the sun through solar panels. By
            embracing solar energy, you're not only reducing your electricity
            bills but also contributing to achieving net zero carbon emissions
            and a greener environment. Here are some key benefits of making the
            switch to solar with TERA:
          </label>
          <br />
          <div className="privacy-policy-expand-bar">
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Significant Cost Savings
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      With solar panels, you can significantly reduce your
                      monthly electricity expenses. Imagine the freedom of
                      generating your own power and exporting excess energy back
                      to the grid. The excess energy will be offset one-to-one
                      on your subsequent bill.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Environmental Responsibility
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      By opting for solar energy, you're helping to reduce
                      greenhouse gas emissions and combat climate change. You're
                      making a positive impact on Malaysia’s air quality and
                      preserving the beauty of your surroundings.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="privacy-policy-sub-expand-bar">
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expand-icon" />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="big-title">
                    Energy Independence
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <Typography className="subtitle">
                      Break free from the uncertainties of rising energy costs
                      and reliance on traditional fossil fuels. Solar energy
                      provides a stable and predictable source of power that
                      empowers you to take control of your energy consumption.
                    </Typography>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <br />
          <h2 className="title">
            The TERA Advantage for Solar Panels in Petaling Jaya:
          </h2>
          <br />
          <ul>
            <li>
              <span className="list-description">
                Expertise and Experience: Years of industry experience with a
                team of skilled solar experts. You can trust us to know the
                ins-and-outs the entire solar panel system, helping you to get
                maximum returns on your investment.
              </span>
            </li>
            <li>
              <span className="list-description">
                Customized Solutions: Since every customer is unique, you can
                count on us to deliver a solution that is uniquely yours;
                offering tailored solar solutions based on individual energy
                needs and site conditions.
              </span>
            </li>
            <li>
              <span className="list-description">
                Quality Products: We use nothing but the best materials to build
                high-performance solar panels and components that are rigorously
                tested for reliability and longevity.
              </span>
            </li>
            <li>
              <span className="list-description">
                Seamless Installation: We strive to provide you with a
                hassle-free experience from consultation, to design to
                installation and after sales service. We want you to sit back
                and enjoy your transition to renewable energy without a single
                worry.
              </span>
            </li>
            <li>
              <span className="list-description">
                Customer-Centric: Our experienced team is dedicated to providing
                exceptional customer service, addressing concerns and providing
                personalized guidance to our clients.
              </span>
            </li>
          </ul>
          <br />
          <h2 className="title">
            Your Trusted Partner for PV Systems in Petaling Jaya
          </h2>
          <br />
          <label className="description">
            At TERA, we understand that every home and business in Petaling
            Jaya has unique energy requirements. Our team of experienced
            professionals specializes in designing and installing PV systems
            that are tailor-made to suit your specific needs. With a commitment
            to excellence and quality, we ensure that your transition to solar
            energy is completely seamless.
          </label>
          <br />
          <label className="title">Hear It From Our Customers</label>
          <br />
          <label className="description">
            Don’t take our word for it? Here’s what our previous customers had
            to say about their time and experience with TERA:
          </label>
          <br />
          <ul>
            <li className="description">
              <div className="description-bold">Mr Chia, Petaling Jaya</div>
              <div>
                “I was satisfied with the installation team that arrived on time
                and did a good job!”
              </div>
            </li>
            <br />
            <li className="description">
              <div className="description-bold">En. Shamsudin , Klang</div>
              <div>
                “Sangat senang berurusan dengan sales team TERA kerana
                respond yang diberikan cepat dan pantas”
              </div>
            </li>
          </ul>
          <br />
          <label className="title">
            Join the Solar Revolution in Petaling Jaya
          </label>
          <br />
          <label className="description">
            Experience the benefits of renewable energy firsthand by choosing
            TERA. We're here to support you in your journey towards a
            sustainable and energy-efficient future. For top-notch solar
            services in Petaling Jaya, choose TERA today.
          </label>
        </div>
      </div>
    </div>
  );
}

export default PetalingJaya;
