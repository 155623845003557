import DetailResidentialPortfoliosBanner from '../DetailResidentialPortfoliosBanner'
import FooterOurLocation from '../../../../components/footer-our-location/FooterOurLocation'
import ResidentialPortfolios from '../ResidentialPortfolios'
import TopNavigation from '../../../../components/top-navigation/TopNavigation'

function DetailPageResidentialPortfolios () {
    return (
        <div className="portfolios" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
            <DetailResidentialPortfoliosBanner />
            <ResidentialPortfolios />
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default DetailPageResidentialPortfolios;