import { useContext } from 'react';
import TopNavigation from '../../../../components/top-navigation/TopNavigation';
import FooterOurLocation from '../../../../components/footer-our-location/FooterOurLocation';
import ContactUs from '../../../../components/contact-us/ContactUs';
import ResidentialBanner from '../ResidentialBanner';
import CompanyDescriptionResidential from '../../CompanyDescriptionResidential';
import CompletedProjectResidential from '../CompletedProjectResidential';
import ResidentialCalculator from '../ResidentialCalculator';
import { DocumentContext } from '../../../../context/DocumentContext';



function Residential() {

    const { hasPartner } = useContext(DocumentContext)
    return (
        <div className="residential" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
                <ResidentialCalculator /> 
                {!hasPartner && (
                    <>
                     <CompanyDescriptionResidential />
                    <CompletedProjectResidential />
                    <ResidentialBanner />
                    <ContactUs />
                    </>
                )} 
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default Residential;