import React from "react";
import "./CompletedProjectCommercial.scss";
import P1 from "../../../images/commercial-P1a.png";
import P2 from "../../../images/commercial-P2a.png";
import P3 from "../../../images/commercial-P3a.png";
import P4 from "../../../images/commercial-P4a.png";
import P5 from "../../../images/commercial-P5a.png";
import P6 from "../../../images/commercial-P6a.png";
import P7 from "../../../images/commercial-P7a.png";
import P8 from "../../../images/commercial-P8a.png";
import P9 from "../../../images/commercial-P9a.png";
import P10 from "../../../images/commercial-P10a.png";
import P11 from "../../../images/commercial-P11a.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

class CompletedProjectCommercial extends React.Component {

  render() {
    return (
      <div className="completed-project-commercial">
        <div className="completed-project-commercial-container">
          <div className="left-node">
            <label className="small-heading">Trust us</label>
            <br />
            <label className="title">Completed Projects</label>
          </div>
          <div id="horizontal-scroll" className="right-node">
            <div className="content">
              <Carousel infiniteLoop useKeyboardArrows autoPlay>
                <div>
                  <img src={P1} alt="commercial"/>
                </div>
                <div>
                  <img src={P2} alt="commercial"/>
                </div>
                <div>
                  <img src={P3} alt="commercial"/>
                </div>
                <div>
                  <img src={P4} alt="commercial"/>
                </div>
                <div>
                  <img src={P5} alt="commercial"/>
                </div>
                <div>
                  <img src={P6} alt="commercial"/>
                </div>
                <div>
                  <img src={P7} alt="commercial"/>
                </div>
                <div>
                  <img src={P8} alt="commercial"/>
                </div>
                <div>
                  <img src={P9} alt="commercial"/>
                </div>
                <div>
                  <img src={P10} alt="commercial"/>
                </div>
                <div>
                  <img src={P11} alt="commercial"/>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompletedProjectCommercial;
