import TopNavigation from "../../../components/top-navigation/TopNavigation";
import FooterOurLocation from "../../../components/footer-our-location/FooterOurLocation";
import PetalingJaya from "../PetalingJaya";

function SolarPanelPetalingJaya() {
  return (
    <div className="about" style={{ overflow: "hidden" }}>
      <TopNavigation />
      <div>
        <PetalingJaya />
      </div>
      <FooterOurLocation />
    </div>
  );
}

export default SolarPanelPetalingJaya;
