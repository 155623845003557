import React, { useState, useContext, useEffect } from 'react';
import { FormControl, InputLabel, Select, InputBase, ButtonGroup, Button, MenuItem, FormHelperText,CircularProgress } from '@material-ui/core';
import ArrowRight from '../../../images/arrow-right.png';
import { useHistory } from "react-router-dom";
import { DocumentContext } from '../../../context/DocumentContext';
import { API, graphqlOperation } from "aws-amplify";
import { getPromotionPartner } from "../../../graphql/queries";
import styled from "styled-components";
import './ResidentialCalculator.scss';
import { inputPreventDefault } from '../../../components/utils/util';

/*
    PhaseType:
    SP = Single Phase
    3p = 3 Phase
*/

interface Props {
    backgroundImage: string;
}

const Wrap = styled.div<Pick<Props, 'backgroundImage'>>`
    background-image: url(${props => props.backgroundImage}) !important;
    background-color: #0a2540e6;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`

function ResidentialCalculator() {
    let history = useHistory();
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const {
        residentialCalcData, 
        setResidentialCalcData,
        calculateSolarInvestment, 
        isCalculate, 
        clearDataResidential, 
        getTarrifData,
        pricing,
        setPricing,
        landingPage,
        setLandingPage,
        hasPartner,
        setHasPartner
    } = useContext(DocumentContext)
    let buildingType = ['Villa', 'Bungalow', 'Semi-D','Superlink','Terrace House', 'Townhouse']
    let modeOfPurchase = ['OutRight Purchase', 'Credit Card Installment']


    useEffect(() => {
        getLandingPageByQueryURL()
        clearDataResidential()
        //query pricing data on first load
        getTarrifData("A").then((res: any) => {
            setPricing(res)
            // setIsLoading(false)
        })
    }, [])

    useEffect(() => {
        localStorage.setItem('document', JSON.stringify(residentialCalcData))
    }, [residentialCalcData])
    
    const [notify, setNotify] = useState({
        show: false,
        item: '',
        message: ''
    })

    const getLandingPageByQueryURL = async () => {
        
        if (hasPartner){
            setResidentialCalcData({ ...residentialCalcData, promoCode: landingPage.promoCode})
            return
        }
        const { pathname } = history.location
        const partner = pathname.split("/")
        if(partner.length > 2 && partner[partner.length-1] !== ""){
            setIsLoading(true)
            setHasPartner(true)
            let partnerURL = "/" + partner[partner.length - 1]
            try {
                let res: any = await API.graphql(graphqlOperation(getPromotionPartner, { partnerURL: partnerURL }));
                if(res){
                    let landingPageData = res.data.getPromotionPartner
                    setLandingPage({
                        ...landingPageData, 
                        displaySecondPageText: "Fill up this form to be entitled for " + landingPageData.partnerName + " Points & a lower deposit rate"
                    })
                    setResidentialCalcData({ ...residentialCalcData, promoCode: landingPageData.promoCode})
                }

            } catch(error){
                console.log(error)
            }
            setIsLoading(false)
        } else {
            setLandingPage({
                ...landingPage, 
                backgroundImage: "https://d29xu9sbwklvj5.cloudfront.net/public/landing-page/default/residential-background-image.jpg"
            })
        }
        
    }

    const onChange = (e: any) => {
        if (e.target.value === ' ') {
            setResidentialCalcData({ ...residentialCalcData, [e.target.name]: '' })
        } else {
            setResidentialCalcData({ ...residentialCalcData, [e.target.name]: e.target.value })
        }

        if (e.target.name === 'buildingType' && e.target.value !== ""){
            setNotify({ ...notify, show: false, item: 'buildingType'})
        }
        if (e.target.name === 'avgElectricityBill' && e.target.value > 0){
            setNotify({ ...notify, show: false, item: 'avgElectricityBill'})
        }

        if (e.target.name === 'modeOfPurchase' && e.target.value !== ""){
            setNotify({ ...notify, show: false, item: 'modeOfPurchase'})
        }
    }
    
    const handleCalculate = (event: any) => {
        event.preventDefault();
        if (residentialCalcData.buildingType === "" || residentialCalcData.buildingType.includes("Select")){
            // console.log("empty buildingType")
            setNotify({
                show: true,
                item: "buildingType",
                message: 'Please select a valid input'
            })
        }
        else if (residentialCalcData.avgElectricityBill === "" ){
            // console.log("empty avgElectricityBill")
            setNotify({
                show: true,
                item: "avgElectricityBill",
                message: 'Please enter an amount more than 0'
            })
        }
        else if (residentialCalcData.modeOfPurchase === "" || residentialCalcData.modeOfPurchase.includes("Select")){
            // console.log("empty modeOfPurchase")
            setNotify({
                show: true,
                item: "modeOfPurchase",
                message: 'Please select a valid input'
            })
        } else {
            setIsSaving(true)
            setResidentialCalcData({ ...residentialCalcData})
            calculateSolarInvestment(residentialCalcData.avgElectricityBill, "Residential", pricing)
            setTimeout(() => {
                setIsSaving(false)
                history.push({
                    pathname: `/fill-up-detail`
                  })
              }, 1500)
            
        }
    }

    return (
        <>
        {isLoading ? <CircularProgress style={{color: "white"}}/> : (
        <Wrap className="residential-calculator" backgroundImage={landingPage.backgroundImage}>
            <div className="cover">
                <div className="residential-calculator-container">
                    <div className="left-node">
                    {/* <ContactUsFloat /> */}
                        {hasPartner ? (
                            <img src={landingPage.partnerLogo} alt={landingPage.partnerName} width="500px" height="178.112px"/>
                        ) : (
                        <h1 className="title">Better green energy to power your home</h1>
                        )}
                    </div>
                    <div className="right-node">
                    {/* <ContactUsFloat /> */}
                        <div className="calculator-form">
                            <div className="title-wrapper">
                                {hasPartner ? (
                                     <label className="title">{landingPage.displayInputText} <span>TERA.</span></label>
                                ) : (
                                    <label className="title">Calculate your electrical bill savings with <span>TERA</span></label>
                                )}
                            </div>
                            <div>
                                <div>
                                    <FormControl variant="filled" className="select-main-theme-wrapper">
                                        <InputLabel className="select-label">Building Type</InputLabel>
                                        <Select 
                                            name="buildingType"
                                            value={residentialCalcData.buildingType !== "" ? residentialCalcData.buildingType : "Select Building Type"}
                                            onChange={onChange}
                                            disabled={isCalculate ? true: false}
                                            className="select-main-theme">
                                                <MenuItem value="Select Building Type">-- Select Building Type --</MenuItem>
                                                {buildingType.map((data: any, index: any) => {
                                                return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                            })}
                                        </Select>
                                        {notify.item === "buildingType" && notify.show ? (
                                            <FormHelperText style={{color: "red"}}>{notify.message}</FormHelperText>
                                        ) : null}
                                        
                                    </FormControl>
                                </div>
                                <div style={{marginTop:"25px"}}>
                                    <div className="custom-price-input ">
                                        <label className="unit">RM</label>
                                        <InputBase 
                                            type="number"
                                            className="price hide-input-arrow "
                                            name="avgElectricityBill"
                                            onChange={(event: any) => {
                                                const regex = /^\d*\.?\d{0,2}$/;
                                                const { value } = event.target;
                                                if (regex.test(value)) {
                                                    if (value < 1000000 ) {
                                                        setResidentialCalcData({
                                                            ...residentialCalcData,
                                                            avgElectricityBill: value
                                                        });
                                                    }
                                                }
                                            }}
                                            onKeyDown={(evt: any) => inputPreventDefault(evt, false)}
                                            inputProps={{ maxLength: 6 }}
                                            value={residentialCalcData.avgElectricityBill}
                                            placeholder="Average electricity bill (monthly)"
                                            disabled={isCalculate ? true: false}
                                        />
                                    </div>
                                    <div style={{marginBottom: "15px"}}>
                                        {notify.item === "avgElectricityBill" && notify.show ? (
                                                <FormHelperText style={{color: "red"}}>{notify.message}</FormHelperText>
                                            ) : null}
                                    </div>
                                   
                                </div>
                                <div>
                                    <FormControl variant="filled" className="select-main-theme-wrapper">
                                        <InputLabel className="select-label">Mode of Purchase</InputLabel>
                                        <Select 
                                            name="modeOfPurchase"
                                            value={residentialCalcData.modeOfPurchase !== "" ? residentialCalcData.modeOfPurchase : "Select Mode of Purchase"}
                                            onChange={onChange}
                                            disabled={isCalculate ? true: false}
                                            className="select-main-theme">
                                                <MenuItem value="Select Mode of Purchase">-- Select Mode of Purchase --</MenuItem>
                                                {modeOfPurchase.map((data: any, index: any) => {
                                                return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                            })}
                                        </Select>
                                        {notify.item === "modeOfPurchase" && notify.show ? (
                                            <FormHelperText style={{color: "red"}}>{notify.message}</FormHelperText>
                                        ) : null}
                                        
                                    </FormControl>
                                </div>
                                <div style={{marginTop:"25px"}}>
                                    <ButtonGroup size="large" variant="contained">
                                        <Button 
                                            onClick={() => {setResidentialCalcData({ ...residentialCalcData, phaseSystem: 'Single Phase' })}}
                                            className={['button-main-theme', 'type', residentialCalcData.phaseSystem === 'Single Phase' ? 'active': 'passive'].join(' ')}
                                            disabled={isCalculate ? true: false}
                                        >Single Phase</Button>
                                        <Button 
                                            onClick={() => {setResidentialCalcData({ ...residentialCalcData, phaseSystem: 'Three Phase' })}}
                                            className={['button-main-theme', 'type', residentialCalcData.phaseSystem === 'Three Phase' ? 'active': 'passive'].join(' ')}
                                            disabled={isCalculate ? true: false}
                                        >Three Phase</Button>
                                    </ButtonGroup>
                                </div>
                                <div>
                                    <Button 
                                        variant="contained" 
                                        size="large" 
                                        className="button-main-theme calculate" 
                                        onClick={handleCalculate}
                                        disabled={isCalculate ? true: false}
                                        >
                                        <div className="wrapper">
                                            <label className="btn-title">Calculate with discounted pricing</label>
                                            <label className="right-arrow">
                                            {isSaving ? (
                                                <CircularProgress style={{color: "white"}} />
                                            ) : (
                                                <img src={ArrowRight} alt="right-arrow" />
                                            )}
                                            </label>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrap>
        )}
        </>
    )
}

export default ResidentialCalculator;

// function ms(res: (value: unknown) => void, ms: any): void {
//     throw new Error('Function not implemented.');
// }
