import "./CompanyDescriptionResidential.scss";
import Money from "../../images/money.svg";
import Meteorite from "../../images/meteorite.svg";
import Thunderbolt from "../../images/thunderbolt.svg";
import ContactUsFloat from "../../components/contact-us-float/ContactUsFloat";
import { ChangeEvent, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function CompanyDescriptionResidential() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div>
      <ContactUsFloat />
      <div className="company-description-residential">
        <div className="company-description-residential-container">
          <div className="company-description">
            <label className="title">
              Elevating Your Home with Clean, Efficient Energy
            </label>
            <br />
            <label className="description">
              Kickstart your journey into renewable energies and supercharge
              your savings today. At TERA, we're dedicated to empowering you
              to tap into our world’s near limitless supply of solar energy,
              transforming your living spaces into sources of clean power that
              contribute to both your wallet and the environment. Discover the
              remarkable advantages of integrating solar panels into your home
              while leaving the hard work of planning and installation to us; so
              you can sit back and reap the endless benefits.
            </label>
            <br />
            <label className="title">The Renewable Dream</label>
            <br />
            <label className="description">
              Picture a home where every beam of sunlight is transformed into a
              source of clean and cost-effective power. With TERA's solar
              panels for homes, this vision becomes a reality. Our top grade
              solar solutions are meticulously designed to seamlessly integrate
              with your home, generating clean electricity while substantially
              reducing your energy bills.
            </label>
            <br />
            <h1 className="title">
              Unlocking the Magic of Home Solar Panels: How They Work
            </h1>
            <br />
            <label className="description">
              Our solar panels for homes capture the sun's energy, transforming
              it into usable electricity to power your household items. At
              TERA, we make this technology efficient and accessible,
              reducing reliance on conventional non-renewable sources. Our solar
              panel installations align seamlessly with Malaysia's Net Energy
              Metering (NEM) scheme.
            </label>
            <br />
            <label className="description">
              Under NEM, surplus energy from your solar panels is fed back to
              the grid, earning you a "one-for-one" rebate. These credits can be
              saved for up to 12 months, optimizing your energy savings. Our
              solar panels not only cut costs but also contribute to a greener
              energy landscape in Malaysia.
            </label>
            <br />
            <h2 className="title">
              Advantages of Residential Solar Panels:
            </h2>
            <br />
            <div className="privacy-policy-expand-bar">
              <div className="privacy-policy-sub-expand-bar">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="big-title">
                      Economical Smartness
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <Typography className="subtitle">
                        By generating your own electricity, you'll experience
                        substantial savings on your monthly utility bills,
                        freeing up resources for your family's priorities. So
                        straddle up and get ready to embrace the power of solar.
                      </Typography>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="privacy-policy-sub-expand-bar">
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="big-title">
                      Environmental Excellence
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <Typography className="subtitle">
                        Solar panels for homes significantly reduce carbon
                        emissions and reliance on non-renewable energy sources
                        such as coal, making a meaningful contribution to
                        environmental preservation; this way our future
                        generations will have a greener world to live in.
                      </Typography>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="privacy-policy-sub-expand-bar">
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="big-title">
                      Energy Rebates
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <Typography className="subtitle">
                        If you end up using less than you generate, the surplus
                        can even be sent back to the grid to be stored and you
                        will be offered a rebate on your next bill!
                      </Typography>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="privacy-policy-sub-expand-bar">
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="big-title">
                      Enhanced Home Value
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <Typography className="subtitle">
                        Investing in solar panels is an investment in your
                        home's future. It not only enhances your property's
                        value but also attracts environmentally conscious buyers
                        who value forward-thinking energy solutions.
                      </Typography>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <br />
            <label className="title">
              Elevate Your Home with Solar Power Today!
            </label>
            <br />
            <label className="description">
              Are you ready to infuse your home with green energy? TERA's
              residential solar panels are your gateway to a brighter, more
              sustainable future. Join the ranks of homeowners who are embracing
              solar power and reaping the rewards. Contact us now to schedule a
              consultation and take the first step toward elevating your home
              with clean, efficient energy solutions.
            </label>
            <br />
          </div>
          {/* <div className="benefits">
            <div className="small-card-container">
              <div className="small-card">
                <img src={Money} alt="Money Logo" />
                <label className="title">Reduce Your Electricity Bill</label>
                <label className="description">
                  Generating your own electricity means that you will be using
                  less from the utility grid. This will immediately translate to
                  savings on your energy bill.
                </label>
              </div>
            </div>
            <div className="small-card-container middle">
              <div className="small-card">
                <img src={Meteorite} alt="Meteorite Logo" />
                <label className="title">Minimise Impact</label>
                <label className="description">
                  As you are generating your own electricity, during electricity
                  tariff increase the impact is minimized.
                </label>
              </div>
            </div>
            <div className="small-card-container">
              <div className="small-card">
                <img src={Thunderbolt} alt="Thunderbolt Logo" />
                <label className="title">Excess Energy</label>
                <label className="description">
                  All excess energy produced can be exported to the utility grid
                  and further reduce energy consumption.
                </label>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default CompanyDescriptionResidential;
