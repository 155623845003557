import TopNavigation from '../../../../components/top-navigation/TopNavigation';
import FooterOurLocation from '../../../../components/footer-our-location/FooterOurLocation';
import CheckoutProposal from '../CheckoutProposal';



const ProposalOverviewResidential = (props:any) => {
    return (
        <div className="residential" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
                <CheckoutProposal {...props} />
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default ProposalOverviewResidential;