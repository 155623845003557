import React from "react";
import "./TeraVaLeadership.scss";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Link,
} from "@material-ui/core";
import { Storage } from "aws-amplify";
import Download from "../../images/download.svg";
import Michael from "../../images/Michael-Leong.jpg";
import Leong from "../../images/Leong-Jit-Ping.jpg";
import Pauline from "../../images/Pauline-Tan.jpg";
import Phoebe from "../../images/Phoebe-Chen.jpg";
import Shahida from "../../images/Shahida.jpg";
import Clive from "../../images/Clive-Chong.jpg";
import Gooi from "../../images/Gooi-Kee-Wee.jpg";
import Chun from "../../images/Mr-Chun-Hoe.jpg"

function downloadFile(blob: any, filename: string) {
  const url = URL.createObjectURL(blob);
  // console.log(url)
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download.pdf";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
}

const downloadProfile = async (event: any) => {
  event.preventDefault();
  const fileKey: string = "about/TeraVA company profile.pdf";
  const result: any = await Storage.get(fileKey, { download: true });
  const blob: any = result.Body;
  downloadFile(blob, "TeraVA_Company_Profile.pdf");
};
class TeraVaLeadership extends React.Component {
  render() {
    return (
      <div className="leadership-content">
        <div className="leadership-explaination">
          <div className="left-description">
            <label className="title">TERA VA LEADERSHIP</label>
            <br />
            <label className="sub-title">Creating Energy Solutions</label>
            <br />
            <label className="description">
              Tera VA is a subsidiary of Boilermech Holdings Berhad, a
              wholly-owned unit of QL Resources Berhad. Together, we are one
              step closer to achieving our vision to become the regional leader
              in renewable energy and sustainable solutions.
            </label>
            <br />
            <img className="icon-download" src={Download} alt="Download" />
            <Link
              href="#"
              underline="always"
              rel="noopener noreferrer"
              className="download-company-profile"
              onClick={downloadProfile}
              color="secondary"
            >
              Download our company profile
            </Link>
          </div>
        </div>
        <div className="f-card-arrangement">
          <div className="images-card">
            <Card>
              <CardActionArea>
                <CardMedia
                  image={Michael}
                  alt="Mr.Michael Leong"
                  title="Mr.Michael Leong"
                  component="img"
                  style={{ width: "295px", height: "230px" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Mr.Michael Leong
                  </Typography>
                  <Typography gutterBottom variant="caption">
                    Managing Director
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea>
                <CardMedia
                  image={Phoebe}
                  alt="Ms.Phoebe Chen"
                  title="Ms.Phoebe Chen"
                  component="img"
                  height="140"
                  style={{ width: "295px", height: "230px" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Ms.Phoebe Chen
                  </Typography>
                  <Typography gutterBottom variant="caption">
                    HR and Admin Manager
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card>
              <CardActionArea>
                <CardMedia
                  image={Leong}
                  alt="Mr.Leong Jit Ping"
                  title="Mr.Leong Jit Ping"
                  component="img"
                  height="140"
                  style={{ width: "295px", height: "230px" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Mr.Leong Jit Ping
                  </Typography>
                  <Typography gutterBottom variant="caption">
                    Business Development Manager
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea>
                <CardMedia
                  image={Shahida}
                  alt="Ms.Shahida"
                  title="Ms.Shahida"
                  component="img"
                  height="140"
                  style={{ width: "295px", height: "230px" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Ms.Shahida
                  </Typography>
                  <Typography gutterBottom variant="caption">
                    Project Management Manager
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea>
                <CardMedia
                  image={Chun}
                  alt="Mr.Chun Hoe"
                  title="Mr.Chun Hoe"
                  component="img"
                  height="140"
                  style={{ width: "295px", height: "230px" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Mr.Leong Chun Hoe
                  </Typography>
                  <Typography gutterBottom variant="caption">
                    Finance & Accounts Manager
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea>
                <CardMedia
                  image={Gooi}
                  alt="Mr.Gooi Kee Wee"
                  title="Mr.Gooi Kee Wee"
                  component="img"
                  height="140"
                  style={{ width: "295px", height: "230px" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Mr.Gooi Kee Wee
                  </Typography>
                  <Typography gutterBottom variant="caption">
                    Operations Manager
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            {/* <Card>
            <CardActionArea>
              <CardMedia
                image={ImageNem}
                alt="Ms.Syida"
                title="Ms.Syida"
                component="img"
                height="140"
                style={{width: "295px", height: "230px"}}
              />
              <CardContent>
                <Typography gutterBottom variant="subtitle1" style={{fontWeight: "bold"}}>
                  Ms.Syida
                </Typography>
                <Typography gutterBottom variant="caption">
                  Project Development Engineer
                </Typography>
            </CardContent>
            </CardActionArea>
          </Card> */}
          </div>
        </div>
      </div>
    );
  }
}

export default TeraVaLeadership;
