import './Home.scss';
import ArrowRight from '../../../images/arrow-right.png';
import HomeNavigation from '../HomeNavigation';
import { Link } from 'react-router-dom';

function Home() {
	return (
		<div className="home">
			<div className="home-navigation-container">
				<HomeNavigation />
			</div>
			<div className="content">
				<div className="leaf left-background" >
					<div className="title-container">
						<h1 className="title">Residential</h1>
					</div>
					<Link className="left-node" to="/residential">
						<div className="description">
							<label>Enter Residential</label>
						</div>
						<div className="right-arrow">
							<img src={ArrowRight} alt="right-arrow"/>
						</div>
					</Link>
				</div>
				<div className="leaf right-background">
					<div className="title-container">
						<h1 className="title">Commercial & Industrial</h1>
					</div>
					<Link className="right-node" to="/commercial-industrial">
						<div className="description">
							<label>Enter Commercial & Industrial</label>
						</div>
						<div className="right-arrow">
							<img src={ArrowRight} alt="right-arrow" />
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Home;
