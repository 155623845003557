import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// @ts-ignore
import Amplify from "aws-amplify";
// @ts-ignore
import config from "./aws-exports";
import DocumentContextProvider from "./context/DocumentContext";

Amplify.configure(config);

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <DocumentContextProvider>
        <App />
      </DocumentContextProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <DocumentContextProvider>
        <App />
      </DocumentContextProvider>
    </React.StrictMode>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
