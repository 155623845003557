import React, {useState} from "react";
import { Checkbox, TextField, Button, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress'
import ArrowRight from '../../images/arrow-right.png';
import AlertBar from '../alert/Alert';
// @ts-ignore
import { API, graphqlOperation } from 'aws-amplify'
import {addEnquiry} from '../../graphql/mutations'
import './ContactUs.scss';

function ContactUs() {
    var validEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validPhonePattern = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]-*[0-9]{7,8}$/;
    let client = ["Residential", "Commercial"]
    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        phone: '',
        clientType:'',
        subscribe: true,
    })
    const [notify, setNotify] = useState({
        show: false,
        severity: '',
        message: ''
      })

    const [isSaving, setIsSaving] = useState(false)

    const onChange = (event: any) => {
        if (event.target.value === ' ') {
            setContactData({ ...contactData, [event.target.name]: '' })
        } 
        else {
            setContactData({ ...contactData, [event.target.name]: event.target.value })
        }
    }

    const handleSubscribe = (event: any) => {
        setContactData({ ...contactData, subscribe: !contactData.subscribe })
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (contactData.name === ''){
            setNotify({
                show: true,
                severity: 'error',
                message: 'Name Field Cannot Be Empty'
            })
        } 
        
        else if (contactData.email === ''){
            setNotify({
                show: true,
                severity: 'error',
                message: 'Email Field Cannot Be Empty'
            })
        } 

        else if (!contactData.email.match(validEmailPattern)){
            setNotify({
                show: true,
                severity: 'error',
                message: 'Invalid Email'
            })
        }
        
        else if (contactData.phone === ''){
            setNotify({
                show: true,
                severity: 'error',
                message: 'Phone Number Field Cannot Be Empty'
            })
        }

        else if (!contactData.phone.match(validPhonePattern)){
            setNotify({
                show: true,
                severity: 'error',
                message: 'Invalid Phone Number'
            })
        }

        else if (contactData.phone.length !== 10 && contactData.phone.length !== 11  ){
            setNotify({
                show: true,
                severity: 'error',
                message: 'Invalid Phone Number'
            })
        }
        else if (contactData.clientType === "" || contactData.clientType.includes("Select")){
            setNotify({
                show: true,
                severity: 'error',
                message: 'Please select a valid input'
            })
        }
        else {
            try {
                setIsSaving(true)
                const params = {
                    name: contactData.name,
                    phone: contactData.phone,
                    email: contactData.email,
                    clientType: contactData.clientType,
                    subscribe: contactData.subscribe
                }
                let res: any = await API.graphql(graphqlOperation(addEnquiry, params))
    
                if(res.data.addEnquiry && res.data.addEnquiry.statusCode === "200"){
                    setNotify({
                        show: true,
                        severity: 'success',
                        message: 'Info Submitted'
                      })
                      setTimeout(() => {
                        setContactData({ ...contactData, name: '', email: '', phone: '', clientType:'', subscribe: false })
                        setIsSaving(false)
                      }, 2000)
                } else{
                    setNotify({
                        show: true,
                        severity: 'error',
                        message: 'Failed! Something went wrong, please try again later.'
                      })
                    setIsSaving(false)
                }
    
            } catch(err){
                setNotify({
                    show: true,
                    severity: 'error',
                    message: 'Failed! Something went wrong, please try again later.'
                })
                setIsSaving(false)
                
            }
        }   
    }
    
    return (
        <>
        <div className="contact-us">
            <div>
                <label className="title">Contact us for free consultation!</label>
                <form className="news-form"  autoComplete="off">
                    <TextField
                        label="Name *" 
                        type="text"
                        variant="standard"
                        inputProps={{ maxLength: 25 }}
                        className="input-main-theme"
                        value={contactData.name || ''}
                        onChange={onChange}
                        name="name"
                        disabled={isSaving ? true : false}
                    />
                    <FormControl variant="filled" className="select-main-theme-wrapper">
                        <InputLabel className="select-label">Client Type</InputLabel>
                            <Select 
                                name="clientType"
                                value={contactData.clientType !== "" ? contactData.clientType : "Select Client Type"}
                                onChange={onChange}
                                disabled={isSaving ? true : false}
                                className="select-main-theme">
                                <MenuItem value="Select Client Type">-- Select Client Type --</MenuItem>
                                {client.map((data: any, index: any) => {
                                    return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                })}
                            </Select> 
                    </FormControl>
                    <TextField 
                        label="Email *" 
                        type="email" 
                        variant="standard"
                        className="input-main-theme"
                        onChange={onChange}
                        value={contactData.email || ''}
                        inputProps={{
                            className: "input-main-theme:invalid",
                            pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$",
                        }}
                        name="email"
                        disabled={isSaving ? true : false}
                    />
                    <TextField 
                        label="Phone *"
                        type="number"  
                        variant="standard"
                        inputProps={{ maxLength: 15}}
                        className="input-main-theme hide-input-arrow"
                        onChange={onChange}
                        value={contactData.phone || ''} 
                        name="phone"
                    />
                    <Button 
                        variant="contained" 
                        size="large" 
                        className="button-main-theme"
                        disabled={isSaving ? true : false}
                        onClick={handleSubmit}
                    >
                        <div className="wrapper">
                            <label className="btn-title">Submit</label>
                            <label className="right-arrow">
                                {isSaving ? (
                                     <CircularProgress style={{color: "white"}} />
                                ) : (
                                     <img src={ArrowRight} alt="right-arrow" />
                                )}
                              
                            </label>
                        </div>
                    </Button>
                    <div className="agreement">
                        <label className="agreement-container">
                            <Checkbox
                                checked={contactData.subscribe} 
                                className="checkbox-main-theme"
                                onChange={handleSubscribe}
                                disabled={isSaving ? true : false}
                                 />
                            <span className="agreement-text">I agree to receive TERA newsletters via email about updates and promotions.</span>
                        </label>
                    </div>
                </form>
            </div> 
        </div>
        <AlertBar notify={notify} setNotify={setNotify} />
        </>
    )
}

export default ContactUs;