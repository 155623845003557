import FooterOurLocation from '../../../components/footer-our-location/FooterOurLocation'
import PortfoliosBanner from '../PortfoliosBanner'
import Portfolio from '../Portfolio'
import TopNavigation from '../../../components/top-navigation/TopNavigation'
import PortfoliosResidentialCommercial from '../PortfoliosResidentialCommercial'

function Portfolios() {
    return (
        <div className="portfolios" style={{overflow: "hidden"}}>
            <TopNavigation />
            <div>
                <PortfoliosBanner />
                <Portfolio />
                <PortfoliosResidentialCommercial />
            </div>
            <FooterOurLocation />
        </div>
    )
}

export default Portfolios;