import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    }
  }))

  

  
function AlertBar(props: any) {
    const classes = useStyles()
    const { notify, setNotify } = props

    const handleClose = (event: any, reason: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setNotify({ ...notify, show: false});
    };

    return (
        <div className={classes.root}>
            <Snackbar open={notify.show} autoHideDuration={3000}  onClose={handleClose} anchorOrigin={{vertical: "top", horizontal: "center"}}>
                <Alert onClose={handleClose} severity={notify.severity}>
                    {notify.message}
                </Alert>
            </Snackbar>
        </div>
    )

}

export default AlertBar;