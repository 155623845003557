import { useState } from 'react'
import { FormControl, InputLabel, Select, InputBase, Button, MenuItem, FormHelperText, Grid } from '@material-ui/core';
import ArrowRight from '../../../images/arrow-right.png';
import './CalculatorBannerCommercial.scss';
import { inputPreventDefault } from '../../../components/utils/util';


function CalculatorBannerCommercial(props: any) {
    const { commercialCalcData, setCommercialCalcData, handleCalculate } = props
    let tariffGroup = ['B', 'C1', 'C2', 'D', 'E1', 'E2', 'E2s', 'E3', 'E3s', 'F', 'F1', 'F2', 'H', 'H1', 'H2']
    let buildingType = ['Manufacturing facility', 'Warehouse', 'Flex', 'Mall', 'Pad site', 'Office building', 'Service apartment', 'Medical facility',
        'Estate', 'Hotels', 'Restaurants', 'Office', 'Shop lots', 'Petrol Station']
    let modeOfPurchase = ['OutRight Purchase', "Self Consumption (SelCo)"]
    let cTRatioType = ["32", "63", "150/5", "200/5", "300/5", "400/5", "500/5", "600/5", "800/5", "1000/5", "1200/5", "1600/5"]

    const [notify, setNotify] = useState({
        show: false,
        item: '',
        message: ''
    })

    const onChange = (e: any) => {
        if (e.target.value === ' ') {
            setCommercialCalcData({ ...commercialCalcData, [e.target.name]: '' })
        } else {
            setCommercialCalcData({ ...commercialCalcData, [e.target.name]: e.target.value })
        }
        if (e.target.name === 'tariffGroup' && e.target.value !== "") {
            setNotify({ ...notify, show: false, item: 'tariffGroup' })
        }
        if (e.target.name === 'buildingType' && e.target.value !== "") {
            setNotify({ ...notify, show: false, item: 'buildingType' })
        }
        if (e.target.name === 'avgElectricityBill' && e.target.value > 0) {
            setNotify({ ...notify, show: false, item: 'avgElectricityBill' })
        }

        if (e.target.name === 'modeOfPurchase' && e.target.value !== "") {
            setNotify({ ...notify, show: false, item: 'modeOfPurchase' })
        }
    }
    return (
        <div className="calculator-banner">
            <div className="cover">
                <div className="calculator-banner-container">
                    <div className="right-node">
                        <div className="calculator-form">
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <FormControl variant="filled" className="select-main-theme-wrapper">
                                        <InputLabel className="select-label">Building Type</InputLabel>
                                        <Select
                                            name="buildingType"
                                            value={commercialCalcData.buildingType !== "" ? commercialCalcData.buildingType : "Select Building Type"}
                                            onChange={onChange}
                                            className="select-main-theme">
                                            {buildingType.map((data: any, index: any) => {
                                                return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                            })}
                                        </Select>
                                        {notify.item === "buildingType" && notify.show ? (
                                            <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <div className="custom-price-input select-main-theme-wrapper">
                                        <label className="unit">RM</label>
                                        <InputBase
                                            type="number"
                                            className="price hide-input-arrow"
                                            name="avgElectricityBill"
                                            onChange={(event: any) => {
                                                const regex = /^\d*\.?\d{0,2}$/;
                                                const { value } = event.target;
                                                if (regex.test(value)) {
                                                    if (value < 1000000) {
                                                        setCommercialCalcData({
                                                            ...commercialCalcData,
                                                            avgElectricityBill: value
                                                        });
                                                    }
                                                }
                                            }}
                                            inputProps={{ maxLength: 6 }}
                                            value={commercialCalcData.avgElectricityBill || ''}
                                            placeholder="Average electricity bill (monthly)"
                                            onKeyDown={(evt: any) => inputPreventDefault(evt, false)}
                                        />
                                    </div>
                                    <div style={{ marginBottom: "15px" }}>
                                        {notify.item === "avgElectricityBill" && notify.show ? (
                                            <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                        ) : null}
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3}>
                                    <FormControl variant="filled" className="select-main-theme-wrapper" style={{ float: "left", width: "290px" }}>
                                        <InputLabel className="select-label">Mode of Purchase</InputLabel>
                                        <Select
                                            name="modeOfPurchase"
                                            value={commercialCalcData.modeOfPurchase !== "" ? commercialCalcData.modeOfPurchase : "Select Mode of Purchase"}
                                            onChange={onChange}
                                            className="select-main-theme">
                                            {modeOfPurchase.map((data: any, index: any) => {
                                                return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                            })}
                                        </Select>
                                        {notify.item === "modeOfPurchase" && notify.show ? (
                                            <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3}>
                                    <FormControl variant="filled" className="select-main-theme-wrapper" style={{ float: "left", width: "290px" }}>
                                        <InputLabel className="select-label">Tariff Group</InputLabel>
                                        <Select
                                            name="tariffGroup"
                                            value={commercialCalcData.tariffGroup !== "" ? commercialCalcData.tariffGroup : "Select Tariff Group"}
                                            onChange={onChange}
                                            className="select-main-theme">
                                            <MenuItem value="Select Mode of Purchase">-- Select Mode of Purchase --</MenuItem>
                                            {tariffGroup.map((data: any, index: any) => {
                                                return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                            })}
                                        </Select>
                                        {notify.item === "tariffGroup" && notify.show ? (
                                            <FormHelperText style={{ color: "red" }}>{notify.message}</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3}>
                                    <div className="custom-price-input ">
                                        <label className="unit">kWp</label>
                                        <InputBase
                                            type="number"
                                            className="price hide-input-arrow "
                                            name="maximumDemand"
                                            onChange={(event: any) => {
                                                const regex = /^\d*\.?\d{0,2}$/;
                                                const { value } = event.target;
                                                if (regex.test(value)) {
                                                    if (value <= 3600) {
                                                        setCommercialCalcData({
                                                            ...commercialCalcData,
                                                            maximumDemand: value
                                                        });
                                                    }
                                                }
                                            }}
                                            inputProps={{
                                                maxLength: 10,
                                            }}
                                            value={commercialCalcData.maximumDemand}
                                            placeholder="Maximum Demand (Optional)"
                                            onKeyDown={(evt: any) => inputPreventDefault(evt, false)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3}>
                                    <FormControl variant="filled" className="select-main-theme-wrapper" style={{ width: "270px" }}>
                                        <InputLabel className="select-label">CT Ratio</InputLabel>
                                        <Select
                                            name="cTRatio"
                                            value={commercialCalcData.cTRatio !== "" ? commercialCalcData.cTRatio : "Select CT Ratio "}
                                            onChange={onChange}
                                            className="select-main-theme">
                                            <MenuItem value="Select CT Ratio ">-- Select CT Ratio  --</MenuItem>
                                            {cTRatioType.map((data: any, index: any) => {
                                                return (<MenuItem key={index} value={data}> {data} </MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className="button-main-theme calculate select-main-theme-wrapper"
                                        onClick={handleCalculate}
                                    >
                                        <div className="wrapper">
                                            <label className="btn-title">Calculate</label>
                                            <label className="right-arrow">
                                                <img src={ArrowRight} alt="right-arrow" />
                                            </label>
                                        </div>
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalculatorBannerCommercial;