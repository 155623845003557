import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from 'aws-amplify';
import { getNewsAndEvent } from '../../graphql/queries'
import { Grid, Box, Chip, CircularProgress } from "@material-ui/core";
import Right from "../../images/icon-right.png";
import { FiCard, FiCardActions, FiCardMedia } from "./FullImageCard";
import './NewsEventsDetail.scss';
import './NewsEventList.scss';

function NewsEventsDetail(props: any) {

  const newsList = props.location.state
  let history = useHistory();

  const [newsDetail, setNewsDetail] = useState({} as any)
  const [isLoading, setIsLoading] = useState(false)
  const bucketURL = "https://d12o165ilpw6mo.cloudfront.net/public/"

  const getNewsDetails = async () => {
    try {
      const id = props.match.params.id
      const res: any = await API.graphql(graphqlOperation(getNewsAndEvent, { newsAndEventId: id }))
      const data = res.data.getNewsAndEvent
      return data
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getNewsDetails().then((res) => {
      setNewsDetail(res)
      setIsLoading(false)
    })
  }, [props])

  const getDaysNum = (createdAt: string) => {
    var newsDate = new Date(createdAt)
    var today = new Date()
    var diff_in_time = today.getTime() - newsDate.getTime();
    var diff_in_days = diff_in_time / (1000 * 3600 * 24);
    return diff_in_days
  }

  const getRandomNews = () => {
    let randomNews: any = []
    newsList.map((news: any) => {
      if (news.newsAndEventId !== props.match.params.id) {
        randomNews.push(news)
      }
    })
    return randomNews
  }

  const handleViewNewsDetails = (data: any) => {
    history.push({
      pathname: `/news-events/${data.newsAndEventId}`,
      state: newsList
    })
    window.scrollTo(0, 0);
  }

  const handleViewNewsList = () => {
    history.push({
      pathname: `/news-events`
    })
    window.scrollTo(0, 0);
  }

  let description = newsDetail?.description?.replace(/(?:\r\n|\r|\n)/g, "<br>");

  return (
    <div className="news-events-details-description">
      <div className="news-events-detail-description-container" >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <div className="news-events-detail-description">
            <label className="title">{newsDetail.title}<br />
              <Chip label={getDaysNum(newsDetail.createdAt).toFixed(0) + " Days Ago"} /></label>
            <img
              src={bucketURL + newsDetail.imageLink}
              className="news-event-image"
              alt="news"
            />
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <br />
          </div>
        )}

        <p style={{ backgroundColor: "#1DA5FF", height: "30px" }}>
          <span className="mini-bar-news-events">More for you</span>
          <span className="mini-bar-news-events" style={{ float: "right" }}><a style={{ color: "white", textDecoration: "none" }} onClick={handleViewNewsList}> More News & Events </a><img src={Right} alt="right" /></span>
        </p>


        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          {getRandomNews().slice(0, 3).map((news: any, index: any) => {
            const imageDisplay = bucketURL + news.imageLink
            var diff_in_days = getDaysNum(news.createdAt)
            return (
              <Grid item xs={12} sm={9} md={6} lg={4} key={index}>
                <Box component="span" >
                  <a onClick={() => handleViewNewsDetails(news)} style={{ textDecoration: "none", cursor: "pointer" }}>
                    <FiCard className="news-events-detail-size">
                      <FiCardMedia image={imageDisplay} />
                      <FiCardActions>
                        <Chip className="news-events-detail-picture-label" label={diff_in_days.toFixed(0) + " Days Ago"} />
                      </FiCardActions>
                    </FiCard>
                  </a>
                  <span className="news-events-detail-title">{news.title}</span><br />
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

export default NewsEventsDetail;